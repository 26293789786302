import { ProposalStatuses } from '@raised/domain';
import { ColorsModule } from '@raised/ui-kit';
import { IconArrowsDiff, IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import { ReactNode } from 'react';

const { Colors } = ColorsModule;

export const PROPOSAL_STATUS_LABELS: { [key in ProposalStatuses]: { text: string; icon: ReactNode; } } = {
  [ProposalStatuses.ACCEPTED]: {
    text: 'Proposal accepted',
    icon: <IconCircleCheck size={16} color={Colors.green} />,
  },
  [ProposalStatuses.DECLINED]: {
    text: 'Proposal declined',
    icon: <IconCircleX size={16} color={Colors.red} />,
  },
  [ProposalStatuses.PENDING]: {
    text: 'Pending',
    icon: <IconArrowsDiff size={16} />,
  },
};
