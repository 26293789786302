import { Match, User } from '@raised/domain';
import { useGetLogMessagesByMatchQuery } from 'components/log-messages/storage/logMessageApi';
import { useGetPaymentsByMatchQuery } from 'components/payments/storage/paymentsApi';
import { PROPOSAL_ROUTES } from 'components/proposal/proposal-routes';
import { useNavigate } from 'react-router-dom';
import { LayoutModule } from '@raised/ui-kit';
import { ExpertTabs } from './tabs/expert-tabs';
import styles from './ExpertMatchInfoComponent.module.css';
import InfoTab from './tabs/info-tab/InfoTab';
import ProposalsTab from './tabs/proposals-tab/ProposalsTab';
import PaymentsTab from './tabs/payments-tab/PaymentsTab';
import LogsTab from './tabs/logs-tab/LogsTab';

const { Space, Tabs } = LayoutModule;

interface ExpertProjectMatchInfoProps {
  projectMatch: Match.Public;
  author?: User.Public;
}

export default function ExpertProjectMatchInfo({
  author,
  projectMatch,
}: ExpertProjectMatchInfoProps) {
  const navigate = useNavigate();

  const {
    data: logMessages, refetch: refetchLogMessages, isFetching: isLogMessagesFetching, isLoading: isLogMessagesLoading,
  } = useGetLogMessagesByMatchQuery({ matchId: projectMatch?.id as string }, { skip: !projectMatch });
  const { data: payments, isLoading: isPaymentsLoading } = useGetPaymentsByMatchQuery({ matchId: projectMatch?.id || '' }, { skip: !projectMatch });

  const onEditProposal = (proposalId: string) => navigate(PROPOSAL_ROUTES.EDIT(proposalId));
  const onTabChange = (tab: ExpertTabs) => {
    if (tab === ExpertTabs.LOG) {
      refetchLogMessages();
    }
  };

  return (
    <Space className={styles.container} direction="vertical" style={{ width: '100%' }}>
      <Tabs
        style={{ width: '100%' }}
        onChange={(tab) => onTabChange(tab as ExpertTabs)}
        items={[
          {
            label: 'Client',
            key: ExpertTabs.INFO,
            children: <InfoTab author={author} />,
          },
          {
            label: 'Proposals',
            key: ExpertTabs.PROPOSAL,
            children: <ProposalsTab match={projectMatch} onEditProposal={onEditProposal} />,
          },
          {
            label: 'Payments',
            key: ExpertTabs.PAYMENT,
            children: <PaymentsTab isLoading={isPaymentsLoading} payments={payments || []} />,
          },
          {
            label: 'Log',
            key: ExpertTabs.LOG,
            children: <LogsTab isLoading={isLogMessagesLoading || isLogMessagesFetching} logMessages={logMessages || []} />,
          },
        ]}
      />
    </Space>
  );
}
