import { Chat, Message, MessageTypes } from '@raised/domain';
import { rootApi } from 'api/rootApi';
import { GetMessagesParamsType } from 'components/messages/storage/types';

export const messageApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    createMessage: build.mutation<Message.Public, Message.Create & { fromDate?: string, fromId?: string }>({
      invalidatesTags: ['NEW_MESSAGE'],
      query: ({ fromDate, fromId, ...body }) => ({
        url: '/message',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (arg, api) => {
        if (arg.fromDate && arg.fromId) {
          const args = {
            chatId: arg.chatId,
            fromDate: arg.fromDate,
          };

          const newMessage: Message.Public = {
            ...arg,
            fromId: arg.fromId,
            createdAt: new Date().toISOString(),
            id: Math.floor(Math.random() * 15).toString(),
            isPending: true,
            viewedBy: [],
            type: MessageTypes.CHAT,
          };

          const patchResult = api.dispatch(messageApi.util.updateQueryData('getNewMessages', args, (draft) => {
            draft.unshift(newMessage);
          }));

          try {
            await api.queryFulfilled;
          } catch {
            patchResult.undo();
          }
        }
      },
    }),
    getMessagesByChat: build.query<Message.Public[], GetMessagesParamsType>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'MESSAGE', id }) as const), 'MESSAGE'] : ['MESSAGE']),
      query: ({ id, ...params }) => ({
        url: `/message/chat/${id}`,
        params,
      }),
    }),
    getTotalMessagesByChat: build.query<number, { id: string }>({
      providesTags: ['MESSAGE_COUNT'],
      query: ({ id }) => ({
        url: `/message/total/chat/${id}`,
      }),
    }),
    viewMessages: build.mutation<void, { messageIds: string[] }>({
      invalidatesTags: ['NEW_MESSAGES_COUNT'],
      query: (body) => ({
        url: '/message/view',
        method: 'PATCH',
        body,
      }),
    }),
    viewAllMessagesByChat: build.mutation<void, { chatId: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'NEW_MESSAGES_COUNT', id: args.chatId }],
      query: ({ chatId }) => ({
        url: `/message/view/chat/${chatId}`,
        method: 'PATCH',
      }),
    }),
    getNewMessages: build.query<Message.Public[], { chatId: string, fromDate: string }>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'NEW_MESSAGE', id }) as const), 'NEW_MESSAGE'] : ['NEW_MESSAGE']),
      query: ({ chatId, fromDate }) => ({
        url: `/message/new/chat/${chatId}`,
        params: {
          fromDate,
        },
      }),
      merge: (currentCacheData, responseData) => {
        const pendingMessages = currentCacheData.filter((message) => {
          const { isPending } = message;
          const isAlreadySend = responseData.findIndex((responseMessage) => responseMessage.text === message.text) !== -1;

          return isPending && !isAlreadySend;
        });

        return [...pendingMessages, ...responseData];
      },
    }),
    getLastViewedByChat: build.query<Message.Public[], { chatId: string }>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'MESSAGE', id }) as const), 'MESSAGE'] : ['MESSAGE']),
      query: ({ chatId }) => ({
        url: `message/last/chat/${chatId}`,
      }),
    }),
    getChatById: build.query<Chat.Public, { chatId: string }>({
      providesTags: (response) => (response ? [{ type: 'CHAT', id: response.id }] : ['CHAT']),
      query: ({ chatId }) => ({
        url: `/chat/${chatId}`,
      }),
    }),
    getAllMatchChats: build.query<Chat.Public[], void>({
      providesTags: (res) => (res?.length ? ([...res.map(({ id }) => ({ type: 'CHAT', id }) as const), 'CHAT']) : ['CHAT']),
      query: () => ({
        url: '/chat/match',
      }),
    }),
    getChatsByProject: build.query<Chat.Public[], { projectId: string }>({
      providesTags: (res) => (res?.length ? ([...res.map(({ id }) => ({ type: 'CHAT', id }) as const), 'CHAT']) : ['CHAT']),
      query: ({ projectId }) => ({
        url: `/chat/project/${projectId}`,
      }),
    }),
    getSupportChat: build.query<Chat.Public, void>({
      providesTags: (res) => (res ? [{ type: 'CHAT', id: res.id }] : ['CHAT']),
      query: () => ({
        url: '/chat/support/user',
      }),
    }),
    getAllSupportChats: build.query<Chat.Public[], void>({
      providesTags: (res) => (res?.length ? [...res.map(({ id }) => ({ type: 'CHAT', id }) as const), 'CHAT'] : ['CHAT']),
      query: () => ({
        url: '/chat/support/all',
      }),
    }),
    viewNotifications: build.mutation<void, { notificationIds: string[] }>({
      invalidatesTags: (_r, _e, args) => [...args.notificationIds.map((id) => ({ id, type: 'NOTIFICATION' }) as const), 'NEW_MESSAGES_COUNT'],
      query: (body) => ({
        url: '/notifications/view',
        method: 'POST',
        body,
      }),
    }),
    viewAllNotifications: build.mutation<void, void>({
      invalidatesTags: ['NOTIFICATION', 'NEW_MESSAGES_COUNT'],
      query: () => ({
        url: '/notifications/view/all',
        method: 'POST',
      }),
    }),
    getNewMessagesCountByChat: build.query<number, { chatId: string }>({
      providesTags: (_r, _e, args) => [{ type: 'NEW_MESSAGES_COUNT', id: args.chatId }],
      query: ({ chatId }) => ({
        url: `/message/new/${chatId}`,
      }),
    }),
  }),
});

export const {
  useCreateMessageMutation,
  useGetMessagesByChatQuery,
  useLazyGetMessagesByChatQuery,
  useGetTotalMessagesByChatQuery,
  useViewAllMessagesByChatMutation,
  useGetLastViewedByChatQuery,
  useViewMessagesMutation,
  useGetNewMessagesQuery,
  useGetChatByIdQuery,
  useGetAllMatchChatsQuery,
  useLazyGetAllMatchChatsQuery,
  useGetChatsByProjectQuery,
  useGetSupportChatQuery,
  useGetAllSupportChatsQuery,
  useGetNewMessagesCountByChatQuery,
} = messageApi;
