import styles from './BackgroundLogo.module.css';

export default function BackgroundLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="815"
      height="816"
      fill="none"
      viewBox="0 0 815 816"
      className={styles.bg_logo}
    >
      <rect
        width="671.074"
        height="671.074"
        x="650.812"
        y="0.824"
        fill="#fff"
        fillOpacity="0.2"
        rx="235"
        transform="rotate(75.885 650.812 .824)"
      />
      <rect
        width="309.327"
        height="309.327"
        x="519.512"
        y="220.346"
        fill="#fff"
        fillOpacity="0.2"
        rx="99"
        transform="rotate(75.885 519.512 220.346)"
      />
    </svg>
  );
}
