import { Typography } from 'antd';
import cn from 'classnames';
import { ComponentProps } from 'react';
import styles from './Title.module.css';
import typographyStyles from '../typography.module.css';

interface TitleProps extends ComponentProps<typeof Typography.Title> {}

export default function Title({
  level,
  className,
  ...props
}: TitleProps) {
  return (
    <Typography.Title
      {...props}
      className={cn(typographyStyles.typography, {
        [styles.h1]: level === 1,
        [styles.h2]: level === 2,
        [styles.h3]: level === 3,
        [styles.h4]: level === 4,
        [styles.h5]: level === 5,
      }, className)}
    />
  );
}
