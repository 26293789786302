import { Project, Template, User } from '@raised/domain';
import { FeedbackModule, TypographyModule } from '@raised/ui-kit';
import { PROJECT_CATEGORIES_VALUES } from 'components/project/constants/project-categories';
import RecommendedTemplateItem from './recommended-template-item/RecommendedTemplateItem';
import styles from './RecommendedTemplates.module.css';

const { Title, Text } = TypographyModule;
const { Spin } = FeedbackModule;

interface RecommendedTemplatesProps {
  templates: Template.Public[];
  isLoading: boolean;
  userCategories: User.Public['projectCategories'];
  onAddClick: (templateId: string) => void;
}

export default function RecommendedTemplates({
  templates,
  isLoading,
  userCategories,
  onAddClick,
}: RecommendedTemplatesProps) {
  const selectedCategories = userCategories && Object.keys(userCategories).filter((cat) => cat in PROJECT_CATEGORIES_VALUES).map((cat) => PROJECT_CATEGORIES_VALUES[cat as keyof Project.Categories].label);

  return (
    <div className={styles.container}>
      <Title level={1}>Project Templates recommended for you</Title>
      <Text type="secondary" className={styles.description}>
        {selectedCategories ? `You chose categories ${selectedCategories.join(', ')}. You can chose more and find more project templates` : 'You haven`t selected any categories'}
      </Text>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          {templates.map((template) => (
            <RecommendedTemplateItem
              onAddClick={() => onAddClick(template.id)}
              template={template}
              key={template.id}
            />
          ))}
        </>
      )}
    </div>
  );
}
