import { LoadingOutlined } from '@ant-design/icons';
import { ColorsModule } from '@raised/ui-kit';
import { IconCheck } from '@tabler/icons-react';
import { Spin } from 'antd';

const { Colors } = ColorsModule;

interface ChatMessageStatusIconProps {
  isPending?: boolean;
  isViewed: boolean;
}

export default function ChatMessageStatusIcon({
  isPending,
  isViewed,
}: ChatMessageStatusIconProps) {
  return (
    <>
      {isPending && (
        <Spin indicator={<LoadingOutlined spin style={{ fontSize: 12 }} />} />
      )}
      {!isPending && !isViewed && (
        <IconCheck color={Colors.black} opacity={0.35} size={16} />
      )}
      {isViewed && (
        <IconCheck color={Colors.accent} size={16} />
      )}
    </>
  );
}
