import { LayoutModule, TypographyModule } from '@raised/ui-kit';

const { Space } = LayoutModule;
const { Title, Text } = TypographyModule;

interface TimelineItemProps {
  title: string;
  description: string;
}

export default function TimelineItem({
  title,
  description,
}: TimelineItemProps) {
  return (
    <Space direction="vertical" size={4}>
      <Title level={4}>{title}</Title>
      <Text>{description}</Text>
    </Space>
  );
}
