import { Project } from '@raised/domain';
import { Typography } from 'antd';
import Table from 'components/layout/components/table/Table';
import { useMemo } from 'react';
import { AssignProjectsTableRow, getAssignProjectsTableConfig } from './assignProjectTableConfig';

function ExpandedRowContent({ description }: AssignProjectsTableRow) {
  return <Typography.Text>{description}</Typography.Text>;
}

interface AssignProjectTableProps {
  isLoading: boolean;
  projects?: Project.Public[];
  getRowLink: (id: string) => string,
  getUserLink: (userId: string) => string,
  getOrganisationLink: (orgId: string) => string,
}

export default function AssignProjectTable({
  isLoading,
  projects,
  getRowLink,
  getUserLink,
  getOrganisationLink,
}: AssignProjectTableProps) {
  const tableData = useMemo(() => projects?.map((project) => ({
    key: project.id,
    createdAt: `${project.createdAt}`,
    title: project.title,
    description: project.description,
    clientId: project.userId,
    clientName: `${project.user?.firstName} ${project.user?.lastName}`,
    isGhost: !!project.user?.isGhost,
    rowLink: getRowLink(project.id),
    userLink: getUserLink(project.userId),
    organization: project.organisation?.companyName || '-',
    organizationLink: project.organisation?.id ? getOrganisationLink(project.organisation.id) : undefined,
  })), [projects]);

  const columns = getAssignProjectsTableConfig();

  return (
    <Table
      tableName="assignProjectTable"
      dataSource={tableData}
      columns={columns}
      expandable={{
        expandedRowRender: ExpandedRowContent,
      }}
      loading={isLoading}
    />
  );
}
