import { MatchStatuses, ProjectActivityTypes } from '@raised/domain';
import { ClientMatchOverviewTabs } from 'components/match/types/client-match-overview-tabs';
import { useGetMatchesByProjectQuery } from 'components/match/storage/matchApi';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSupportChatQuery } from 'components/messages/storage/messageApi';
import CandidateModal from 'components/project/components/candidate-modal/CandidateModal';
import ClientMatchesTable from './client-matches-table/ClientMatchesTable';

interface ClientProjectMatchInfoProps {
  onSubmitMatch: (status: MatchStatuses, text: string, matchId: string, chatId?: string) => void;
  onMatchOverviewClick: (matchId: string, tab?: ClientMatchOverviewTabs) => void;
  getMatchLink: (matchId: string) => string;
  isUpdateMatchLoading: boolean;
  projectTitle: string;
  projectActivityType: ProjectActivityTypes;
}

export default function ClientProjectMantchInfo({
  onSubmitMatch,
  onMatchOverviewClick,
  isUpdateMatchLoading,
  projectTitle,
  getMatchLink,
  projectActivityType,
}: ClientProjectMatchInfoProps) {
  const { id } = useParams();
  const { data: matches, isLoading: isMatchesLoading } = useGetMatchesByProjectQuery({ id: id as string });
  const { data: supportChat } = useGetSupportChatQuery();

  const [matchId, setMatchId] = useState('');
  const [decision, setDecision] = useState<MatchStatuses>(MatchStatuses.INVITED_TO_INTERVIEW);

  const onDeclineClick = useCallback((matchid: string) => {
    setMatchId(matchid);
    setDecision(MatchStatuses.DECLINED_BY_CLIENT);
  }, []);
  const onInviteToInterviewClick = useCallback((matchid: string) => {
    onSubmitMatch(MatchStatuses.INVITED_TO_INTERVIEW, '', matchid);
  }, []);
  const onInterstedClick = useCallback((matchid: string) => {
    onSubmitMatch(MatchStatuses.INTERSTED_BY_CLIENT, '', matchid);
  }, []);
  const onModalSubmit = useCallback((msg: string) => {
    const selectedMatch = matches?.find((match) => match.id === matchId);

    const message = decision === MatchStatuses.DECLINED_BY_CLIENT
      ? `Declining ${selectedMatch?.user?.firstName} ${selectedMatch?.user?.lastName} for project ${projectTitle}: ${msg}`
      : msg;
    const chatId = decision === MatchStatuses.DECLINED_BY_CLIENT ? supportChat?.id : selectedMatch?.chat?.[0]?.id;

    onSubmitMatch(decision, message, matchId, chatId);
    setMatchId('');
  }, [decision, matchId]);
  const onModalClose = useCallback(() => {
    setMatchId('');
  }, []);

  return (
    <>
      <ClientMatchesTable
        matches={matches || []}
        isLoading={isMatchesLoading || isUpdateMatchLoading}
        onDeclineClick={onDeclineClick}
        onInviteToInterviewClick={onInviteToInterviewClick}
        onInterstedClick={onInterstedClick}
        onMatchOverviewClick={onMatchOverviewClick}
        getMatchLink={getMatchLink}
        projectActivityType={projectActivityType}
      />
      <CandidateModal
        isVisible={!!matchId}
        onClose={onModalClose}
        onSubmit={onModalSubmit}
        decision={decision}
      />
    </>
  );
}
