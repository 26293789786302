import { removeToken } from 'api/helpers';
import { rootApi } from 'api/rootApi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AUTH_ROUTES } from '../auth-routes';

export default function useLogout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = async () => {
    removeToken();
    dispatch(rootApi.util.resetApiState());
    navigate(AUTH_ROUTES.LOGIN());
  };

  return {
    logout,
  };
}
