import {
  useMemo, useEffect, useState, MouseEvent,
} from 'react';
import cn from 'classnames';
import { zodResolver } from '@hookform/resolvers/zod';
import { User, UserRole, UserSchemas } from '@raised/domain';
import { ButtonsModule, LayoutModule, TypographyModule } from '@raised/ui-kit';
import { useForm } from 'react-hook-form';
import { scrollToError } from 'utils/forms';
import MainInfo from './steps/main-info/MainInfo';
import CareerHistoryStep from './steps/career-history/CareerHistoryStep';

const { Title } = TypographyModule;
const { Tabs } = LayoutModule;
const { Button } = ButtonsModule;

enum Steps {
  MAIN_INFO = 'MAIN_INFO',
  CAREER_INFO = 'CAREER_INFO',
}
const getSchemas = (isGhost: boolean) => ({
  [Steps.MAIN_INFO]: isGhost ? UserSchemas.createGhostMainInfoSchema : UserSchemas.createMainInfoSchema,
  [Steps.CAREER_INFO]: isGhost ? UserSchemas.createGhostMainInfoSchema : UserSchemas.createUserSchema,
});

interface CreateUserFormProps {
  onSubmit: (values: User.Create) => void;
  title?: string;
  isLoading: boolean;
  isAdmin?: boolean;
  isGhost?: boolean;
  isUploadAvatar: boolean;
}

export default function CreateUserForm({
  onSubmit,
  isLoading,
  title = 'Create Profile',
  isAdmin = false,
  isGhost = false,
  isUploadAvatar,
}: CreateUserFormProps) {
  const schemas = getSchemas(isGhost);
  const [step, setStep] = useState(Steps.MAIN_INFO);
  const {
    control, handleSubmit, watch, resetField, setValue, trigger,
  } = useForm<User.Create>({
    shouldUnregister: false,
    resolver: zodResolver(schemas[step]),
  });
  const role = watch('role', UserRole.CLIENT);

  useEffect(() => {
    resetField('biography');
    resetField('externalLink');
    resetField('calendlyLink');
    resetField('rate');
    resetField('tags');
    resetField('fulltimeRoles');
    resetField('parttimeRoles');
    resetField('testimonials');
    resetField('headline');

    if (role === UserRole.EXPERT) {
      setValue('testimonials', []);
      setValue('tags', {});
    }
  }, [role]);

  const tabs = useMemo(() => {
    if (role === UserRole.EXPERT) {
      return [
        {
          label: 'Main Info',
          key: Steps.MAIN_INFO,
          children: <MainInfo isGhost={isGhost} isUploadAvatar={isUploadAvatar} setValue={setValue} isAdmin={isAdmin} role={role} control={control} />,
        },
        {
          label: 'Career History',
          key: Steps.CAREER_INFO,
          children: <CareerHistoryStep control={control} />,
        },
      ];
    }

    return [
      {
        label: 'Main Info',
        key: Steps.MAIN_INFO,
        children: <MainInfo isGhost={isGhost} isUploadAvatar={isUploadAvatar} setValue={setValue} isAdmin={isAdmin} role={role} control={control} />,
      },
    ];
  }, [role]);
  const handleButtonClick = async (event: MouseEvent) => {
    if (role === UserRole.CLIENT) {
      return;
    }

    if (step === Steps.MAIN_INFO) {
      event.preventDefault();
      const isValid = await trigger();

      if (isValid) {
        setStep(Steps.CAREER_INFO);
      } else {
        scrollToError();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, scrollToError)} className={cn('form_container')}>
      <Title level={1} style={{ textAlign: 'center' }}>{title}</Title>
      <Tabs
        activeKey={step}
        onChange={(key) => setStep(key as Steps)}
        items={tabs}
      />
      <Button loading={isLoading} disabled={isLoading} onClick={handleButtonClick} type="primary" htmlType="submit">
        {(step === Steps.CAREER_INFO || role === UserRole.CLIENT) ? 'Create' : 'Next'}
      </Button>
    </form>
  );
}
