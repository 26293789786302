import { Match } from '@raised/domain';
import Table from 'components/layout/components/table/Table';
import { useMemo } from 'react';
import { getCreateProposalsTableConfig } from './createProposalsTableConfig';

interface CreateProposalsTabProps {
  onCreateProposalClick: (matchId: string) => void;
  matches: Match.Public[];
  getUserLink: (userId: string) => string;
}

export default function CreateProposalsTab({
  onCreateProposalClick,
  matches,
  getUserLink,
}: CreateProposalsTabProps) {
  const columns = useMemo(() => getCreateProposalsTableConfig({
    onCreateProposalClick,
  }), []);
  const tableData = useMemo(() => matches.map((match) => ({
    key: match.id,
    expertName: `${match.user?.firstName} ${match.user?.lastName}`,
    clientName: `${match.project?.user?.firstName} ${match.project?.user?.lastName}`,
    status: match.status,
    createdAt: match.createdAt,
    expertLink: getUserLink(match.userId),
    clientLink: getUserLink(match.project?.userId || ''),
  })), [matches]);

  return (
    <Table
      tableName="createProposals"
      columns={columns}
      dataSource={tableData}
    />
  );
}
