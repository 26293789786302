import { TypographyModule } from '@raised/ui-kit';
import { ComponentProps, ReactNode } from 'react';
import { linkRegExp } from 'utils/regExps';

const { Link, Text } = TypographyModule;

interface UserLinkProps extends ComponentProps<typeof Link> {
  children?: ReactNode;
  href: string;
}

export default function UserLink({
  children,
  href,
  ...rest
}: UserLinkProps) {
  if (!linkRegExp.test(href)) {
    return <Text>{href}</Text>;
  }

  const formattedLink = href.startsWith('http://') || href.startsWith('https://') ? href : `https://${href}`;

  return (
    <Link
      target="_blank"
      rel="noreferrer"
      {...rest}
      href={formattedLink}
    >
      {children}
    </Link>
  );
}
