import { UserRole } from '@raised/domain';
import { IconLayoutSidebarLeftCollapse, IconLayoutSidebarLeftExpand } from '@tabler/icons-react';
import { Button, Divider, Layout as AntLayout } from 'antd';
import classNames from 'classnames';
import useLogout from 'components/auth/hooks/useLogout';
import { getInitialRoute } from 'components/auth/utils';
import Avatar from 'components/layout/components/avatar/Avatar';
import Logo from 'components/layout/components/logo/Logo';
import MobileMenu from 'components/layout/components/mobile-menu/MobileMenu';
import NotificationsButton from 'components/layout/components/notifications-button/NotificationsButton';
import BottomSideMenu from 'components/layout/components/side-menu/BottomSideMenu';
import SideMenu from 'components/layout/components/side-menu/SideMenu';
import NotificationCenter from 'components/notifications/components/notification-center/NotificationCenter';
import { PAYMENTS_ROUTES } from 'components/payments/payments-routes';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { USER_ROUTES } from 'components/user/user-routes';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './MainLayout.module.css';

const {
  Header, Content, Sider,
} = AntLayout;

interface MainLayoutProps {
  withoutSpacing?: boolean;
}

export default function MainLayout({ withoutSpacing = false }: MainLayoutProps) {
  const navigate = useNavigate();
  const { logout } = useLogout();
  const [collapsed, setCollapsed] = useState(false);
  const { data, isSuccess } = useGetProfileQuery();
  const [isNotificationsOpened, setIsNotificationsOpened] = useState(false);

  const onGoToProfileClick = () => {
    if (!isSuccess) return;

    navigate(USER_ROUTES.PERSONAL_PROFILE());
  };
  const onGoToBillingInfoClick = () => {
    if (!data) return;

    if (data.role === UserRole.CLIENT) {
      navigate(PAYMENTS_ROUTES.CLIENT_BILLING_INFO());
    }

    if (data.role === UserRole.EXPERT) {
      navigate(PAYMENTS_ROUTES.EXPERT_BILLING_INFO());
    }
  };
  const onLogoClick = () => {
    if (!data) return;

    navigate(getInitialRoute(data.role));
  };
  const onNotificationsClose = () => {
    setIsNotificationsOpened(false);
  };
  const onNotificationsOpen = () => {
    setIsNotificationsOpened(true);
  };
  const toggleNotifications = () => {
    if (isNotificationsOpened) {
      onNotificationsClose();
    } else {
      onNotificationsOpen();
    }
  };
  const toggleSider = () => {
    setCollapsed(!collapsed);
  };

  return (
    <AntLayout className={styles.wrapper}>
      <Header className={classNames(styles.header, {
        [styles.header_light]: true,
      })}
      >
        <Logo onClick={onLogoClick} />
        <div className={styles.headerRight}>
          {isSuccess && (
            <>
              <NotificationsButton
                profileId={data.id}
                onNotificationsClick={toggleNotifications}
                isNotificationsOpened={isNotificationsOpened}
              />
              <Avatar
                onGoToProfileClick={onGoToProfileClick}
                onGoToBillingInfoClick={onGoToBillingInfoClick}
                role={data.role}
                avatarPath={data.avatar}
                onLogout={logout}
              />
              <MobileMenu
                role={data.role}
              />
            </>
          )}
        </div>
      </Header>
      <AntLayout>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          trigger={null}
          width={210}
          className={classNames(styles.sider, {
            [styles.sider_light]: true,
          })}
        >
          <div className={styles.sider_content}>
            <SideMenu
              isLogin={isSuccess}
              profile={data}
              onNotificationsClick={onNotificationsOpen}
            />
            <div className={styles.sider_bottom}>
              {data && data?.role !== UserRole.ADMIN ? (
                <BottomSideMenu />
              ) : null}
              <Divider style={{ margin: '4px 0 6px' }} />
              <Button
                type="link"
                className={styles.collapse_button}
                onClick={toggleSider}
                icon={collapsed ? <IconLayoutSidebarLeftExpand stroke={1.5} color="#000" size={24} /> : <IconLayoutSidebarLeftCollapse stroke={1.5} color="#000" size={24} />}
              />
            </div>
            {/* {!collapsed && ( */}
            {/*   <ThemeSwitcher */}
            {/*     value={themeName} */}
            {/*     onChangeTheme={handleThemeChange} */}
            {/*   /> */}
            {/* )} */}
          </div>
        </Sider>
        <AntLayout className={styles.contentWrapper}>
          <Content>
            <div className={classNames(styles.content, {
              [styles.content_padding]: !withoutSpacing,
            })}
            >
              <Outlet />
            </div>
          </Content>
        </AntLayout>
      </AntLayout>
      {data ? (
        <NotificationCenter
          isVisible={isNotificationsOpened}
          onClose={onNotificationsClose}
          profile={data}
        />
      ) : null}
    </AntLayout>
  );
}
