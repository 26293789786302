import cn from 'classnames';
import { zodResolver } from '@hookform/resolvers/zod';
import { SuccessStory, SuccessStorySchemas } from '@raised/domain';
import { useForm } from 'react-hook-form';
import { renderFormConfig } from 'utils/forms';
import { Ref } from 'react';
import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import styles from './successStoryForm.module.css';
import { getCreateSuccessStoryFormConfigPart1, getCreateSuccessStoryFormConfigPart2 } from './successStoryFormConfig';
import FilesInput from './files-input/FilesInput';

const { Button } = ButtonsModule;
const { Title } = TypographyModule;

interface EditSuccessStoryFormProps {
  onSubmit: (values: SuccessStory.EditForm) => void;
  defaultValues?: Partial<SuccessStory.Create>;
  formRef?: Ref<HTMLFormElement>;
  isControls?: boolean;
  isLoading?: boolean;
  title?: string;
  onCancel?: () => void;
}

export default function EditSuccessStoryForm({
  onSubmit,
  defaultValues,
  formRef,
  isControls,
  isLoading,
  onCancel,
  title,
}: EditSuccessStoryFormProps) {
  const { handleSubmit, control, reset } = useForm<SuccessStory.EditForm>({
    defaultValues,
    resolver: zodResolver(SuccessStorySchemas.editSuccessStorySchema),
  });

  const formConfigPart1 = getCreateSuccessStoryFormConfigPart1(control);
  const formConfigPart2 = getCreateSuccessStoryFormConfigPart2(control);

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
      className={cn('form_container', styles.container)}
      onReset={() => reset()}
    >
      {title && <Title style={{ textAlign: 'center' }} level={1}>{title}</Title>}
      {renderFormConfig(formConfigPart1)}
      <FilesInput control={control} />
      {renderFormConfig(formConfigPart2)}
      {isControls ? (
        <div className={styles.buttons}>
          <Button loading={isLoading} disabled={isLoading} type="primary" htmlType="submit" className={styles.submit_btn}>Edit</Button>
          <Button loading={isLoading} disabled={isLoading} danger onClick={onCancel}>Cancel</Button>
        </div>
      ) : null}
    </form>
  );
}
