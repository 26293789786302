import { FeedbackModule } from '@raised/ui-kit';
import { ReactNode } from 'react';
import styles from './PublicExpertProfileContent.module.css';

const { Spin } = FeedbackModule;

interface PublicExpertProfileContentProps {
  children: ReactNode;
  isLoading: boolean;
}

export default function PublicExpertProfileContent({
  children,
  isLoading,
}: PublicExpertProfileContentProps) {
  return (
    <main className={styles.container}>
      {isLoading ? <Spin /> : children}
    </main>
  );
}
