import { User } from '@raised/domain';
import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import useLogin from 'components/auth/hooks/useLogin';
import { useEffect } from 'react';
import { useActivateGhostMutation } from '../storage/userApi';

interface UseActivateGhostProps {
  onSuccess: (user: User.Public) => void;
}

export default function useActivateGhost({
  onSuccess,
}: UseActivateGhostProps) {
  const [activate, info] = useActivateGhostMutation();
  const {
    isSuccess, isError, error, originalArgs, isLoading,
  } = info;
  const { login, isLoading: isLoginLoading } = useLogin({
    onSuccess: (user: User.Public) => {
      onSuccess(user);
    },
  });

  useEffect(() => {
    if (!isSuccess || !originalArgs) return;

    message.success('Profile successfully activated');
    login({ email: originalArgs.email, password: originalArgs.password });
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      message.error(getErrorMessage(error));
    }
  }, [isError]);

  return {
    activate,
    ...info,
    isLoading: isLoading || isLoginLoading,
  };
}
