import { Match, UserRole } from '@raised/domain';
import Table from 'components/layout/components/table/Table';
import { useMemo } from 'react';
import { getMatchActionsTableConfig } from './matchActionsTableConfig';

interface MatchActionsTabProps {
  matches: Match.Public[];
  onDeclineClick: (matchId: string, role: UserRole) => void;
  onRestoreClick: (matchId: string) => void;
  onFinishMatch: (matchId: string) => void;
  onAcceptClick: (matchId: string, role: UserRole) => void;
  onAcceptAssignClick: (matchId: string) => void;
  onInviteToInterviewClick: (matchId: string) => void;
  getUserLink: (userId: string) => string;
  isLoading: boolean;
}
export default function MatchActionsTab({
  matches,
  onDeclineClick,
  onRestoreClick,
  getUserLink,
  onFinishMatch,
  onAcceptClick,
  onAcceptAssignClick,
  onInviteToInterviewClick,
  isLoading,
}: MatchActionsTabProps) {
  const columns = useMemo(() => getMatchActionsTableConfig({
    onRestoreClick,
    onDeclineClick,
    onFinishMatch,
    onAcceptClick,
    onAcceptAssignClick,
    onInviteToInterviewClick,
    isLoading,
  }), [isLoading, matches]);

  const tableData = useMemo(() => matches.map((match) => ({
    key: match.id,
    expertId: match.userId,
    expertName: `${match.user?.firstName} ${match.user?.lastName}`,
    clientName: `${match.project?.user?.firstName} ${match.project?.user?.lastName}`,
    status: match.status,
    isRestore: !!match.oldStatus,
    clientLink: getUserLink(match.project?.userId || ''),
    expertLink: getUserLink(match.userId),
  })), [matches]);

  return (
    <Table
      tableName="matchActions"
      columns={columns}
      dataSource={tableData}
    />
  );
}
