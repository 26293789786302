import { z } from 'zod';
import { CreateForm, EditForm } from './success-story';

const fileSchema = z.object({
  filePath: z.custom<File>(),
  description: z.string().optional(),
}); 

export const createSuccessStorySchema: z.ZodType<CreateForm> = z.object({
  companyName: z.string(),
  companyDescription: z.string(),
  successStoryName: z.string(),
  brief: z.string(),
  challenge: z.string(),
  solutions: z.string(),
  results: z.string(),
  files: fileSchema.array().optional(),
  clientReview: z.string(),
  clientName: z.string(),
  clientPosition: z.string(),
  usecaseId: z.string(),
  userId: z.string().optional(),
});

export const editSuccessStorySchema: z.ZodType<EditForm> = z.object({
  companyName: z.string(),
  companyDescription: z.string(),
  successStoryName: z.string(),
  brief: z.string(),
  challenge: z.string(),
  solutions: z.string(),
  results: z.string(),
  files: fileSchema.array().optional(),
  clientReview: z.string(),
  clientName: z.string(),
  clientPosition: z.string(),
})
