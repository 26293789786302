import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { Match } from '@raised/domain';
import { useEffect } from 'react';
import { useCreateMatchMutation } from '../storage/matchApi';
import { successApiMessages } from '../storage/successApiMessages';

interface UseCreateMatchProps {
  onSuccess?: (match: Match.Public[]) => void;
  isNotify?: boolean;
}

export default function useCreateMatch({
  onSuccess,
  isNotify = true,
}: UseCreateMatchProps) {
  const [create, data] = useCreateMatchMutation();

  useEffect(() => {
    if (data.isError) {
      message.error(getErrorMessage(data.error));
    }
  }, [data.isError]);

  useEffect(() => {
    if (data.isSuccess && data.originalArgs?.status) {
      if (isNotify) {
        message.success(successApiMessages.CREATE[data.originalArgs.status]);
      }

      if (onSuccess) {
        onSuccess(data.data);
      }
    }
  }, [data.isSuccess]);

  return {
    create,
    ...data,
  };
}
