import { FormsModule } from '@raised/ui-kit';
import { Control } from 'react-hook-form';

const { Input, TextArea } = FormsModule;

export const getCareerInfoFormConfig = (control: Control, prefix: string) => [
  {
    controller: {
      name: `${prefix}.website`,
      control,
      defaultValue: '',
    },
    component: Input,
    label: 'Company website',
    componentProps: {
      placeholder: 'Company website',
    },
  },
  {
    controller: {
      name: `${prefix}.name`,
      control,
      defaultValue: '',
    },
    component: Input,
    label: 'Company name*',
    componentProps: {
      placeholder: 'Company name*',
    },
  },
  {
    controller: {
      name: `${prefix}.position`,
      control,
      defaultValue: '',
    },
    component: Input,
    label: 'Position',
    componentProps: {
      placeholder: 'Position',
    },
  },
  {
    controller: {
      name: `${prefix}.info`,
      control,
      defaultValue: '',
    },
    component: TextArea,
    label: 'Company info (size, industry, description)',
    componentProps: {
      rows: 5,
      showCount: true,
      maxLength: 500,
    },
  },
];
