import { User } from '@raised/domain';
import { FormsModule } from '@raised/ui-kit';
import { Control } from 'react-hook-form';
import { FormConfig } from 'utils/forms';

export const getMainInfoFormConfig = (control: Control<User.Edit>): FormConfig<User.Edit> => [
  {
    controller: {
      name: 'firstName',
      control,
      defaultValue: '',
    },
    component: FormsModule.Input,
    label: 'Name',
    componentProps: {
      placeholder: 'First name',
      'data-testid': 'form_createUser__first_name',
    },
  },
  {
    controller: {
      name: 'lastName',
      control,
      defaultValue: '',
    },
    component: FormsModule.Input,
    label: 'Surname',
    componentProps: {
      placeholder: 'Last name',
      'data-testid': 'form_createUser__last_name',
    },
  },
];

export const getExpertAdditionalFieldsFormConfig = (control: Control<User.Edit>): FormConfig<User.Edit> => [
  {
    controller: {
      name: 'headline',
      control,
    },
    component: FormsModule.TextArea,
    label: 'Headline',
    subLabel: 'Choose a catchy headline for your profile (E.g. First growth manager at Miro)',
    componentProps: {
      rows: 3,
      showCount: true,
      maxLength: 150,
      placeholder: 'Headline',
    },
  },
  {
    controller: {
      name: 'biography',
      control,
    },
    component: FormsModule.TextArea,
    label: 'About You',
    subLabel: 'An impactful ‘About you’ will clearly define the relevant experience, client wins, specialized knowledge, or proven outcomes that make you a true expert',
    componentProps: {
      placeholder: 'Tell about you',
      rows: 5,
      'data-testid': 'form_createUser__biography',
      showCount: true,
      maxLength: 500,
    },
  },
  {
    controller: {
      name: 'externalLink',
      control,
    },
    component: FormsModule.Input,
    label: 'LinkedIn link',
    componentProps: {
      placeholder: 'Paste link here',
      'data-testid': 'form_createUser__external_link',
    },
  },
  {
    controller: {
      name: 'calendlyLink',
      control,
    },
    component: FormsModule.Input,
    label: 'Calendly (or alternative) link (not public)',
    subLabel: 'The client will only see the link after you have approved the project. It will help the client schedule the introductory call faster.',
    componentProps: {
      placeholder: 'Paste link here',
      'data-testid': 'form_createUser__calendly_link',
    },
  },
];
