import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useDeleteProjectMutation } from '../storage/projectApi';
import { successProjectMessages } from '../storage/successProjectMessages';

export default function useDeleteProject() {
  const [deleteProject, data] = useDeleteProjectMutation();

  useEffect(() => {
    if (data.isSuccess) {
      message.success(successProjectMessages.DELETE);
    }
  }, [data.isSuccess]);

  useEffect(() => {
    if (data.isError) {
      message.error(getErrorMessage(data.error));
    }
  }, [data.isError]);

  return {
    deleteProject,
    ...data,
  };
}
