import { Usecase } from '@raised/domain';
import { rootApi } from 'api/rootApi';

export const usecasesApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getPersonalUsecases: build.query<Usecase.Public[], void>({
      providesTags: (res) => (res ? [
        ...res.map((c) => ({ id: c.id, type: 'USE_CASE' }) as const),
        'USE_CASE',
      ] : ['USE_CASE']),
      query: () => ({
        url: '/usecase',
      }),
    }),
    getUserUsecases: build.query<Usecase.Public[], { id: string }>({
      providesTags: (res) => (res ? [
        ...res.map((c) => ({ id: c.id, type: 'USE_CASE' }) as const),
        'USE_CASE',
      ] : ['USE_CASE']),
      query: ({ id }) => ({
        url: `/usecase/user/${id}`,
      }),
    }),
    getAllUsecases: build.query<Usecase.Public[], void>({
      providesTags: (res) => (res ? [
        ...res.map((c) => ({ id: c.id, type: 'USE_CASE' }) as const),
        'USE_CASE',
      ] : ['USE_CASE']),
      query: () => ({
        url: '/usecase/all',
      }),
    }),
    getUsecaseById: build.query<Usecase.Public, { id: string }>({
      providesTags: (res) => (res ? [{ id: res.id, type: 'USE_CASE' } as const] : ['USE_CASE']),
      query: ({ id }) => ({
        url: `/usecase/${id}`,
      }),
    }),
    createUsecase: build.mutation<Usecase.Public, Usecase.Create>({
      invalidatesTags: ['USE_CASE'],
      query: (body) => ({
        url: '/usecase',
        method: 'POST',
        body,
      }),
    }),
    updateUsecase: build.mutation<Usecase.Public, Usecase.Create & { id: string }>({
      invalidatesTags: (_r, _a, args) => [{ id: args.id, type: 'USE_CASE' }],
      query: ({ id, ...body }) => ({
        url: `/usecase/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteUsecase: build.mutation<void, { id: string }>({
      invalidatesTags: ['USE_CASE'],
      query: ({ id }) => ({
        url: `/usecase/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetPersonalUsecasesQuery,
  useGetUserUsecasesQuery,
  useGetUsecaseByIdQuery,
  useCreateUsecaseMutation,
  useUpdateUsecaseMutation,
  useDeleteUsecaseMutation,
  useGetAllUsecasesQuery,
} = usecasesApi;
