import { LogMessage, LogMessageEvents } from '@raised/domain';
import { rootApi } from 'api/rootApi';

export const logMessageApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    createFeedback: build.mutation<LogMessage.Public, LogMessage.Create<LogMessageEvents.FEEDBACK>>({
      invalidatesTags: (res) => (res ? [{ type: 'LOG_MESSAGE', id: res.id }] : ['LOG_MESSAGE']),
      query: (body) => ({
        url: '/log-message',
        method: 'POST',
        body,
      }),
    }),
    getLogMessagesByMatch: build.query<LogMessage.Public[], { matchId: string }>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'LOG_MESSAGE', id }) as const), 'LOG_MESSAGE'] : ['LOG_MESSAGE']),
      query: ({ matchId }) => ({
        url: `/log-message/match/${matchId}`,
      }),
    }),
    getLogMessagesByProject: build.query<LogMessage.Public[], { projectId: string }>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'LOG_MESSAGE', id }) as const), 'LOG_MESSAGE'] : ['LOG_MESSAGE']),
      query: ({ projectId }) => ({
        url: `/log-message/project/${projectId}`,
      }),
    }),
    getAllLogMessages: build.query<LogMessage.Public[], { page: number; per_page: number }>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'LOG_MESSAGE', id }) as const), 'LOG_MESSAGE'] : ['LOG_MESSAGE']),
      query: (params) => ({
        url: '/log-message',
        params,
      }),
    }),
    getLastActinos: build.query<LogMessage.LastActionsResponse[], void>({
      providesTags: (res) => (res?.length ? [...res.map(({ id }) => ({ type: 'ACTION', id }) as const), 'ACTION'] : ['ACTION']),
      query: () => ({
        url: '/log-message/last-actions',
      }),
    }),
    getAllLogMessagesCount: build.query<number, void>({
      providesTags: () => ['LOG_MESSAGES_COUNT'],
      query: () => ({
        url: '/log-message/count',
      }),
    }),
  }),
});

export const {
  useGetLogMessagesByMatchQuery,
  useGetLogMessagesByProjectQuery,
  useGetAllLogMessagesQuery,
  useCreateFeedbackMutation,
  useGetLastActinosQuery,
  useGetAllLogMessagesCountQuery,
} = logMessageApi;
