import { ColorsModule, TypographyModule } from '@raised/ui-kit';
import { IconHelp, IconHome } from '@tabler/icons-react';
import { MATCH_ROUTES } from 'components/match/match-routes';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../Links.module.css';

const { Text } = TypographyModule;
const { Colors } = ColorsModule;

const getIconProps = (isActive: boolean) => (isActive ? { color: Colors.accent, stroke: 2 } : { color: Colors.black, stroke: 1.5 });

interface ExpertLinksProps {
  isSupportOpened: boolean;
  onSupportClick: () => void;
}

export default function ExpertLinks({
  isSupportOpened,
  onSupportClick,
}: ExpertLinksProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className={styles.client_links}>
      <button type="button" className={styles.button} onClick={() => navigate(MATCH_ROUTES.CONFIRM_MATCH())}>
        <IconHome size={24} {...getIconProps(pathname === MATCH_ROUTES.CONFIRM_MATCH())} />
        <Text className={styles.button_text}>Home</Text>
      </button>
      <button type="button" className={styles.button} onClick={onSupportClick}>
        <IconHelp size={24} {...getIconProps(isSupportOpened)} />
        <Text className={styles.button_text}>Support</Text>
      </button>
    </div>
  );
}
