import { ProjectActivityTypes, ProjectStatuses } from '@raised/domain';
import { ColorsModule, TypographyModule } from '@raised/ui-kit';
import { IconFilePower } from '@tabler/icons-react';
import { PROJECT_STATUS_CONFIG, PROJECT_STATUS_LABELS } from 'components/project/constants/project-status-labels';
import { ReactNode } from 'react';
import styles from './ProjectStatus.module.css';

const { Title, Text } = TypographyModule;
const { Colors } = ColorsModule;

interface ProjectStatusProps {
  status: ProjectStatuses;
  actions: ReactNode;
  projectActivityType: ProjectActivityTypes;
}

export default function ProjectStatus({
  status,
  actions,
  projectActivityType,
}: ProjectStatusProps) {
  const background = projectActivityType === ProjectActivityTypes.RECOMMENDED ? Colors.liner : Colors['light-grayish-cyan'];

  return (
    <div className={styles.container} style={{ background }}>
      <div className={styles.info}>
        {projectActivityType === ProjectActivityTypes.RECOMMENDED ? (
          <>
            <IconFilePower stroke={1.5} size={32} />
            <div className={styles.text}>
              <Title level={5}>Are you interested in this request?</Title>
              <Text>Raised will find professionals for this project</Text>
            </div>
          </>
        ) : (
          <>
            {PROJECT_STATUS_CONFIG[status].icon}
            <Title level={5}>{PROJECT_STATUS_LABELS[status]}</Title>
          </>
        )}
      </div>
      {actions}
    </div>
  );
}
