import { ProjectCategories, ProjectTypes } from '@raised/domain';
import { FormsModule, TypographyModule } from '@raised/ui-kit';
import { PROJECT_CATEGORIES } from 'components/project/constants/project-categories';
import { PROJECT_TYPES } from 'components/project/constants/project-types';
import { GetTemplatesParamsType } from 'components/templates/storage/types';
import styles from './TemplatesFilters.module.css';

const { Title } = TypographyModule;
const { Checkbox } = FormsModule;

interface TemplatesFiltersProps {
  handleCategoryClick: (category: ProjectCategories) => void;
  handleTypeClick: (type: ProjectTypes) => void;
  handleAllTypesClick: () => void;
  handleAllCategoriesClick: () => void;
  filters: GetTemplatesParamsType;
}

export default function TemplatesFilters({
  handleTypeClick,
  handleCategoryClick,
  handleAllTypesClick,
  handleAllCategoriesClick,
  filters,
}: TemplatesFiltersProps) {
  return (
    <div className={styles.container}>
      <div className={styles.filters_block}>
        <Title level={5}>Project Category</Title>
        <Checkbox checked={filters.category.length === Object.values(ProjectCategories).length} onClick={handleAllCategoriesClick}>All</Checkbox>
        {Object.values(ProjectCategories).map((category) => (
          <Checkbox
            key={category}
            checked={filters.category.includes(category)}
            onClick={() => handleCategoryClick(category)}
          >
            {PROJECT_CATEGORIES[category]}
          </Checkbox>
        ))}
      </div>
      <div className={styles.filters_block}>
        <Title level={5}>Type</Title>
        <Checkbox checked={filters.type.length === Object.values(ProjectTypes).length} onClick={handleAllTypesClick}>All</Checkbox>
        {Object.values(ProjectTypes).map((type) => (
          <Checkbox
            key={type}
            checked={filters.type.includes(type)}
            onClick={() => handleTypeClick(type)}
          >
            {PROJECT_TYPES[type]}
          </Checkbox>
        ))}
      </div>
    </div>
  );
}
