import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { z } from 'zod';

interface UseLocationStateProps<T extends Record<string, any>> {
  schema: z.ZodSchema<T>;
}

export default function useLocationState<T extends Record<string, any>>({
  schema,
}: UseLocationStateProps<T>): T | null {
  const { state } = useLocation();

  const parsedState = useMemo(() => {
    const s = schema.safeParse(state);

    if (!s.success) {
      return null;
    }

    return s.data;
  }, [state]);

  return parsedState;
}
