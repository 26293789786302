import { Chat, Message } from '@raised/domain';
import Table from 'components/layout/components/table/Table';
import { useMemo } from 'react';
import { getSupportChatsListConfig, SupportChatsListTableRow } from './supportChatsListConfig';

interface SupportListTableProps {
  chats: Chat.Public[];
  isLoading: boolean;
  getChatLink: (userId: string) => string;
  getUserLink: (chatId: string) => string;
  personalProfileId: string;
}

export default function SupportListTable({
  chats,
  isLoading,
  getChatLink,
  getUserLink,
  personalProfileId,
}: SupportListTableProps) {
  const getIsNewMessages = (messages: Message.Public[], profileId: string) => !!messages.find((message) => {
    if (message.fromId === personalProfileId) {
      return false;
    }

    return !message.viewedBy.includes(profileId);
  });
  const columns = useMemo(() => getSupportChatsListConfig(), []);
  const tableData: Array<SupportChatsListTableRow> = useMemo(() => chats.map((chat) => ({
    key: chat.id,
    userLink: getUserLink(chat.participantIds[0]),
    userName: `${chat.participants?.[0]?.firstName} ${chat.participants?.[0]?.lastName}`,
    lastMessageTime: chat.messages?.[0]?.createdAt || '',
    isNewMessages: getIsNewMessages(chat.messages, personalProfileId),
    chatLink: getChatLink(chat.id),
  })), [chats]);

  return (
    <Table
      tableName="SupportListTable"
      loading={isLoading}
      dataSource={tableData}
      columns={columns}
    />
  );
}
