import { FormsModule } from '@raised/ui-kit';
import { Control } from 'react-hook-form';
import { FormConfig } from 'utils/forms';
import { ResetPasswordType } from './reset-password-types';

const { Password } = FormsModule;

export const getResetPasswordFormConfig = (control: Control<ResetPasswordType>): FormConfig<ResetPasswordType> => [
  {
    controller: {
      name: 'password',
      control,
      defaultValue: '',
    },
    component: Password,
    componentProps: {
      placeholder: 'Password',
      'data-testid': 'form_resetPassword__input_password',
    },
  },
  {
    controller: {
      name: 'confirmPassword',
      control,
      defaultValue: '',
    },
    component: Password,
    componentProps: {
      placeholder: 'Confirm password',
      'data-testid': 'form_resetPassword__input_confirm',
    },
  },

];
