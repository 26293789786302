/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { User } from '@raised/domain';
import {
  ColorsModule, DataModule, FeedbackModule, TypographyModule,
} from '@raised/ui-kit';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import UserAvatar from 'components/user/components/UserAvatar';
import styles from './MobileChatHeader.module.css';

const { Spin } = FeedbackModule;
const { Text } = TypographyModule;
const { Colors } = ColorsModule;
const { Badge } = DataModule;

interface MobileChatHeaderProps {
  newMessagesCount: number;
  title: string;
  onClick: () => void;
  isLoading: boolean;
  isChatVisible?: boolean;
  chatMembers?: User.Public[];
}

export default function MobileChatHeader({
  newMessagesCount,
  title,
  onClick,
  isLoading,
  isChatVisible = true,
  chatMembers,
}: MobileChatHeaderProps) {
  return (
    <div onClick={onClick} className={styles.container}>
      <div className={styles.left_side}>
        <Text className={styles.title}>{title}</Text>
        <Badge count={isLoading ? <Spin size="small" /> : newMessagesCount} color={newMessagesCount ? Colors.gray : undefined} />
        {!isChatVisible ? (
          <IconChevronUp className={styles.icon} size={20} />
        ) : (
          <IconChevronDown className={styles.icon} size={20} />
        )}
      </div>
      {chatMembers ? (
        <div className={styles.chat_members}>
          <span>Chat members</span>
          <div className={styles.avatars}>
            {chatMembers.map((member) => (
              <UserAvatar className={styles.avatar} key={member.id} size={32} role={member.role} filePath={member.avatar} />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
