import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

interface CollapseIconProps {
  isActive?: boolean;
}

export default function CollapseIcon({
  isActive,
}: CollapseIconProps) {
  return isActive ? <IconChevronDown size={20} /> : <IconChevronUp size={20} />;
}
