import { Typography } from 'antd';
import cn from 'classnames';
import { ComponentProps } from 'react';
import typographyStyles from '../typography.module.css';
import styles from './Text.module.css';

interface TextProps extends ComponentProps<typeof Typography.Text> {
  caption?: boolean;
}

export default function Text({
  className,
  caption,
  ...props
}: TextProps) {
  return (
    <Typography.Text
      {...props}
      className={cn(typographyStyles.typography, styles.text, {
        [typographyStyles.caption]: caption,
      }, className)}
    />
  );
}
