import { User } from '@raised/domain';
import { Space, Tag, Typography } from 'antd';
import { ReactNode } from 'react';

interface RecommendUserRowProps {
  user: User.Public;
  status?: string;
  controls?: ReactNode;
}

export default function RecommendUserRow({
  user, status, controls,
}: RecommendUserRowProps) {
  return (
    <Space style={{
      justifyContent: 'space-between',
      width: '100%',
      padding: 8,
      borderRadius: 10,
      background: 'rgba(230, 207, 207, 0.1)',
    }}
    >
      <Typography.Text>{`${user.firstName} ${user.lastName}`}</Typography.Text>
      <Space>
        {status && (
        <Tag color="processing" style={{ alignSelf: 'flex-end' }}>{status}</Tag>
        )}
        {controls}
      </Space>
    </Space>
  );
}
