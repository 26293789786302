import { Spin } from 'antd';
import { Outlet, Navigate } from 'react-router-dom';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { AUTH_ROUTES } from 'components/auth/auth-routes';

export default function ProtectedRouteLayout() {
  const { isError, isLoading } = useGetProfileQuery();

  if (isLoading) {
    return <Spin />;
  }

  if (isError) {
    return <Navigate to={AUTH_ROUTES.LOGIN()} replace />;
  }

  return <Outlet />;
}
