import { MatchStatuses } from '@raised/domain';
import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useDeleteMatchMutation } from '../storage/matchApi';
import { successApiMessages } from '../storage/successApiMessages';

export default function useDeleteMatch() {
  const [deleteMatch, data] = useDeleteMatchMutation();

  useEffect(() => {
    if (data.isError) {
      message.error(getErrorMessage(data.error));
    }
  }, [data.isError]);

  useEffect(() => {
    if (data.isSuccess) {
      message.success(successApiMessages.DELETE[MatchStatuses.RECOMMENDED_TO_EXPERT]);
    }
  }, [data.isSuccess]);

  return {
    deleteMatch,
    ...data,
  };
}
