import { Usecase } from '@raised/domain';
import { ButtonsModule } from '@raised/ui-kit';
import FormModal from 'components/layout/components/form-modal/FormModal';
import UseCaseItem from '../../use-cases/use-cases/use-case-item/UseCaseItem';
import styles from './SelectUsecaseModal.module.css';

const { Button } = ButtonsModule;

interface SelectUsecaseModalProps {
  isVisible: boolean;
  onClose: () => void;
  usecases: Usecase.Public[];
  onSelectUsecase: (usecaseId: string) => void;
}

export default function SelectUsecaseModal({
  isVisible,
  onClose,
  usecases,
  onSelectUsecase,
}: SelectUsecaseModalProps) {
  return (
    <FormModal
      isVisible={isVisible}
      onClose={onClose}
      footer={null}
      title="Select Use Case"
    >
      <div className={styles.list}>
        {usecases.map((usecase) => (
          <UseCaseItem
            key={usecase.id}
            usecase={usecase}
            additionalContent={<Button type="primary" className={styles.select_btn} onClick={() => onSelectUsecase(usecase.id)}>Select</Button>}
          />
        ))}
      </div>
    </FormModal>
  );
}
