import React, { ReactNode, useRef } from 'react';

interface ChildrenObserverRrops {
  children: ReactNode;
  onChange: () => void;
}

const getChildrenCount = (children: ReactNode) => React.Children.toArray(children).length;

export default function ChildrenObserver({
  children,
  onChange,
}: ChildrenObserverRrops) {
  const lastCount = useRef(getChildrenCount(children));
  const currentCount = getChildrenCount(children);

  if (lastCount.current !== currentCount) {
    lastCount.current = currentCount;
    onChange();
  }

  return <>{children}</>;
}
