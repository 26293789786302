import { Project } from '@raised/domain';

export const getProjectBudget = (project: Project.Public): string => {
  if (!project.budget) {
    return 'Not specified';
  }

  // const prefix = project.type === 'FIXED_SCOPE' ? 'Project amount: ' : 'Monthly expense: ';
  const prefix = 'Project amount: ';
  const priceRange = `$${project.budget.min} - $${project.budget.max}`;

  return `${prefix}${priceRange}`;
};
