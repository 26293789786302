import {
  MatchStatuses, Project, ProposalStatuses, UserRole,
} from '@raised/domain';
import useCreateMatch from 'components/match/hooks/useCreateMatch';
import useDeleteMatch from 'components/match/hooks/useDeleteMatch';
import useRestoreMatch from 'components/match/hooks/useRestoreMatch';
import useUpdateMatch from 'components/match/hooks/useUpdateMatch';
import { useGetMatchesByProjectQuery, useGetMatchesWithNoProposalsQuery } from 'components/match/storage/matchApi';
import { MESSAGE_ROUTES } from 'components/messages/messages-routes';
import { useGetChatsByProjectQuery } from 'components/messages/storage/messageApi';
import useUpdateProposalStatus from 'components/proposal/hooks/useUpdateProposalStatus';
import { PROPOSAL_ROUTES } from 'components/proposal/proposal-routes';
import { useGetProposalsByProjectQuery } from 'components/proposal/storage/proposalApi';
import { useGetExpertsQuery } from 'components/user/storage/userApi';
import { USER_ROUTES } from 'components/user/user-routes';
import { useNavigate } from 'react-router-dom';
import useMessageModal from '../../../components/message-modal/useMessageModal';
import MessageModal from '../../../components/message-modal/MessageModal';
import AdminProjectMatchInfoComponent from './AdminProjectMatchInfoComponent';

interface AdminProjectMatchInfoProps {
  project: Project.Public;
}

export default function AdminProjectMatchInfo({
  project,
}: AdminProjectMatchInfoProps) {
  const { id: projectId } = project;
  const navigate = useNavigate();

  const { deleteMatch, isLoading: isDeleteLoading } = useDeleteMatch();
  const { create, isLoading: isCreateLoading } = useCreateMatch({});
  const { update, isLoading: isUpdateLoading } = useUpdateMatch();
  const { restore, isLoading: isRestoreLoading } = useRestoreMatch();
  const { updateProposalStatus, isLoading: isUpdateProposalLoading } = useUpdateProposalStatus();

  const { data: matchesByProject } = useGetMatchesByProjectQuery({ id: projectId });
  const { data: matchesWithNoProposals } = useGetMatchesWithNoProposalsQuery({ projectId });
  const { data: experts } = useGetExpertsQuery();
  const { data: chats } = useGetChatsByProjectQuery({ projectId });
  const { data: proposals } = useGetProposalsByProjectQuery({ projectId });

  const {
    decision,
    expertId,
    openMessageModal,
    closeMessageModal,
    isMessageModalVisible,
    modalTitle,
  } = useMessageModal();

  const onInviteToInterviewClick = (matchId: string) => {
    update({ id: matchId, data: { status: MatchStatuses.INVITED_TO_INTERVIEW } });
  };
  const onAcceptMatch = (matchId: string, role: UserRole) => {
    const status = role === UserRole.EXPERT ? MatchStatuses.INTERSTED_BY_EXPERT : MatchStatuses.INTERSTED_BY_CLIENT;

    update({ id: matchId, data: { status } });
  };
  const onAcceptAssignMatch = (matchId: string) => {
    update({ id: matchId, data: { status: MatchStatuses.CALL_OVER } });
  };
  const onDeclineMatch = (matchId: string, role: UserRole) => {
    const status = role === UserRole.EXPERT ? MatchStatuses.DECLINED_BY_EXPERT : MatchStatuses.DECLINED_BY_CLIENT;

    update({ id: matchId, data: { status } });
  };
  const onFinishMatch = (matchId: string) => {
    update({ id: matchId, data: { status: MatchStatuses.FINISHED } });
  };
  const onRestoreMatch = (matchId: string) => {
    restore({ matchId });
  };
  const onEditProposal = (proposalId: string) => navigate(PROPOSAL_ROUTES.EDIT(proposalId));
  const getChatLink = (matchId: string) => MESSAGE_ROUTES.CHAT_PAGE(matchId);
  const getUserLink = (userId: string) => USER_ROUTES.USER_PROFILE(userId);
  const onCreateProposalClick = (matchId: string) => navigate(PROPOSAL_ROUTES.CREATE(matchId));
  const onSubmitAssign = (userId: string) => {
    if (!projectId) return;

    openMessageModal(userId, MatchStatuses.RECOMMENDED_TO_CLIENT);
  };
  const onDeclineProposal = (matchId: string, proposalId: string) => {
    updateProposalStatus({ matchId, status: ProposalStatuses.DECLINED, proposalId });
  };
  const onAcceptProposal = (matchId: string, proposalId: string) => {
    updateProposalStatus({ matchId, proposalId, status: ProposalStatuses.ACCEPTED });
  };
  const onSubmitRecommend = (userId: string) => {
    if (!projectId) return;

    openMessageModal(userId, MatchStatuses.RECOMMENDED_TO_EXPERT);
  };
  const onSubmitUnrecommend = (matchId: string) => {
    deleteMatch({ id: matchId });
  };
  const onMessageSubmit = (message: string) => {
    if (!projectId) return;

    if (decision === MatchStatuses.RECOMMENDED_TO_EXPERT) {
      create({
        projectIds: [projectId],
        userId: expertId,
        status: MatchStatuses.RECOMMENDED_TO_EXPERT,
        message,
      });
    }

    if (decision === MatchStatuses.RECOMMENDED_TO_CLIENT) {
      create({
        projectIds: [projectId],
        userId: expertId,
        status: MatchStatuses.RECOMMENDED_TO_CLIENT,
        message,
      });
    }
  };

  return (
    <>
      {experts && (
        <AdminProjectMatchInfoComponent
          projectId={projectId}
          getChatLink={getChatLink}
          isCreateLoading={isCreateLoading}
          isDeleteLoading={isDeleteLoading}
          onSubmitRecommend={onSubmitRecommend}
          onSubmitUnrecommend={onSubmitUnrecommend}
          onSubmitAssign={onSubmitAssign}
          project={project}
          experts={experts.results}
          proposals={proposals}
          chats={chats}
          onCreateProposalClick={onCreateProposalClick}
          matchesWithNoProposals={matchesWithNoProposals || []}
          getUserLink={getUserLink}
          isUpdateProposalLoading={isUpdateProposalLoading}
          onAcceptProposal={onAcceptProposal}
          onDeclineProposal={onDeclineProposal}
          onEditProposal={onEditProposal}
          matchesByProject={matchesByProject || []}
          onDeclineMatch={onDeclineMatch}
          onRestoreMatch={onRestoreMatch}
          isMatchActionsLoading={isRestoreLoading || isUpdateLoading}
          onFinishMatch={onFinishMatch}
          onAcceptClick={onAcceptMatch}
          onAcceptAssignClick={onAcceptAssignMatch}
          onInviteToInterviewClick={onInviteToInterviewClick}
        />
      )}
      <MessageModal
        isVisible={isMessageModalVisible}
        onClose={closeMessageModal}
        onSubmit={onMessageSubmit}
        title={modalTitle}
      />
    </>
  );
}
