import { zodResolver } from '@hookform/resolvers/zod';
import { Organisation, OrganisationSchemas, UserRole } from '@raised/domain';
import { Button, Typography } from 'antd';
import CompanyDetailsFieldsForm from 'components/user/components/forms/user-info-form/company-details-form/CompanyDetailsForm';
import { useForm } from 'react-hook-form';

interface EditOrganisationComponentProps {
  isLoading: boolean;
  onSubmit: (data: Organisation.Edit) => void;
  defaultValues: Organisation.Public;
}

export default function EditOrganisationComponent({
  isLoading,
  onSubmit,
  defaultValues,
}: EditOrganisationComponentProps) {
  const { control, handleSubmit, resetField } = useForm<Organisation.Edit>({
    defaultValues,
    resolver: zodResolver(OrganisationSchemas.editOrganisationSchema),
  });

  return (
    <form className="form_container" onSubmit={handleSubmit(onSubmit)}>
      <Typography.Title level={2} style={{ textAlign: 'center' }}>Edit organisation</Typography.Title>
      <CompanyDetailsFieldsForm control={control} role={UserRole.CLIENT} resetField={resetField} />
      <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>Submit</Button>
    </form>
  );
}
