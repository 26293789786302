import { User } from '@raised/domain';
import Table from 'components/layout/components/table/Table';
import { useMemo } from 'react';
import { getAdminUsersTableConfig } from './adminUsersTableConfig';

interface AdminUsersTableProps {
  isLoading: boolean;
  users: User.Public[];
  getUserLink: (userId: string) => string;
  getOrganizationLink: (orgId: string) => string;
}

export default function AdminUsersTable({
  isLoading,
  users,
  getUserLink,
  getOrganizationLink,
}: AdminUsersTableProps) {
  const tableData = useMemo(() => users.map((user) => ({
    key: user.id,
    name: `${user.firstName} ${user.lastName}`,
    role: user.role,
    email: user.email,
    isActivated: !user.isGhost,
    userLink: getUserLink(user.id),
    comment: user.adminComment?.comment || '',
    organization: user.organisation?.companyName || '-',
    organizationLink: user.organisation?.id ? getOrganizationLink(user.organisation.id) : '',
  })), [users]);
  const columns = useMemo(() => getAdminUsersTableConfig(), []);

  return (
    <Table
      tableName="adminUsersList"
      columns={columns}
      dataSource={tableData}
      loading={isLoading}
    />
  );
}
