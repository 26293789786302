/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import { Usecase } from '@raised/domain';
import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { useState } from 'react';
import styles from './UseCases.module.css';

const { Text, Title, Paragraph } = TypographyModule;
const { Button, PublicButton } = ButtonsModule;

interface UseCasesProps {
  usecases: Usecase.Public[]
  onUsecaseClick: (usecase: Usecase.Public) => void;
}

export default function UseCases({
  usecases,
  onUsecaseClick,
}: UseCasesProps) {
  const [isOpen, setIsOpen] = useState(false);
  const isShowMore = usecases.length > 6;

  const cases = !isOpen ? usecases.slice(0, 6) : usecases;

  return (
    <div className={styles.usecases}>
      <Text className={styles.title}>Use Cases</Text>
      <div className={cn(styles.list, {
        [styles.list_more]: isShowMore && !isOpen,
        [styles.full]: !isShowMore,
      })}
      >
        {cases.map((usecase) => (
          <div onClick={() => onUsecaseClick(usecase)} key={usecase.id} className={styles.item}>
            <Title level={2}>{usecase.title}</Title>
            <Paragraph>{usecase.description}</Paragraph>
            <Button className={styles.see_more} type="link">See more</Button>
          </div>
        ))}
      </div>
      {isShowMore && !isOpen ? (
        <div className={styles.fade} />
      ) : null}
      {isShowMore ? (
        <PublicButton
          onClick={() => setIsOpen(!isOpen)}
          className={cn(styles.open_btn, {
            [styles.opened]: isOpen,
          })}
        >
          {isOpen ? 'Less cases' : 'More cases'}
        </PublicButton>
      ) : null}
    </div>
  );
}
