import { TypographyModule } from '@raised/ui-kit';
import { IconChevronLeft } from '@tabler/icons-react';
import { Menu, MenuProps } from 'antd';
import cn from 'classnames';
import { NotificationLinkType } from '../../../../hooks/useNotificationLinks';
import NotificationLink from '../notification-link/NotificationLink';
import styles from './NotificationLinks.module.css';

const { Title } = TypographyModule;

interface NotificationLinksProps {
  onLinkClick: (link: string) => void;
  links: Array<NotificationLinkType>;
  selectedLink: string;
  onBack: () => void;
  personalProfileId: string;
}

type MenuItem = Required<MenuProps>['items'][number];

export default function NotificationLinks({
  onLinkClick,
  links,
  selectedLink,
  onBack,
  personalProfileId,
}: NotificationLinksProps) {
  const menuItems: MenuItem[] = [
    {
      key: 'all',
      label: <NotificationLink personalProfileId={personalProfileId} link={{ title: 'All projects', value: 'all' }} />,
    },
    ...links.map((link) => ({
      key: link.value,
      label: <NotificationLink personalProfileId={personalProfileId} link={link} />,
    })),
  ];

  return (
    <div className={styles.container}>
      <div className={styles.back_block}>
        <IconChevronLeft className={styles.icon} onClick={onBack} size={24} />
        <Title level={2} className={styles.title}>Notifications</Title>
      </div>
      <Menu
        selectedKeys={[selectedLink]}
        onClick={(e) => onLinkClick(e.key)}
        items={menuItems}
        rootClassName={cn(styles.menu, 'notifications-menu')}
      />
    </div>
  );
}
