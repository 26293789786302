import { MatchStatuses, Project, User } from '@raised/domain';
import { Space, Button } from 'antd';
import { ADMIN_MATCH_STATUS_LABELS } from 'components/match/constants/match-status-lables';
import RecommendUserRow from 'components/project/components/RecommendUserRow';

interface AssignTabProps {
  experts: User.Public[];
  project: Project.Public;
  isLoading: boolean;
  onSubmitAssign: (userId: string) => void;
  onSubmitUnassign: (matchId: string) => void;
}

export default function AssignTab({
  experts,
  project,
  isLoading,
  onSubmitAssign,
  onSubmitUnassign,
}: AssignTabProps) {
  const getUserStatus = (user: User.Public) => {
    const prevMatch = user.matches?.find((match) => match.projectId === project.id);

    const isControls = prevMatch ? (prevMatch.status === MatchStatuses.RECOMMENDED_TO_EXPERT || prevMatch.status === MatchStatuses.RECOMMENDED_TO_CLIENT) : true;

    return {
      status: (prevMatch && prevMatch.status !== MatchStatuses.RECOMMENDED_TO_CLIENT) ? ADMIN_MATCH_STATUS_LABELS[prevMatch.status] : '',
      isControls,
    };
  };

  const getInterstedMatch = (userId: string) => project.matches?.find((match) => match.userId === userId && match.status === MatchStatuses.RECOMMENDED_TO_CLIENT);

  const onButtonClickHandler = (userId: string) => {
    const match = getInterstedMatch(userId);

    if (match) {
      onSubmitUnassign(match.id);
    } else {
      onSubmitAssign(userId);
    }
  };

  return (
    <Space style={{ width: 500 }} direction="vertical">
      {experts.map((user) => (
        <RecommendUserRow
          key={user.id}
          user={user}
          status={getUserStatus(user).status}
          controls={getUserStatus(user).isControls && (
            <Button
              loading={isLoading}
              danger={!!getInterstedMatch(user.id)}
              onClick={() => onButtonClickHandler(user.id)}
              data-testid="button_adminAssign"
            >
              {getInterstedMatch(user.id) ? 'Delete assign' : 'Assign'}
            </Button>
          )}
        />
      ))}
    </Space>
  );
}
