import { Organisation, User } from '@raised/domain';
import { Typography } from 'antd';

interface ExpertAcceptProps {
  client: User.Public;
  expert: User.Public;
  organisation: Organisation.Public;
}
export default function ExpertAccept({
  client,
  expert,
  organisation,
}: ExpertAcceptProps) {
  return (
    <Typography.Text>
      Hi! It’s chat between
      {' '}
      {client.firstName}
      {' '}
      {client.lastName}
      {' '}
      from
      {' '}
      {organisation?.companyName}
      {' '}
      and
      {expert.firstName}
      {' '}
      {expert.lastName}
      .
    </Typography.Text>
  );
}
