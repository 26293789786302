import {
  Proposal, ProposalStatuses,
} from '@raised/domain';
import { DataModule, LayoutModule, TypographyModule } from '@raised/ui-kit';
import ViewProposal from 'components/proposal/components/view-proposal/ViewProposal';
import ProposalActions from './proposal-actions/ProposalActions';
import ProposalDeclined from './proposal-declined/ProposalDeclined';
import ProposalHeader from './proposal-header/ProposalHeader';
import ProposalPaid from './proposal-paid/ProposalPaid';
import ProposalPay from './proposal-pay/ProposalPay';

const { Collapse, Panel } = DataModule;
const { Space } = LayoutModule;
const { Text } = TypographyModule;

interface ProposalTabProps {
  proposals: Proposal.Public[];
  isUpdateStatusLoading: boolean;
  onUpdateProposalStatus: (proposalId: string, status: ProposalStatuses) => void;
  onPayClick: (propId: string) => void;
  isPaymentLinkLoading: boolean;
  isPaymentLinkSuccess: boolean;
}

export default function ProposalTab({
  proposals,
  isUpdateStatusLoading,
  onUpdateProposalStatus,
  onPayClick,
  isPaymentLinkSuccess,
  isPaymentLinkLoading,
}: ProposalTabProps) {
  const onAccept = (id: string) => onUpdateProposalStatus(id, ProposalStatuses.ACCEPTED);
  const onDecline = (id: string) => onUpdateProposalStatus(id, ProposalStatuses.DECLINED);

  const getIsProposalPaid = (proposal: Proposal.Public) => !!proposal?.payments?.length;

  return (
    <>
      {proposals.length ? (
        <Collapse>
          {proposals.map((proposal) => (
            <Panel
              key={proposal.id}
              header={(
                <ProposalHeader
                  isPaid={getIsProposalPaid(proposal)}
                  title={proposal.title}
                  status={proposal.status}
                />
              )}
            >
              <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <ViewProposal proposal={proposal} />
                {proposal.status === ProposalStatuses.PENDING ? (
                  <ProposalActions
                    isLoading={isUpdateStatusLoading}
                    onAccept={() => onAccept(proposal.id)}
                    onDecline={() => onDecline(proposal.id)}
                  />
                ) : null}
                {proposal.status === ProposalStatuses.ACCEPTED && !getIsProposalPaid(proposal) ? (
                  <ProposalPay
                    onPayClick={() => onPayClick(proposal.id)}
                    isSuccess={isPaymentLinkSuccess}
                    isLoading={isPaymentLinkLoading}
                  />
                ) : null}
                {getIsProposalPaid(proposal) ? (
                  <ProposalPaid />
                ) : null}
                {proposal.status === ProposalStatuses.DECLINED ? (
                  <ProposalDeclined />
                ) : null}
              </Space>
            </Panel>
          ))}
        </Collapse>
      ) : (
        <Text>No proposals have been created yet</Text>
      )}
    </>
  );
}
