import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { IconUpload } from '@tabler/icons-react';
import AttachedFile from 'components/layout/components/attached-file/AttachedFile';
import File from 'components/layout/components/file/File';
import { ChangeEvent, useRef } from 'react';
import styles from './AttachFiles.module.css';

const { Button } = ButtonsModule;
const { Text } = TypographyModule;

interface AttachFilesProps {
  label?: string;
  subLabel?: string;
  files?: Array<File>;
  uploadedFiles?: string[];
  addFiles: (files: File[]) => void;
  removeFile: (fileName: string) => void;
}

export default function AttachFiles({
  label,
  subLabel,
  files,
  uploadedFiles,
  addFiles,
  removeFile,
}: AttachFilesProps) {
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const onButtonClick = () => fileUploadRef.current?.click();
  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      addFiles(Array.from(e.target.files));
    }
  };
  const getFileLink = (file: File) => window.URL.createObjectURL(file);

  return (
    <div className={styles.attach_files}>
      <input
        onChange={onChangeFile}
        type="file"
        ref={fileUploadRef}
        multiple
        style={{ display: 'none' }}
      />
      <Text>{label}</Text>
      <Text type="secondary">{subLabel}</Text>
      <Button onClick={onButtonClick}>
        <IconUpload size={14} />
        Click to attach file
      </Button>
      <div className={styles.files}>
        {uploadedFiles?.map((file) => (
          <AttachedFile fileName={file} onDelete={() => removeFile(file)} key={file} />
        ))}
        {files?.map((file) => (
          <File name={file.name} link={getFileLink(file)} onDelete={() => removeFile(file.name)} />
        ))}
      </div>
    </div>
  );
}
