import { Project } from '@raised/domain';
import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import useAttachedFiles from 'components/files/hooks/useAttachedFiles';
import useUploadFiles from 'components/files/hooks/useUploadFiles';
import { useUpdateProjectMutation } from '../storage/projectApi';
import { successProjectMessages } from '../storage/successProjectMessages';

interface UseUpdateProjectProps {
  initialFiles?: string[];
  onSuccess: (project: Project.Public) => void;
}

export default function useUpdateProject({
  onSuccess,
  initialFiles,
}: UseUpdateProjectProps) {
  const [mutate, data] = useUpdateProjectMutation();
  const {
    files, addFiles, removeFile, uploadedFiles,
  } = useAttachedFiles({
    initialValues: initialFiles,
  });
  const { uploadFiles, isLoading: isFilesLoading } = useUploadFiles();

  const update = async (updateData: { data: Project.Create, id: string }) => {
    const attachedFiles = [...uploadedFiles];

    if (files.length) {
      const result = await uploadFiles(files);

      if (result && result?.length) {
        attachedFiles.push(...result.map((file) => file.url));
      }
    }

    mutate({
      id: updateData.id,
      data: {
        ...updateData.data,
        attachedFile: attachedFiles,
      },
    });
  };

  useEffect(() => {
    if (data.isError) {
      message.error(getErrorMessage(data.error));
    }
  }, [data.isError]);

  useEffect(() => {
    if (data.isSuccess) {
      onSuccess(data.data);
      message.success(successProjectMessages.UPDATE);
    }
  }, [data.isSuccess]);

  return {
    update,
    addFiles,
    removeFile,
    uploadedFiles,
    files,
    ...data,
    isLoading: data.isLoading || isFilesLoading,
  };
}
