import { ComponentProps, ReactNode } from 'react';
import cn from 'classnames';
import { Tag } from '../tag';
import styles from './IconTag.module.css';

interface IconTagProps extends Omit<ComponentProps<typeof Tag>, 'label'> {
  icon: ReactNode;
}

export default function IconTag({
  icon,
  className,
  ...props
}: IconTagProps) {
  return (
    <Tag
      label={icon}
      {...props}
      className={cn(styles.icon_tag, className)}
    />
  );
}
