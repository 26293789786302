import { Template } from '@raised/domain';
import {
  ButtonsModule,
  ColorsModule, DataModule, TypographyModule,
} from '@raised/ui-kit';
import {
  IconAd2, IconClockHour3, IconCloudStorm, IconPencil,
} from '@tabler/icons-react';
import { PROJECT_CATEGORIES } from 'components/project/constants/project-categories';
import { PROJECT_TYPES } from 'components/project/constants/project-types';
import { formatDate } from 'utils/formats';
import styles from './TemplateInfo.module.css';
import TimelineItem from './TimelineItem';

const { Button } = ButtonsModule;
const { Title, Text } = TypographyModule;
const { Timeline } = DataModule;
const { Colors } = ColorsModule;

interface TemplateInfoProps {
  template: Template.Public;
  isAdmin: boolean;
  onEditClick: () => void;
  onCreateClick: () => void;
}

export default function TemplateInfo({
  template,
  isAdmin,
  onEditClick,
  onCreateClick,
}: TemplateInfoProps) {
  const timelineItems = template.parts?.map((item) => ({
    children: (
      <TimelineItem title={item.title} description={item.description} />
    ),
    position: 'right',
  }));

  return (
    <div className={styles.container}>
      <div className={styles.main_info}>
        <div className={styles.title_block}>
          <Title level={1}>{template.title}</Title>
          {isAdmin ? (
            <Button onClick={onCreateClick}>Create similar project</Button>
          ) : null}
        </div>

        <div className={styles.date_block}>
          <div className={styles.date}>
            <IconClockHour3 color={Colors.gray} size={14} />
            <Text>{formatDate(new Date(template.createdAt))}</Text>
          </div>
          {isAdmin ? (
            <Text onClick={onEditClick} className={styles.edit} type="secondary">
              Edit template
              <IconPencil size={16} color={Colors.gray} />
            </Text>
          ) : null}
        </div>

        {template.description ? (
          <div className={styles.description_block}>
            <div className={styles.description_block__icon}>
              <IconAd2 size={20} />
            </div>
            <Text style={{ whiteSpace: 'pre-wrap' }}>{template.description}</Text>
          </div>
        ) : null}

        <div className={styles.info}>
          {template.category?.length ? (
            <>
              <Text type="secondary">Categories</Text>
              <Text>
                {template.category?.map((category) => PROJECT_CATEGORIES[category]).join(', ')}
              </Text>
            </>
          ) : null}
          {template.type ? (
            <>
              <Text type="secondary">Project Type</Text>
              <Text>{template.type?.map((type) => PROJECT_TYPES[type]).join(', ')}</Text>
            </>
          ) : null}
        </div>
      </div>

      {template.challenge ? (
        <div className={styles.challenge_block}>
          <IconCloudStorm size={22} />
          <div className={styles.challenge_content}>
            <Title level={4}>Project Challenge</Title>
            <Text>{template.challenge}</Text>
          </div>
        </div>
      ) : null}

      <Timeline items={timelineItems} />
    </div>
  );
}
