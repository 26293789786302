import cn from 'classnames';
import { User, UserRole, UserSchemas } from '@raised/domain';
import {
  Control, FieldValues, Controller, useForm,
} from 'react-hook-form';
import { renderFormConfig, scrollToError } from 'utils/forms';
import UploadAvatar from 'components/files/upload-avatar/UploadAvatar';
import { ButtonsModule, FormsModule } from '@raised/ui-kit';
import { zodResolver } from '@hookform/resolvers/zod';
import { getExpertAdditionalFieldsFormConfig, getMainInfoFormConfig } from './mainInfoFormConfig';
import styles from './MainInfo.module.css';
import ExpertTags from '../../../form-components/expert-tags/ExpertTags';
import ExpertRate from '../../../form-components/ExpertRate';
import ProfileVisibility from '../../../form-components/ProfileVisibility';
import ExpertAvailability from '../../../form-components/ExpertAvailability';
import ExpertAdminComment from '../../../form-components/expert-admin-comment/ExpertAdminComment';
import ExpertActivityType from '../../../form-components/ExpertActivityType';
import ProfilePrivacy from '../../../form-components/profile-privacy/ProfilePrivacy';
import ExpertCategories from '../../../form-components/expert-categories/ExpertCategories';

const { Input } = FormsModule;
const { Button } = ButtonsModule;

interface MainInfoProps {
  onSubmit: (values: User.Edit) => void;
  onCancel: () => void;
  isAdmin: boolean;
  defaultValues: User.Public;
  isUploadAvatar: boolean;
  isGhost: boolean;
  isLoading: boolean;
}

export default function MainInfo({
  onSubmit,
  onCancel,
  defaultValues,
  isAdmin,
  isUploadAvatar,
  isGhost,
  isLoading,
}: MainInfoProps) {
  const {
    control, handleSubmit, setValue,
  } = useForm<User.Edit>({
    defaultValues: {
      ...defaultValues,
      adminComment: defaultValues.adminComment?.comment || undefined,
    },
    resolver: zodResolver(UserSchemas.editUserSchema),
  });

  const formConfig = getMainInfoFormConfig(control);
  const expertAdditionalFields = getExpertAdditionalFieldsFormConfig(control);

  const onAvatarUpload = (avatar: string) => setValue('avatar', avatar);
  const onAvatarDelete = () => setValue('avatar', '');

  return (
    <form onSubmit={handleSubmit(onSubmit, scrollToError)} className={cn(styles.main_info, 'form_container')}>
      {isUploadAvatar && (
        <UploadAvatar
          userRole={defaultValues.role}
          onSuccess={onAvatarUpload}
          onDelete={onAvatarDelete}
        />
      )}
      {isAdmin || !isGhost ? (
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <Input label="Email" {...field} status={fieldState.error ? 'error' : undefined} placeholder="Email" type="email" />
          )}
        />
      ) : null}
      {renderFormConfig(formConfig)}
      {defaultValues.role === UserRole.EXPERT ? (
        <>
          {renderFormConfig(expertAdditionalFields)}
          <ExpertTags control={control as unknown as Control<FieldValues>} />
          <ExpertCategories control={control} setValue={setValue} />
          <ExpertRate control={control as unknown as Control<FieldValues>} />
          {isAdmin ? (
            <ProfileVisibility control={control as unknown as Control<FieldValues>} />
          ) : null}
          <ExpertAvailability control={control as unknown as Control<FieldValues>} />
          {isAdmin ? (
            <>
              <ExpertAdminComment control={control as unknown as Control<FieldValues>} />
              <ExpertActivityType control={control as unknown as Control<FieldValues>} />
              <ProfilePrivacy control={control} />
            </>
          ) : null}
        </>
      ) : null}
      <div className={styles.buttons}>
        <Button
          loading={isLoading}
          disabled={isLoading}
          type="primary"
          htmlType="submit"
          style={{ flex: 2 }}
        >
          Save
        </Button>
        <Button onClick={onCancel} style={{ flex: 1 }}>Cancel</Button>
      </div>
    </form>
  );
}
