import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { IconFileLike, IconReceipt } from '@tabler/icons-react';
import styles from './ProposalPay.module.css';

const { Text, Title } = TypographyModule;
const { Button } = ButtonsModule;

interface ProposalPayProps {
  onPayClick: () => void;
  isLoading: boolean;
  isSuccess: boolean;
}

export default function ProposalPay({
  onPayClick,
  isLoading,
  isSuccess,
}: ProposalPayProps) {
  return (
    <div className={styles.container}>
      <div className={styles.status}>
        <IconFileLike size={32} stroke={1.5} />
        <Title level={5}>You accepted a proposal</Title>
        {isSuccess ? (
          <Text type="secondary">Please refresh this page after making the payment</Text>
        ) : null}
      </div>
      <Button
        loading={isLoading}
        disabled={isLoading}
        onClick={onPayClick}
        type="primary"
      >
        <IconReceipt size={18} />
        Proceed to pay
      </Button>
    </div>
  );
}
