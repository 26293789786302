import { Project } from '@raised/domain';
import { FormsModule } from '@raised/ui-kit';
import { Control } from 'react-hook-form';
import { FormConfig } from 'utils/forms';
import { PROJECT_CATEGORIES } from '../constants/project-categories';
import { PROJECT_TYPES } from '../constants/project-types';

const { TextArea, Select } = FormsModule;

const projectCategoryOptions = Object.entries(PROJECT_CATEGORIES).map(([value, label]) => ({ value, label }));
const projectTypeOptions = Object.entries(PROJECT_TYPES).map(([value, label]) => ({ value, label }));

export const getCreateProjectFormConfigPart1 = (control: Control<Project.Create, any>): FormConfig<Project.Create> => [
  {
    controller: {
      name: 'title',
      control,
      defaultValue: '',
    },
    label: 'Project title',
    component: TextArea,
    componentProps: {
      placeholder: 'Title',
      'data-testid': 'form_createProject__input_title',
      rows: 2,
    },
  },
  {
    controller: {
      name: 'category',
      control,
    },
    label: 'Function',
    subLabel: 'Which functions are the best fit for your project? This will help us provide you with the best expert matches',
    component: Select,
    componentProps: {
      placeholder: 'Select function',
      options: projectCategoryOptions,
      mode: 'multiple',
    },
  },
];

export const getCreateProjectFormConfigPart2 = (control: Control<Project.Create, any>): FormConfig<Project.Create> => [
  {
    controller: {
      name: 'type',
      control,
      // defaultValue: [projectTypeOptions[0].value],
    },
    label: 'Type of the project',
    component: Select,
    componentProps: {
      placeholder: 'Select category',
      options: projectTypeOptions,
      mode: 'multiple',
    },
  },
  {
    controller: {
      name: 'description',
      control,
      defaultValue: '',
    },
    label: 'Project description',
    subLabel: 'Describe the challenge you are facing and the work you need the professional to do.',
    component: TextArea,
    componentProps: {
      placeholder: 'Description',
      'data-testid': 'form_createProject__input_description',
      rows: 4,
    },
  },
  {
    controller: {
      name: 'expertPreference',
      control,
      defaultValue: '',
    },
    label: 'Expert preference',
    subLabel: 'Provide a description of the desired expert, including their skills, level of experience, and any other relevant qualifications.',
    component: TextArea,
    componentProps: {
      placeholder: 'Expert preference',
      rows: 4,
    },
  },

];
