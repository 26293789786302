import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface MenuItemProps {
  children: ReactNode;
  to: LinkProps['to'];
}

export default function MenuItem({
  children,
  to,
}: MenuItemProps) {
  return (
    <Link to={to}>
      {children}
    </Link>
  );
}
