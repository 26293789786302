import { Typography } from 'antd';
import { ReactNode } from 'react';

interface AdminChatListComponentProps {
  children: ReactNode;
}

export default function AdminChatListComponent({
  children,
}: AdminChatListComponentProps) {
  return (
    <div>
      <Typography.Title level={2}>List of chats</Typography.Title>
      {children}
    </div>
  );
}
