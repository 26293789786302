import { useGetUserByIdQuery } from 'components/user/storage/userApi';
import { Link } from 'react-router-dom';

interface DeletedByCellProps {
  userId: string;
  userLink: string;
}

export default function DeletedByCell({
  userId,
  userLink,
}: DeletedByCellProps) {
  const { data, isLoading } = useGetUserByIdQuery({ id: userId });

  return (
    <Link
      to={userLink}
    >
      {isLoading ? (
        <>Loading...</>
      ) : (
        <>{`${data?.firstName} ${data?.lastName}`}</>
      )}
    </Link>
  );
}
