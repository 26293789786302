/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Organisation } from '@raised/domain';
import { ColorsModule, LayoutModule, TypographyModule } from '@raised/ui-kit';
import { IconPencil } from '@tabler/icons-react';
import InviteToOrganisation from './invite-to-organisation/InviteToOrganisation';
import styles from './OrganisationDashboardComponent.module.css';
import TeammatesList from './teammates-list/TeammatesList';

const { Title, Text, Paragraph } = TypographyModule;
const { Space } = LayoutModule;
const { Colors } = ColorsModule;

interface OrganisationDashboardComponentProps {
  organisation: Organisation.Public;
  onInviteToOrganisation: (email: string) => void;
  onRemoveFromOrganisation: (userId: string) => void;
  isInviteLoading: boolean;
  isRemoveLoading: boolean;
  isOwner: boolean;
  personalId: string;
  onEditClick: () => void;
}

export default function OrganisationDashboardComponent({
  organisation,
  onRemoveFromOrganisation,
  onInviteToOrganisation,
  isRemoveLoading,
  isInviteLoading,
  isOwner,
  personalId,
  onEditClick,
}: OrganisationDashboardComponentProps) {
  const organisationMembers = organisation?.users.filter((user) => user.id !== personalId);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Title level={1} className={styles.title}>{organisation.companyName}</Title>
      {organisation.aboutCompany ? (
        <Paragraph className={styles.description}>{organisation.aboutCompany}</Paragraph>
      ) : null}
      {isOwner ? (
        <>
          <div className={styles.edit} onClick={onEditClick}>
            <Text type="secondary">Edit Company</Text>
            <IconPencil size={16} color={Colors.gray} />
          </div>
          <InviteToOrganisation
            isLoading={isInviteLoading}
            onInviteToOrganisation={onInviteToOrganisation}
          />
        </>
      ) : null}
      <TeammatesList
        onDelete={onRemoveFromOrganisation}
        isDeleteLoading={isRemoveLoading}
        members={organisationMembers}
        invites={organisation?.invites || []}
      />
    </Space>
  );
}
