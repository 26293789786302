import { User } from '@raised/domain';
import { FormsModule } from '@raised/ui-kit';
import { Control } from 'react-hook-form';
import { FormConfig } from 'utils/forms';

const { Input, Password } = FormsModule;

export const getJoinOrganisationFormConfig = (control: Control<User.JoinOrganisation>): FormConfig<User.JoinOrganisation> => [
  {
    controller: {
      name: 'email',
      control,
      defaultValue: '',
    },
    component: Input,
    label: 'Email',
    componentProps: {
      placeholder: 'Email',
      'data-testid': 'form_createUser__input_email',
    },
  },
  {
    controller: {
      name: 'firstName',
      control,
      defaultValue: '',
    },
    component: Input,
    label: 'First name',
    componentProps: {
      placeholder: 'First name',
      'data-testid': 'form_createUser__first_name',
    },
  },
  {
    controller: {
      name: 'lastName',
      control,
      defaultValue: '',
    },
    component: Input,
    label: 'Last name',
    componentProps: {
      placeholder: 'Last name',
      'data-testid': 'form_createUser__last_name',
    },
  },
  {
    controller: {
      name: 'password',
      control,
      defaultValue: '',
    },
    component: Password,
    label: 'Password',
    componentProps: {
      placeholder: 'Password',
      'data-testid': 'form_createUser__input_password',
    },
  },
];
