import { Helmet } from 'react-helmet';
import { useGetClientPaymentsQuery } from '../storage/paymentsApi';
import ClientBillingInfoContainer from './ClientBillingInfoContainer';
import PaymentsTable from './payments-table/PaymentsTable';

export default function ClientBillingInfoPage() {
  const {
    data: payments,
    isLoading: isPaymentsLoading,
  } = useGetClientPaymentsQuery();

  return (
    <>
      <Helmet>
        <title>Raised - Billing info</title>
      </Helmet>
      <ClientBillingInfoContainer>
        <PaymentsTable
          payments={payments || []}
          isLoading={isPaymentsLoading}
        />
      </ClientBillingInfoContainer>
    </>
  );
}
