import { Proposal } from '@raised/domain';
import { Result, Typography } from 'antd';
import ViewProposal from 'components/proposal/components/view-proposal/ViewProposal';
import styles from './PaymentInfoComponent.module.css';

interface PaymentInfoComponentProps {
  proposal: Proposal.Public;
}

export default function PaymentInfoComponent({
  proposal,
}: PaymentInfoComponentProps) {
  return (
    <Result
      status="success"
      title="Successfully paid for the proposal"
      className={styles.result}
    >
      <Typography.Title level={2}>Proposal info</Typography.Title>
      <ViewProposal proposal={proposal} />
    </Result>
  );
}
