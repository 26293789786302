import { ConfigProvider } from 'antd';
import { ThemeContext } from 'components/layout/theme/themeContext';
import { RECAPTCHA_CONFIG } from 'configs/recaptcha-config';
import React, { useContext } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import setupStore from 'store';
import router from './router';

const store = setupStore();

function App() {
  const { theme } = useContext(ThemeContext);

  return (
    <GoogleReCaptchaProvider {...RECAPTCHA_CONFIG}>
      <ConfigProvider theme={theme}>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </ConfigProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
