import { LogMessage } from '@raised/domain';
import Table from 'components/layout/components/table/Table';
import { useMemo } from 'react';
import { formatDate } from 'utils/formats';
import { getLastActionsTableConfig, LastActionsTableRow } from './lastActionsTableConfig';

interface LastActionsTableProps {
  isLoading: boolean;
  actions: LogMessage.LastActionsResponse[];
  getUserLink: (userId: string) => string;
  getProjectLink: (projectId: string) => string;
}

export default function LastActionsTable({
  isLoading,
  actions,
  getUserLink,
  getProjectLink,
}: LastActionsTableProps) {
  const tableData: Array<LastActionsTableRow> = useMemo(() => actions.map((action) => ({
    key: action.id,
    projectId: action.projectId,
    projectTitle: action.project.title,
    expertName: `${action.expert.firstName} ${action.expert.lastName}`,
    lastMessageText: action.lastMessageText,
    lastMessageFromName: `${action.lastMessageFrom.firstName} ${action.lastMessageFrom.lastName}`,
    lastMessageFromRole: action.lastMessageFrom.role,
    lastMessageDate: formatDate(new Date(action.lastMessageCreated)),
    clientName: `${action.project.user?.firstName} ${action.project.user?.lastName}`,
    clientLink: getUserLink(action.project.userId),
    expertLink: getUserLink(action.expert.id),
    projectLink: getProjectLink(action.projectId),
    lastMessageFromLink: getUserLink(action.lastMessageFromId),
  })), [actions]);

  const tableColumns = useMemo(() => getLastActionsTableConfig(), []);

  return (
    <Table
      tableName="lastActions"
      loading={isLoading}
      columns={tableColumns}
      dataSource={tableData}
    />
  );
}
