import DefaultFooter from 'components/layout/components/form-modal/default-footer/DefaultFooter';
import FormModal from 'components/layout/components/form-modal/FormModal';
import useCreateProject from 'components/project/hooks/useCreateProject';
import { useRef } from 'react';
import CreateProjectPage from '../CreateProjectPage';

interface CreateProjectModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export default function CreateProjectModal({
  isVisible,
  onClose,
}: CreateProjectModalProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const { isLoading } = useCreateProject({ isNotify: false });

  const onSubmit = () => {
    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  const handleClose = () => {
    onClose();
    formRef.current?.dispatchEvent(new Event('reset', { cancelable: true, bubbles: true }));
  };

  return (
    <FormModal
      title="Create Project"
      footer={<DefaultFooter isLoading={isLoading} onCancel={handleClose} onSubmit={onSubmit} />}
      isVisible={isVisible}
      onClose={handleClose}
    >
      <CreateProjectPage formRef={formRef} isModal onSuccess={handleClose} />
    </FormModal>
  );
}
