// import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useGetProfileQuery } from '../storage/userApi';
import { USER_ROUTES } from '../user-routes';
import PersonalProfileComponent from './PersonalProfileComponent';

export default function PersonalProfilePage() {
  const navigate = useNavigate();
  const { data, isSuccess } = useGetProfileQuery();

  const onEditClickHandler = () => navigate(USER_ROUTES.EDIT_PERSONAL_PROFILE());

  return (
    <>
      <Helmet>
        <title>Raised - Profile</title>
      </Helmet>
      {isSuccess && (
        <PersonalProfileComponent
          profileData={data}
          onEditClickHandler={onEditClickHandler}
        />
      )}
    </>
  );
}
