import { User } from '@raised/domain';
import { Menu } from 'antd';
import { useLocation } from 'react-router-dom';
import styles from './SideMenu.module.css';
import useSideMenu from './useSideMenu';

interface SideMenuProps {
  isLogin: boolean;
  onNotificationsClick: () => void;
  onClick?: () => void;
  profile?: User.Public;
}

export default function SideMenu({
  isLogin,
  onClick,
  profile,
  onNotificationsClick,
}: SideMenuProps) {
  const { pathname } = useLocation();
  const { menuItems } = useSideMenu({
    isLogin, userRole: profile?.role, profileId: profile?.id, onNotificationsClick,
  });

  return (
    <>
      <Menu
        onClick={onClick}
        className={styles.container}
        items={menuItems}
        selectedKeys={[pathname]}
      />
    </>
  );
}
