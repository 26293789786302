import MainLayout from 'components/layout/containers/main-layout/MainLayout';
import { RouteObject } from 'react-router-dom';
import ForgotPasswordPage from './forgot-password/ForgotPasswordPage';
import LoginPage from './login/LoginPage';
import ResetPasswordPage from './reset-password/ResetPasswordPage';
import TokenLoginPage from './token-login/TokenLoginPage';

export const AUTH_ROUTES = {
  LOGIN: () => '/login',
  TOKEN_LOGIN: () => '/token-login',
  FORGOT_PASSWORD: () => '/forgot-password',
  RESET_PASSWORD: () => '/reset-password',
};

const authRoutes: Array<RouteObject> = [
  {
    element: <MainLayout />,
    children: [
      {
        path: AUTH_ROUTES.LOGIN(),
        element: <LoginPage />,
      },
      {
        path: AUTH_ROUTES.TOKEN_LOGIN(),
        element: <TokenLoginPage />,
      },
      {
        path: AUTH_ROUTES.FORGOT_PASSWORD(),
        element: <ForgotPasswordPage />,
      },
      {
        path: AUTH_ROUTES.RESET_PASSWORD(),
        element: <ResetPasswordPage />,
      },
    ],
  },
];

export default authRoutes;
