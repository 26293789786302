import { MatchStatuses, UserRole } from '@raised/domain';
import { Button, Space } from 'antd';

interface MatchActionsCellProps {
  onDeclineClick: (role: UserRole) => void;
  onRestoreClick: () => void;
  onFinishMatch: () => void;
  onAcceptClick: (role: UserRole) => void;
  onAcceptAssignClick: () => void;
  onInviteToInterviewClick: () => void;
  status: MatchStatuses;
  isRestore: boolean;
  isLoading: boolean;
}

export default function MatchActionsCell({
  onDeclineClick,
  onRestoreClick,
  onFinishMatch,
  onAcceptClick,
  onAcceptAssignClick,
  onInviteToInterviewClick,
  status,
  isRestore,
  isLoading,
}: MatchActionsCellProps) {
  const isRestoreAvailable = isRestore && (status === MatchStatuses.DECLINED_BY_CLIENT || status === MatchStatuses.FINISHED || status === MatchStatuses.DECLINED_BY_EXPERT);
  const isDeclineByClientAvailable = ![MatchStatuses.DECLINED_BY_EXPERT, MatchStatuses.DECLINED_BY_CLIENT, MatchStatuses.FINISHED, MatchStatuses.RECOMMENDED_TO_CLIENT, MatchStatuses.INTERSTED_BY_EXPERT].includes(status);
  const isDeclineByExpertAvailable = ![MatchStatuses.DECLINED_BY_EXPERT, MatchStatuses.DECLINED_BY_CLIENT, MatchStatuses.FINISHED, MatchStatuses.INTERSTED_BY_CLIENT, MatchStatuses.RECOMMENDED_TO_EXPERT].includes(status);

  return (
    <Space direction="vertical">
      {status === MatchStatuses.RECOMMENDED_TO_EXPERT ? (
        <>
          <Button type="primary" loading={isLoading} disabled={isLoading} onClick={() => onAcceptClick(UserRole.EXPERT)}>Accept by expert</Button>
          <Button danger loading={isLoading} disabled={isLoading} onClick={() => onDeclineClick(UserRole.EXPERT)}>Decline by expert</Button>
        </>
      ) : null}
      {status === MatchStatuses.RECOMMENDED_TO_CLIENT ? (
        <>
          <Button type="primary" loading={isLoading} disabled={isLoading} onClick={() => onAcceptClick(UserRole.CLIENT)}>Accept by client</Button>
          <Button danger loading={isLoading} disabled={isLoading} onClick={() => onDeclineClick(UserRole.CLIENT)}>Decline by client</Button>
        </>
      ) : null}
      {status === MatchStatuses.INTERSTED_BY_CLIENT ? (
        <>
          <Button type="primary" loading={isLoading} disabled={isLoading} onClick={() => onAcceptAssignClick()}>Accept by expert</Button>
          <Button danger loading={isLoading} disabled={isLoading} onClick={() => onDeclineClick(UserRole.EXPERT)}>Decline by expert</Button>
        </>
      ) : null}
      {status === MatchStatuses.INTERSTED_BY_EXPERT ? (
        <>
          <Button type="primary" loading={isLoading} disabled={isLoading} onClick={onInviteToInterviewClick}>Invite to interview</Button>
          <Button danger loading={isLoading} disabled={isLoading} onClick={() => onDeclineClick(UserRole.CLIENT)}>Decline by client</Button>
        </>
      ) : null}
      {isDeclineByExpertAvailable ? (
        <Button danger loading={isLoading} disabled={isLoading} onClick={() => onDeclineClick(UserRole.EXPERT)}>Decline by expert</Button>
      ) : null}
      {isDeclineByClientAvailable ? (
        <Button danger loading={isLoading} disabled={isLoading} onClick={() => onDeclineClick(UserRole.CLIENT)}>Decline by client</Button>
      ) : null}
      {isRestoreAvailable ? (
        <Button disabled={isLoading} loading={isLoading} onClick={onRestoreClick}>Restore</Button>
      ) : null}
      {status === MatchStatuses.PAYMENT_RECEIVED ? (
        <Button disabled={isLoading} loading={isLoading} type="primary" onClick={onFinishMatch}>Mark as finished</Button>
      ) : null}
    </Space>
  );
}
