import { IconHourglassFilled, IconTrash } from '@tabler/icons-react';
import { Space } from 'antd';
import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { Control, useFieldArray } from 'react-hook-form';
import { renderFormConfig } from 'utils/forms';
import AchievmentsForm from './achievments-form/AchievmentsForm';
import { getCareerInfoFormConfig } from './careerInfoFormConfig';
import PeriodInput from './period-input/PeriodInput';
import styles from './FulltimeCareerForm.module.css';

const { Title, Text } = TypographyModule;
const { Button } = ButtonsModule;

interface ExpertCareerFormProps {
  control: Control;
}

export default function FulltimeCareerForm({
  control,
}: ExpertCareerFormProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fulltimeRoles',
  });

  const handleAppend = () => {
    append({});
  };
  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <Space size={8} direction="vertical" style={{ width: '100%' }}>
      <Space size={16} direction="vertical" style={{ width: '100%' }}>
        {fields.map((item, index) => (
          <div key={item.id} className={styles.form_item}>
            <div className={styles.message}>
              <Title level={4}>{`Full time role #${index + 1}`}</Title>
              {index === 0 ? (
                <Text type="secondary">
                  You do not need to add your entire work history, start with 2-3 most relevant roles. Start with the latest one
                </Text>
              ) : null}
            </div>
            {renderFormConfig(getCareerInfoFormConfig(control, `fulltimeRoles.${index}`))}
            <PeriodInput control={control} prefix={`fulltimeRoles.${index}`} />
            <AchievmentsForm control={control} fieldName={`fulltimeRoles.${index}.keyAchievments`} />
            <Button onClick={() => handleRemove(index)} danger className={styles.delete_btn}>
              <IconTrash size={18} />
              {`Delete full time role #${index + 1}`}
            </Button>
          </div>
        ))}
      </Space>
      <Button onClick={handleAppend} style={{ marginTop: fields?.length ? 25 : 0 }} type="link">
        <IconHourglassFilled size={18} />
        {`Add Full Time Role #${(fields?.length || 0) + 1}`}
      </Button>
    </Space>
  );
}
