import { UserRole } from '@raised/domain';
import { Space, Typography } from 'antd';
import { getRoleLabel } from 'components/user/utils';
import { Link } from 'react-router-dom';

interface LastActionCellProps {
  fromName: string;
  fromRole: UserRole;
  userLink: string;
  text: string;
}

export default function LastActionCell({
  fromName,
  fromRole,
  userLink,
  text,
}: LastActionCellProps) {
  return (
    <Space style={{ whiteSpace: 'nowrap' }}>
      <Typography.Text type="secondary">{`From ${getRoleLabel(fromRole)}`}</Typography.Text>
      <Link to={userLink}>{`${fromName}: `}</Link>
      <Typography.Text>{text}</Typography.Text>
    </Space>
  );
}
