import { FeedbackModule } from '@raised/ui-kit';
import { useGetFileQuery } from 'components/files/storage/filesApi';
import File from '../file/File';

const { Spin } = FeedbackModule;

interface AttachedFileProps {
  fileName: string;
  onDelete?: () => void;
}

export default function AttachedFile({
  fileName,
  onDelete,
}: AttachedFileProps) {
  const { data, isLoading } = useGetFileQuery({ fileName });

  return (
    <File
      onDelete={onDelete}
      link={data?.url || ''}
      name={(
        <>
          {isLoading ? (
            <Spin />
          ) : null}
          {fileName}
        </>
    )}
    />
  );
}
