/* eslint-disable jsx-a11y/no-static-element-interactions */
import { SearchOutlined } from '@ant-design/icons';
import { UserRole } from '@raised/domain';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import { getRoleLabel } from 'components/user/utils';
import { Link } from 'react-router-dom';
import SearchTooltip from './SearchTooltip';

export interface AdminUsersTableRow {
  key: string;
  name: string;
  email: string;
  role: UserRole;
  isActivated: boolean;
  userLink: string;
  comment: string;
  organization: string;
  organizationLink: string;
}

const roleFilters = Object.keys(UserRole).map((role) => ({
  value: role,
  text: getRoleLabel(role),
}));
const activatedFilters = [{ value: false, text: 'No' }, { value: true, text: 'Yes' }];

const filterConfig = (dataIndex: keyof AdminUsersTableRow): ColumnType<AdminUsersTableRow> => ({
  filterDropdown: ({
    setSelectedKeys, selectedKeys, confirm, clearFilters, close,
  }) => (
    <SearchTooltip
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      close={close}
      confirm={confirm}
      clearFilters={clearFilters}
    />
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value, record) => record[dataIndex]
    .toString()
    .toLowerCase()
    .includes((value as string).toLowerCase()),
});

export const getAdminUsersTableConfig = (): ColumnsType<AdminUsersTableRow> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ...filterConfig('name'),
    render: (value, record) => <Link to={record.userLink}>{value}</Link>,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ...filterConfig('email'),
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    filters: roleFilters,
    render: (value) => getRoleLabel(value),
    onFilter: (value, record) => record.role.startsWith(value as string),
  },
  {
    title: 'Organization',
    dataIndex: 'organization',
    render: (value, record) => (record.organizationLink ? <Link to={record.organizationLink}>{value}</Link> : value),
  },
  {
    title: 'Activated',
    dataIndex: 'isActivated',
    key: 'isActivated',
    filters: activatedFilters,
    onFilter: (value, record) => record.isActivated === value,
    render: (value) => (value ? 'yes' : 'no'),
  },
];
