import { z } from 'zod';
import type { Base, Categories, Create, CreateFromTemplate } from './project';
import ProjectCategories from './project-categories';
import ProjectTypes from './project-types';
import ProjectActivityTypes from './project-activity-types';

export const budgetSchema = z.object({
  min: z.number(),
  max: z.number(),
})

type BudgetType = z.infer<typeof budgetSchema>;

export const isMaxBiggerThanMin = (value: BudgetType) => value.max > value.min;

export const projectCategoriesSchema = z.object({
  ceoAndFounders: z.string().array().optional(),
  marketing: z.string().array().optional(),
  salesAndBusinessDevelopment: z.string().array().optional(),
  product: z.string().array().optional(),
  softwareDevelopment: z.string().array().optional(),
  people: z.string().array().optional(),
  operations: z.string().array().optional(),
  strategy: z.string().array().optional(),
  finance: z.string().array().optional(),
  customerSuccess: z.string().array().optional(),
  legal: z.string().array().optional(),
}) satisfies z.ZodType<Categories>;

export const baseProjectSchema = z.object({
  title: z.string(),
  category: z.nativeEnum(ProjectCategories).array().nonempty(),
  type: z.nativeEnum(ProjectTypes).array(),
  attachedFile: z.string().array().optional(),
  description: z.string(),
  projectCategories: projectCategoriesSchema.optional(),
}) satisfies z.ZodType<Base>;

export const createProjectSchema: z.ZodSchema<Create> = z.object({
  expertPreference: z.string().min(1),
  budget: budgetSchema.refine(isMaxBiggerThanMin, 'Max value must be greater than min').nullable(),
  clientId: z.string().optional(),
  usersToNotifyId: z.string().array(),
  projectActivityType: z.nativeEnum(ProjectActivityTypes).optional(),
}).and(baseProjectSchema);

export const createProjectFromTemplateSchema: z.ZodType<CreateFromTemplate> = z.object({
  expertPreference: z.string().min(1),
  budget: budgetSchema.refine(isMaxBiggerThanMin, 'Max value must be greater than min').nullable(),
  clientId: z.string().optional(),
  usersToNotifyId: z.string().array(),
  challenge: z.string(),
  parts: z.object({
          title: z.string().min(1),
          description: z.string().min(1),
        }).array().optional(),
}).and(baseProjectSchema);
