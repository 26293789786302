import { PROJECT_ROUTES } from 'components/project/project-routes';
import { USER_ROUTES } from 'components/user/user-routes';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useGetPaymentsToExpertQuery } from '../storage/paymentsApi';
import ExpertBillingInfoComponent from './ExpertBillingInfoComponent';

export default function ExpertBillingInfoPage() {
  const navigate = useNavigate();

  const {
    data: payments,
    isLoading: isPaymentsLoading,
  } = useGetPaymentsToExpertQuery();

  const onProjectClick = (projectId: string) => navigate(PROJECT_ROUTES.PROJECT(projectId));
  const onClientClick = (clientId: string) => navigate(USER_ROUTES.USER_PROFILE(clientId));

  return (
    <>
      <Helmet>
        <title>Raised - Billing info</title>
      </Helmet>
      <ExpertBillingInfoComponent
        payments={payments || []}
        isLoading={isPaymentsLoading}
        onProjectClick={onProjectClick}
        onClientClick={onClientClick}
      />
    </>
  );
}
