import { ColorsModule } from '@raised/ui-kit';
import { theme } from 'antd';
import { ThemeConfig } from 'antd/es/config-provider/context';

const { Colors } = ColorsModule;

export enum ThemeEnum {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

const darkTheme: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
};

const lightTheme: ThemeConfig = {
  algorithm: theme.defaultAlgorithm,
  token: {
    colorBgBase: '#fff',
    fontFamily: "'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    fontSizeHeading1: 24,

    fontSizeHeading2: 20,

    fontSizeHeading3: 16,

    fontSizeHeading4: 16,
  },
  components: {
    Menu: {
      colorItemBgSelected: Colors['light-gray'],
      colorItemBgHover: Colors['light-gray'],
      colorItemTextSelected: 'rgba(0, 0, 0, 0.85)',
    },
    Button: {
      colorPrimary: Colors.black,
      colorPrimaryHover: Colors.gray,
    },
    Select: {
      colorFillSecondary: Colors.base,
    },
  },
};

const themes: { [key in ThemeEnum]: ThemeConfig } = {
  [ThemeEnum.DARK]: darkTheme,
  [ThemeEnum.LIGHT]: lightTheme,
};

export default themes;
