import { Modal, Spin, Collapse } from 'antd';
import ViewProposal from 'components/proposal/components/view-proposal/ViewProposal';
import { useGetProposalsByMatchQuery } from 'components/proposal/storage/proposalApi';

const { Panel } = Collapse;

interface ViewProposalsModalProps {
  isVisible: boolean;
  onClose: () => void;
  matchId: string;
}

export default function ViewProposalsModal({
  isVisible,
  onClose,
  matchId,
}: ViewProposalsModalProps) {
  const {
    data,
    isLoading,
    isSuccess,
  } = useGetProposalsByMatchQuery({ matchId }, { skip: !isVisible });

  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null}
    >
      {isLoading ? (
        <Spin />
      ) : null}
      {isSuccess ? (
        <div style={{ paddingRight: 20 }}>
          <Collapse>
            {data.map((proposal) => (
              <Panel key={proposal.id} header={`Title: ${proposal.title}`}>
                <ViewProposal proposal={proposal} />
              </Panel>
            ))}
          </Collapse>
        </div>
      ) : null}
    </Modal>
  );
}
