/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  LogMessage, Match, MatchStatuses, Payment, Proposal, ProposalStatuses,
} from '@raised/domain';
import { ClientMatchOverviewTabs } from 'components/match/types/client-match-overview-tabs';
import { useState } from 'react';
import { IconChevronLeft } from '@tabler/icons-react';
import ChatView from 'components/messages/components/chat-view/ChatView';
import useChatWindow from 'components/messages/hooks/useChatWindow';
import { LayoutModule, NavigationModule, TypographyModule } from '@raised/ui-kit';
import CandidateModal from 'components/project/components/candidate-modal/CandidateModal';
import MobileChatHeader from 'components/messages/components/chat-view/mobile-chat-header/MobileChatHeader';
import MatchInfo from './match-info/MatchInfo';
import MatchActions from './MatchActions';
import LogTab from './tabs/log-tab/LogTab';
import PaymentsTab from './tabs/payments-tab/PaymentsTab';
import ProposalTab from './tabs/proposal-tab/ProposalTab';
import styles from './ClientMatchOverviewComponent.module.css';
import BioTab from './tabs/bio-tab/BioTab';

const { Tabs } = LayoutModule;
const { Text } = TypographyModule;
const { Breadcrumb } = NavigationModule;

interface ClientMatchOverviewComponentProps {
  onBack: () => void;
  selectedTab?: ClientMatchOverviewTabs;
  match: Match.Public;
  logMessages?: LogMessage.Public[];
  isLogMessagesLoading: boolean;
  proposals: Proposal.Public[];
  isUpdateStatusLoading: boolean;
  onUpdateProposalStatus: (proposalId: string, status: ProposalStatuses) => void;
  payments: Payment.Public[];
  isPaymentsLoading: boolean;
  isUpdateMatchLoading: boolean;
  onRestoreMatch: () => void;
  isRestoreMatchLoading: boolean;
  onSubmitMatchUpdate: (status: MatchStatuses, message: string) => void;
  onTabChange: (tab: ClientMatchOverviewTabs) => void;
  isProposalLinkLoading: boolean;
  onProposalPayClick: (propId: string) => void;
  isProposalLinkSuccess: boolean;
}

export default function ClientMatchOverviewComponent({
  selectedTab,
  onBack,
  match,
  logMessages,
  isLogMessagesLoading,
  proposals,
  isUpdateStatusLoading,
  onUpdateProposalStatus,
  payments,
  isPaymentsLoading,
  isUpdateMatchLoading,
  onRestoreMatch,
  isRestoreMatchLoading,
  onSubmitMatchUpdate,
  onTabChange,
  isProposalLinkSuccess,
  isProposalLinkLoading,
  onProposalPayClick,
}: ClientMatchOverviewComponentProps) {
  const [decision, setDecision] = useState<MatchStatuses>();
  const { isChatVisible, toggleChat, openChat } = useChatWindow();

  const onDeclineClick = () => setDecision(MatchStatuses.DECLINED_BY_CLIENT);
  const onAcceptClick = () => setDecision(MatchStatuses.INTERSTED_BY_CLIENT);
  const onInviteToInterviewClick = () => setDecision(MatchStatuses.INVITED_TO_INTERVIEW);
  const onFinishClick = () => setDecision(MatchStatuses.FINISHED);
  const onModalClose = () => setDecision(undefined);

  const onModalSubmit = (message: string) => {
    if (!decision) return;

    onSubmitMatchUpdate(decision, message);
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabs_container}>
        <Breadcrumb
          className={styles.breadcrumb}
          items={[
            {
              title: <a onClick={onBack}>Project</a>,
            },
            {
              title: `${match.user?.firstName} ${match.user?.lastName}`,
            },
          ]}
        />
        <div onClick={onBack} className={styles.mobile_back}>
          <IconChevronLeft size={24} stroke={1.5} />
          <Text className={styles.back_text}>{match.project?.title}</Text>
        </div>
        {/* <TimezoneMessage /> */}
        {match.user ? (
          <MatchInfo
            expert={match.user}
            actions={(
              <MatchActions
                isLoading={isUpdateMatchLoading || isRestoreMatchLoading}
                onAcceptClick={onAcceptClick}
                onInviteClick={onInviteToInterviewClick}
                onDeclineClick={onDeclineClick}
                onRestoreClick={onRestoreMatch}
                onFinishClick={onFinishClick}
                match={match}
                proposals={proposals}
              />
            )}
          />
        ) : null}
        <Tabs
          defaultActiveKey={selectedTab || ClientMatchOverviewTabs.BIO}
          onChange={(key) => onTabChange(key as ClientMatchOverviewTabs)}
          items={[
            {
              label: 'Expert Profile',
              key: ClientMatchOverviewTabs.BIO,
              children: <BioTab
                expert={match.user}
              />,
            },
            {
              label: 'Proposals',
              key: ClientMatchOverviewTabs.PROPOSAL,
              children: <ProposalTab
                proposals={proposals}
                isUpdateStatusLoading={isUpdateStatusLoading}
                onUpdateProposalStatus={onUpdateProposalStatus}
                onPayClick={onProposalPayClick}
                isPaymentLinkLoading={isProposalLinkLoading}
                isPaymentLinkSuccess={isProposalLinkSuccess}
              />,
            },
            {
              label: 'Payments',
              key: ClientMatchOverviewTabs.PAYMENTS,
              children: <PaymentsTab
                payments={payments}
                isLoading={isPaymentsLoading}
              />,
            },
            {
              label: 'Log',
              key: ClientMatchOverviewTabs.LOG,
              children: <LogTab
                logMessages={logMessages}
                isLogMessagesLoading={isLogMessagesLoading}
              />,
            },
          ]}
        />
        <CandidateModal
          isVisible={!!decision}
          decision={decision}
          onSubmit={onModalSubmit}
          onClose={onModalClose}
        />
      </div>
      {match.chat?.[0] ? (
        <ChatView
          onClose={toggleChat}
          onOpen={openChat}
          className={styles.chat}
          isVisible={isChatVisible}
          chatId={match.chat[0].id}
          withPreview
          chatHeader={(
            <MobileChatHeader
              title="Chat"
              newMessagesCount={0}
              isLoading={false}
              onClick={toggleChat}
              isChatVisible={isChatVisible}
              chatMembers={match.chat[0].participants}
            />
          )}
        />
      ) : null}
    </div>
  );
}
