import { ProjectStatuses } from '@raised/domain';
import {
  Button, Select, Space, Typography,
} from 'antd';
import { useState } from 'react';
import { PROJECT_STATUS_LABELS } from '../../constants/project-status-labels';
import styles from './SelectProjectStatus.module.css';

interface SelectProjectStatusComponentProps {
  defaultValue: ProjectStatuses;
  onChange: (status: ProjectStatuses) => void;
  isLoading: boolean;
}

const statusOptions = Object.entries(PROJECT_STATUS_LABELS).map(([status, label]) => ({
  value: status,
  label,
}));

export default function SelectProjectStatusComponent({
  defaultValue,
  onChange,
  isLoading,
}: SelectProjectStatusComponentProps) {
  const [status, setStatus] = useState(defaultValue);

  return (
    <Space className={styles.container} style={{ marginTop: 16 }}>
      <Typography.Text type="secondary" style={{ margin: 0 }}>Project status</Typography.Text>
      <Space.Compact>
        <Select
          options={statusOptions}
          value={status}
          onChange={(value) => setStatus(value)}
          loading={isLoading}
          disabled={isLoading}
          className={styles.select}
        />
        <Button
          onClick={() => onChange(status)}
          disabled={isLoading}
          loading={isLoading}
          type="primary"
        >
          Save
        </Button>
      </Space.Compact>
    </Space>
  );
}
