import { useGetProfileQuery } from 'components/user/storage/userApi';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useUploadFile from 'components/files/hooks/useUploadFIle';
import { Helmet } from 'react-helmet';
import useCreateMessage from '../hooks/useCreateMessage';
import ChatView from './components/chat-view/ChatView';
import { useGetChatByIdQuery, useGetSupportChatQuery } from '../storage/messageApi';

interface ChatPageProps {
  isSupport?: boolean;
}

export default function ChatPage({
  isSupport = false,
}: ChatPageProps) {
  const [entryDate] = useState(() => new Date().toISOString());
  const { id } = useParams<{ id: string }>();
  const { data: profile, isSuccess: isProfileSuccess } = useGetProfileQuery();
  const { data: matchChat } = useGetChatByIdQuery({ chatId: id as string }, { skip: !id || isSupport });
  const { data: supportChat } = useGetSupportChatQuery(undefined, { skip: !isSupport });
  const {
    uploadFile, uploadedFilePath, isLoading: isFileUploading, resetState,
  } = useUploadFile();

  const { createMessage } = useCreateMessage();

  const chat = isSupport ? supportChat : matchChat;

  const onSendMessage = (text: string) => {
    if (!profile) return;

    const chatId = isSupport ? supportChat?.id : id;

    if (!chatId) return;

    createMessage({
      text, chatId, fromDate: entryDate, fromId: profile.id, fileUrl: uploadedFilePath,
    }).then(() => {
      resetState();
    });
  };

  // const chatTitle = useMemo(() => {
  //   const defaultTitle = chat?.match?.project?.title || 'Support';
  //
  //   if (profile?.role === UserRole.ADMIN) {
  //     if (chat?.match) {
  //       return `${defaultTitle}${chat?.match ? ` / Status: ${ADMIN_MATCH_STATUS_LABELS[chat?.match.status]}` : ''}`;
  //     }
  //
  //     return `${defaultTitle} - ${chat?.participants?.[0]?.firstName} ${chat?.participants?.[0]?.lastName}`;
  //   }
  //
  //   return defaultTitle;
  // }, [chat, profile]);

  return (
    <>
      <Helmet>
        <title>{`Raised - ${isSupport ? 'Support' : 'Chat'}`}</title>
      </Helmet>
      {isProfileSuccess && chat ? (
        <ChatView
          personalProfileId={profile?.id}
          onSendMessage={onSendMessage}
          entryDate={entryDate}
          uploadFile={uploadFile}
          isFileUploading={isFileUploading}
          isFileLoaded={!!uploadedFilePath}
          chat={chat}
          profile={profile}
          // chatHeader={<ChatHeader title={chatTitle} />}
        />
      ) : null}
    </>
  );
}
