import {
  ButtonsModule, ColorsModule, FeedbackModule, TypographyModule,
} from '@raised/ui-kit';
import { IconSquareRoundedPlus } from '@tabler/icons-react';
import styles from './TemplatesTitle.module.css';

const { Title } = TypographyModule;
const { Button } = ButtonsModule;
const { Spin } = FeedbackModule;
const { Colors } = ColorsModule;

interface TemplatesTitleProps {
  isCreateButton: boolean;
  onCreateClick: () => void;
  templatesCount?: number;
  isLoading: boolean;
}

export default function TemplatesTitle({
  isCreateButton,
  onCreateClick,
  templatesCount,
  isLoading,
}: TemplatesTitleProps) {
  return (
    <div className={styles.templates_title}>
      <Title level={1}>Templates</Title>
      {!isLoading ? (
        <Title className={styles.count} level={1}>{templatesCount}</Title>
      ) : (
        <Spin size="small" />
      )}
      {isCreateButton ? (
        <Button type="link" icon={<IconSquareRoundedPlus color={Colors.blue} size={24} />} onClick={onCreateClick} />
      ) : null}
    </div>
  );
}
