import { User, UserSchemas } from '@raised/domain';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { scrollToError } from 'utils/forms';
import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { zodResolver } from '@hookform/resolvers/zod';
import FulltimeCareerForm from '../../../form-components/expert-career/FulltimeCareerForm';
import ParttimeCareerForm from '../../../form-components/expert-career/PartimeCareerForm';
import ExpertTestimonialsForm from '../../../form-components/expert-testimonials/ExpertTestimonialsForm';
import styles from './CareerHistoryStep.module.css';

const { Title } = TypographyModule;
const { Button } = ButtonsModule;

interface CareerHistoryStepProps {
  defaultValues: User.Public;
  onSubmit: (values: User.Edit) => void;
  isLoading: boolean;
  onCancel: () => void;
}

export default function CareerHistoryStep({
  defaultValues,
  onSubmit,
  isLoading,
  onCancel,
}: CareerHistoryStepProps) {
  const { handleSubmit, control } = useForm<User.Edit>({
    defaultValues: {
      ...defaultValues,
      adminComment: defaultValues.adminComment?.comment || undefined,
    },
    resolver: zodResolver(UserSchemas.editUserSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit, scrollToError)}>
      <Title level={2} className={styles.title}>Career highlights</Title>
      <FulltimeCareerForm control={control as unknown as Control<FieldValues>} />
      <ParttimeCareerForm control={control as unknown as Control<FieldValues>} />
      <ExpertTestimonialsForm control={control as unknown as Control<FieldValues>} />
      <div className={styles.buttons}>
        <Button
          loading={isLoading}
          disabled={isLoading}
          type="primary"
          htmlType="submit"
          style={{ flex: 2 }}
        >
          Save
        </Button>
        <Button onClick={onCancel} style={{ flex: 1 }}>Cancel</Button>
      </div>
    </form>
  );
}
