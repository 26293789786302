import {
  IconAd2, IconCloudStorm, IconBulb, IconTargetArrow,
} from '@tabler/icons-react';
import { useGetSuccessStoryByIdQuery } from 'components/success-stories/storage/successStoriesApi';
import { USER_ROUTES } from 'components/user/user-routes';
import { useNavigate, useParams } from 'react-router-dom';
import BottomBlur from './components/bottom-blur/BottomBlur';
import ClientQuote from './components/client-quote/ClientQuote';
import ContentBlock from './components/content-block/ContentBlock';
import ExpertInfo from './components/expert-info/ExpertInfo';
import SuccessStoryHeader from './components/success-story-header/SuccessStoryHeader';
import SuccessStorySlider from './components/success-story-slider/SuccessStorySlider';
import ViewSuccessStoryContent from './ViewSuccessStoryContent';

export default function ViewSuccessStoryPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: successStory,
    isLoading: isSuccessStoryLoading,
  } = useGetSuccessStoryByIdQuery({ id: id || '' }, { skip: !id });

  const onExpertPageClick = () => {
    if (!successStory?.user || !successStory?.user?.publishedOnPublicWebsites) return;

    navigate(USER_ROUTES.PUBLIC_EXPERT_PROFILE(successStory.user.publishedOnPublicWebsites));
  };

  return (
    <ViewSuccessStoryContent isLoading={isSuccessStoryLoading}>
      <BottomBlur />
      <SuccessStoryHeader successStory={successStory} />
      <ContentBlock
        icon={<IconAd2 size={20} />}
        title="Brief"
        content={successStory?.brief || ''}
      />
      <ContentBlock
        icon={<IconCloudStorm size={20} />}
        title="Challenge"
        content={successStory?.challenge || ''}
      />
      <ContentBlock
        icon={<IconBulb size={20} />}
        title="Solution"
        content={successStory?.solutions || ''}
      />
      <ContentBlock
        icon={<IconTargetArrow size={20} />}
        title="Results"
        content={successStory?.results || ''}
      />
      <SuccessStorySlider images={successStory?.files} />
      <ClientQuote successStory={successStory} />
      <ExpertInfo
        onExpertPageClick={onExpertPageClick}
        expert={successStory?.user}
      />
    </ViewSuccessStoryContent>
  );
}
