import { LogMessage, Message } from '@raised/domain';
import { Spin, Typography } from 'antd';
import NotificationItem from './notification-item/NotificationItem';
import styles from './NotificationsList.module.css';

interface NotificationsListProps {
  notifications: Array<Message.Public | LogMessage.Public>;
  onNotificationClick: (notification: Message.Public | LogMessage.Public) => void;
  isLoading: boolean;
  setObserverForNotification: (element: HTMLDivElement) => void;
  personalProfileId: string;

}

export default function NotificationsList({
  notifications,
  onNotificationClick,
  isLoading,
  setObserverForNotification,
  personalProfileId,
}: NotificationsListProps) {
  if (isLoading) {
    return <Spin />;
  }

  const getIsNotificationViewed = (notification: LogMessage.Public | Message.Public) => notification.viewedBy.includes(personalProfileId);

  return (
    <div className={styles.container}>
      {!notifications.length ? (
        <Typography.Text>No new notifications</Typography.Text>
      ) : null}
      {notifications.map((notification) => (
        <NotificationItem
          item={notification}
          onClick={() => onNotificationClick(notification)}
          key={notification.id}
          isViewed={getIsNotificationViewed(notification)}
          observerRef={getIsNotificationViewed(notification) ? undefined : setObserverForNotification}
        />
      ))}
    </div>
  );
}
