import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { IconHourglassLow, IconTrash } from '@tabler/icons-react';
import { Space } from 'antd';
import { Control, useFieldArray } from 'react-hook-form';
import { renderFormConfig } from 'utils/forms';
import { getExpertTestimonialsFormConfig } from './expertTestimonialsFormConfig';
import styles from './ExpertTestimonialsForm.module.css';

const { Button } = ButtonsModule;
const { Title, Text } = TypographyModule;

interface ExpertTestimonialsFormProps {
  control: Control;
}

export default function ExpertTestimonialsForm({
  control,
}: ExpertTestimonialsFormProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'testimonials',
  });

  const handleRemove = (index: number) => remove(index);

  return (
    <Space direction="vertical" size={0} style={{ width: '100%' }}>
      <Title level={2} style={{ margin: 0 }}>Testimonials</Title>
      <Text type="secondary" style={{ marginBottom: 8, display: 'block' }}>Please Submit Testimonials from Your Previous Clients, Managers, and Employers</Text>
      <Space size={24} direction="vertical" style={{ width: '100%' }}>
        {fields.map((item, index) => (
          <div key={item.id} className={styles.form_item}>
            <Title level={4} className={styles.form_item__title}>{`Testimonial #${index + 1}`}</Title>
            {renderFormConfig(getExpertTestimonialsFormConfig(control, `testimonials.${index}`))}
            <Button onClick={() => handleRemove(index)} danger className={styles.delete_btn}>
              <IconTrash size={18} />
              {`Delete testimonial #${index + 1}`}
            </Button>
          </div>
        ))}
      </Space>
      <Button type="link" onClick={append} style={{ marginTop: fields.length ? 24 : 0 }}>
        <IconHourglassLow size={18} />
        Add Testimonial
      </Button>
    </Space>
  );
}
