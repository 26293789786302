import { zodResolver } from '@hookform/resolvers/zod';
import { UserSchemas } from '@raised/domain';
import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { useForm } from 'react-hook-form';
import { renderFormConfig } from 'utils/forms';
import { ResetPasswordType } from './reset-password-types';
import { getResetPasswordFormConfig } from './resetPasswordFormConfig';

const { Button } = ButtonsModule;
const { Title } = TypographyModule;

interface ResetPasswordComponentProps {
  onSubmit: (password: string) => void;
  isLoading: boolean;
}

export default function ResetPasswordComponent({
  onSubmit,
  isLoading,
}: ResetPasswordComponentProps) {
  const { control, handleSubmit } = useForm<ResetPasswordType>({
    resolver: zodResolver(UserSchemas.resetPasswordSchema),
  });

  const formConfig = getResetPasswordFormConfig(control);

  return (
    <form
      className="form_container"
      onSubmit={handleSubmit((values) => onSubmit(values.password))}
    >
      <Title
        level={1}
        style={{ textAlign: 'center' }}
      >
        Reset account password
      </Title>
      {renderFormConfig(formConfig)}
      <Button
        htmlType="submit"
        type="primary"
        disabled={isLoading}
        loading={isLoading}
      >
        Submit
      </Button>
    </form>
  );
}
