/* eslint-disable consistent-return */
import { useEffect } from 'react';

export default function useChatHeight() {
  useEffect(() => {
    if (window.innerWidth > 500) return;

    const handleResize = () => {
      document.documentElement.style.setProperty('--document-height', `${window.innerHeight}px`);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
}
