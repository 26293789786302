import { z } from 'zod';
import ProjectPaymentPeriods from '../project/project-payment-periods';
import ProjectPayments from '../project/project-payments';
import ProjectTypes from '../project/project-types';
import { Create } from './proposal';
import ProposalPaymentsReceive from './proposal-payments-receive';

const projectLengthSchema: z.ZodSchema<Create['projectLength']> = z.object({
  period: z.nativeEnum(ProjectPaymentPeriods),
  value: z.number(),
});

export const createProposalSchema: z.ZodSchema<Create> = z.object({
  matchId: z.string(),
  title: z.string().min(1),
  description: z.string().min(1),
  projectType: z.nativeEnum(ProjectTypes).optional(),
  documentUrls: z.string().array().optional(),
  approachAndDeliverables: z.string(),
  projectPayment: z.nativeEnum(ProjectPayments),
  paymentPeriod: z.nativeEnum(ProjectPaymentPeriods).optional(),
  commitmentForPeriod: z.string().optional(),
  paymentRate: z.number().optional(),
  paymentsReceivePeriod: z.nativeEnum(ProposalPaymentsReceive).optional(),
  dateOfStart: z.string().optional(),
  dateOfFinish: z.string().optional(),
  projectLength: projectLengthSchema.nullable().optional(),
  upfront: z.number().optional(),
}).refine(val => {
  if (val.projectPayment === ProjectPayments.PAY_AS_YOU_GO) {
    return !!val.paymentRate && !!val.paymentPeriod && !!val.commitmentForPeriod && !!val.paymentsReceivePeriod && !!val.dateOfStart && typeof val.projectLength !== 'undefined';
  }

  if (val.projectPayment === ProjectPayments.FIXED_SUBSCRIPTION) {
    return !!val.paymentRate && !!val.paymentPeriod && !!val.commitmentForPeriod && !!val.paymentsReceivePeriod && !!val.dateOfStart && typeof val.projectLength !== 'undefined';
  }

  if (val.projectPayment === ProjectPayments.FIXED_SCOPE_PROJECT) {
    return !!val.projectLength && !!val.paymentRate && !!val.paymentPeriod && !!val.dateOfStart;
  }

  return true
}, { message: 'inavlid' });
