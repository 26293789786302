import { ButtonsModule, LayoutModule, TypographyModule } from '@raised/ui-kit';
import { IconFileStack } from '@tabler/icons-react';
import styles from './TemplateControls.module.css';

const { Space } = LayoutModule;
const { Text, Title } = TypographyModule;
const { Button } = ButtonsModule;

interface TemplateControlsProps {
  onCreate: () => void;
  onContact: () => void;
}

export default function TemplateControls({
  onCreate,
  onContact,
}: TemplateControlsProps) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.info}>
          <IconFileStack stroke={1} size={38} />
          <Space direction="vertical" size={0}>
            <Title level={4}>Post a similar project</Title>
            <Text>Or contact us to help you scope the project</Text>
          </Space>
        </div>
        <div className={styles.buttons}>
          <Button type="primary" onClick={onCreate} className={styles.create_btn}>Post by yourself</Button>
          <Button onClick={onContact} className={styles.contact_btn}>Contact us</Button>
        </div>
      </div>
    </div>
  );
}
