import { FormsModule, LayoutModule, TypographyModule } from '@raised/ui-kit';
import { Control, Controller } from 'react-hook-form';

const { Checkbox } = FormsModule;
const { Space } = LayoutModule;
const { Title, Text } = TypographyModule;

interface ProfileVisibilityProps {
  control: Control;
}

export default function ProfileVisibility({
  control,
}: ProfileVisibilityProps) {
  const isMobile = window.innerWidth < 500;

  return (
    <Space direction="vertical" size={0}>
      <Title style={{ margin: 0 }} level={2}>Visibility</Title>
      <Text style={{ display: 'flex', alignItems: 'center', gap: 4 }} type="secondary">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
          <path d="M3 6.33333V3.66667C3 2.95942 3.28095 2.28115 3.78105 1.78105C4.28115 1.28095 4.95942 1 5.66667 1C6.37391 1 7.05219 1.28095 7.55229 1.78105C8.05238 2.28115 8.33333 2.95942 8.33333 3.66667V6.33333M1 7.66667C1 7.31304 1.14048 6.97391 1.39052 6.72386C1.64057 6.47381 1.97971 6.33333 2.33333 6.33333H9C9.35362 6.33333 9.69276 6.47381 9.94281 6.72386C10.1929 6.97391 10.3333 7.31304 10.3333 7.66667V11.6667C10.3333 12.0203 10.1929 12.3594 9.94281 12.6095C9.69276 12.8595 9.35362 13 9 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V7.66667ZM5 9.66667C5 9.84348 5.07024 10.013 5.19526 10.1381C5.32029 10.2631 5.48986 10.3333 5.66667 10.3333C5.84348 10.3333 6.01305 10.2631 6.13807 10.1381C6.2631 10.013 6.33333 9.84348 6.33333 9.66667C6.33333 9.48986 6.2631 9.32029 6.13807 9.19526C6.01305 9.07024 5.84348 9 5.66667 9C5.48986 9 5.32029 9.07024 5.19526 9.19526C5.07024 9.32029 5 9.48986 5 9.66667Z" stroke="black" strokeOpacity="0.45" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        not public
      </Text>
      <Text type="secondary" style={{ marginBottom: 6, display: 'block' }}>
        Let us know if you would like to take a break
      </Text>
      <Controller
        control={control}
        name="isProfileVisible"
        defaultValue={false}
        render={({ field }) => (
          <Space size={40}>
            <Checkbox style={{ whiteSpace: 'nowrap' }} checked={field.value} onChange={() => field.onChange(true)}>Keep My Profile Visible</Checkbox>
            <Checkbox style={{ whiteSpace: 'nowrap' }} checked={!field.value} onChange={() => field.onChange(false)}>{isMobile ? 'Pause and Hide' : 'Pause and Hide My Profile'}</Checkbox>
          </Space>
        )}
      />
    </Space>
  );
}
