import { ComponentProps } from 'react';
import cn from 'classnames';
import { Button } from '../button';
import styles from './ActionButton.module.css';

interface ActionButtonProps extends ComponentProps<typeof Button> {}

export default function ActionButton({
  className,
  ...props
}: ActionButtonProps) {
  return (
    <Button type="text" {...props} className={cn(styles.action_button, className)} />
  );
}
