import { Organisation } from '@raised/domain';
import { rootApi } from 'api/rootApi';

export const organisationApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganisationById: build.query<Organisation.Public, { id: string }>({
      providesTags: (res) => (res ? [{ type: 'ORGANISATION', id: res.id }] : ['ORGANISATION']),
      query: ({ id }) => ({
        url: `/organisation/${id}`,
      }),
    }),
    getAllOrganisations: build.query<Organisation.Public[], void>({
      providesTags: (res) => (res?.length ? (
        [...res.map((org) => ({ type: 'ORGANISATION', id: org.id } as const)), 'ORGANISATION']
      ) : ['ORGANISATION']),
      query: () => ({
        url: '/organisation',
      }),
    }),
    updateOrganisationById: build.mutation<Organisation.Public, Organisation.Edit & { organisationId: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'ORGANISATION', id: args.organisationId }],
      query: ({ organisationId, ...body }) => ({
        url: `/organisation/${organisationId}`,
        body,
        method: 'PUT',
      }),
    }),
    verifyOrganisation: build.mutation<boolean, { organisationId: string, token: string }>({
      query: (body) => ({
        url: '/organisation/verify',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetOrganisationByIdQuery,
  useGetAllOrganisationsQuery,
  useUpdateOrganisationByIdMutation,
  useVerifyOrganisationMutation,
} = organisationApi;
