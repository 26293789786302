import { Match, MatchStatuses, Proposal } from '@raised/domain';
import { ButtonsModule, TypographyModule, ColorsModule } from '@raised/ui-kit';
import { IconRestore, IconThumbDown, IconThumbUp } from '@tabler/icons-react';
import { getClientMatchStatusLabel } from 'components/match/constants/match-status-lables';
import styles from './MatchActions.module.css';

const { Title, Text } = TypographyModule;
const { Colors } = ColorsModule;
const { Button } = ButtonsModule;

interface MatchActionsProps {
  onDeclineClick: () => void;
  onRestoreClick: () => void;
  onFinishClick: () => void;
  onAcceptClick: () => void;
  onInviteClick: () => void;
  match: Match.Public;
  isLoading: boolean;
  proposals: Proposal.Public[];
}

export default function MatchActions({
  onDeclineClick,
  onRestoreClick,
  onFinishClick,
  onAcceptClick,
  onInviteClick,
  match,
  isLoading,
  proposals,
}: MatchActionsProps) {
  const isDeclineAvailable = match.status !== MatchStatuses.PAYMENT_RECEIVED && match.status !== MatchStatuses.DECLINED_BY_CLIENT && match.status !== MatchStatuses.DECLINED_BY_EXPERT && match.status !== MatchStatuses.FINISHED && match.status !== MatchStatuses.RECOMMENDED_TO_CLIENT && match.status !== MatchStatuses.INTERSTED_BY_EXPERT;
  const isRestoreAvailable = (match.status === MatchStatuses.DECLINED_BY_CLIENT || match.status === MatchStatuses.FINISHED) && match.oldStatus;
  const isFinishAvailable = match.status === MatchStatuses.PAYMENT_RECEIVED;
  const matchStatus = getClientMatchStatusLabel(match.status, proposals);

  const containerColors = () => {
    if (match.status === MatchStatuses.DECLINED_BY_CLIENT || match.status === MatchStatuses.DECLINED_BY_EXPERT) {
      return {
        background: Colors.pink,
        border: 'rgba(0, 0, 0, 0.05)',
      };
    }

    return {
      background: Colors['light-green'],
      border: Colors.green,
    };
  };

  return (
    <div className={styles.container} style={{ backgroundColor: containerColors().background, borderColor: containerColors().border }}>
      <div className={styles.status_block}>
        {matchStatus.icon}
        <div className={styles.status_block__content}>
          <Title level={4}>{matchStatus.text}</Title>
          {matchStatus?.description ? (
            <Text>{matchStatus.description}</Text>
          ) : null}
        </div>
      </div>
      {match.status === MatchStatuses.RECOMMENDED_TO_CLIENT ? (
        <div className={styles.buttons}>
          <Button className={styles.button} danger onClick={onDeclineClick}>
            <IconThumbDown size={18} />
            {' '}
            Decline
          </Button>
          <Button className={styles.button} type="primary" onClick={onAcceptClick}>
            <IconThumbUp size={18} />
            {' '}
            I`m intersted
          </Button>
        </div>
      ) : null}
      {match.status === MatchStatuses.INTERSTED_BY_EXPERT ? (
        <div className={styles.buttons}>
          <Button onClick={onDeclineClick} danger className={styles.button}>
            <IconThumbDown size={18} />
            {' '}
            Decline
          </Button>
          <Button type="primary" onClick={onInviteClick} className={styles.button}>
            <IconThumbUp size={18} />
            {' '}
            Invite to interview
          </Button>
        </div>
      ) : null}
      {isDeclineAvailable ? (
        <Button
          className={styles.button}
          danger
          onClick={onDeclineClick}
          loading={isLoading}
          disabled={isLoading}
        >
          <IconThumbDown size={18} />
          Decline
        </Button>
      ) : null}
      {isRestoreAvailable ? (
        <Button
          className={styles.button}
          onClick={onRestoreClick}
          loading={isLoading}
          disabled={isLoading}
        >
          <IconRestore size={18} />
          Restore the Expert
        </Button>
      ) : null}
      {isFinishAvailable ? (
        <Button
          className={styles.button}
          type="primary"
          onClick={onFinishClick}
          loading={isLoading}
          disabled={isLoading}
        >
          Mark as finished
        </Button>
      ) : null}
    </div>
  );
}
