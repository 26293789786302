import { ColorsModule, DataModule } from '@raised/ui-kit';
import styles from './ProjectsTabLabel.module.css';

const { Badge } = DataModule;
const { Colors } = ColorsModule;

interface ProjectsTabLabelProps {
  count: number;
  title: string;
  isActive: boolean;
}

export default function ProjectsTabLabel({
  count,
  title,
  isActive,
}: ProjectsTabLabelProps) {
  return (
    <div className={styles.tab_label}>
      {title}
      <Badge
        count={count}
        showZero
        color={isActive ? Colors['light-blue'] : Colors['light-gray']}
        style={{ color: isActive ? Colors.blue : Colors.gray }}
      />
    </div>
  );
}
