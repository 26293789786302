import { ProjectPaymentPeriods, Proposal } from '@raised/domain';
import { Radio, Space, Typography } from 'antd';
import { Control, Controller } from 'react-hook-form';
import CalculatePaymentInfo from '../components/CalculatePaymentInfo';
import PaymentRateInput from '../inputs/PaymentRateInput';
import ProjectLengthInput from '../inputs/project-length/ProjectLengthInput';
import ProjectDateInput from '../inputs/ProjectDateInput';

interface FixedFormProps {
  control: Control<Proposal.Create>;
  // handleSetValue: UseFormSetValue<Proposal.Create>;
  profilePaymentRate?: string;
}

export default function FixedForm({
  control,
  profilePaymentRate,
  // handleSetValue,
}: FixedFormProps) {
  return (
    <Space size={16} direction="vertical">
      <Controller
        name="paymentPeriod"
        control={control}
        defaultValue={ProjectPaymentPeriods.HOURS}
        render={({ field }) => (
          <Space direction="vertical">
            <Typography.Text strong>Payment period</Typography.Text>
            <Radio.Group value={field.value} onChange={(e) => field.onChange(e.target.value)}>
              <Radio.Button value={ProjectPaymentPeriods.HOURS}>Hours</Radio.Button>
              <Radio.Button value={ProjectPaymentPeriods.DAYS}>Days</Radio.Button>
              <Radio.Button value={ProjectPaymentPeriods.WEEKS}>Weeks</Radio.Button>
              <Radio.Button value={ProjectPaymentPeriods.MONTHLY}>Months</Radio.Button>
            </Radio.Group>
          </Space>
        )}
      />
      <ProjectLengthInput
        control={control}
        isNullable={false}
        isPeriodSelectable={false}
      />
      <PaymentRateInput profilePaymentRate={profilePaymentRate} control={control} />
      <CalculatePaymentInfo control={control} />
      <Space direction="vertical">
        <Typography.Text strong>Start date</Typography.Text>
        <ProjectDateInput control={control} fieldName="dateOfStart" />
      </Space>
      <Space direction="vertical">
        <Typography.Text strong>End date</Typography.Text>
        <ProjectDateInput control={control} fieldName="dateOfFinish" />
      </Space>
    </Space>
  );
}
