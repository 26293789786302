import { Usecase } from '@raised/domain';
import { FeedbackModule } from '@raised/ui-kit';
import UseCaseItem from './use-case-item/UseCaseItem';
import styles from './UseCases.module.css';

const { Spin } = FeedbackModule;

interface UseCasesProps {
  isLoading: boolean;
  usecases: Usecase.Public[];
  onUsecaseClick: (usecaseId: string) => void;
}

export default function UseCases({
  isLoading,
  usecases,
  onUsecaseClick,
}: UseCasesProps) {
  return (
    <div className={styles.container}>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          {usecases?.map((c) => (
            <UseCaseItem
              key={c.id}
              usecase={c}
              onClick={() => onUsecaseClick(c.id)}
            />
          ))}
        </>
      )}
    </div>
  );
}
