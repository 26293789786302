import { Typography } from 'antd';
import { ReactNode } from 'react';

interface LastActionsComponentProps {
  children: ReactNode;
}

export default function LastActionsComponent({
  children,
}: LastActionsComponentProps) {
  return (
    <div>
      <Typography.Title level={2}>Last actions</Typography.Title>
      {children}
    </div>
  );
}
