import { FeedbackModule, LayoutModule, TypographyModule } from '@raised/ui-kit';

const { Space } = LayoutModule;
const { Spin } = FeedbackModule;
const { Text } = TypographyModule;

export default function TokenLoginComponent() {
  return (
    <Space>
      <Spin />
      <Text>Verification...</Text>
    </Space>
  );
}
