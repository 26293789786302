import AdminRouteLayout from 'components/layout/containers/admin-route/AdminRouteLayout';
import ClientProtectedRoute from 'components/layout/containers/client-route/ClientProtectedRoute';
import ExpertProtectedRoute from 'components/layout/containers/expert-route/ExpertProtectedRoute';
import MainLayout from 'components/layout/containers/main-layout/MainLayout';
import ProtectedRouteLayout from 'components/layout/containers/protected-route-layout/ProtectedRouteLayout';
import { RouteObject } from 'react-router-dom';
import ClientBillingInfoPage from './client-billing-info/ClientBillingInfoPage';
import AdminCreatePaymentsPage from './expert-billing-info/admin-create-payments/AdminCreatePaymentsPage';
import ExpertBillingInfoPage from './expert-billing-info/ExpertBillingInfoPage';
import PaymentInfoPage from './payment-info/PaymentInfoPage';

export const PAYMENTS_ROUTES = {
  PAYMENT_INFO: (proposalId: string) => `/payment/info/${proposalId}`,
  CLIENT_BILLING_INFO: () => '/payment/client/billing-info',
  EXPERT_BILLING_INFO: () => '/payment/expert/billing-info',
  ADMIN_CREATE_PAYMENTS: () => '/payment/admin/create',
};

const paymentsRoutes: Array<RouteObject> = [
  {
    element: <MainLayout />,
    children: [
      {
        element: <ProtectedRouteLayout />,
        children: [
          {
            path: PAYMENTS_ROUTES.PAYMENT_INFO(':id'),
            element: <PaymentInfoPage />,
          },
        ],
      },
      {
        element: <ClientProtectedRoute />,
        children: [
          {
            path: PAYMENTS_ROUTES.CLIENT_BILLING_INFO(),
            element: <ClientBillingInfoPage />,
          },
        ],
      },
      {
        element: <ExpertProtectedRoute />,
        children: [
          {
            path: PAYMENTS_ROUTES.EXPERT_BILLING_INFO(),
            element: <ExpertBillingInfoPage />,
          },
        ],
      },
      {
        element: <AdminRouteLayout />,
        children: [
          {
            path: PAYMENTS_ROUTES.ADMIN_CREATE_PAYMENTS(),
            element: <AdminCreatePaymentsPage />,
          },
        ],
      },
    ],
  },
];

export default paymentsRoutes;
