import { Space, Typography } from 'antd';
import useNewNotifications from 'components/notifications/hooks/useNewNotifications';
import NotificationDot from '../notification-dot/NotificationDot';

interface NotificationsMenuLabelProps {
  profileId?: string;
}

export default function NotificationsMenuLabel({
  profileId,
}: NotificationsMenuLabelProps) {
  const { isNewNotifications } = useNewNotifications({
    profileId,
  });

  return (
    <Space style={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography.Text>Notifications</Typography.Text>
      {isNewNotifications ? (
        <NotificationDot />
      ) : null}
    </Space>
  );
}
