import { Collapse as AntCollapse, CollapseProps as AntCollapseProps } from 'antd';
import cn from 'classnames';
import styles from './Collapse.module.css';
import CollapseIcon from './CollapseIcon';

interface CollapseProps extends AntCollapseProps {}

export default function Collapse({
  bordered = false,
  className,
  ...rest
}: CollapseProps) {
  return (
    <AntCollapse
      bordered={bordered}
      expandIcon={CollapseIcon}
      {...rest}
      className={cn(styles.collapse, className)}
    />
  );
}
