import { ProjectStatuses } from '@raised/domain';
import { TypographyModule } from '@raised/ui-kit';
import { PROJECT_STATUS_CONFIG, PROJECT_STATUS_LABELS } from 'components/project/constants/project-status-labels';
import styles from './ProjectStatus.module.css';

const { Text } = TypographyModule;

interface ProjectStatusProps {
  status: ProjectStatuses;
}

export default function ProjectStatus({
  status,
}: ProjectStatusProps) {
  return (
    <div className={styles.project_status} style={{ background: PROJECT_STATUS_CONFIG[status].background }}>
      {PROJECT_STATUS_CONFIG[status].icon}
      <Text>{PROJECT_STATUS_LABELS[status]}</Text>
    </div>
  );
}
