import AdminRouteLayout from 'components/layout/containers/admin-route/AdminRouteLayout';
import MainLayout from 'components/layout/containers/main-layout/MainLayout';
import ProtectedRouteLayout from 'components/layout/containers/protected-route-layout/ProtectedRouteLayout';
import { RouteObject } from 'react-router-dom';
import AdminChatListPage from './admin-chat-list/AdminChatListPage';
import AdminSupportChatsListPage from './admin-support-chats-list/AdminSupportChatsListPage';
import ChatPage from './chat/ChatPage';

export const MESSAGE_ROUTES = {
  CHAT_PAGE: (id: string) => `/chat/${id}`,
  SUPPORT_CHAT_PAGE: () => '/chat/support',
  ADMIN_CHATS_LIST: () => '/chat/list',
  ADMIN_SUPPORT_CHATS_LIST: () => '/chat/support/list',
};

const messagesRoutes: Array<RouteObject> = [
  {
    element: <MainLayout withoutSpacing />,
    children: [
      {
        element: <ProtectedRouteLayout />,
        children: [
          {
            path: MESSAGE_ROUTES.CHAT_PAGE(':id'),
            element: <ChatPage />,
          },
          {
            path: MESSAGE_ROUTES.SUPPORT_CHAT_PAGE(),
            element: <ChatPage isSupport />,
          },
        ],
      },
    ],
  },
  {
    element: <MainLayout />,
    children: [
      {
        element: <AdminRouteLayout />,
        children: [
          {
            path: MESSAGE_ROUTES.ADMIN_CHATS_LIST(),
            element: <AdminChatListPage />,
          },
          {
            path: MESSAGE_ROUTES.ADMIN_SUPPORT_CHATS_LIST(),
            element: <AdminSupportChatsListPage />,
          },
        ],
      },
    ],
  },
];

export default messagesRoutes;
