import { z } from 'zod';

export const baseErrorSchema = z.object({
  status: z.number(),
  data: z.object({
    message: z.string().optional(),
    error: z.string(),
  }),
});

export type BaseErrorType = z.infer<typeof baseErrorSchema>;
