import { useState } from 'react';

export default function useCreateUsecaseModal() {
  const [isVisible, setIsVisible] = useState<string | boolean>(false);

  const openModal = () => setIsVisible(true);
  const openModalWithTemplate = (tId: string) => {
    setIsVisible(tId);
  };
  const closeModal = () => {
    setIsVisible(false);
  };
  const toggleModal = () => {
    if (isVisible) {
      closeModal();
    } else {
      openModal();
    }
  };

  return {
    openModal,
    openModalWithTemplate,
    closeModal,
    toggleModal,
    isVisible: Boolean(isVisible),
    templateId: typeof isVisible === 'string' ? isVisible : undefined,
  };
}
