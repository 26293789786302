import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResetPasswordMutation } from '../storage/authApi';

interface UseResetPasswordProps {
  onSuccess: () => void;
}

export default function useResetPassword({
  onSuccess,
}: UseResetPasswordProps) {
  const navigate = useNavigate();
  const [mutate, info] = useResetPasswordMutation();
  const { isSuccess, isError, error } = info;

  const { search } = useLocation();
  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return {
      token: searchParams.get('token'),
      email: searchParams.get('email'),
    };
  }, [search]);

  const resetPassword = (password: string) => {
    if (!params.token || !params.email) return;

    mutate({ password, token: params.token, email: params.email });
  };

  useEffect(() => {
    if (!params.token || !params.email) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (!isError) return;

    message.error(getErrorMessage(error));
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;

    message.success('Password successfully changed');
    onSuccess();
  }, [isSuccess]);

  return {
    ...info,
    resetPassword,
  };
}
