import { useLayoutEffect, useState } from 'react';

export default function useChatWindow() {
  const [isChatVisible, setIsChatVisible] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth <= 500) return;

    setIsChatVisible(true);
  }, []);

  const openChat = () => {
    setIsChatVisible(true);
  };
  const closeChat = () => {
    setIsChatVisible(false);
  };
  const toggleChat = () => {
    if (isChatVisible) {
      closeChat();
    } else {
      openChat();
    }
  };

  return {
    isChatVisible,
    toggleChat,
    closeChat,
    openChat,
  };
}
