import { Collapse, CollapsePanelProps as AntPanelProps } from 'antd';

const { Panel: AntPanel } = Collapse;

interface PanelProps extends AntPanelProps {}

export default function Panel(props: PanelProps) {
  return (
    <AntPanel {...props} />
  );
}
