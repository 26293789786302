import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { ReactNode } from 'react';
import styles from './ProjectsComponent.module.css';

const { Title } = TypographyModule;
const { Button } = ButtonsModule;

interface ProjectsComponentProps {
  children: ReactNode;
  onCreateProject: () => void;
}

export default function ProjectsComponent({
  children,
  onCreateProject,
}: ProjectsComponentProps) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Title level={1}>Projects</Title>
        <Button onClick={onCreateProject} className={styles.btn} type="primary">Create Project</Button>
      </div>
      {/* <TimezoneMessage /> */}
      {children}
    </div>
  );
}
