import { FormsModule, TypographyModule } from '@raised/ui-kit';
import { Control, Controller } from 'react-hook-form';
import styles from './ProfilePublictyInput.module.css';

const { Switch } = FormsModule;
const { Text } = TypographyModule;

interface ProfilePublictyInputRrops {
  control: Control<any>;
}

export default function ProfilePublictyInput({
  control,
}: ProfilePublictyInputRrops) {
  return (
    <Controller
      control={control}
      name="isProfilePublic"
      defaultValue={false}
      render={({ field }) => (
        <div className={styles.publicity_input}>
          <Text>Public profile</Text>
          <Switch checked={field.value} onChange={field.onChange} />
        </div>
      )}
    />
  );
}
