import { Payment } from '@raised/domain';
import Table from 'components/layout/components/table/Table';
import { useMemo } from 'react';
import { getPaymentsTableConfig } from './paymentsTableConfig';

interface PaymentsTableProps {
  payments: Payment.Public[]
  onProjectClick: (projectId: string) => void;
  isLoading: boolean;
  onViewProposalClick: (proposalId: string) => void;
  onClientClick: (clientId: string) => void;
}

export default function PaymentsTable({
  payments,
  onProjectClick,
  isLoading,
  onViewProposalClick,
  onClientClick,
}: PaymentsTableProps) {
  const tableData = useMemo(() => payments.map((payment) => ({
    key: payment.id,
    createdAt: payment.createdAt,
    amount: payment.amount,
    status: payment.status,
    projectTitle: payment.proposal?.match?.project?.title || '',
    projectId: payment.proposal?.match?.project?.id || '',
    proposalId: payment.proposalId,
    clientId: payment.clientId,
    clientName: `${payment.client?.firstName} ${payment.client?.lastName}`,
  })), [payments]);

  const projectFilters = useMemo(() => {
    const projects = new Set([...payments.map((payment) => payment.proposal?.match?.project?.title || '')]);

    return [...Array.from(projects)].map((title) => ({ text: title, value: title }));
  }, [payments]);

  const columns = useMemo(() => getPaymentsTableConfig({
    onProjectClick,
    projectFilters,
    onViewProposalClick,
    onClientClick,
  }), [payments, projectFilters]);

  return (
    <Table
      tableName="payments"
      loading={isLoading}
      dataSource={tableData}
      columns={columns}
    />
  );
}
