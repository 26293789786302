import { Proposal } from '@raised/domain';
import { formatCurrency, formatDate } from 'utils/formats';
import { useCalculatePaymentInfoMutation } from 'components/proposal/storage/proposalApi';
import { useEffect } from 'react';
import { PROPOSAL_PAYMENT_PERIODS } from 'components/proposal/constants/proposal-payment-periods';
import { LayoutModule, TypographyModule } from '@raised/ui-kit';
import { PROPOSAL_PROJECT_PAYMENTS } from '../../constants/proposal-project-payments';
import ViewProposalFile from './ViewProposalFile';
import ProposalFieldLabel from './ProposalFieldLabel';
import styles from './ViewProposal.module.css';

const { Paragraph, Text } = TypographyModule;
const { Space } = LayoutModule;

interface ViewProposalProps {
  proposal: Proposal.Public;
}

export default function ViewProposal({
  proposal,
}: ViewProposalProps) {
  const [calculate, { data }] = useCalculatePaymentInfoMutation();

  useEffect(() => {
    if (!data) {
      calculate(proposal);
    }
  }, []);

  return (
    <div className={styles.container}>
      <Paragraph className={styles.description}>{proposal.description}</Paragraph>
      <div className={styles.content}>
        <Text type="secondary">Approach & deliverables</Text>
        <Paragraph>{proposal.approachAndDeliverables}</Paragraph>
        {/* {proposal?.projectType ? ( */}
        {/*   <> */}
        {/*     <Text type="secondary">Project Type</Text> */}
        {/*     <Text>{PROPOSAL_RPOJECT_TYPES[proposal.projectType]}</Text> */}
        {/*   </> */}
        {/* ) : null} */}
        <Text type="secondary">Payment Type</Text>
        <Text>{PROPOSAL_PROJECT_PAYMENTS[proposal.projectPayment].title}</Text>
        {proposal?.paymentRate ? (
          <>
            <Text type="secondary">Payment Gross (Rate)</Text>
            <Text>{formatCurrency(proposal.paymentRate)}</Text>
          </>
        ) : null}
        <Text type="secondary">Project length</Text>
        <Text>{proposal.projectLength && proposal.paymentPeriod ? `${proposal.projectLength.value} ${PROPOSAL_PAYMENT_PERIODS[proposal.paymentPeriod].toLowerCase()}` : 'Not specified'}</Text>
        <Text type="secondary">Total Gross Amount</Text>
        <Text>{data?.total_amount_gross ? formatCurrency(data.total_amount_gross) : '-'}</Text>
        <Text type="secondary">Date of start</Text>
        <Text>{proposal.dateOfStart ? formatDate(new Date(proposal.dateOfStart)) : 'Not specified'}</Text>
        <Text type="secondary">Date of finish</Text>
        <Text>{proposal.dateOfFinish ? formatDate(new Date(proposal.dateOfFinish)) : 'Not specified'}</Text>
      </div>
      {proposal.documentUrls?.length ? (
        <Space direction="vertical">
          <ProposalFieldLabel>Attached files</ProposalFieldLabel>
          {proposal.documentUrls.map((url) => (
            <ViewProposalFile key={url} fileUrl={url} />
          ))}
        </Space>
      ) : null}
    </div>
  );
}
