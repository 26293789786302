import { UserRole } from '@raised/domain';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import LastActionCell from './LastActionCell';

export interface LastActionsTableRow {
  key: string;
  projectTitle: string;
  expertName: string;
  lastMessageText: string;
  lastMessageFromLink:string;
  lastMessageFromName: string;
  lastMessageFromRole: UserRole;
  lastMessageDate: string;
  clientName: string;
  clientLink: string;
  expertLink: string;
  projectLink: string;
}

export const getLastActionsTableConfig = (): ColumnsType<LastActionsTableRow> => [
  {
    title: 'Project',
    dataIndex: 'projectTitle',
    key: 'projectTitle',
    render: (value, record) => <Link to={record.projectLink}>{value}</Link>,
  },
  {
    title: 'Expert',
    dataIndex: 'expertName',
    key: 'expertName',
    render: (value, record) => <Link to={record.expertLink}>{value}</Link>,
  },
  {
    title: 'Client',
    dataIndex: 'clientName',
    key: 'clientName',
    render: (value, record) => <Link to={record.clientLink}>{value}</Link>,
  },
  {
    title: 'Last action date',
    dataIndex: 'lastMessageDate',
    key: 'lastMessageDate',
  },
  {
    title: 'Last action',
    render: (_, record) => (
      <LastActionCell
        fromName={record.lastMessageFromName}
        fromRole={record.lastMessageFromRole}
        userLink={record.lastMessageFromLink}
        text={record.lastMessageText}
      />
    ),
  },
];
