import { Chat, Message, MessageTypes } from '@raised/domain';
import { Spin } from 'antd';
import { useGetMessagesByChatQuery } from 'components/messages/storage/messageApi';
import { Fragment, useEffect } from 'react';
import ChatMessage from '../chat-message/ChatMessage';
import TechMessage from '../tech-messasge/TechMessage';

interface MessagesListProps {
  chat: Chat.Public;
  skip: number;
  take: number;
  onLoad: () => void;
  personalProfileId?: string;
  getIsMessageViewed: (message: Message.Public) => boolean;
}

export default function MessagesList({
  chat,
  skip,
  take,
  onLoad,
  personalProfileId,
  getIsMessageViewed,
}: MessagesListProps) {
  const { data, isSuccess, isLoading } = useGetMessagesByChatQuery({
    id: chat.id,
    skip,
    take,
  }, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isSuccess) {
      onLoad();
    }
  }, [isSuccess]);

  return (
    <>
      {isLoading && (
        <>
          <Spin />
        </>
      )}
      {data?.map((message) => (
        <Fragment key={`${message.text}-${message.createdAt}`}>
          {message.type === MessageTypes.CHAT ? (
            <ChatMessage
              message={message}
              personalProfileId={personalProfileId}
              isViewed={getIsMessageViewed(message)}
            />
          ) : (
            <TechMessage type={message.type} chat={chat} />
          )}
        </Fragment>
      ))}
    </>
  );
}
