import AdminRouteLayout from 'components/layout/containers/admin-route/AdminRouteLayout';
import ClientProtectedRoute from 'components/layout/containers/client-route/ClientProtectedRoute';
import MainLayout from 'components/layout/containers/main-layout/MainLayout';
import ProtectedRouteLayout from 'components/layout/containers/protected-route-layout/ProtectedRouteLayout';
import { RouteObject } from 'react-router-dom';
import AdminOrganizationsListPage from './admin-organizations-list/AdminOrganizationsListPage';
import EditOrganisationPage from './edit-organisation/EditOrganisationPage';
import OrganisationDashboardPage from './organisation-dashboard/OrganisationDashboardPage';

export const ORGANISATION_ROUTES = {
  ORGANISATION_DASHBOARD: () => '/organization/dashboard',
  EDIT_ORGANISATION: (id: string) => `/organization/edit/${id}`,
  ADMIN_ORGANISATIONS_LIST: () => '/organizations',
  ADMIN_ORGANIZATION: (id: string) => `/organization/${id}`,
};

const organisationRoutes: Array<RouteObject> = [
  {
    element: <MainLayout />,
    children: [
      {
        element: <ProtectedRouteLayout />,
        children: [
          {
            path: ORGANISATION_ROUTES.EDIT_ORGANISATION(':id'),
            element: <EditOrganisationPage />,
          },
        ],
      },
      {
        element: <ClientProtectedRoute />,
        children: [
          {
            path: ORGANISATION_ROUTES.ORGANISATION_DASHBOARD(),
            element: <OrganisationDashboardPage />,
          },
        ],
      },
      {
        element: <AdminRouteLayout />,
        children: [
          {
            path: ORGANISATION_ROUTES.ADMIN_ORGANISATIONS_LIST(),
            element: <AdminOrganizationsListPage />,
          },
          {
            path: ORGANISATION_ROUTES.ADMIN_ORGANIZATION(':id'),
            element: <OrganisationDashboardPage />,
          },
        ],
      },
    ],
  },
];

export default organisationRoutes;
