import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/formats';
import NewMessagesCell from '../../components/NewMessagesCell';

export interface AdminChatListTableRow {
  key: string;
  clientName: string;
  expertName: string;
  projectTitle: string;
  lastMessageTime: string;
  isNewMessages: boolean;
  projectLink: string;
  chatLink: string;
}

export const getAdminChatListTableConfig = (): ColumnsType<AdminChatListTableRow> => [
  {
    title: 'Client',
    dataIndex: 'clientName',
    key: 'clientName',
    render: (value, record) => <Link to={record.chatLink}>{value}</Link>,
  },
  {
    title: 'Expert',
    dataIndex: 'expertName',
    key: 'expertName',
  },
  {
    title: 'Project',
    dataIndex: 'projectTitle',
    key: 'projectTitle',
    render: (value, record) => (
      <Link to={record.projectLink}>
        {value}
      </Link>
    ),
  },
  {
    title: 'Last message time',
    dataIndex: 'lastMessageTime',
    key: 'lastMessageTime',
    sorter: (a, b) => {
      const opA = new Date(a.lastMessageTime).getTime();
      const opB = new Date(b.lastMessageTime).getTime();
      return opB - opA;
    },
    defaultSortOrder: 'ascend',
    render: (value) => formatDate(new Date(value)),
  },
  {
    title: '',
    dataIndex: 'isNewMessages',
    key: 'isNewMessages',
    render: (value) => <NewMessagesCell isNewMessages={value} />,
  },
];
