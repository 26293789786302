import { Project } from '@raised/domain';
import ProjectCard from 'components/project/components/project-card/ProjectCard';
import { PROJECT_ROUTES } from 'components/project/project-routes';
import { Helmet } from 'react-helmet';
import { useGetPersonalMatchesQuery } from '../storage/matchApi';
import ConfirmMatchContainer from './ConfirmMatchContainer';
import MatchStatus from './match-status/MatchStatus';

export default function ConfirmMatchPage() {
  const { data, isLoading } = useGetPersonalMatchesQuery();

  const getRowLink = (projectId: string) => PROJECT_ROUTES.PROJECT(projectId);

  return (
    <>
      <Helmet>
        <title>Raised - Confirm match</title>
      </Helmet>
      <ConfirmMatchContainer
        loading={isLoading}
        invitationCount={data?.length || 0}
      >
        {data?.map((match) => (
          <ProjectCard
            project={match.project as Project.Public}
            linkToProject={getRowLink(match.projectId)}
            author={match.project?.organisation?.companyName || ''}
            statusContent={(
              <MatchStatus status={match.status} />
            )}
          />
        ))}
      </ConfirmMatchContainer>
    </>
  );
}
