import { Project } from '@raised/domain';
import { FeedbackModule } from '@raised/ui-kit';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useCreateProjectFromTemplateMutation } from '../storage/projectApi';

const { Message } = FeedbackModule;

interface UseCreateProjectFromTemplateProps {
  onSuccess: (data: Project.Public) => void;
}

export default function useCreateProjectFromTemplate({
  onSuccess,
}: UseCreateProjectFromTemplateProps) {
  const [mutate, info] = useCreateProjectFromTemplateMutation();
  const {
    isError, error, data, isSuccess,
  } = info;

  useEffect(() => {
    if (!isError) return;

    Message.error(getErrorMessage(error));
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;

    Message.success('Project successfully created');
    onSuccess(data);
  }, [isSuccess]);

  const createProjectFromTemplate = (formData: Project.CreateFromTemplate) => mutate(formData);

  return {
    ...info,
    createProjectFromTemplate,
  };
}
