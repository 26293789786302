import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useRestoreMatchMutation } from '../storage/matchApi';

export default function useRestoreMatch() {
  const [restore, info] = useRestoreMatchMutation();
  const {
    isError, error, isSuccess,
  } = info;

  useEffect(() => {
    if (!isSuccess) return;

    message.success('Match successfully restored');
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;

    message.error(getErrorMessage(error));
  }, [isError]);

  return {
    restore,
    ...info,
  };
}
