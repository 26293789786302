import { Chat } from '@raised/domain';
import cn from 'classnames';
import { ThemeEnum } from 'components/layout/theme/themeConfig';
import { ThemeContext } from 'components/layout/theme/themeContext';
import useChatHistory from 'components/messages/hooks/useChatHistory';
import useChatScroll from 'components/messages/hooks/useChatScroll';
import useInView from 'components/messages/hooks/useInView';
import useLastViewedMessage from 'components/messages/hooks/useLastViewedMessage';
import useViewMessages from 'components/messages/hooks/useViewMessages';
import { useContext, useEffect } from 'react';
import styles from './ChatMessages.module.css';
import MessagesList from './messages-list/MessagesList';
import NewMessagesList from './messages-list/NewMessagesList';

const POLLING_INTERVAL = 2000;

interface ChatMessagesProps {
  personalProfileId?: string;
  entryDate: string;
  chat: Chat.Public;
  isVisible: boolean;
}

export default function ChatMessages({
  personalProfileId,
  entryDate,
  chat,
  isVisible: isMessagesVisible,
}: ChatMessagesProps) {
  const { themeName } = useContext(ThemeContext);
  const isDarkTheme = themeName === ThemeEnum.DARK;
  const { ref, isVisible } = useInView();
  const {
    chatMessagesRef,
    onNewMessage,
  } = useChatScroll();
  const {
    addNewPage, isLoading, pages, onPageLoaded,
  } = useChatHistory({
    chatId: chat.id,
  });
  const { setObserveForMessage } = useViewMessages({
    chatId: chat.id,
  });
  const { getIsMessageViewed } = useLastViewedMessage({
    chatId: chat.id,
    pollingInterval: POLLING_INTERVAL,
  });

  useEffect(() => {
    if (isVisible && !isLoading) {
      addNewPage();
    }
  }, [isVisible, isLoading]);

  return (
    <div
      data-testid="chat_messages_container"
      className={cn(styles.container, {
        [styles.dark_container]: isDarkTheme,
        [styles.centered_container]: isLoading,
        [styles.hidden]: !isMessagesVisible,
      })}
      ref={chatMessagesRef}
    >
      <NewMessagesList
        chat={chat}
        fromDate={entryDate}
        personalProfileId={personalProfileId}
        pollingInterval={POLLING_INTERVAL}
        setObserveForMessage={setObserveForMessage}
        onNewMessage={onNewMessage}
      />
      {pages.map((page, index) => (
        <MessagesList
          key={page.skip}
          skip={page.skip}
          take={page.take}
          onLoad={() => onPageLoaded(index)}
          personalProfileId={personalProfileId}
          chat={chat}
          getIsMessageViewed={getIsMessageViewed}
        />
      ))}
      <div data-testid="fetch-trigger" ref={ref} style={{ visibility: 'hidden' }} />
    </div>
  );
}
