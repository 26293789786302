import { useMemo } from 'react';
import { excludeNullFields } from 'utils/forms';
import { useGetUserByIdQuery } from '../storage/userApi';

interface UseGhostUserProps {
  id?: string;
}

export default function useGhostUser({
  id,
}: UseGhostUserProps) {
  const info = useGetUserByIdQuery({ id: id as string }, { skip: !id });
  const { data: userData } = info;

  const data = useMemo(() => {
    if (!userData) return undefined;

    const userInfo = excludeNullFields(userData, ['to']);

    if (!userInfo.email?.includes('@')) {
      userInfo.email = '';
    }

    return userInfo;
  }, [userData]);

  return {
    ...info,
    data,
  };
}
