import { PaymentStatuses } from '@raised/domain';
import { ColorsModule } from '@raised/ui-kit';
import { IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import { ReactNode } from 'react';

const { Colors } = ColorsModule;

export const PAYMENT_STATUS_LABELS: Record<PaymentStatuses, {
  icon?: ReactNode;
  value: string;
}> = {
  [PaymentStatuses.CREATED]: {
    value: 'Created',
  },
  [PaymentStatuses.CANCELED]: {
    icon: <IconCircleX size={16} color={Colors.red} />,
    value: 'Canceled',
  },
  [PaymentStatuses.PROCESSING]: {
    value: 'Processing',
  },
  [PaymentStatuses.REQUIRES_ACTION]: {
    icon: <IconCircleX size={16} color={Colors.red} />,
    value: 'Requires action',
  },
  [PaymentStatuses.REQUIRES_CAPTURE]: {
    icon: <IconCircleX size={16} color={Colors.red} />,
    value: 'Requires capture',
  },
  [PaymentStatuses.REQUIRES_CONFIRMATION]: {
    icon: <IconCircleX size={16} color={Colors.red} />,
    value: 'Requires confirmation',
  },
  [PaymentStatuses.REQUIRES_PAYMENT_METHOD]: {
    icon: <IconCircleX size={16} color={Colors.red} />,
    value: 'Requires payment method',
  },
  [PaymentStatuses.SUCCEEDED]: {
    icon: <IconCircleCheck size={16} color={Colors['solid-green']} />,
    value: 'Succeeded',
  },
  [PaymentStatuses.FAILED]: {
    icon: <IconCircleX size={16} color={Colors.red} />,
    value: 'Failed',
  },
  [PaymentStatuses.PAID]: {
    icon: <IconCircleCheck size={16} color={Colors['solid-green']} />,
    value: 'Paid',
  },
  [PaymentStatuses.UNPAID]: {
    icon: <IconCircleX size={16} color={Colors.red} />,
    value: 'Unpaid',
  },
};
