import { ProjectStatuses } from '@raised/domain';
import { ButtonsModule } from '@raised/ui-kit';
import { IconArchive } from '@tabler/icons-react';
import {
  ComponentProps, ComponentType, useState,
} from 'react';
import cn from 'classnames';
import styles from './ArchiveProject.module.css';
import ArchiveModal from './archive-modal/ArchiveModal';

const { Button } = ButtonsModule;

type ButtonProps = ComponentProps<typeof Button>;

interface ArchiveProjectProps extends Omit<ButtonProps, 'onSubmit'> {
  PropsButton?: ComponentType<ButtonProps>;
  onSubmit: (status: ProjectStatuses, message?: string) => void;
  buttonText?: string;
}

export default function ArchiveProject({
  PropsButton,
  onSubmit,
  className,
  buttonText = 'Archive',
  ...rest
}: ArchiveProjectProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  return (
    <>
      {PropsButton ? (
        <PropsButton onClick={openModal} {...rest} />
      ) : (
        <Button onClick={openModal} {...rest} className={cn(styles.btn, className)}>
          <IconArchive size={20} />
          {buttonText}
        </Button>
      )}
      <ArchiveModal isVisible={isModalVisible} onClose={closeModal} onSubmit={onSubmit} />
    </>
  );
}
