import { Table as AntTable, TableProps as AntTableProps } from 'antd';

type RecordType = Record<string, any>;

interface TableProps<T extends RecordType> extends AntTableProps<T> {
  tableName: string;
}

const classNameGenerator = (tableName: string) => (record: RecordType) => `table_${tableName}_rowKey_${record?.key}`;

export default function Table<T extends RecordType>({ tableName, ...rest }: TableProps<T>) {
  return <AntTable scroll={{ x: true }} {...rest} rowClassName={classNameGenerator(tableName)} />;
}
