import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/formats';
import NewMessagesCell from '../../components/NewMessagesCell';

export interface SupportChatsListTableRow {
  key: string;
  userName: string;
  lastMessageTime: string;
  isNewMessages: boolean;
  userLink: string;
  chatLink: string;
}

export const getSupportChatsListConfig = (): ColumnsType<SupportChatsListTableRow> => [
  {
    title: 'User',
    key: 'userName',
    dataIndex: 'userName',
    render: (value, record) => (
      <Link to={record.chatLink}>
        {value}
      </Link>
    ),
  },
  {
    title: 'Last message time',
    dataIndex: 'lastMessageTime',
    key: 'lastMessageTime',
    sorter: (a, b) => {
      const opA = new Date(a.lastMessageTime).getTime();
      const opB = new Date(b.lastMessageTime).getTime();
      return opB - opA;
    },
    defaultSortOrder: 'ascend',
    render: (value) => formatDate(new Date(value)),
  },
  {
    title: '',
    dataIndex: 'isNewMessages',
    key: 'isNewMessages',
    render: (value) => <NewMessagesCell isNewMessages={value} />,
  },
];
