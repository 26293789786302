import { configureStore, combineReducers, PreloadedState } from '@reduxjs/toolkit';
import { errorHandlerMiddleware } from 'api/errorHandlerMiddleware';
import { rootApi } from 'api/rootApi';

const rootReducer = combineReducers({
  [rootApi.reducerPath]: rootApi.reducer,
});

const setupStore = (preloadedState?: PreloadedState<RootState>) => configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([rootApi.middleware, errorHandlerMiddleware]),
});

export default setupStore;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
