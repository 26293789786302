import { User } from '@raised/domain';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { excludeNullFields } from 'utils/forms';
import useLocationState from 'utils/useLocationState';
import UpdateUserForm from '../components/forms/update-user/UpdateUserForm';
import useEditProfile from '../hooks/useEditProfile';
import { useGetProfileQuery } from '../storage/userApi';
import Messages from './messages/Messages';
import { editProfileStateSchema } from './messages/types';

export default function EditProfilePage() {
  const navigate = useNavigate();
  const state = useLocationState({ schema: editProfileStateSchema });
  const { editProfile, isLoading } = useEditProfile();
  const { data, isSuccess } = useGetProfileQuery();

  const onFormSubmit = (values: User.Edit) => editProfile(values);
  const onCancel = () => navigate(-1);

  return (
    <>
      <Helmet>
        <title>Raised - Edit profile</title>
      </Helmet>
      {isSuccess && (
        <UpdateUserForm
          user={data}
          onSubmit={onFormSubmit}
          isLoading={isLoading}
          defaultValues={excludeNullFields(data, ['to'])}
          onCancel={onCancel}
          title="Edit profile"
          messageComponent={(
            state?.message ? Messages[state.message] : null
          )}
        />
      )}
    </>
  );
}
