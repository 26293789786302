/* eslint-disable consistent-return */
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useCallback, useState } from 'react';
import { useUploadFileMutation } from 'components/files/storage/filesApi';

export default function useUploadFile() {
  const [upload, result] = useUploadFileMutation();
  const [uploadedFilePath, setUploadedFilePath] = useState('');

  const uploadFile = useCallback(async (file: File) => {
    try {
      const data = await upload({ file }).unwrap();
      setUploadedFilePath(data.url);
      return data.url;
    } catch (error) {
      message.error(getErrorMessage(error as FetchBaseQueryError));
    }
  }, []);

  const resetState = useCallback(() => {
    setUploadedFilePath('');
  }, []);

  return {
    uploadFile,
    uploadedFilePath,
    resetState,
    ...result,
  };
}
