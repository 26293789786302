import { FormsModule, TypographyModule } from '@raised/ui-kit';
import { USER_ACTIVITY_TYPE_LABELS } from 'components/user/constants/user-activity-types';
import { ExpertFilters } from 'components/user/hooks/useGetExperts';
import styles from './ExpertPublicityFilters.module.css';

const { Text } = TypographyModule;
const { Select, Input } = FormsModule;

const selectOptions = [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }, { label: 'All', value: null }];
const activityOptions = [...Object.entries(USER_ACTIVITY_TYPE_LABELS).map(([value, label]) => ({ value, label })), { label: 'All', value: null }];

interface ExpertPublicityFiltersProps {
  onChangeFilter: (field: keyof ExpertFilters, value: string | null) => void;
  filters: ExpertFilters;
}

export default function ExpertPublicityFilters({
  onChangeFilter,
  filters,
}: ExpertPublicityFiltersProps) {
  return (
    <div className={styles.container}>
      <Text strong>Publicity Filters</Text>
      <Select options={selectOptions} label="Public" value={filters.public} onChange={(v) => onChangeFilter('public', v)} />
      <Select options={selectOptions} label="Activated" value={filters.activated} onChange={(v) => onChangeFilter('activated', v)} />
      <Select options={selectOptions} label="Published on public websites" value={filters.publishedOnPublicWebsites} onChange={(v) => onChangeFilter('publishedOnPublicWebsites', v)} />
      <Select options={activityOptions} label="Activity Type" value={filters.activityType} onChange={(v) => onChangeFilter('activityType', v)} />
      <Input label="Search" placeholder="Search..." value={filters.search} onChange={(v) => onChangeFilter('search', v.target.value)} />
    </div>
  );
}
