import { FormsModule } from '@raised/ui-kit';
import { useGetAllOrganisationsQuery } from 'components/organisation/storage/organisationApi';
import { useMemo } from 'react';

const { Select } = FormsModule;

interface AdminOrganisationSelectorProps {
  selectedOrganisation: string;
  onSelectOrganisation: (org: string) => void;
}

export default function AdminOrganisationSelector({
  selectedOrganisation,
  onSelectOrganisation,
}: AdminOrganisationSelectorProps) {
  const {
    data: organisations,
    isLoading,
  } = useGetAllOrganisationsQuery();

  const selectOptions = useMemo(() => organisations?.map((org) => ({ label: org.companyName, value: org.id })), [organisations]);

  return (
    <Select
      label="Select organisation"
      style={{ width: '100%' }}
      loading={isLoading}
      options={selectOptions}
      value={selectedOrganisation}
      onChange={onSelectOrganisation}
      placeholder="Select organisation"
    />
  );
}
