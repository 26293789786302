import { Usecase } from '@raised/domain';
import { FeedbackModule } from '@raised/ui-kit';
import { getErrorMessage } from 'api/helpers';
import useAttachedFiles from 'components/files/hooks/useAttachedFiles';
import useUploadFiles from 'components/files/hooks/useUploadFiles';
import { useEffect } from 'react';
import { useCreateUsecaseMutation } from '../storage/usecasesApi';

const { Message } = FeedbackModule;

interface UseCreateUsecaseProps {
  onSuccess?: (data: Usecase.Public) => void;
  isNotify?: boolean;
}

export default function useCreateUsecase({
  onSuccess,
  isNotify = true,
}: UseCreateUsecaseProps) {
  const [mutate, info] = useCreateUsecaseMutation({
    fixedCacheKey: 'create-usecase',
  });
  const {
    isSuccess, isError, error, data,
  } = info;
  const { uploadFiles, isLoading: isFilesLoading } = useUploadFiles();
  const {
    files,
    uploadedFiles,
    addFiles,
    removeFile,
  } = useAttachedFiles({});

  const createUsecase = async (formData: Usecase.Create) => {
    const attachedFiles = [...uploadedFiles];

    if (files.length) {
      const res = await uploadFiles(files);

      if (res) {
        attachedFiles.push(...res.map((file) => file.url));
      }
    }

    mutate({
      ...formData,
      attachedFile: attachedFiles,
    });
  };

  useEffect(() => {
    if (!isSuccess) return;

    if (onSuccess) {
      onSuccess(data);
    }

    if (isNotify) {
      Message.success('Usecase successfully created');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isError || !isNotify) return;

    Message.error(getErrorMessage(error));
  }, [isError]);

  return {
    createUsecase,
    files,
    uploadedFiles,
    addFiles,
    removeFile,
    ...info,
    isLoading: info.isLoading || isFilesLoading,
  };
}
