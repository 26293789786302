import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useForgotPasswordMutation } from '../storage/authApi';
import { ForgotPasswordRequestType } from '../storage/types';

export default function useForgotPassword() {
  const [mutate, info] = useForgotPasswordMutation();
  const { isSuccess, isError, error } = info;

  const forgotPassword = (data: ForgotPasswordRequestType) => mutate(data);

  useEffect(() => {
    if (!isError) return;

    message.error(getErrorMessage(error));
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;

    message.success('An email with a link to recover your password has been sent to you');
  }, [isSuccess]);

  return {
    ...info,
    forgotPassword,
  };
}
