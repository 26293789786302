import LogoImage from 'assets/images/logo.png';
import cn from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './Logo.module.css';

interface LogoProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export default function Logo({ className, ...rest }: LogoProps) {
  return (
    <div {...rest} className={cn(styles.logo, className)}>
      <img className={styles.logo__image} alt="" src={LogoImage} />
    </div>
  );
}
