import { Match } from '@raised/domain';
import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useCallback, useEffect } from 'react';
import { useUpdateMatchMutation } from '../storage/matchApi';
import { successApiMessages } from '../storage/successApiMessages';

export default function useUpdateMatch() {
  const [updateMatch, data] = useUpdateMatchMutation();

  useEffect(() => {
    if (data.isError) {
      message.error(getErrorMessage(data.error));
    }
  }, [data.isError]);

  useEffect(() => {
    if (data.isSuccess && data.originalArgs?.data.status) {
      message.success(successApiMessages.UPDATE[data.originalArgs.data.status]);
    }
  }, [data.isSuccess]);

  const update = useCallback((args: { data: Match.Update & { message?: string }, id: string }) => {
    updateMatch(args);
  }, []);

  return {
    update,
    ...data,
  };
}
