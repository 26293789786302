import { Match, Proposal, ProposalStatuses } from '@raised/domain';
import {
  ButtonsModule, ColorsModule, DataModule, LayoutModule, TypographyModule,
} from '@raised/ui-kit';
import { IconSpeakerphone } from '@tabler/icons-react';
import ViewProposal from 'components/proposal/components/view-proposal/ViewProposal';
import ProposalHeader from './proposal-header/ProposalHeader';
import styles from './ProposalsTab.module.css';

const { Button } = ButtonsModule;
const { Collapse, Panel, Empty } = DataModule;
const { Space } = LayoutModule;
const { Colors } = ColorsModule;
const { Text } = TypographyModule;

interface ProposalsTabProps {
  match: Match.Public;
  onEditProposal: (id: string) => void;
}

export default function ProposalsTab({
  match,
  onEditProposal,
}: ProposalsTabProps) {
  const getIsProposalPaid = (proposal: Proposal.Public) => !!proposal?.payments?.length;

  return (
    <Space direction="vertical" className={styles.container}>
      <Collapse>
        {!match.proposal?.length ? (
          <Empty icon={<IconSpeakerphone size={80} stroke={0.6} strokeOpacity={0.5} color={Colors.gray} />}>
            <Text type="secondary">No proposals have been created yet</Text>
          </Empty>
        ) : null}
        {match.proposal?.length && match.proposal.map((proposal) => (
          <Panel
            key={proposal.id}
            header={(
              <ProposalHeader
                isPaid={getIsProposalPaid(proposal)}
                title={proposal.title}
                status={proposal.status}
              />
              )}
          >
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <ViewProposal proposal={proposal} key={proposal.id} />
              {proposal.status === ProposalStatuses.PENDING ? (
                <Button
                  type="primary"
                  onClick={() => onEditProposal(proposal.id)}
                  style={{ marginTop: 16 }}
                >
                  Edit proposal
                </Button>
              ) : null}
            </Space>
          </Panel>
        ))}
      </Collapse>
    </Space>
  );
}
