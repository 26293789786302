import { User } from '@raised/domain';
import {
  ButtonsModule, ColorsModule, FormsModule, TypographyModule,
} from '@raised/ui-kit';
import { IconPlus } from '@tabler/icons-react';
import { camelCaseToSeparated } from 'utils/formats';
import cn from 'classnames';
import { useState } from 'react';
import styles from './FilterItem.module.css';

const { Select } = FormsModule;
const { Text } = TypographyModule;
const { Button } = ButtonsModule;
const { Colors } = ColorsModule;

interface FilterItemProps {
  name: keyof User.Tags;
  selectedValues: string[];
  options: { label: string, value: string }[];
  onChangeFilter: (category: keyof User.Tags, values: string[]) => void;
}

export default function FilterItem({
  name,
  selectedValues,
  options,
  onChangeFilter,
}: FilterItemProps) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  return (
    <div className={styles.filter_item}>
      <Text>{camelCaseToSeparated(name)}</Text>
      <Button
        icon={<IconPlus color={Colors.gray} size={12} strokeWidth={1.5} />}
        type="text"
        className={styles.add_btn}
        onClick={() => setIsDropdownVisible(true)}
      />
      <Select
        open={isDropdownVisible}
        mode="multiple"
        options={options}
        value={selectedValues}
        onChange={(values) => onChangeFilter(name, values)}
        onDropdownVisibleChange={(isVisible) => setIsDropdownVisible(isVisible)}
        className={cn(styles.select, {
          [styles.empty_select]: !selectedValues.length,
        })}
      />
    </div>
  );
}
