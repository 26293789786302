import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useLazyGetProposalPaymentLinkQuery } from 'components/proposal/storage/proposalApi';
import { useCallback, useEffect } from 'react';

export default function usePaymentLink() {
  const [trigger, info] = useLazyGetProposalPaymentLinkQuery();
  const { isError, error } = info;

  useEffect(() => {
    if (isError) {
      message.error(getErrorMessage(error));
    }
  }, [isError]);

  const onPayClick = useCallback(async (proposalId: string) => {
    const newTab = window.open('', '_blank');
    newTab?.document.write('Loading, please do not close this tab');

    const response = await trigger({ proposalId }).unwrap();

    if (response.link && newTab) {
      newTab.location.href = response.link;
    }
  }, [trigger]);

  return {
    onPayClick,
    ...info,
  };
}
