import { Message, UserRole } from '@raised/domain';
import {
  Space, Typography,
} from 'antd';
import cn from 'classnames';
import { ThemeEnum } from 'components/layout/theme/themeConfig';
import { ThemeContext } from 'components/layout/theme/themeContext';
import { useGetFileQuery } from 'components/files/storage/filesApi';
import { useContext } from 'react';
import { formatDate } from 'utils/formats';
import UserAvatar from 'components/user/components/UserAvatar';
import { getRoleLabel } from 'components/user/utils';
import ChatFile from './chat-file/ChatFile';
import styles from './ChatMessage.module.css';
import ChatMessageStatusIcon from './ChatMessageStatusIcon';
import MessageText from './message-text/MessageText';

interface ChatMessageProps {
  personalProfileId?: string;
  message: Message.Public;
  setObserveForMessage?: (element: HTMLDivElement) => void;
  isViewed?: boolean;
}

export default function ChatMessage({
  personalProfileId,
  message,
  setObserveForMessage,
  isViewed,
}: ChatMessageProps) {
  const { data: fileLink } = useGetFileQuery({ fileName: message.fileUrl as string }, { skip: !message.fileUrl });
  const { themeName } = useContext(ThemeContext);
  const isDarkTheme = themeName === ThemeEnum.DARK;

  const isPersonal = message.fromId === personalProfileId;
  const isMessageViewed = isViewed || (isPersonal && Boolean(message.viewedBy.find((user) => user !== personalProfileId)));

  return (
    <div
      className={cn(styles.container, {
        [styles.container_personal]: isPersonal,
      })}
      data-testid="chat_message_container"
      data-messageid={message.id}
      ref={setObserveForMessage}
    >
      {!isPersonal && (
        <UserAvatar size="s" role={message.from?.role} style={{ flexShrink: 0 }} filePath={message.from?.avatar} />
      )}
      <div className={cn(styles.message, {
        [styles.dark_message]: isDarkTheme,
        [styles.message_personal]: isPersonal,
        [styles.dark_message_personal]: isPersonal && isDarkTheme,
      })}
      >
        {!isPersonal && (
          <Space size={4}>
            {message.from?.role === UserRole.ADMIN && (
              <Typography.Text type="secondary">{getRoleLabel(message.from.role)}</Typography.Text>
            )}
            <Typography.Text className={styles.name}>{`${message.from?.firstName} ${message.from?.lastName}`}</Typography.Text>
          </Space>
        )}
        <MessageText message={message.text} />
        {fileLink && (
          <ChatFile fileUrl={fileLink.url} />
        )}
        <Space size="small" align="center">
          {isPersonal && (
            <ChatMessageStatusIcon
              isPending={message.isPending}
              isViewed={isMessageViewed}
            />
          )}
          <Typography.Text
            style={{ textAlign: isPersonal ? 'right' : 'initial', fontSize: 12 }}
            type="secondary"
          >
            {formatDate(new Date(message.createdAt))}
          </Typography.Text>
        </Space>
      </div>
    </div>
  );
}
