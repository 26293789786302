import { User } from '@raised/domain';
import { LayoutModule, TypographyModule } from '@raised/ui-kit';
import { IconBrandLinkedin } from '@tabler/icons-react';
import UserAvatar from 'components/user/components/UserAvatar';
import UserLink from 'components/user/components/UserLink';
import { ReactNode } from 'react';

const { Space } = LayoutModule;
const { Title, Text } = TypographyModule;

interface MatchInfoProps {
  expert: User.Public;
  actions: ReactNode;
}

export default function MatchInfo({
  expert,
  actions,
}: MatchInfoProps) {
  return (
    <Space direction="vertical" style={{ margin: '16px 0 0', width: '100%' }} size={12}>
      <Space size={16}>
        <UserAvatar size="m" filePath={expert.avatar} />
        <Space size={8} direction="vertical">
          <Space size={6} style={{ alignItems: 'center' }}>
            <Title level={2}>{`${expert.firstName} ${expert.lastName}`}</Title>
            {expert.externalLink ? (
              <UserLink
                href={expert.externalLink}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                target="_blank"
              >
                <IconBrandLinkedin size={18} />
              </UserLink>
            ) : null}
          </Space>
          {expert.headline ? (
            <Text>{expert.headline}</Text>
          ) : null}
        </Space>
      </Space>
      {actions}
    </Space>
  );
}
