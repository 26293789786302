import { User, UserRole } from '@raised/domain';
import { Space, Typography } from 'antd';
import { formatDate } from 'utils/formats';
import { Link } from 'react-router-dom';
import ExpertTags from './ExpertTags';
import { USER_ACTIVITY_TYPE_LABELS } from '../constants/user-activity-types';
import { getRoleLabel } from '../utils';
import UserAvatar from './UserAvatar';
import UserLink from './UserLink';

interface UserInfoProps {
  profileData: User.Public
  isShowHidden?: boolean;
}

export default function UserInfo({
  profileData,
  isShowHidden = false,
}: UserInfoProps) {
  const getProfileActivatedInfo = () => {
    if (profileData.isGhost) {
      return {
        how: '-',
        when: '-',
      };
    }

    if (profileData.activatedAt) {
      return {
        how: 'ghost',
        when: formatDate(new Date(profileData.activatedAt)),
      };
    }

    return {
      how: 'registration',
      when: formatDate(new Date(profileData.createdAt)),
    };
  };

  return (
    <Space direction="vertical">
      <Space>
        <UserAvatar role={profileData.role} size="s" filePath={profileData.avatar} />
        <Typography.Text>
          {`${profileData.firstName} ${profileData.lastName}`}
        </Typography.Text>
      </Space>
      {isShowHidden ? (
        <Space>
          <Typography.Text type="secondary">Email: </Typography.Text>
          <Typography.Link href={`mailto:${profileData.email}`}>{profileData.email}</Typography.Link>
        </Space>
      ) : null}
      <Space>
        <Typography.Text type="secondary">Role: </Typography.Text>
        <Typography.Text>{getRoleLabel(profileData.role)}</Typography.Text>
      </Space>
      {profileData.rate ? (
        <Space>
          <Typography.Text type="secondary">Rate: </Typography.Text>
          <Typography.Text>{profileData.rate}</Typography.Text>
        </Space>
      ) : null}
      {profileData.externalLink ? (
        <Space>
          <Typography.Text type="secondary">LinkedIn link: </Typography.Text>
          <UserLink href={profileData.externalLink}>{profileData.externalLink}</UserLink>
        </Space>
      ) : null}
      {profileData.calendlyLink ? (
        <Space>
          <Typography.Text type="secondary">Calendly (or alternative) link: </Typography.Text>
          <UserLink href={profileData.calendlyLink}>{profileData.calendlyLink}</UserLink>
        </Space>
      ) : null}
      {isShowHidden ? (
        <>
          {profileData.createdAt ? (
            <Space>
              <Typography.Text type="secondary">When profile was made: </Typography.Text>
              <Typography.Text>{formatDate(new Date(profileData.createdAt))}</Typography.Text>
            </Space>
          ) : null}
          {getProfileActivatedInfo().when ? (
            <Space>
              <Typography.Text type="secondary">When activated: </Typography.Text>
              <Typography.Text>{getProfileActivatedInfo().when}</Typography.Text>
            </Space>
          ) : null}
          <Space>
            <Typography.Text type="secondary">How activated: </Typography.Text>
            <Typography.Text>{getProfileActivatedInfo().how}</Typography.Text>
          </Space>
          {profileData.role === UserRole.EXPERT ? (
            <Space>
              <Typography.Text type="secondary">Activity type: </Typography.Text>
              <Typography.Text>{USER_ACTIVITY_TYPE_LABELS[profileData.activityType]}</Typography.Text>
            </Space>
          ) : null}
        </>
      ) : null}
      {profileData.biography ? (
        <Space direction="vertical" align="start">
          <Typography.Text style={{ whiteSpace: 'nowrap' }} type="secondary">Biography: </Typography.Text>
          <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>{profileData.biography}</Typography.Text>
        </Space>
      ) : null}
      {profileData.headline ? (
        <Space direction="vertical" align="start">
          <Typography.Text style={{ whiteSpace: 'nowrap' }} type="secondary">Headline: </Typography.Text>
          <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>{profileData.headline}</Typography.Text>
        </Space>
      ) : null}
      {isShowHidden ? (
        <>
          {profileData.publishedOnPublicWebsites ? (
            <Space>
              <Typography.Text style={{ whiteSpace: 'nowrap' }} type="secondary">Published On Public Websites: </Typography.Text>
              <Link to={`/expert-profile/${profileData.publishedOnPublicWebsites}`}>{profileData.publishedOnPublicWebsites}</Link>
            </Space>
          ) : null}
          <ExpertTags expert={profileData} />
        </>
      ) : null}
    </Space>
  );
}
