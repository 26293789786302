import { LogMessage } from '@raised/domain';
import {
  Space, Spin, Typography, Pagination,
} from 'antd';
import LogMessageComponent from '../components/LogMessageComponent';
import styles from './LogMessagesFeedComponent.module.css';

interface LogMessagesFeedComponentProps {
  messages?: LogMessage.Public[];
  isLoading: boolean;
  isFetching: boolean;
  page: number;
  toPage: (page: number) => void;
  total: number;
}

export default function LogMessagesFeedComponent({
  messages,
  isLoading,
  page,
  toPage,
  total,
  isFetching,
}: LogMessagesFeedComponentProps) {
  return (
    <div className={styles.container}>
      <Typography.Title style={{ marginBottom: 16 }} level={2}>Log messages feed</Typography.Title>
      {(isLoading || isFetching) && (
        <Spin />
      )}
      <div className={styles.content}>
        <Space direction="vertical" size={10}>
          {messages?.map((message) => (
            <LogMessageComponent message={message} key={message.id} />
          ))}
        </Space>
        <Pagination disabled={isLoading} current={page} total={total} onChange={toPage} />
      </div>
    </div>
  );
}
