import { SuccessStory } from '@raised/domain';
import { TypographyModule } from '@raised/ui-kit';
import { Link } from 'react-router-dom';
import styles from './SuccessStoryItem.module.css';

const { Title, Text, Paragraph } = TypographyModule;

interface SuccessStoryItemProps {
  successStory: SuccessStory.Public;
  link: string;
}

export default function SuccessStoryItem({
  successStory,
  link,
}: SuccessStoryItemProps) {
  return (
    <div className={styles.success_story}>
      <Link to={link}><Title className={styles.title} level={4}>{successStory.successStoryName}</Title></Link>
      <div className={styles.info}>
        <Title level={4}>{successStory.companyName}</Title>
        <Paragraph>{successStory.companyDescription}</Paragraph>
      </div>
      <div className={styles.info}>
        <Text type="secondary">Brief</Text>
        <Paragraph>{successStory.brief}</Paragraph>
      </div>
    </div>
  );
}
