import {
  Checkbox,
  Col, DatePicker, Row, Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';
import { Control, Controller } from 'react-hook-form';
import styles from './PeriodInput.module.css';

interface PeriodInputProps {
  control: Control
  prefix: string;
}

export default function PeriodInput({
  control,
  prefix,
}: PeriodInputProps) {
  const handleCheckboxChange = (changeField: (value: any) => void) => (event: CheckboxChangeEvent) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      changeField(null);
    } else {
      changeField('');
    }
  };

  return (
    <div className={styles.container}>
      <Row gutter={8}>
        <Col span={12} className={styles.half}>
          <Typography.Text>From</Typography.Text>
          <div className={styles.inputs}>
            <Controller
              control={control}
              name={`${prefix}.from`}
              render={({ field, fieldState }) => (
                <DatePicker
                  status={fieldState.error ? 'error' : undefined}
                  className={styles.datepicker}
                  picker="month"
                  placeholder="From"
                  onChange={(value) => field.onChange(value?.toISOString())}
                  value={field.value ? dayjs(field.value) : null}
                />
              )}
            />
          </div>
        </Col>
        <Col span={12} className={styles.half}>
          <Typography.Text>To</Typography.Text>
          <div className={styles.inputs}>
            <Controller
              control={control}
              name={`${prefix}.to`}
              render={({ field, fieldState }) => (
                <DatePicker
                  status={fieldState.error ? 'error' : undefined}
                  className={styles.datepicker}
                  picker="month"
                  placeholder="To"
                  disabled={field.value === null}
                  onChange={(value) => field.onChange(value?.toISOString())}
                  value={field.value ? dayjs(field.value) : null}
                />
              )}
            />
          </div>
        </Col>
      </Row>
      <Controller
        control={control}
        name={`${prefix}.to`}
        render={({ field }) => (
          <Checkbox
            checked={field.value === null}
            onChange={handleCheckboxChange(field.onChange)}
          >
            Currently working here
          </Checkbox>
        )}
      />
    </div>
  );
}
