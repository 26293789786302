import { UserRole } from '@raised/domain';
import { TypographyModule } from '@raised/ui-kit';
import {
  IconCash, IconChevronDown, IconChevronUp, IconTransferOut, IconUserCircle,
} from '@tabler/icons-react';
import { Popover } from 'antd';
import UserAvatar from 'components/user/components/UserAvatar';
import { useState } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { PAYMENTS_ROUTES } from 'components/payments/payments-routes';
import { USER_ROUTES } from 'components/user/user-routes';
import styles from './Avatar.module.css';

const { Text } = TypographyModule;

interface AvatarProps {
  onGoToProfileClick: () => void;
  onGoToBillingInfoClick: () => void;
  role: UserRole;
  avatarPath?: string;
  onLogout: () => void;
}

export default function Avatar({
  role,
  onGoToProfileClick,
  onGoToBillingInfoClick,
  avatarPath,
  onLogout,
}: AvatarProps) {
  const { pathname } = useLocation();
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const onLinkClick = (cb: Function) => () => {
    setIsMenuOpened(false);
    cb();
  };

  return (
    <Popover
      placement="bottomRight"
      open={isMenuOpened}
      showArrow={false}
      onOpenChange={setIsMenuOpened}
      content={(
        <div className={styles.popoverContent}>
          <Text
            className={cn(styles.link, {
              [styles.active]: pathname === USER_ROUTES.PERSONAL_PROFILE(),
            })}
            onClick={onLinkClick(onGoToProfileClick)}
          >
            <IconUserCircle size={24} stroke={1.5} />
            Profile
          </Text>
          {(role === UserRole.CLIENT || role === UserRole.EXPERT) ? (
            <Text
              className={cn(styles.link, {
                [styles.active]: pathname === PAYMENTS_ROUTES.CLIENT_BILLING_INFO() || pathname === PAYMENTS_ROUTES.EXPERT_BILLING_INFO(),
              })}
              onClick={onLinkClick(onGoToBillingInfoClick)}
            >
              <IconCash size={24} stroke={1.5} />
              Billing info
            </Text>
          ) : null}
          <Text className={styles.link} onClick={onLinkClick(onLogout)}>
            <IconTransferOut size={24} stroke={1.5} />
            Log out
          </Text>
        </div>
    )}
      trigger={['hover', 'click']}
    >
      <div className={styles.content}>
        <UserAvatar size="s" role={role} filePath={avatarPath} />
        {isMenuOpened ? <IconChevronUp size={20} /> : <IconChevronDown size={20} />}
      </div>
    </Popover>
  );
}
