import { ReactNode } from 'react';
import styles from './FilterButton.module.css';

interface FilterButtonProps {
  onClick: () => void;
  children: ReactNode;
}

export default function FilterButton({
  onClick,
  children,
}: FilterButtonProps) {
  return (
    <button
      className={styles.filter_button}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
}
