import { Organisation } from '@raised/domain';
import Table from 'components/layout/components/table/Table';
import { getOrganizationsTableConfig } from './organizationsTableConfig';

interface OrganizationsTableProps {
  organizations: Organisation.Public[];
  getOrganizationLink: (organizationId: string) => string;
  isLoading: boolean;
}

export default function OrganizationsTable({
  organizations,
  getOrganizationLink,
  isLoading,
}: OrganizationsTableProps) {
  const tableData = organizations.map((org) => ({
    key: org.id,
    organisationLink: getOrganizationLink(org.id),
    name: org.companyName || '-',
    website: org.companyWebsite || '-',
    createdAt: org.createdAt,
  }));
  const tableConfig = getOrganizationsTableConfig();

  return (
    <Table
      tableName="organizations"
      columns={tableConfig}
      dataSource={tableData}
      loading={isLoading}
    />
  );
}
