import { useRef } from 'react';
import { Template } from '@raised/domain';
import DefaultFooter from 'components/layout/components/form-modal/default-footer/DefaultFooter';
import FormModal from 'components/layout/components/form-modal/FormModal';
import useCreateUsecase from 'components/usecases/hooks/useCreateUsecase';
import CreateUsecasePage from 'components/usecases/pages/create-usecase/CreateUsecasePage';

interface CreateUsecaseModalProps {
  isVisible: boolean;
  onClose: () => void;
  defaultValues?: Partial<Template.Public>;
}

export default function CreateUsecaseModal({
  isVisible,
  onClose,
  defaultValues,
}: CreateUsecaseModalProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const { isLoading } = useCreateUsecase({ isNotify: false });

  const onSubmit = () => {
    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  const handleClose = () => {
    onClose();
    formRef.current?.dispatchEvent(new Event('reset', { cancelable: true, bubbles: true }));
  };

  return (
    <FormModal
      isVisible={isVisible}
      onClose={handleClose}
      title="Create Use Case"
      footer={<DefaultFooter isLoading={isLoading} onCancel={handleClose} onSubmit={onSubmit} />}
    >
      <CreateUsecasePage defaultValues={defaultValues} formRef={formRef} isModal onSuccess={handleClose} />
    </FormModal>
  );
}
