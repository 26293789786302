import { zodResolver } from '@hookform/resolvers/zod';
import { User, UserSchemas } from '@raised/domain';
import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { getRoleLabel } from 'components/user/utils';
import { useForm } from 'react-hook-form';
import { renderFormConfig, scrollToError } from 'utils/forms';
import { getActivateUserFormConfig } from './activateUserFormConfig';

const { Title } = TypographyModule;
const { Button } = ButtonsModule;

interface ActivateUserFormProps {
  defaultValues: User.Public;
  onSubmit: (values: User.ActivateGhost) => void;
  isLoading: boolean;
}

export default function ActivateUserForm({
  onSubmit,
  isLoading,
  defaultValues,
}: ActivateUserFormProps) {
  const { control, handleSubmit } = useForm<User.ActivateGhost>({
    resolver: zodResolver(UserSchemas.activateGhostSchema),
    defaultValues: {
      ...defaultValues,
      userId: defaultValues.id,
    },
  });

  const formConfig = getActivateUserFormConfig(control);

  return (
    <form onSubmit={handleSubmit(onSubmit, scrollToError)} className="form_container">
      <Title style={{ textAlign: 'center' }} level={2}>
        {`Activate Your ${getRoleLabel(defaultValues.role)}’s Profile`}
      </Title>
      {renderFormConfig(formConfig)}
      <Button
        type="primary"
        htmlType="submit"
        loading={isLoading}
        disabled={isLoading}
      >
        Activate
      </Button>
    </form>
  );
}
