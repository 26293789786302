import { RouteObject } from 'react-router-dom';
import MainLayout from 'components/layout/containers/main-layout/MainLayout';
import ProtectedRouteLayout from 'components/layout/containers/protected-route-layout/ProtectedRouteLayout';
import CreateProposalPage from './create-proposal/CreateProposalPage';
import EditProposalPage from './edit-proposal/EditProposalPage';

export const PROPOSAL_ROUTES = {
  CREATE: (matchId: string) => `/proposal/create/${matchId}`,
  EDIT: (proposalId: string) => `/proposal/edit/${proposalId}`,
};

const proposalRoutes: Array<RouteObject> = [
  {
    element: <MainLayout />,
    children: [
      {
        element: <ProtectedRouteLayout />,
        children: [
          {
            path: PROPOSAL_ROUTES.CREATE(':id'),
            element: <CreateProposalPage />,
          },
          {
            path: PROPOSAL_ROUTES.EDIT(':id'),
            element: <EditProposalPage />,
          },
        ],
      },
    ],
  },
];

export default proposalRoutes;
