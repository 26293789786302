import { Button, Typography } from 'antd';
import styles from './ExpertBio.module.css';

interface ExpertBioProps {
  text: string,
  onMoreClick: () => void;
  isMore?: boolean;
}

export default function ExpertBio({
  text,
  onMoreClick,
  isMore = true,
}: ExpertBioProps) {
  return (
    <div className={styles.container}>
      <Typography.Paragraph
        className={styles.text}
        ellipsis={{
          rows: 2,
          expandable: false,
        }}
      >
        {text || '-'}
      </Typography.Paragraph>
      {text && isMore ? (
        <Button className={styles.button} type="link" onClick={onMoreClick}>more</Button>
      ) : null}
    </div>
  );
}
