import { User } from '@raised/domain';
import { useState } from 'react';

interface UseExpertModalProps {
  experts?: User.Public[];
}

export default function useExpertModal({
  experts,
}: UseExpertModalProps) {
  const [expertId, setExpertId] = useState('');

  const openModal = (userId: string) => setExpertId(userId);
  const closeModal = () => setExpertId('');
  const selectedExpert = experts?.find((expert) => expert.id === expertId);
  const isVisible = Boolean(selectedExpert);

  return {
    openModal,
    closeModal,
    isVisible,
    selectedExpert,
  };
}
