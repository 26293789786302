import { TypographyModule } from '@raised/ui-kit';
import { ReactNode } from 'react';
import styles from './AdminOrganizationsListContainer.module.css';

const { Title } = TypographyModule;

interface AdminOrganizationsListContainerProps {
  children: ReactNode;
}

export default function AdminOrganizationsListContainer({
  children,
}: AdminOrganizationsListContainerProps) {
  return (
    <div className={styles.container}>
      <Title level={1}>Organizations</Title>
      {children}
    </div>
  );
}
