import { FC } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import type { ControllerProps } from 'react-hook-form';

interface FormConfigItem<T extends FieldValues> {
  controller: Omit<ControllerProps<T>, 'render'>;
  component: FC<any>;
  componentProps?: any;
  label?: string;
  subLabel?: string;
}

export type FormConfig<T extends FieldValues> = Array<FormConfigItem<T>>;

export const renderFormConfig = <T extends FieldValues>(
  formConfig: FormConfig<T>,
) => formConfig.map((item) => (
  <Controller
    key={item.controller.name}
    {...item.controller}
    render={({ field, fieldState }) => (
      <item.component
        style={{ width: '100%' }}
        label={item.label}
        subLabel={item.subLabel}
        {...item.componentProps}
        {...field}
        status={fieldState.error ? 'error' : undefined}
      />
    )}
  />
  ));

export const excludeNullFields = <T extends Record<string, any>>(data: T, exceptions?: string[]): T => JSON.parse(
  JSON.stringify(data, (key, value) => (value === null && !exceptions?.includes(key) ? undefined : value)),
);

export const scrollToError = () => {
  const errorClassnames = ['.ant-input-status-error', '.ant-select-status-error', '.ant-picker-status-error'];

  setTimeout(() => {
    let errorElement!: Element | null;

    errorClassnames.forEach((className) => {
      if (!errorElement) {
        errorElement = document.querySelector(className);
      }
    });

    errorElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, 100);
};
