import { BreadcrumbProps as AntBreadcrumbProps, Breadcrumb as AntBreadcrumb } from 'antd';
import cn from 'classnames';
import styles from './Breadcrumb.module.css';

interface BreadcrumbProps extends AntBreadcrumbProps {}

export default function Breadcrumb({
  className,
  ...props
}: BreadcrumbProps) {
  return (
    <AntBreadcrumb {...props} className={cn(styles.breadcrumb, className)} />
  );
}
