import { RouteObject } from 'react-router-dom';
import MainLayout from 'components/layout/containers/main-layout/MainLayout';
import ExpertProtectedRoute from 'components/layout/containers/expert-route/ExpertProtectedRoute';
import ClientProtectedRoute from 'components/layout/containers/client-route/ClientProtectedRoute';
import ConfirmMatchPage from './confirm-match/ConfirmMatchPage';
import ClientMatchOverviewPage from './match-overview/client/ClientMatchOverviewPage';

export const MATCH_ROUTES = {
  CONFIRM_MATCH: () => '/match/confirm',
  CLIENT_MATCH_OVERVIEW: (matchId: string) => `/match/client/overview/${matchId}`,
};

const matchRoutes: Array<RouteObject> = [
  {
    element: <MainLayout />,
    children: [
      {
        element: <ExpertProtectedRoute />,
        children: [
          {
            path: MATCH_ROUTES.CONFIRM_MATCH(),
            element: <ConfirmMatchPage />,
          },
        ],
      },
    ],
  },
  {
    element: <MainLayout withoutSpacing />,
    children: [
      {
        element: <ClientProtectedRoute />,
        children: [
          {
            path: MATCH_ROUTES.CLIENT_MATCH_OVERVIEW(':id'),
            element: <ClientMatchOverviewPage />,
          },
        ],
      },
    ],
  },
];

export default matchRoutes;
