import AdminRouteLayout from 'components/layout/containers/admin-route/AdminRouteLayout';
import MainLayout from 'components/layout/containers/main-layout/MainLayout';
import PublicLayout from 'components/layout/containers/public-layout/PublicLayout';
import { RouteObject } from 'react-router-dom';
import AdminSuccessStoryListPage from './pages/admin-success-story-list/AdminSuccessStoryListPage';
import EditSuccessStoryPage from './pages/edit-success-story/EditSuccessStoryPage';
import ViewSuccessStoryPage from './pages/view-success-story/ViewSuccessStoryPage';

export const SUCCESS_STORY_ROUTES = {
  EDIT: (id: string) => `/success-story/edit/${id}`,
  PUBLIC_SUCCESS_STORY: (id: string) => `/success-story/${id}`,
  ADMIN_SUCCESS_STORY_LIST: () => '/success-story/list',
};

const successStoryRoutes: Array<RouteObject> = [
  {
    element: <MainLayout />,
    children: [
      {
        element: <AdminRouteLayout />,
        children: [
          {
            element: <EditSuccessStoryPage />,
            path: SUCCESS_STORY_ROUTES.EDIT(':id'),
          },
          {
            element: <AdminSuccessStoryListPage />,
            path: SUCCESS_STORY_ROUTES.ADMIN_SUCCESS_STORY_LIST(),
          },
        ],
      },
    ],
  },
  {
    element: <PublicLayout />,
    children: [
      {
        element: <ViewSuccessStoryPage />,
        path: SUCCESS_STORY_ROUTES.PUBLIC_SUCCESS_STORY(':id'),
      },
    ],
  },
];

export default successStoryRoutes;
