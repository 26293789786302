import { User } from '@raised/domain';
import { Button, Divider, Typography } from 'antd';
import UserInfo from '../components/UserInfo';

interface ProfileComponentProps {
  profileData: User.Public;
  onEditClickHandler: () => void;
}

export default function PersonalProfileComponent({
  profileData,
  onEditClickHandler,
}: ProfileComponentProps) {
  return (
    <div>
      <Typography.Title style={{ marginBottom: 24 }} level={4}>Profile details</Typography.Title>
      <UserInfo profileData={profileData} isShowHidden />
      <Divider />
      <Button type="primary" onClick={onEditClickHandler}>Edit profile</Button>
    </div>
  );
}
