import { useState } from 'react';
import { useGetAllLogMessagesCountQuery, useGetAllLogMessagesQuery } from '../storage/logMessageApi';

export default function useAllLogMessages() {
  const [page, setPage] = useState(1);
  const { data: count, isLoading: isCountLoading, isFetching: isCountFetching } = useGetAllLogMessagesCountQuery();
  const data = useGetAllLogMessagesQuery({
    page,
    per_page: 10,
  }, {
    refetchOnMountOrArgChange: true,
  });

  const toPage = (newPage: number) => setPage(newPage);

  return {
    ...data,
    count,
    isLoading: data.isLoading || isCountLoading,
    isFetching: data.isFetching || isCountFetching,
    page,
    toPage,
  };
}
