/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { LogMessage, Message } from '@raised/domain';
import { TypographyModule } from '@raised/ui-kit';
import { isMessage } from 'components/notifications/storage/type-guards';
import UserAvatar from 'components/user/components/UserAvatar';
import { formatDate } from 'utils/formats';
import styles from './NotificationItem.module.css';

const { Text } = TypographyModule;

interface NotificationItemProps {
  item: LogMessage.Public | Message.Public;
  onClick: () => void;
  isViewed: boolean;
  observerRef?: (element: HTMLDivElement) => void;
}

export default function NotificationItem({
  item,
  onClick,
  isViewed,
  observerRef,
}: NotificationItemProps) {
  return (
    <div
      data-notificationid={item.id}
      ref={observerRef}
      onClick={onClick}
      className={styles.container}
    >
      <UserAvatar role={item.from?.role} size="s" filePath={item.from?.avatar} style={{ flexShrink: 0 }} />
      <div className={styles.content}>
        <Text>
          <b>{`${item.from?.firstName} ${item.from?.lastName}`}</b>
          {' '}
          {isMessage(item) ? 'sent a new message' : null}
        </Text>
        <Text>
          {item.text}
        </Text>
        {!isMessage(item) ? (
          <Text type="secondary" className={styles.project_title}>{item.match?.project?.title}</Text>
        ) : null}
        <Text type="secondary" className={styles.date}>{formatDate(new Date(item.createdAt))}</Text>
        {!isViewed ? (
          <div className={styles.notViewedBadge} />
        ) : null}
      </div>
    </div>
  );
}
