import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { IconAlertCircle } from '@tabler/icons-react';
import styles from './ContactRaised.module.css';

const { Text } = TypographyModule;
const { Button } = ButtonsModule;

interface ContactRaisedProps {
  onContact: () => void;
}

export default function ContactRaised({
  onContact,
}: ContactRaisedProps) {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <IconAlertCircle size={20} />
        <Text>Please, contact Raised if you want to work with this Expert</Text>
      </div>
      <Button className={styles.btn} onClick={onContact}>Contact Raised</Button>
    </div>
  );
}
