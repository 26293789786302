import { ButtonsModule } from '@raised/ui-kit';
import { Layout } from 'antd';
import PublicLogo from 'assets/images/public_logo.png';
import styles from './PublicHeader.module.css';

const { Header } = Layout;
const { PublicButton } = ButtonsModule;

export default function PublicHeader() {
  return (
    <Header className={styles.public_header}>
      <img alt="" src={PublicLogo} className={styles.logo} />
      <div className={styles.buttons}>
        <PublicButton type="ghost" className={styles.success_btn}>Success Stories</PublicButton>
        <PublicButton type="default" className={styles.apply_btn}>Apply as an expert</PublicButton>
        <PublicButton type="primary" className={styles.find_btn}>Find a professional</PublicButton>
      </div>
    </Header>
  );
}
