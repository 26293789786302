import { ProjectPaymentPeriods, Proposal } from '@raised/domain';
import { Space, Typography, InputNumber } from 'antd';
import { PROJECT_PAYMENT_PERIODS } from 'components/project/constants/project-payment-periods';
import {
  Control, useController, useWatch,
} from 'react-hook-form';

interface PaymentRateInputProps {
  control: Control<Proposal.Create>;
  profilePaymentRate?: string;
}

export default function PaymentRateInput({
  control,
  profilePaymentRate,
}: PaymentRateInputProps) {
  const { field } = useController({
    control,
    name: 'paymentRate',
  });
  const selectedPaymentPeriod = useWatch({
    control,
    name: 'paymentPeriod',
    defaultValue: ProjectPaymentPeriods.DAYS,
  });

  return (
    <Space direction="vertical" size={16}>
      <Space direction="vertical">
        <Typography.Text strong>Payment rate for this project (Gross)</Typography.Text>
        <Typography.Text type="secondary">You will get 85% of this rate (after Raised 15% commission)</Typography.Text>
        <InputNumber
          value={field.value}
          onChange={(value) => field.onChange(value ? +value : null)}
          addonBefore={PROJECT_PAYMENT_PERIODS[selectedPaymentPeriod || ProjectPaymentPeriods.DAYS]}
          addonAfter="$"
          precision={2}
          min={0}
        />
        {profilePaymentRate ? (
          <Typography.Text>
            {`Your rate in the profile: ${profilePaymentRate}`}
            <br />
            (Feel free to change your profile rate if needed)
          </Typography.Text>
        ) : null}
      </Space>
    </Space>
  );
}
