import MatchStatuses from "../match/match-statuses";
import ProjectStatuses from "../project/project-statuses";
import LogMessageEvents from "./log-message-events";

export interface LogMessageEventPayloads {
  [LogMessageEvents.USER_CREATE_ACCOUNT]: void,
  [LogMessageEvents.GHOST_ACTIVATE_ACCOUNT]: void;
  [LogMessageEvents.PROPOSAL_ACCEPTED]: {
    proposalId: string;
    proposalTitle: string;
  };
  [LogMessageEvents.PROPOSAL_DECLINED]: {
    proposalId: string;
    proposalTitle: string;
  },
  [LogMessageEvents.PROPOSAL_SENT]: {
    proposalId: string;
    proposalTitle: string;
  };
  [LogMessageEvents.MATCH_CREATED]: {
    status: MatchStatuses;
  };
  [LogMessageEvents.MATCH_STATUS_CHANGED]: {
    status: MatchStatuses;
    message?: string;
  };
  [LogMessageEvents.MATCH_RESTORE]: {
    status: MatchStatuses,
    message?: string;
  },
  [LogMessageEvents.FEEDBACK]: {
    message: string;
  };
  [LogMessageEvents.ADMIN_CREATE_PROJECT]: {
    projectId: string;
    projectTitle: string;
  },
  [LogMessageEvents.PROPOSAL_PAID]: {
    isAdmin: boolean;
    proposalId: string;
    proposalTitle: string;
  },
  [LogMessageEvents.PROJECT_STATUS_CHANGED]: {
    projectId: string;
    projectTitle: string;
    projectStatus: ProjectStatuses;
    message?: string;
  },
  [LogMessageEvents.GHOST_CREATED]: void,
}
