import { MatchStatuses } from '@raised/domain';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ADMIN_MATCH_STATUS_LABELS } from 'components/match/constants/match-status-lables';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/formats';

export interface CreateProposalsTableRow {
  key: string;
  expertName: string;
  clientName: string;
  status: MatchStatuses;
  createdAt: string;
  expertLink: string;
  clientLink: string;
}

interface GetConfigProps {
  onCreateProposalClick: (matchId: string) => void;
}

export const getCreateProposalsTableConfig = ({
  onCreateProposalClick,
}: GetConfigProps): ColumnsType<CreateProposalsTableRow> => [
  {
    title: 'Expert',
    dataIndex: 'expertName',
    key: 'expertName',
    render: (value, record) => (
      <Link to={record.expertLink}>
        {value}
      </Link>
    ),
  },
  {
    title: 'Client',
    dataIndex: 'clientName',
    key: 'clientName',
    render: (value, record) => (
      <Link to={record.clientLink}>
        {value}
      </Link>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (value) => ADMIN_MATCH_STATUS_LABELS[value as MatchStatuses],
  },
  {
    title: 'Creation date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => {
      const opA = new Date(a.createdAt).getTime();
      const opB = new Date(b.createdAt).getTime();
      return opA - opB;
    },
    render: (value) => formatDate(new Date(value)),
  },
  {
    title: '',
    render: (_, record) => (
      <Button
        type="primary"
        onClick={() => onCreateProposalClick(record.key)}
      >
        Create a proposal
      </Button>
    ),
  },
];
