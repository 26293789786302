import { Spin } from 'antd';
import { Outlet, Navigate } from 'react-router-dom';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { AUTH_ROUTES } from 'components/auth/auth-routes';
import { UserRole } from '@raised/domain';
import { PROJECT_ROUTES } from 'components/project/project-routes';

export default function AdminRouteLayout() {
  const { isError, isLoading, data } = useGetProfileQuery();

  if (isLoading) {
    return <Spin />;
  }

  if (isError) {
    return <Navigate to={AUTH_ROUTES.LOGIN()} replace />;
  }

  if (data) {
    if (data.role !== UserRole.ADMIN) {
      return <Navigate to={PROJECT_ROUTES.PROJECTS()} replace />;
    }

    return <Outlet />;
  }

  return null;
}
