import { useDeferredValue, useState } from 'react';
import { useGetProjectsQuery } from '../storage/projectApi';

export default function useGetProjects() {
  const [search, setSearch] = useState('');
  const deferredSearch = useDeferredValue(search);
  const data = useGetProjectsQuery({
    search: deferredSearch,
  });

  const changeSearch = (newSearch: string) => setSearch(newSearch);

  return {
    ...data,
    search,
    changeSearch,
  };
}
