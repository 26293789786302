import { Helmet } from 'react-helmet';
import useForgotPassword from '../hooks/useForgotPassword';
import ForgotPasswordComponent from './ForgotPasswordComponent';

export default function ForgotPasswordPage() {
  const {
    forgotPassword,
    isLoading,
  } = useForgotPassword();

  return (
    <>
      <Helmet>
        <title>
          Raised - Forgot password
        </title>
      </Helmet>
      <ForgotPasswordComponent
        isLoading={isLoading}
        onSubmit={forgotPassword}
      />
    </>
  );
}
