import { Project } from '@raised/domain';
import { Typography } from 'antd';
import Table from 'components/layout/components/table/Table';
import { useMemo } from 'react';
import { DeletedProjectsTableRow, getDeletedProjectTableConfig } from './deletedProjectsTableConfig';

interface DeletedProjectsTableProps {
  isLoading: boolean;
  deletedProjects?: Project.Public[];
  getProjectLink: (projectId: string) => string;
  getUserLink: (userId: string) => string;
}

function ExpandedRowContent({ description }: DeletedProjectsTableRow) {
  return <Typography.Text>{description}</Typography.Text>;
}

export default function DeletedProjectsTable({
  isLoading,
  deletedProjects,
  getUserLink,
  getProjectLink,
}: DeletedProjectsTableProps) {
  const tableData = useMemo(() => deletedProjects?.map((project) => ({
    key: project.id,
    createdAt: `${project.createdAt}`,
    deletedAt: `${project.deletedAt}`,
    deletedBy: `${project.deletedBy}`,
    title: project.title,
    description: project.description,
    projectLink: getProjectLink(project.id),
    userLink: getUserLink(project.deletedBy || ''),
  })), [deletedProjects]);

  const columns = useMemo(() => getDeletedProjectTableConfig(), []);

  return (
    <Table
      tableName="deletedProjects"
      dataSource={tableData}
      columns={columns}
      loading={isLoading}
      expandable={{
        expandedRowRender: ExpandedRowContent,
      }}
    />
  );
}
