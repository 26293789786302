import { Proposal, UserRole } from '@raised/domain';
import { Spin } from 'antd';
import { useGetMatchByIdQuery } from 'components/match/storage/matchApi';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import useCreateProposal from '../hooks/useCreateProposal';
import CreateProposalComponent from './CreateProposalComponent';

export default function CreateProposalPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { createProposal, isSuccess, isLoading } = useCreateProposal();
  const { data: profile, isSuccess: isProfileSuccess } = useGetProfileQuery();
  const { data: match, isSuccess: isMatchSuccess, isLoading: isMatchLoading } = useGetMatchByIdQuery({ id: id as string }, { skip: !id });

  const onFormSubmit = (values: Proposal.Create) => {
    if (profile?.role === UserRole.ADMIN) {
      createProposal({ ...values, expertId: match?.userId });
      return;
    }
    createProposal(values);
  };
  const onBack = () => navigate(-1);

  useEffect(() => {
    if (!isProfileSuccess) return;

    if (profile.role === UserRole.CLIENT) {
      onBack();
    }
  }, [isProfileSuccess]);

  useEffect(() => {
    if (isSuccess) {
      onBack();
    }
  }, [isSuccess]);

  return (
    <>
      <Helmet>
        <title>Raised - Create proposal</title>
      </Helmet>
      {isMatchLoading ? (
        <Spin />
      ) : null}
      {isMatchSuccess && id && (
        <CreateProposalComponent
          onFormSubmit={onFormSubmit}
          onBack={onBack}
          match={match}
          matchId={id}
          isLoading={isLoading}
          profilePaymentRate={profile?.rate}
        />
      )}
    </>
  );
}
