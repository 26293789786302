import { Helmet } from 'react-helmet';
import useAllLogMessages from '../hooks/useAllLogMessages';
import LogMessagesFeedComponent from './LogMessagesFeedComponent';

export default function LogMessagesFeedPage() {
  const {
    data, isLoading, page, toPage, count, isFetching,
  } = useAllLogMessages();

  return (
    <>
      <Helmet>
        <title>Raised - Log messages feed</title>
      </Helmet>
      <LogMessagesFeedComponent
        messages={data}
        isLoading={isLoading}
        isFetching={isFetching}
        page={page}
        toPage={toPage}
        total={count || 0}
      />
    </>
  );
}
