import { PROJECT_ROUTES } from 'components/project/project-routes';
import { MESSAGE_ROUTES } from 'components/messages/messages-routes';
import { useGetTemplateByIdQuery } from 'components/templates/storage/templatesApi';
import { useNavigate, useParams } from 'react-router-dom';
// import useGetExperts from 'components/user/hooks/useGetExperts';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { UserRole } from '@raised/domain';
import { TEMPLATES_ROUTES } from 'components/templates/templates-routes';
import TemplateControls from './template-controls/TemplateControls';
import TemplateInfo from './template-info/TemplateInfo';
import TemplateContainer from './TemplateContainer';
// import ExpertSpotlight from './expert-spotlight/ExpertSpotlight';

export default function TemplatePage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: profile,
  } = useGetProfileQuery();
  const {
    data: template,
    isLoading: isTemplateLoading,
  } = useGetTemplateByIdQuery({ id: id || '' }, { skip: !id });
  // const {
  //   data: experts,
  //   isLoading: isExpertsLoading,
  // } = useGetExperts();

  const onBack = () => navigate(-1);
  const onCreate = () => {
    if (!id) return;

    navigate(PROJECT_ROUTES.CREATE_PROJECT_FROM_TEMPLATE(id));
  };
  const onContact = () => navigate(MESSAGE_ROUTES.SUPPORT_CHAT_PAGE());
  const onEditClick = () => {
    if (!id) return;

    navigate(TEMPLATES_ROUTES.EDIT(id));
  };

  return (
    <TemplateContainer
      templateTitle={template?.title}
      isLoading={isTemplateLoading}
      onBack={onBack}
      controls={profile?.role === UserRole.CLIENT ? (
        <TemplateControls
          onCreate={onCreate}
          onContact={onContact}
        />
      ) : null}
    >
      {template ? (
        <>
          <TemplateInfo
            isAdmin={profile?.role === UserRole.ADMIN}
            onEditClick={onEditClick}
            onCreateClick={onCreate}
            template={template}
          />
        </>
      ) : null}
      {/* {experts?.length ? ( */}
      {/*   <ExpertSpotlight experts={experts} /> */}
      {/* ) : null} */}
    </TemplateContainer>
  );
}
