import { Proposal } from '@raised/domain';
import { FormsModule } from '@raised/ui-kit';
import { Control } from 'react-hook-form';
import { FormConfig } from 'utils/forms';

const { Input, TextArea } = FormsModule;

// const projectTypeOptions = Object.entries(PROPOSAL_RPOJECT_TYPES).map(([value, label]) => ({ value, label }));

export const getCreateProposalFormConfig = (control: Control<Proposal.Create>): FormConfig<Proposal.Create> => [
  {
    controller: {
      name: 'title',
      control,
    },
    label: 'Title for the project',
    subLabel: 'Keep title from project request or write new title',
    component: Input,
    componentProps: {
      placeholder: 'Title...',
    },
  },
  {
    controller: {
      name: 'description',
      control,
    },
    label: 'Project description',
    subLabel: 'We have added the description from the project posting. Feel free to change or add details',
    component: TextArea,
    componentProps: {
      placeholder: 'Description...',
      rows: 5,
    },
  },
  {
    controller: {
      name: 'approachAndDeliverables',
      control,
    },
    label: 'Approach & deliverables',
    subLabel: 'Describe your plan to meet the goals of the project ',
    component: TextArea,
    componentProps: {
      placeholder: 'Approach & deliverables...',
      rows: 5,
    },
  },
  // {
  //   controller: {
  //     name: 'projectType',
  //     control,
  //   },
  //   label: 'Payment Type',
  //   component: Select,
  //   componentProps: {
  //     placeholder: 'Select project type',
  //     options: projectTypeOptions,
  //   },
  // },
];
