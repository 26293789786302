import { ProposalStatuses } from '@raised/domain';
import { TypographyModule } from '@raised/ui-kit';
import { PROPOSAL_STATUS_LABELS } from 'components/proposal/constants/proposal-statuses';
import styles from './ProposalHeader.module.css';

const { Title, Text } = TypographyModule;

interface ProposalHeaderProps {
  status: ProposalStatuses;
  title: string;
  isPaid: boolean;
}

export default function ProposalHeader({
  title,
  status,
  isPaid,
}: ProposalHeaderProps) {
  return (
    <div className={styles.proposal_header}>
      <Title level={5}>{title}</Title>
      <div className={styles.status}>
        {isPaid ? (
          <>
            <Text>Proposal is paid</Text>
            {PROPOSAL_STATUS_LABELS[ProposalStatuses.ACCEPTED].icon}
          </>
        ) : (
          <>
            <Text>
              {PROPOSAL_STATUS_LABELS[status].text}
            </Text>
            {PROPOSAL_STATUS_LABELS[status].icon}

          </>
        )}
      </div>
    </div>
  );
}
