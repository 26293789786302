import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useCreateGhost from '../hooks/useCreateGhost';
import { USER_ROUTES } from '../user-routes';
import CreateUserForm from '../components/forms/create-user/CreateUserForm';

export default function CreateGhostPage() {
  const navigate = useNavigate();
  const { createGhost, isLoading } = useCreateGhost({
    onSuccess: ({ id }) => navigate(USER_ROUTES.USER_PROFILE(id)),
  });

  return (
    <>
      <Helmet>
        <title>Raised - Create ghost account</title>
      </Helmet>
      <CreateUserForm
        isAdmin
        isGhost
        onSubmit={createGhost}
        isLoading={isLoading}
        title="Create Ghost User"
        isUploadAvatar
      />
    </>
  );
}
