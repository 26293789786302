import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useGetProjectByIdQuery } from '../storage/projectApi';

interface UseGetProjectProps {
  id?: string;
}

export default function useGetProjectById({ id }: UseGetProjectProps) {
  const data = useGetProjectByIdQuery({ id: id as string }, { skip: !id });

  useEffect(() => {
    if (data.isError) {
      message.error(getErrorMessage(data.error));
    }
  }, [data.isError]);

  return data;
}
