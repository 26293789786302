/* eslint-disable jsx-a11y/anchor-is-valid */
import { Payment } from '@raised/domain';
import {
  ColorsModule, DataModule, FeedbackModule, TypographyModule,
} from '@raised/ui-kit';
import { IconClockHour3, IconSpeakerphone } from '@tabler/icons-react';
import PaymentStatus from 'components/payments/components/PaymentStatus';
import ViewProposalModal from 'components/proposal/components/ViewProposalModal';
import UserAvatar from 'components/user/components/UserAvatar';
import { useState } from 'react';
import { formatCurrency, formatDate } from 'utils/formats';
import styles from './PaymentsTable.module.css';

const { Spin } = FeedbackModule;
const { Empty } = DataModule;
const { Colors } = ColorsModule;
const { Title, Text, Link } = TypographyModule;

interface PaymentsTableProps {
  payments: Payment.Public[]
  isLoading: boolean;
}

export default function PaymentsTable({
  payments,
  isLoading,
}: PaymentsTableProps) {
  const [viewPaymentProposal, setViewPaymentProposal] = useState('');

  const onProposalClick = (proposalId: string) => setViewPaymentProposal(proposalId);
  const onCloseProposalModal = () => setViewPaymentProposal('');

  return (
    <div className={styles.table}>
      {isLoading ? (
        <Spin />
      ) : null}
      {!isLoading && !payments.length ? (
        <Empty icon={<IconSpeakerphone size={80} stroke={0.6} strokeOpacity={0.5} color={Colors.gray} />}>
          <Text type="secondary">No payments yet</Text>
        </Empty>
      ) : null}
      {!isLoading && payments.length ? (
        <>
          {payments.map((payment) => (
            <div key={payment.id} className={styles.table_row}>
              <div className={styles.row_content}>
                <div className={styles.title_block}>
                  <Title level={5}>{payment.proposal?.title}</Title>
                  <Text className={styles.mobile_date} caption type="secondary">{formatDate(new Date(payment.createdAt))}</Text>
                  <Link className={styles.proposal_link} onClick={() => onProposalClick(payment.proposalId)}>View Proposal</Link>
                </div>
                <div className={styles.user_block}>
                  <UserAvatar className={styles.avatar} size="s" role={payment.proposal?.expert?.role} filePath={payment.proposal?.expert?.avatar} />
                  <Text className={styles.username}>{`${payment.proposal?.expert?.firstName} ${payment.proposal?.expert?.lastName}`}</Text>
                </div>
                <div className={styles.date_block}>
                  <IconClockHour3 size={14} color={Colors.gray} />
                  <Text type="secondary">{formatDate(new Date(payment.createdAt))}</Text>
                </div>
              </div>
              <div className={styles.amount_block}>
                <Text>{formatCurrency(payment.amount / 100)}</Text>
                <PaymentStatus paymentStatus={payment.status} />
              </div>
            </div>
          ))}
        </>
      ) : null}
      <ViewProposalModal
        isVisible={!!viewPaymentProposal}
        onClose={onCloseProposalModal}
        proposalId={viewPaymentProposal}
      />
    </div>
  );
}
