import { useEffect, useState } from 'react';

interface UseUploadeTemplateFilesProps {
  initialValues?: string[];
}

export default function useTemplateFiles({
  initialValues,
}: UseUploadeTemplateFilesProps) {
  const [uploadedFiles, setUploadedFiles] = useState<string[]>(initialValues || []);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (initialValues?.length) {
      setUploadedFiles(initialValues);
    }
  }, [initialValues]);

  const addFiles = (newFiles: File[]) => setFiles((stateFiles) => [...stateFiles, ...newFiles]);
  const removeFile = (fileName: string) => {
    setFiles((stateFiles) => stateFiles.filter((file) => file.name !== fileName));
    setUploadedFiles((stateFiles) => stateFiles.filter((file) => file !== fileName));
  };

  return {
    addFiles,
    removeFile,
    files,
    uploadedFiles,
  };
}
