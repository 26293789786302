import { User } from '@raised/domain';
import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useCreateGhostMutation } from '../storage/userApi';

interface UseCreateGhostProps {
  onSuccess: (createdUser: User.Public) => void;
}

export default function useCreateGhost({
  onSuccess,
}: UseCreateGhostProps) {
  const [createGhost, info] = useCreateGhostMutation();
  const {
    isError, isSuccess, error, data,
  } = info;

  useEffect(() => {
    if (isError) {
      message.error(getErrorMessage(error));
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      message.success('Ghost client successfully created');
      onSuccess(data);
    }
  }, [isSuccess]);

  return {
    createGhost,
    ...info,
  };
}
