import cn from 'classnames';
import {
  FileDoneOutlined, LoadingOutlined,
} from '@ant-design/icons';
import { Button, Input, Spin } from 'antd';
import {
  ChangeEvent, KeyboardEvent, useContext, useRef, useState,
} from 'react';
import { ThemeContext } from 'components/layout/theme/themeContext';
import { ThemeEnum } from 'components/layout/theme/themeConfig';
import { IconSquareRoundedPlus, IconSend } from '@tabler/icons-react';
import styles from './ChatInput.module.css';

interface ChatInputProps {
  onSendMessage: (text: string) => void;
  uploadFile: (file: File) => void;
  isFileUploading: boolean;
  isFileLoaded: boolean;
  onInputFocus?: () => void;
}

function IconButton(isLoading: boolean, isSuccess: boolean) {
  if (isLoading) {
    return <Spin indicator={<LoadingOutlined spin />} />;
  }

  if (isSuccess) {
    return <FileDoneOutlined />;
  }

  return <IconSquareRoundedPlus color="#000" opacity={0.3} size={24} />;
}

export default function ChatInput({
  onSendMessage,
  uploadFile,
  isFileUploading,
  isFileLoaded,
  onInputFocus,
}: ChatInputProps) {
  const fileUploaderRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState('');
  const { themeName } = useContext(ThemeContext);
  const isDarkTheme = themeName === ThemeEnum.DARK;

  const handleValueChange = (e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value);
  const send = () => {
    if (!message) return;
    onSendMessage(message);
    setMessage('');
  };
  const handleKeyPress = (e: KeyboardEvent) => {
    const isMobile = window.innerWidth < 500;

    if (e.code === 'Enter' && e.shiftKey) {
      return;
    }

    if (e.code === 'Enter' && !isMobile) {
      e.preventDefault();
      send();
    }
  };

  const onAddFileClickHandler = () => {
    fileUploaderRef.current?.click();
  };

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      uploadFile(e.target.files[0]);
    }
  };

  return (
    <div
      className={cn(styles.container, {
        [styles.dark_container]: isDarkTheme,
      })}
    >
      <div className={styles.input_container}>
        <Input.TextArea
          value={message}
          onChange={handleValueChange}
          onKeyDown={handleKeyPress}
          className={styles.input}
          autoSize={{ maxRows: 7 }}
          placeholder="Write a message..."
          onFocus={onInputFocus}
          data-testid="input_chatMessage"
        />
        <div className={styles.buttons}>
          <Button
            onClick={onAddFileClickHandler}
            type="link"
            className={styles.uploadButton}
            disabled={isFileUploading || isFileLoaded}
            icon={IconButton(isFileUploading, isFileLoaded)}
          />
          <Button
            onClick={send}
            className={styles.send_button}
            type="link"
            icon={<IconSend size={24} color="#0057FF" />}
          />
        </div>
      </div>
      <input
        onChange={onChangeFile}
        type="file"
        ref={fileUploaderRef}
        style={{ display: 'none' }}
      />
    </div>
  );
}
