import { ProposalStatuses } from '@raised/domain';
import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import PaymentCell from './PaymentCell';

interface ColumnType {
  key: string;
  proposalTitle: string;
  status: ProposalStatuses;
  expertName: string;
  expertLink: string;
}

interface GetConfigProps {
  onPayClick: (proposalId: string) => void;
  onViewProposalClick: (proposalId: string) => void;
}

export const getCreatePaymentsTableConfig = ({
  onPayClick,
  onViewProposalClick,
}: GetConfigProps): ColumnsType<ColumnType> => [
  {
    title: 'Proposal title',
    dataIndex: 'proposalTitle',
    key: 'proposalTitle',
  },
  {
    title: 'Expert',
    dataIndex: 'expertName',
    key: 'expertName',
    render: (value, record) => (
      <Link to={record.expertLink}>
        {value}
      </Link>
    ),
  },
  {
    title: 'View proposal',
    dataIndex: 'view_proposal',
    key: 'view_proposal',
    render: (_v, record) => (
      <Typography.Link onClick={(e) => {
        e.stopPropagation();
        onViewProposalClick(record.key);
      }}
      >
        View proposal
      </Typography.Link>
    ),
  },
  {
    title: '',
    dataIndex: 'isPaid',
    key: 'isPaid',
    render: (_, record) => (
      <PaymentCell
        onPayClick={() => onPayClick(record.key)}
      />
    ),
  },
];
