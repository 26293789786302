import { ReactNode } from 'react';
import styles from './TemplatesGrid.module.css';

interface TemplatesContentProps {
  children: ReactNode;
}

export default function TemplatesGrid({ children }: TemplatesContentProps) {
  return (
    <div className={styles.templates_grid}>{children}</div>
  );
}
