import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { formatDate } from 'utils/formats';

export interface SuccessStoriesTableRow {
  key: string;
  name: string;
  successStoryLink: string;
  expertName: string;
  expertLink: string;
  createdAt: string;
}

export const getSuccessStoriesTableConfig = (): ColumnsType<SuccessStoriesTableRow> => [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (value, record) => <Link to={record.successStoryLink}>{value}</Link>,
  },
  {
    title: 'Expert',
    dataIndex: 'expertName',
    render: (value, record) => <Link to={record.expertLink}>{value}</Link>,
  },
  {
    title: 'Creation date',
    dataIndex: 'createdAt',
    sorter: (a, b) => {
      const opA = new Date(a.createdAt).getTime();
      const opB = new Date(b.createdAt).getTime();
      return opA - opB;
    },
    render: (value) => formatDate(new Date(value)),
  },

];
