import { FeedbackModule } from '@raised/ui-kit';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useAcceptProjectMutation } from '../storage/projectApi';

const { Message } = FeedbackModule;

export default function useAcceptProject() {
  const [mutate, info] = useAcceptProjectMutation();
  const { isSuccess, isError, error } = info;

  useEffect(() => {
    if (!isError) return;

    Message.error(getErrorMessage(error));
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;

    Message.success('Project successfully accepted');
  }, [isSuccess]);

  const acceptProject = (projectId: string) => {
    mutate({ projectId });
  };

  return {
    ...info,
    acceptProject,
  };
}
