/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Organisation, Project, User } from '@raised/domain';
import {
  ButtonsModule, ColorsModule, LayoutModule, TypographyModule,
} from '@raised/ui-kit';
import {
  IconAd2, IconClockHour3, IconPencil,
} from '@tabler/icons-react';
import AttachedFile from 'components/layout/components/attached-file/AttachedFile';
import { getProjectBudget } from 'components/project/utils';
import { ReactNode, useState } from 'react';
import { formatDate } from 'utils/formats';
import cn from 'classnames';
import { PROJECT_CATEGORIES } from '../../constants/project-categories';
import { PROJECT_TYPES } from '../../constants/project-types';
import DeletedProjectMessage from './DeletedProjectMessage';
import styles from './ProjectInfo.module.css';

const { Button } = ButtonsModule;
const { Text, Title } = TypographyModule;
const { Colors } = ColorsModule;
const { Space } = LayoutModule;

interface ProjectInfoProps {
  project: Project.Public;
  onUserClick: (userId: string) => void;
  onEditClick?: () => void;
  isProjectEditable: boolean;
  archiveButton: ReactNode;
  statusContent: ReactNode;
  author?: User.Public;
  organisation?: Organisation.Public;
  isPersonal: boolean;
}

export default function ProjectInfo({
  project,
  onUserClick,
  onEditClick,
  isProjectEditable,
  archiveButton,
  statusContent,
  author,
  organisation,
  isPersonal,
}: ProjectInfoProps) {
  const [isMoreDetails, setIsMoreDetails] = useState(false);

  const onMoreClick = () => {
    if (isMoreDetails) {
      setIsMoreDetails(false);
    } else {
      setIsMoreDetails(true);
    }
  };

  return (
    <Space direction="vertical">
      {/* <TimezoneMessage style={{ margin: 0 }} /> */}
      <div className={styles.top_block}>
        <div className={styles.mobile_header}>
          {isProjectEditable ? (
            <div className={styles.edit_block} onClick={onEditClick}>
              <Text type="secondary">Edit project</Text>
              <IconPencil size={16} color={Colors.gray} />
            </div>
          ) : null}
          {archiveButton}
        </div>
        <div className={styles.title_block}>
          <Title style={{ margin: 0 }} level={1}>{project.title}</Title>
          <div className={styles.desktop_archive_btn_container}>
            {archiveButton}
          </div>
        </div>
        <div className={styles.date_block}>
          <IconClockHour3 color={Colors.gray} size={14} />
          <Text className={styles.date} caption type="secondary">{formatDate(new Date(project.createdAt))}</Text>
        </div>
        {isProjectEditable ? (
          <div className={cn(styles.edit_block, styles.desktop_edit_block)} onClick={onEditClick}>
            <Text type="secondary">Edit project</Text>
            <IconPencil size={16} color={Colors.gray} />
          </div>
        ) : null}
      </div>
      {!isPersonal && author && organisation ? (
        <div className={styles.created_by_block}>
          <div className={styles.created_by_item}>
            <Text type="secondary">Created by</Text>
            <Text>{`${author?.firstName} ${author?.lastName}`}</Text>
          </div>
          <div className={styles.created_by_item}>
            <Text type="secondary">Organization</Text>
            <Text>{organisation?.companyName}</Text>
          </div>
        </div>
      ) : null}
      <div className={styles.mobile_status_container}>
        {statusContent}
      </div>
      <div className={styles.description}>
        <IconAd2 fill={Colors.liner} />
        <Text style={{ whiteSpace: 'pre-wrap' }}>{project.description}</Text>
      </div>
      {!isMoreDetails ? (
        <Button type="link" className={styles.more_btn} onClick={onMoreClick}>More project details</Button>
      ) : null}
      <div className={cn(styles.content, {
        [styles.more_content]: isMoreDetails,
      })}
      >
        <Text type="secondary">Expert Preference</Text>
        <Text style={{ whiteSpace: 'pre-wrap' }}>{project.expertPreference}</Text>

        <Text type="secondary">Categories</Text>
        <Text>{project.category.map((category) => PROJECT_CATEGORIES[category]).join(', ')}</Text>

        <Text type="secondary">Project type: </Text>
        <Text>{project.type?.map((type) => PROJECT_TYPES[type]).join(', ')}</Text>

        <Text type="secondary">Project budget: </Text>
        <Text>{getProjectBudget(project)}</Text>

        {project.attachedFile?.length ? (
          <>
            <Text type="secondary" style={{ whiteSpace: 'nowrap' }}>Attached files: </Text>
            <Space wrap>
              {project.attachedFile.map((file) => <AttachedFile fileName={file} />)}
            </Space>
          </>
        ) : null}

      </div>
      {isMoreDetails ? (
        <Button type="link" className={styles.more_btn} onClick={onMoreClick}>Less project details</Button>
      ) : null}
      {project.deletedAt && project.deletedBy ? (
        <DeletedProjectMessage
          deletedAt={project.deletedAt}
          deletedBy={project.deletedBy}
          onUserClick={() => onUserClick(project.deletedBy || '')}
        />
      ) : null}
      <div className={styles.desktop_status_container}>
        {statusContent}
      </div>
    </Space>
  );
}
