import { MatchStatuses, UserRole } from '@raised/domain';
import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ADMIN_MATCH_STATUS_LABELS } from 'components/match/constants/match-status-lables';
import { Link } from 'react-router-dom';
import MatchActionsCell from './MatchActionsCell';

export interface MatchActionsTableRow {
  key: string;
  clientName: string;
  expertName: string;
  status: MatchStatuses;
  isRestore: boolean;
  expertLink: string;
  clientLink: string;
}

interface GetConfigProps {
  onDeclineClick: (matchId: string, role: UserRole) => void;
  onRestoreClick: (matchId: string) => void;
  onFinishMatch: (matchId: string) => void;
  onAcceptClick: (matchId: string, role: UserRole) => void;
  onAcceptAssignClick: (matchId: string) => void;
  onInviteToInterviewClick: (matchId: string) => void;
  isLoading: boolean;
}

const matchStatusFilters = Object.values(MatchStatuses).map((value) => ({
  value,
  text: ADMIN_MATCH_STATUS_LABELS[value],
}));

export const getMatchActionsTableConfig = ({
  onDeclineClick,
  onRestoreClick,
  onFinishMatch,
  onAcceptClick,
  onAcceptAssignClick,
  onInviteToInterviewClick,
  isLoading,
}: GetConfigProps): ColumnsType<MatchActionsTableRow> => [
  {
    title: 'Expert',
    dataIndex: 'expertName',
    key: 'expertName',
    render: (value, record) => <Link to={record.expertLink}>{value}</Link>,
  },
  {
    title: 'Client',
    dataIndex: 'clientName',
    key: 'clientName',
    render: (value, record) => <Link to={record.clientLink}>{value}</Link>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: matchStatusFilters,
    onFilter: (value, record) => record.status.startsWith(value as string),
    render: (value) => <Typography.Text>{ADMIN_MATCH_STATUS_LABELS[value as MatchStatuses]}</Typography.Text>,
  },
  {
    title: 'Actions',
    render: (_v, record) => (
      <MatchActionsCell
        onDeclineClick={(role) => onDeclineClick(record.key, role)}
        onRestoreClick={() => onRestoreClick(record.key)}
        onFinishMatch={() => onFinishMatch(record.key)}
        onAcceptClick={(role) => onAcceptClick(record.key, role)}
        onAcceptAssignClick={() => onAcceptAssignClick(record.key)}
        onInviteToInterviewClick={() => onInviteToInterviewClick(record.key)}
        status={record.status}
        isRestore={record.isRestore}
        isLoading={isLoading}
      />
    ),
  },
];
