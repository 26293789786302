import { UserRole } from '@raised/domain';
import { Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PROJECT_ROUTES } from 'components/project/project-routes';
import { Helmet } from 'react-helmet';
import { ORGANISATION_ROUTES } from 'components/organisation/organisation-routes';
import { useGetProfileQuery, useGetUserByIdQuery } from '../storage/userApi';
import UserNotFound from '../components/UserNotFound';
import UserProfileComponent from './UserProfileComponent';
import { USER_ROUTES } from '../user-routes';

export default function UserProfilePage() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data: personalProfile } = useGetProfileQuery();
  const {
    data: userProfile,
    isSuccess: isUserSuccess,
    isError: isUserError,
    isLoading: isUserLoading,
  } = useGetUserByIdQuery({ id: id as string }, { skip: !id });

  const isAdmin = personalProfile?.role === UserRole.ADMIN;
  const getProjectLink = (projectId: string) => PROJECT_ROUTES.PROJECT(projectId);
  const getOrganizationLink = (orgId: string) => ORGANISATION_ROUTES.ADMIN_ORGANIZATION(orgId);
  const onEditProfileClick = () => {
    if (!id) return;
    navigate(USER_ROUTES.EDIT_USER_PROFILE(id));
  };

  return (
    <>
      <Helmet>
        <title>Raised - Profile</title>
      </Helmet>
      {isUserLoading ? (
        <Spin />
      ) : null}
      {isUserError ? (
        <UserNotFound />
      ) : null}
      {isUserSuccess ? (
        <UserProfileComponent
          profileData={userProfile}
          isAdmin={isAdmin}
          getProjectLink={getProjectLink}
          getOrganizationLink={getOrganizationLink}
          onEditProfileClick={onEditProfileClick}
        />
      ) : null}
    </>
  );
}
