import { SuccessStory } from '@raised/domain';
import { LayoutModule, TypographyModule } from '@raised/ui-kit';
import styles from './SuccessStoryHeader.module.css';

const { Text, Paragraph, Title } = TypographyModule;
const { Divider } = LayoutModule;

interface SuccessStoryHeaderProps {
  successStory?: SuccessStory.Public;
}

export default function SuccessStoryHeader({
  successStory,
}: SuccessStoryHeaderProps) {
  return (
    <div className={styles.success_story_header}>
      <Text className={styles.caption}>Success Story</Text>
      <Title level={1} className={styles.title}>{successStory?.successStoryName}</Title>
      <Text className={styles.caption}>Client</Text>
      <Text strong>{successStory?.companyName}</Text>
      <Paragraph className={styles.description}>{successStory?.companyDescription}</Paragraph>
      <Divider className={styles.divider} />
    </div>
  );
}
