import AdminRouteLayout from 'components/layout/containers/admin-route/AdminRouteLayout';
import MainLayout from 'components/layout/containers/main-layout/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminUsecasesListPage from './pages/admin-usecases-list/AdminUsecasesListPage';
import CreateUsecasePage from './pages/create-usecase/CreateUsecasePage';
import EditUsecasePage from './pages/edit-usecase/EditUsecasePage';

export const USECASE_ROUTES = {
  CREATE: () => '/usecase/create',
  EDIT: (id: string) => `/usecase/edit/${id}`,
  USECASE: (id: string) => `/usecase/${id}`,
  ADMIN_USECASES_LIST: () => '/usecases/list',
};

const usecaseRoutes: Array<RouteObject> = [
  {
    element: <MainLayout />,
    children: [
      {
        element: <AdminRouteLayout />,
        children: [
          {
            element: <CreateUsecasePage />,
            path: USECASE_ROUTES.CREATE(),
          },
          {
            element: <EditUsecasePage />,
            path: USECASE_ROUTES.EDIT(':id'),
          },
          {
            element: <AdminUsecasesListPage />,
            path: USECASE_ROUTES.ADMIN_USECASES_LIST(),
          },
        ],
      },

    ],
  },
];

export default usecaseRoutes;
