import { Ref } from 'react';
import AttachFiles from 'components/files/attach-files/AttachFiles';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import useCreateUsecase from 'components/usecases/hooks/useCreateUsecase';
import { Template, Usecase } from '@raised/domain';
import UsecaseForm from 'components/usecases/forms/UsecaseForm';

interface CreateUsecasePageProps {
  isModal?: boolean;
  formRef?: Ref<HTMLFormElement>;
  onSuccess?: () => void;
  defaultValues?: Partial<Template.Public>;
}

export default function CreateUsecasePage({
  isModal,
  formRef,
  onSuccess,
  defaultValues,
}: CreateUsecasePageProps) {
  const navigate = useNavigate();
  const onBack = () => navigate(-1);

  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  const {
    isLoading,
    createUsecase,
    files,
    addFiles,
    removeFile,
    uploadedFiles,
  } = useCreateUsecase({
    onSuccess: handleSuccess,
  });

  const handleCreate = (values: Usecase.Create) => {
    const updatedValues = { ...values };

    if (defaultValues && defaultValues.id) {
      updatedValues.templateId = defaultValues.id;
    }

    createUsecase(updatedValues);
  };

  return (
    <>
      {!isModal && (
        <Helmet>
          <title>Raised - Create Usecase</title>
        </Helmet>
      )}
      <UsecaseForm
        formRef={formRef}
        title={isModal ? undefined : 'Create Usecase'}
        isLoading={isLoading}
        onSubmit={handleCreate}
        defaultValues={defaultValues}
        onBack={onBack}
        isControls={!isModal}
        filesInput={(
          <AttachFiles
            files={files}
            addFiles={addFiles}
            uploadedFiles={uploadedFiles}
            removeFile={removeFile}
            subLabel="Provide a description of the desired expert, including their skills, level of experience, and any other relevant qualifications"
            label="Additional documents (optional)"
          />
      )}
      />
    </>
  );
}
