import {
  LoginOutlined, ProjectOutlined, UserAddOutlined, FormOutlined, CreditCardOutlined, CommentOutlined,
} from '@ant-design/icons';
import { UserRole } from '@raised/domain';
import {
  IconBellRinging, IconBellRinging2, IconCash, IconFiles, IconFileStack, IconHome, IconSquareRoundedPlus, IconUsersGroup,
} from '@tabler/icons-react';
import { MenuProps } from 'antd';
import { AUTH_ROUTES } from 'components/auth/auth-routes';
import { LOG_MESSAGES_ROUTES } from 'components/log-messages/log-messages-routes';
import { MATCH_ROUTES } from 'components/match/match-routes';
import { MESSAGE_ROUTES } from 'components/messages/messages-routes';
import { ORGANISATION_ROUTES } from 'components/organisation/organisation-routes';
import { PAYMENTS_ROUTES } from 'components/payments/payments-routes';
import { PROJECT_ROUTES } from 'components/project/project-routes';
import { SUCCESS_STORY_ROUTES } from 'components/success-stories/success-story-routes';
import { TEMPLATES_ROUTES } from 'components/templates/templates-routes';
import { USECASE_ROUTES } from 'components/usecases/usecase-routes';
import { USER_ROUTES } from 'components/user/user-routes';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminSupportMenuLabel from './AdminSupportMenuLabel';
import MenuItem from './MenuItem';
import NotificationsMenuLabel from './NotificationsMenuLabel';

interface UseSideMenuProps {
  onNotificationsClick: () => void;
  isLogin: boolean;
  profileId?: string;
  userRole?: UserRole;
}

export default function useSideMenu({
  isLogin,
  userRole,
  profileId,
  onNotificationsClick,
}: UseSideMenuProps) {
  const navigate = useNavigate();

  const menuItems = useMemo((): MenuProps['items'] => {
    if (!isLogin) {
      return [
        {
          label: <MenuItem to={AUTH_ROUTES.LOGIN()}>Sign In</MenuItem>,
          icon: <LoginOutlined />,
          key: AUTH_ROUTES.LOGIN(),
          onClick: () => navigate(AUTH_ROUTES.LOGIN()),
          className: 'menu_signIn side_menu_item',
        },
        {
          label: <MenuItem to={USER_ROUTES.CREATE()}>Create account</MenuItem>,
          icon: <UserAddOutlined />,
          key: USER_ROUTES.CREATE(),
          onClick: () => navigate(USER_ROUTES.CREATE()),
          className: 'menu_createAccount side_menu_item',
        },
      ];
    }

    const commonMenuItems = [
      {
        label: <NotificationsMenuLabel profileId={profileId} />,
        icon: <IconBellRinging stroke={1.5} />,
        key: 'notifications',
        onClick: onNotificationsClick,
        className: 'menu_notifications side_menu_item',
      },
    ];

    switch (userRole) {
      case UserRole.ADMIN:
        return [
          {
            label: <MenuItem to={PROJECT_ROUTES.CREATE_PROJECT()}>Create project</MenuItem>,
            icon: <IconSquareRoundedPlus stroke={1.5} />,
            key: PROJECT_ROUTES.CREATE_PROJECT(),
            onClick: () => navigate(PROJECT_ROUTES.CREATE_PROJECT()),
            className: 'menu_createProject side_menu_item',
          },
          {
            label: <MenuItem to={PROJECT_ROUTES.ASSIGN_PROJECTS()}>Projects</MenuItem>,
            icon: <IconFiles stroke={1.5} />,
            key: PROJECT_ROUTES.ASSIGN_PROJECTS(),
            onClick: () => navigate(PROJECT_ROUTES.ASSIGN_PROJECTS()),
            className: 'menu_assignExpert side_menu_item',
          },
          {
            label: <MenuItem to={TEMPLATES_ROUTES.TEMPLATES()}>Project Templates</MenuItem>,
            icon: <IconFileStack stroke={1.5} />,
            key: TEMPLATES_ROUTES.TEMPLATES(),
            onClick: () => navigate(TEMPLATES_ROUTES.TEMPLATES()),
            className: 'side_menu_item',
          },
          {
            label: <MenuItem to={USER_ROUTES.ADMIN_EXPERTS_LIST()}>Experts</MenuItem>,
            icon: <IconUsersGroup stroke={1.5} />,
            key: USER_ROUTES.ADMIN_EXPERTS_LIST(),
            onClick: () => navigate(USER_ROUTES.ADMIN_EXPERTS_LIST()),
            className: 'menu_adminExpertsList side_menu_item',
          },
          {
            label: <MenuItem to={LOG_MESSAGES_ROUTES.LAST_ACTIONS()}>Last actions</MenuItem>,
            icon: <IconBellRinging2 stroke={1.5} />,
            key: LOG_MESSAGES_ROUTES.LAST_ACTIONS(),
            onClick: () => navigate(LOG_MESSAGES_ROUTES.LAST_ACTIONS()),
            className: 'menu_lastActions side_menu_item',
          },
          {
            label: <MenuItem to={MESSAGE_ROUTES.ADMIN_CHATS_LIST()}>Chats</MenuItem>,
            icon: <IconCash stroke={1.5} />,
            key: MESSAGE_ROUTES.ADMIN_CHATS_LIST(),
            onClick: () => navigate(MESSAGE_ROUTES.ADMIN_CHATS_LIST()),
            className: 'menu_chatList side_menu_item',
          },
          {
            label: <MenuItem to={MESSAGE_ROUTES.ADMIN_SUPPORT_CHATS_LIST()}><AdminSupportMenuLabel /></MenuItem>,
            icon: <CommentOutlined />,
            key: MESSAGE_ROUTES.ADMIN_SUPPORT_CHATS_LIST(),
            onClick: () => navigate(MESSAGE_ROUTES.ADMIN_SUPPORT_CHATS_LIST()),
            className: 'menu_support side_menu_item',
          },
          {
            label: <MenuItem to={PAYMENTS_ROUTES.ADMIN_CREATE_PAYMENTS()}>Create payment</MenuItem>,
            icon: <CreditCardOutlined />,
            key: PAYMENTS_ROUTES.ADMIN_CREATE_PAYMENTS(),
            onClick: () => navigate(PAYMENTS_ROUTES.ADMIN_CREATE_PAYMENTS()),
            className: 'menu_createPayment side_menu_item',
          },
          {
            label: <MenuItem to={USER_ROUTES.ADMIN_USER_LIST()}>Users</MenuItem>,
            icon: <IconUsersGroup stroke={1.5} />,
            key: USER_ROUTES.ADMIN_USER_LIST(),
            onClick: () => navigate(USER_ROUTES.ADMIN_USER_LIST()),
            className: 'menu_usersList side_menu_item',
          },
          {
            label: <MenuItem to={ORGANISATION_ROUTES.ADMIN_ORGANISATIONS_LIST()}>Organizations</MenuItem>,
            icon: <IconUsersGroup />,
            key: ORGANISATION_ROUTES.ADMIN_ORGANISATIONS_LIST(),
            onClick: () => navigate(ORGANISATION_ROUTES.ADMIN_ORGANISATIONS_LIST()),
            className: 'menu_organizationsList side_menu_item',
          },
          {
            label: <MenuItem to={PROJECT_ROUTES.DELETED_PROJECTS()}>List of deleted projects</MenuItem>,
            icon: <ProjectOutlined />,
            key: PROJECT_ROUTES.DELETED_PROJECTS(),
            onClick: () => navigate(PROJECT_ROUTES.DELETED_PROJECTS()),
            className: 'menu_deletedProjects side_menu_item',
          },
          {
            label: <MenuItem to={LOG_MESSAGES_ROUTES.LOG_MESSAGES_FEED()}>Log messages feed</MenuItem>,
            icon: <FormOutlined />,
            key: LOG_MESSAGES_ROUTES.LOG_MESSAGES_FEED(),
            onClick: () => navigate(LOG_MESSAGES_ROUTES.LOG_MESSAGES_FEED()),
            className: 'menu_logMessagesFeed side_menu_item',
          },
          {
            label: <MenuItem to={USER_ROUTES.CREATE_GHOST()}>Create ghost</MenuItem>,
            icon: <UserAddOutlined />,
            key: USER_ROUTES.CREATE_GHOST(),
            onClick: () => navigate(USER_ROUTES.CREATE_GHOST()),
            className: 'menu_createGhostClient side_menu_item',
          },
          {
            label: <MenuItem to={USECASE_ROUTES.ADMIN_USECASES_LIST()}>Usecases</MenuItem>,
            icon: <UserAddOutlined />,
            key: USECASE_ROUTES.ADMIN_USECASES_LIST(),
            onClick: () => navigate(USECASE_ROUTES.ADMIN_USECASES_LIST()),
            className: 'menu_usecasesList side_menu_item',
          },
          {
            label: <MenuItem to={SUCCESS_STORY_ROUTES.ADMIN_SUCCESS_STORY_LIST()}>Success Stories</MenuItem>,
            icon: <UserAddOutlined />,
            key: SUCCESS_STORY_ROUTES.ADMIN_SUCCESS_STORY_LIST(),
            onClick: () => navigate(SUCCESS_STORY_ROUTES.ADMIN_SUCCESS_STORY_LIST()),
            className: 'menu_successStoriesList side_menu_item',
          },
        ];
      case UserRole.CLIENT:
        return [
          ...commonMenuItems,
          {
            label: <MenuItem to={PROJECT_ROUTES.PROJECTS()}>Home</MenuItem>,
            icon: <IconHome stroke={1.5} />,
            key: PROJECT_ROUTES.PROJECTS(),
            onClick: () => navigate(PROJECT_ROUTES.PROJECTS()),
            className: 'menu_projects side_menu_item',
          },
          {
            label: <MenuItem to={TEMPLATES_ROUTES.TEMPLATES()}>Project Templates</MenuItem>,
            icon: <IconFileStack stroke={1.5} />,
            key: TEMPLATES_ROUTES.TEMPLATES(),
            onClick: () => navigate(TEMPLATES_ROUTES.TEMPLATES()),
            className: 'side_menu_item',
          },
          // {
          //   label: <RelevantProfessionalsMenuLabel to={USER_ROUTES.CLIENT_EXPERTS_LIST()} />,
          //   icon: <IconUsers />,
          //   key: USER_ROUTES.CLIENT_EXPERTS_LIST(),
          //   onClick: () => navigate(USER_ROUTES.CLIENT_EXPERTS_LIST()),
          //   className: 'menu_clietExpertsList side_menu_item',
          // },
          {
            label: <MenuItem to={ORGANISATION_ROUTES.ORGANISATION_DASHBOARD()}>Invite Teammates</MenuItem>,
            icon: <IconUsersGroup stroke={1.5} />,
            key: ORGANISATION_ROUTES.ORGANISATION_DASHBOARD(),
            onClick: () => navigate(ORGANISATION_ROUTES.ORGANISATION_DASHBOARD()),
            className: 'menu_organisationDashboard side_menu_item',
          },
        ];
      case UserRole.EXPERT:
        return [
          {
            label: <MenuItem to={MATCH_ROUTES.CONFIRM_MATCH()}>Home</MenuItem>,
            icon: <IconHome stroke={1.5} />,
            key: MATCH_ROUTES.CONFIRM_MATCH(),
            onClick: () => navigate(MATCH_ROUTES.CONFIRM_MATCH()),
            className: 'menu_confirmProject side_menu_item',
          },
          ...commonMenuItems,
        ];
      default:
        return [];
    }
  }, [isLogin, userRole]);

  return { menuItems };
}
