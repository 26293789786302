import { Template } from '@raised/domain';
import { FeedbackModule } from '@raised/ui-kit';
import { getErrorMessage } from 'api/helpers';
import useAttachedFiles from 'components/files/hooks/useAttachedFiles';
import useUploadFiles from 'components/files/hooks/useUploadFiles';
import { useEffect } from 'react';
import { useCreateTemplateMutation } from '../storage/templatesApi';

const { Message } = FeedbackModule;

interface UseCreateTemplateProps {
  onSuccess?: (data: Template.Public) => void;
  isNotify?: boolean;
}

export default function useCreateTemplate({
  onSuccess,
  isNotify = true,
}: UseCreateTemplateProps) {
  const [mutate, info] = useCreateTemplateMutation({
    fixedCacheKey: 'create-template',
  });
  const {
    isSuccess, isError, error, data,
  } = info;
  const { uploadFiles, isLoading: isFilesLoading } = useUploadFiles();
  const {
    files,
    uploadedFiles,
    addFiles,
    removeFile,
  } = useAttachedFiles({});

  const createTemplate = async (formData: Template.Create) => {
    const attachedFiles = [...uploadedFiles];

    if (files.length) {
      const res = await uploadFiles(files);

      if (res) {
        attachedFiles.push(...res.map((file) => file.url));
      }
    }

    mutate({
      ...formData,
      attachedFile: attachedFiles,
    });
  };

  useEffect(() => {
    if (!isSuccess) return;

    if (onSuccess) {
      onSuccess(data);
    }

    if (isNotify) {
      Message.success('Template successfully created');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isError || !isNotify) return;

    Message.error(getErrorMessage(error));
  }, [isError]);

  return {
    createTemplate,
    files,
    uploadedFiles,
    addFiles,
    removeFile,
    ...info,
    isLoading: info.isLoading || isFilesLoading,
  };
}
