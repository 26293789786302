import { SuccessStory } from '@raised/domain';
import { TypographyModule } from '@raised/ui-kit';
import styles from './ClientQuote.module.css';

const { Text, Paragraph, Title } = TypographyModule;

interface ClientQuoteProps {
  successStory?: SuccessStory.Public;
}

export default function ClientQuote({
  successStory,
}: ClientQuoteProps) {
  return (
    <div className={styles.client_quote}>
      <Text className={styles.caption}>Client’s quote</Text>
      <Paragraph className={styles.paragraph}>{`"${successStory?.clientReview}"`}</Paragraph>
      <Title level={2} className={styles.name}>{successStory?.clientName}</Title>
      <Text className={styles.position}>{successStory?.clientPosition}</Text>
    </div>
  );
}
