import { useViewNotificationsMutation } from 'components/notifications/storage/notificationsApi';
import { useCallback, useEffect, useRef } from 'react';

export default function useViewNotifications() {
  const [view] = useViewNotificationsMutation();
  const observerRef = useRef<IntersectionObserver>();

  const intersectionCallback: IntersectionObserverCallback = (entries) => {
    const notificationIds: string[] = [];

    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;
      const notificationId = (entry.target as HTMLDivElement).dataset.notificationid as string;

      if (typeof notificationId === 'string') {
        notificationIds.push(notificationId);
        observerRef.current?.unobserve(entry.target);
      }
    });
    view({ notificationIds });
  };

  useEffect(() => {
    if (!observerRef.current) {
      observerRef.current = new IntersectionObserver(intersectionCallback, {
        root: null,
        threshold: 1,
      });
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, []);

  const setObserverForNotification = useCallback((element: HTMLDivElement) => {
    if (!observerRef.current || !element) return;

    observerRef.current.observe(element);
  }, [observerRef]);

  return {
    setObserverForNotification,
  };
}
