/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ProjectTypes } from '@raised/domain';
import { FormsModule, LayoutModule, TypographyModule } from '@raised/ui-kit';
import {
  Control, useController, useWatch,
} from 'react-hook-form';
import styles from './ProjectBudgetInput.module.css';

const { InputNumber, Checkbox } = FormsModule;
const { Space } = LayoutModule;
const { Text } = TypographyModule;

interface ProjectBudgetInputProps {
  control: Control;
}

export default function ProjectBudgetInput({
  control,
}: ProjectBudgetInputProps) {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name: 'budget' });
  const selectedProjectType = useWatch({
    control,
    name: 'type',
  });

  const isMonthly = selectedProjectType !== ProjectTypes.FIXED_SCOPE;

  const onLaterClick = () => {
    if (field.value === null) {
      field.onChange({ min: null, max: null });
    } else {
      field.onChange(null);
    }
  };
  const onChangeMinValue = (value: string | number | null) => field.onChange({ min: value, max: field.value?.max || 0 });
  const onChangeMaxValue = (value: string | number | null) => field.onChange({ min: field.value?.min, max: value });

  return (
    <Space direction="vertical" size={0}>
      <Text>Budget</Text>
      <Text type="secondary" style={{ marginBottom: 8, display: 'block' }}>This will help us match you with experts but it isn`t binding. You will determine the final project budget with the expert you engage.</Text>
      <div className={styles.options}>
        <Space size={4} direction="vertical">
          <Text>
            {isMonthly ? 'Monthly expense' : 'Project amount'}
          </Text>
          <Space>
            <InputNumber className={styles.budget_input} disabled={field.value === null} name="budgetMinInput" min="0" value={field.value?.min ? field.value.min.toString() : undefined} onChange={onChangeMinValue} placeholder="Min" addonAfter="$" />
            <InputNumber disabled={field.value === null} name="budgetMaxInput" min="0" value={field.value?.max ? field.value.max.toString() : undefined} onChange={onChangeMaxValue} placeholder="Max" addonAfter="$" />
          </Space>
        </Space>
        <Checkbox onClick={onLaterClick} checked={field.value === null}>Decide later</Checkbox>
      </div>
      {error && (
        <Text type="danger">{error.message}</Text>
      )}
    </Space>
  );
}
