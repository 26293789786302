import { LeftOutlined } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { Proposal, ProposalSchemas } from '@raised/domain';
import { Button, Space, Typography } from 'antd';
import useUploadFiles from 'components/files/hooks/useUploadFiles';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { excludeNullFields } from 'utils/forms';
import CreateProposalForm from '../create-proposal/create-proposal-form/CreateProposalForm';

interface EditProposalFormProps {
  initialValues: Proposal.Public;
  onSubmit: (values: Proposal.Edit) => void;
  isLoading: boolean;
  onBack: () => void;
  isAdmin: boolean;
}

export default function EditProposalForm({
  initialValues,
  isLoading,
  onSubmit,
  onBack,
  isAdmin,
}: EditProposalFormProps) {
  const {
    handleSubmit, control, setValue, reset,
  } = useForm<Proposal.Create>({
    defaultValues: initialValues,
    resolver: zodResolver(ProposalSchemas.createProposalSchema),
  });

  const { uploadFiles, uploadedFiles, isLoading: isFilesLoading } = useUploadFiles();

  useEffect(() => {
    if (uploadedFiles.length) {
      setValue('documentUrls', uploadedFiles.map((file) => file.url));
    }
  }, [uploadedFiles]);

  const onRestoreClick = () => {
    if (!initialValues.original) return;

    reset({
      ...excludeNullFields(initialValues.original),
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', maxWidth: '500px' }}>
      <Space direction="vertical">
        <Button onClick={onBack} style={{ marginBottom: 16 }}>
          <LeftOutlined />
          {' '}
          Back to project
        </Button>
        <Space align="center" size={16} style={{ justifyContent: 'space-between' }}>
          <Typography.Title level={3} style={{ marginBottom: 0 }}>Edit a proposal</Typography.Title>
          {isAdmin && initialValues.original ? (
            <Button onClick={onRestoreClick}>Restore to original</Button>
          ) : null}
        </Space>
        <CreateProposalForm
          control={control}
          uploadFiles={uploadFiles}
          uploadedFiles={uploadedFiles}
          isFilesLoading={isFilesLoading}
        />
        <Button
          loading={isLoading || isFilesLoading}
          disabled={isLoading || isFilesLoading}
          style={{ marginTop: 8, width: 120 }}
          type="primary"
          htmlType="submit"
        >
          Update
        </Button>
      </Space>
    </form>
  );
}
