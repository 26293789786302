import { Drawer } from 'antd';
import { ReactNode } from 'react';
import styles from './NotificationDrawer.module.css';

interface NotificationDrawerProps {
  children: ReactNode;
  isVisible: boolean;
  onClose: () => void;
}

export default function NotificationDrawer({
  children,
  isVisible,
  onClose,
}: NotificationDrawerProps) {
  const drawerWidth = window.innerWidth < 500 ? window.innerWidth : 700;

  return (
    <Drawer
      open={isVisible}
      onClose={onClose}
      title={null}
      placement="left"
      bodyStyle={{ padding: 0 }}
      width={drawerWidth}
      closable={false}
      rootClassName={styles.drawer}
      zIndex={9}
    >
      {children}
    </Drawer>
  );
}
