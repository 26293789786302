import { MatchStatuses, ProjectStatuses } from '@raised/domain';
import { message, Spin } from 'antd';
import { getErrorMessage } from 'api/helpers';
import useUpdateMatch from 'components/match/hooks/useUpdateMatch';
import { ClientMatchOverviewTabs } from 'components/match/types/client-match-overview-tabs';
import { MATCH_ROUTES } from 'components/match/match-routes';
import useCreateMessage from 'components/messages/hooks/useCreateMessage';
import { PROPOSAL_ROUTES } from 'components/proposal/proposal-routes';
import { useGetProfileQuery, useGetUserByIdQuery } from 'components/user/storage/userApi';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { USER_ROUTES } from 'components/user/user-routes';
import { Helmet } from 'react-helmet';
import { useGetProjectByIdQuery } from '../storage/projectApi';
import ProjectComponent from './ProjectComponent';
import { PROJECT_ROUTES } from '../project-routes';
import useUpdateProjectStatus from '../hooks/useUpdateProjectStatus';
import useAcceptProject from '../hooks/useAcceptProject';

export default function ProjectPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: userData } = useGetProfileQuery();
  const {
    data, isLoading, isError, error, isSuccess,
  } = useGetProjectByIdQuery({ id: id as string }, { skip: !id });

  const isPersonalProject = data?.organisationId === userData?.organisationId;
  const { data: projectUser } = useGetUserByIdQuery({ id: data?.userId as string }, { skip: isPersonalProject || !data });

  const {
    acceptProject,
    isLoading: isAcceptProjectLoading,
  } = useAcceptProject();
  const {
    update: updateMatch, isSuccess: isUpdateMatchSuccess, isLoading: isUpdateMatchLoading, originalArgs,
  } = useUpdateMatch();
  const { updateStatus, isLoading: isUpdateStatusLoading } = useUpdateProjectStatus();
  const { createMessage } = useCreateMessage();
  const onSubmitMatch = (status: MatchStatuses, text: string, matchId: string, chatId?: string) => {
    if (!userData) return;

    updateMatch({
      id: matchId,
      data: {
        message: text || undefined,
        status,
      },
    });

    if (text && chatId) {
      createMessage({
        text,
        chatId,
      });
    }
  };
  const onEditProjectClick = () => {
    if (!id) return;

    navigate(PROJECT_ROUTES.EDIT_PROJECT(id));
  };
  const onUserClick = (userId: string) => {
    if (userId === userData?.id) {
      navigate(USER_ROUTES.PERSONAL_PROFILE());
    } else {
      navigate(USER_ROUTES.USER_PROFILE(userId));
    }
  };
  const onCreateProposalClick = (matchId: string) => navigate(PROPOSAL_ROUTES.CREATE(matchId));
  const onClientMatchOverviewClick = (matchId: string, tab?: ClientMatchOverviewTabs) => {
    navigate({
      pathname: MATCH_ROUTES.CLIENT_MATCH_OVERVIEW(matchId),
    }, {
      state: {
        selectedTab: tab,
      },
    });
  };
  const getClientMatchOverviewLink = (matchId: string) => MATCH_ROUTES.CLIENT_MATCH_OVERVIEW(matchId);
  const onUpdateProjectStatus = (status: ProjectStatuses, updateMesage?: string) => {
    if (!id) return;

    updateStatus({ status, message: updateMesage, id });
  };
  const onBack = () => navigate(-1);
  const onFindProfessionalsClick = () => {
    if (!id) return;

    acceptProject(id);
  };

  useEffect(() => {
    if (isError) {
      message.error(getErrorMessage(error));
    }
  }, [isError]);

  useEffect(() => {
    if (!isUpdateMatchSuccess || originalArgs?.data.status !== MatchStatuses.INVITED_TO_INTERVIEW) return;

    navigate(MATCH_ROUTES.CLIENT_MATCH_OVERVIEW(originalArgs.id));
  }, [isUpdateMatchSuccess]);

  return (
    <>
      <Helmet>
        <title>Raised - Project</title>
      </Helmet>
      {isLoading && <Spin />}
      {isSuccess && (
        <ProjectComponent
          project={data}
          isPersonal={isPersonalProject}
          userData={userData}
          onSubmitMatch={onSubmitMatch}
          author={projectUser}
          onEditProjectClick={onEditProjectClick}
          onCreateProposalClick={onCreateProposalClick}
          onClientMatchOverviewClick={onClientMatchOverviewClick}
          getClientMatchOverviewLink={getClientMatchOverviewLink}
          onUserClick={onUserClick}
          isUpdateMatchLoading={isUpdateMatchLoading}
          isUpdateStatusLoading={isUpdateStatusLoading}
          onUpdateProjectStatus={onUpdateProjectStatus}
          onBack={onBack}
          onFindProfessionalsClick={onFindProfessionalsClick}
          isFindProfessionalsLoading={isAcceptProjectLoading}
        />
      )}
    </>
  );
}
