import { User, UserRole } from '@raised/domain';
import { FormsModule } from '@raised/ui-kit';
import { PUBLIC_USER_ROLES } from 'components/user/constants/public-user-roles';
import { getRoleLabel } from 'components/user/utils';
import { Control } from 'react-hook-form';
import { FormConfig } from 'utils/forms';

const rolesOptions = Object.keys(PUBLIC_USER_ROLES).map((role) => ({
  value: role,
  label: getRoleLabel(role),
}));

const baseMainInfoFormConfig = (control: Control<User.Create>): FormConfig<User.Create> => [
  {
    controller: {
      name: 'role',
      control,
      defaultValue: UserRole.CLIENT,
    },
    component: FormsModule.Select,
    label: 'Select role',
    componentProps: {
      placeholded: 'Select role',
      options: rolesOptions,
      'data-testid': 'form_createUser__input_role',
    },
  },
  {
    controller: {
      name: 'firstName',
      control,
      defaultValue: '',
    },
    component: FormsModule.Input,
    label: 'Name',
    componentProps: {
      placeholder: 'First name',
      'data-testid': 'form_createUser__first_name',
    },
  },
  {
    controller: {
      name: 'lastName',
      control,
      defaultValue: '',
    },
    component: FormsModule.Input,
    label: 'Surname',
    componentProps: {
      placeholder: 'Last name',
      'data-testid': 'form_createUser__last_name',
    },
  },
];

export const getGhostMainInfoFormConfig = (control: Control<User.Create>): FormConfig<User.Create> => [
  {
    controller: {
      name: 'email',
      control,
      defaultValue: '',
    },
    component: FormsModule.Input,
    label: 'Email',
    componentProps: {
      placeholder: 'Email',
      'data-testid': 'form_createUser__input_email',
    },
  },
  ...baseMainInfoFormConfig(control),
];

export const getMainInfoFormConfig = (control: Control<User.Create>): FormConfig<User.Create> => [
  {
    controller: {
      name: 'email',
      control,
      defaultValue: '',
    },
    component: FormsModule.Input,
    label: 'Email',
    componentProps: {
      placeholder: 'Email',
      'data-testid': 'form_createUser__input_email',
    },
  },
  {
    controller: {
      name: 'password',
      control,
      defaultValue: '',
    },
    component: FormsModule.Password,
    label: 'Password',
    componentProps: {
      placeholder: 'Password',
      'data-testid': 'form_createUser__input_password',
    },
  },
  ...baseMainInfoFormConfig(control),
];

export const getCompanyDetailsFormConfig = (control: Control<User.Create>): FormConfig<User.Create> => [
  {
    controller: {
      name: 'companyName',
      control,
    },
    component: FormsModule.Input,
    label: 'Company name',
    componentProps: {
      placeholder: 'Company name',
      'data-testid': 'form_createUser__company_name',
    },
  },
  {
    controller: {
      name: 'aboutCompany',
      control,
    },
    component: FormsModule.TextArea,
    label: 'About company',
    componentProps: {
      placeholder: 'About company',
      'data-testid': 'form_createUser__about_company',
      rows: 4,
    },
  },
  {
    controller: {
      name: 'companyWebsite',
      control,
    },
    component: FormsModule.Input,
    label: 'Company website',
    componentProps: {
      placeholder: 'Company website',
      'data-testid': 'form_createUser__company_website',
    },
  },
];

export const getExpertAdditionalFieldsFormConfig = (control: Control<User.Create>): FormConfig<User.Create> => [
  {
    controller: {
      name: 'headline',
      control,
    },
    component: FormsModule.TextArea,
    label: 'Headline',
    subLabel: 'Choose a catchy headline for your profile (E.g. First growth manager at Miro)',
    componentProps: {
      rows: 3,
      showCount: true,
      maxLength: 150,
      placeholder: 'Headline',
    },
  },
  {
    controller: {
      name: 'biography',
      control,
    },
    component: FormsModule.TextArea,
    label: 'About You',
    subLabel: 'An impactful ‘About you’ will clearly define the relevant experience, client wins, specialized knowledge, or proven outcomes that make you a true expert',
    componentProps: {
      placeholder: 'Tell about you',
      rows: 5,
      'data-testid': 'form_createUser__biography',
      showCount: true,
      maxLength: 500,
    },
  },
  {
    controller: {
      name: 'externalLink',
      control,
    },
    component: FormsModule.Input,
    label: 'LinkedIn link',
    componentProps: {
      placeholder: 'Paste link here',
      'data-testid': 'form_createUser__external_link',
    },
  },
  {
    controller: {
      name: 'calendlyLink',
      control,
    },
    component: FormsModule.Input,
    label: 'Calendly (or alternative) link (not public)',
    subLabel: 'The client will only see the link after you have approved the project. It will help the client schedule the introductory call faster.',
    componentProps: {
      placeholder: 'Paste link here',
      'data-testid': 'form_createUser__calendly_link',
    },
  },
];
