import { LayoutModule } from '@raised/ui-kit';
import { ReactNode } from 'react';
import { NotificationTabs as NotificationTabsEnum } from '../../types';
import styles from './NotificationTabs.module.css';

const { Tabs } = LayoutModule;

interface NotificationTabsProps {
  projectActionsContent: ReactNode;
  messagesContent: ReactNode;
  allContent: ReactNode;
  onTabChange: (tab: NotificationTabsEnum) => void;
}

export default function NotificationTabs({
  projectActionsContent,
  messagesContent,
  allContent,
  onTabChange,
}: NotificationTabsProps) {
  return (
    <Tabs
      className={styles.desktop_tabs}
      onChange={(tab) => onTabChange(tab as NotificationTabsEnum)}
      items={[
        {
          label: 'All notifications',
          key: NotificationTabsEnum.ALL,
          children: allContent,
        },
        {
          label: 'Messages',
          key: NotificationTabsEnum.MESSAGES,
          children: messagesContent,
        },
        {
          label: 'Project actions',
          key: NotificationTabsEnum.PROJECT,
          children: projectActionsContent,
        },
      ]}
    />
  );
}
