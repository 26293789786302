import { FeedbackModule } from '@raised/ui-kit';
import { ReactNode } from 'react';
import styles from './FormModal.module.css';

const { Modal } = FeedbackModule;

interface FormModalProps {
  children: ReactNode;
  isVisible: boolean;
  onClose: () => void;
  footer: ReactNode;
  title: string;
}

export default function FormModal({
  children,
  isVisible,
  onClose,
  footer,
  title,
}: FormModalProps) {
  return (
    <Modal
      centered={window.innerWidth > 500}
      open={isVisible}
      onCancel={onClose}
      width={550}
      closeIcon={null}
      footer={footer}
      title={title}
      className={styles.form_modal}
    >
      {children}
    </Modal>
  );
}
