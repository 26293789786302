import { Typography } from 'antd';
import { ReactNode } from 'react';

interface DeletedProjectsComponentProps {
  children: ReactNode;
}

export default function DeletedProjectsComponent({
  children,
}: DeletedProjectsComponentProps) {
  return (
    <div>
      <Typography.Title level={2}>List of deleted projects</Typography.Title>
      {children}
    </div>
  );
}
