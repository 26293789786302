import { useState } from 'react';

export default function useInvitedExperts() {
  const [invitedExperts, setInvitedExperts] = useState<string[]>([]);

  const onInviteExpert = (expertId: string) => {
    if (!invitedExperts.includes(expertId)) {
      setInvitedExperts((experts) => [...experts, expertId]);
    }
  };

  return {
    invitedExperts,
    onInviteExpert,
  };
}
