import { Project } from '@raised/domain';
import { ColorsModule, FeedbackModule, TypographyModule } from '@raised/ui-kit';
import { IconClockHour3 } from '@tabler/icons-react';
import NotificationDot from 'components/layout/components/notification-dot/NotificationDot';
import { useGetNewNotificationsForProjectQuery } from 'components/notifications/storage/notificationsApi';
import UserAvatar from 'components/user/components/UserAvatar';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/formats';
import { ReactNode } from 'react';
import styles from './ProjectCard.module.css';

const { Text, Title } = TypographyModule;
const { Spin } = FeedbackModule;
const { Colors } = ColorsModule;

interface ProjectCardProps {
  project: Project.Public;
  linkToProject: string;
  statusContent: ReactNode;
  actionsContent?: ReactNode;
  author: string;
}

export default function ProjectCard({
  project,
  linkToProject,
  statusContent,
  actionsContent,
  author,
}: ProjectCardProps) {
  const { data, isLoading, isFetching } = useGetNewNotificationsForProjectQuery({ projectId: project.id }, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.user}>
          <div className={styles.user_info}>
            <UserAvatar role={project.user?.role} size="s" filePath={project.user?.avatar} />
            <Text>{author}</Text>
          </div>
          {actionsContent}
        </div>
        <div className={styles.title_block}>
          <Link to={linkToProject}><Title className={styles.title} level={4}>{project.title}</Title></Link>
          {isLoading || isFetching ? (
            <Spin size="small" />
          ) : (
            <>
              {data?.length ? (
                <NotificationDot />
              ) : null}
            </>
          )}
        </div>
        <div className={styles.date_block}>
          <IconClockHour3 size={14} color={Colors.gray} />
          <Text type="secondary" className={styles.date}>{formatDate(new Date(project.createdAt))}</Text>
        </div>
      </div>
      {statusContent}
    </div>
  );
}
