import {
  Chat,
  Match, Project, Proposal, User, UserRole,
} from '@raised/domain';
import { Tabs } from 'antd';
import ChatsComponent from './ChatsComponent';
import ProposalsComponent from './ProposalsComponent';
import AssignTab from './tabs/AssignTab';
import CreateProposalsTab from './tabs/create-proposal-tab/CreateProposalsTab';
import LogTab from './tabs/LogTab';
import MatchActionsTab from './tabs/match-actions-tab/MatchActionsTab';
import RecommendTab from './tabs/RecommendTab';

enum OverviewTabs {
  LOG = 'LOG',
  RECOMMEND_EXPERT = 'RECOMMED_EXPERT',
  ASSIGN_EXPERT = 'ASSIGN_EXPERT',
  CREATE_PROPOSALS = 'CREATE_PROPOSALS',
  MATCH_ACTIONS = 'MATCH_ACTIONS',
}

interface AdminProjectMatchInfoComponentProps {
  projectId: string;
  getChatLink: (matchId: string) => string;
  isCreateLoading: boolean;
  isDeleteLoading: boolean;
  onSubmitRecommend: (userId: string) => void;
  onSubmitUnrecommend: (userId: string) => void;
  onSubmitAssign: (userId: string) => void;
  project: Project.Public;
  experts: User.Public[];
  chats?: Chat.Public[];
  proposals?: Proposal.Public[];
  onCreateProposalClick: (matchId: string) => void;
  matchesWithNoProposals: Match.Public[];
  getUserLink: (userId: string) => string;
  isUpdateProposalLoading: boolean;
  onAcceptProposal: (matchId: string, proposalId: string) => void;
  onDeclineProposal: (matchId: string, proposalId: string) => void;
  onEditProposal: (proposalId: string) => void;
  matchesByProject: Match.Public[];
  onDeclineMatch: (matchId: string, role: UserRole) => void;
  onRestoreMatch: (matchId: string) => void;
  onFinishMatch: (matchId: string) => void;
  onAcceptClick: (matchId: string, role: UserRole) => void;
  onAcceptAssignClick: (matchId: string) => void;
  onInviteToInterviewClick: (matchId: string) => void;
  isMatchActionsLoading: boolean;
}

export default function AdminProjectMatchInfoComponent({
  projectId,
  getChatLink,
  isCreateLoading,
  isDeleteLoading,
  onSubmitAssign,
  onSubmitRecommend,
  onSubmitUnrecommend,
  project,
  experts,
  chats,
  proposals,
  onCreateProposalClick,
  matchesWithNoProposals,
  getUserLink,
  isUpdateProposalLoading,
  onAcceptProposal,
  onDeclineProposal,
  onEditProposal,
  matchesByProject,
  onRestoreMatch,
  onDeclineMatch,
  isMatchActionsLoading,
  onFinishMatch,
  onAcceptClick,
  onAcceptAssignClick,
  onInviteToInterviewClick,
}: AdminProjectMatchInfoComponentProps) {
  return (
    <div>
      {chats && (
        <ChatsComponent
          chats={chats}
          getChatLink={getChatLink}
        />
      )}
      {proposals && (
        <ProposalsComponent
          proposals={proposals}
          isLoading={isUpdateProposalLoading}
          onAccept={onAcceptProposal}
          onDecline={onDeclineProposal}
          onEdit={onEditProposal}
        />
      )}
      <Tabs
        defaultActiveKey={OverviewTabs.LOG}
        destroyInactiveTabPane
        items={[
          {
            label: 'Log',
            key: OverviewTabs.LOG,
            children: <LogTab projectId={projectId} />,
          },
          {
            label: 'Invite expert',
            key: OverviewTabs.RECOMMEND_EXPERT,
            children: <RecommendTab
              project={project}
              onSubmitRecommend={onSubmitRecommend}
              onSubmitUnrecommend={onSubmitUnrecommend}
              experts={experts}
              isLoading={isDeleteLoading || isCreateLoading}
            />,
          },
          {
            label: 'Assign expert',
            key: OverviewTabs.ASSIGN_EXPERT,
            children: <AssignTab
              project={project}
              experts={experts}
              onSubmitAssign={onSubmitAssign}
              onSubmitUnassign={onSubmitUnrecommend}
              isLoading={isDeleteLoading || isCreateLoading}
            />,
          },
          {
            label: 'Create proposals',
            key: OverviewTabs.CREATE_PROPOSALS,
            children: <CreateProposalsTab
              onCreateProposalClick={onCreateProposalClick}
              matches={matchesWithNoProposals}
              getUserLink={getUserLink}
            />,
          },
          {
            label: 'Match actions',
            key: OverviewTabs.MATCH_ACTIONS,
            children: <MatchActionsTab
              matches={matchesByProject}
              onDeclineClick={onDeclineMatch}
              onRestoreClick={onRestoreMatch}
              onAcceptClick={onAcceptClick}
              onAcceptAssignClick={onAcceptAssignClick}
              onInviteToInterviewClick={onInviteToInterviewClick}
              getUserLink={getUserLink}
              onFinishMatch={onFinishMatch}
              isLoading={isMatchActionsLoading}
            />,
          },
        ]}
      />
    </div>
  );
}
