import { TypographyModule } from '@raised/ui-kit';
import { IconFileX } from '@tabler/icons-react';
import styles from './ProposalDeclined.module.css';

const { Title } = TypographyModule;

export default function ProposalDeclined() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <IconFileX size={32} stroke={1.5} />
        <Title level={5}>You declined a proposal</Title>
      </div>
    </div>
  );
}
