import { Layout } from 'antd';
import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './NotificationsLayout.module.css';

const { Content, Sider } = Layout;

interface NotificationsLayoutProps {
  navigation: ReactNode;
  children: ReactNode;
}

export default function NotificationsLayout({
  navigation,
  children,
}: NotificationsLayoutProps) {
  return (
    <Layout className={cn(styles.layout, styles.layout_main)}>
      <Sider className={styles.sider} width={250}>{navigation}</Sider>
      <Layout className={styles.layout}>
        <Content className={styles.content}>{children}</Content>
      </Layout>
    </Layout>
  );
}
