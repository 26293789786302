import { ColorsModule } from '@raised/ui-kit';
import { ThemeConfig } from 'antd/es/config-provider/context';
import {
  createContext, PropsWithChildren, useCallback, useState, useMemo, useLayoutEffect,
} from 'react';
import { getLocalTheme, setLocalTheme } from './helpers';
import themes, { ThemeEnum } from './themeConfig';

const { Colors } = ColorsModule;

interface IThemeContext {
  theme: ThemeConfig;
  handleThemeChange: (theme: ThemeEnum) => void;
  themeName: ThemeEnum;
}

export const ThemeContext = createContext<IThemeContext>({
  themeName: ThemeEnum.DARK,
  theme: themes[ThemeEnum.DARK],
  handleThemeChange: () => {},
});

const setThemeColorVariables = () => {
  const root = document.documentElement;

  Object.entries(Colors).forEach(([name, value]) => {
    root?.style.setProperty(`--color-${name}`, value);
  });
};

export function ThemeContextProvider({ children }: PropsWithChildren<unknown>) {
  const [selectedThemeName, setSelectedThemeName] = useState<ThemeEnum>(ThemeEnum.LIGHT);

  useLayoutEffect(() => {
    const localTheme = getLocalTheme();
    setThemeColorVariables();

    if (localTheme) {
      setSelectedThemeName(localTheme as ThemeEnum);
    }
  }, []);

  const handleThemeChange = useCallback((newTheme: ThemeEnum) => {
    setLocalTheme(newTheme);
    setSelectedThemeName(newTheme);
  }, []);

  const contextValues = useMemo(() => ({
    theme: themes[selectedThemeName],
    themeName: selectedThemeName,
    handleThemeChange,
  }), [selectedThemeName]);

  return (
    <ThemeContext.Provider value={contextValues}>
      {children}
    </ThemeContext.Provider>
  );
}
