import { FormsModule } from '@raised/ui-kit';
import { USER_ACTIVITY_TYPE_LABELS } from 'components/user/constants/user-activity-types';
import { Control, Controller } from 'react-hook-form';

const { Select } = FormsModule;

interface ExpertActivityTypeProps {
  control: Control;
}

const activityTypeOptions = Object.entries(USER_ACTIVITY_TYPE_LABELS).map(([value, label]) => ({ value, label }));

export default function ExpertActivityType({
  control,
}: ExpertActivityTypeProps) {
  return (
    <Controller
      control={control}
      name="activityType"
      render={({ field, fieldState }) => (
        <Select
          label="Activity Type"
          style={{ width: '100%' }}
          placeholder="Select Activity Type"
          {...field}
          status={fieldState.error ? 'error' : undefined}
          options={activityTypeOptions}
        />
      )}
    />
  );
}
