import { User } from '@raised/domain';
import { ButtonsModule, FeedbackModule, TypographyModule } from '@raised/ui-kit';
import CreateSuccessStoryModal from 'components/success-stories/components/modals/create-success-story/CreateSuccessStoryModal';
import useCreateSuccessStoryModal from 'components/success-stories/components/modals/create-success-story/useCreateSuccessStoryModal';
import useCreateSuccessStory from 'components/success-stories/hooks/useCreateSuccessStory';
import { useGetUserSuccessStoriesQuery } from 'components/success-stories/storage/successStoriesApi';
import { SUCCESS_STORY_ROUTES } from 'components/success-stories/success-story-routes';
import CreateUsecaseModal from 'components/usecases/components/create-usecase-modal/CreateUsecaseModal';
import useCreateUsecaseModal from 'components/usecases/components/create-usecase-modal/useCreateUsecaseModal';
import { useGetUserUsecasesQuery } from 'components/usecases/storage/usecasesApi';
import { useRef } from 'react';
import NoUsecasesMessage from './no-usecases-message/NoUsecasesMessage';
import SelectUsecaseModal from './select-usecase-modal/SelectUsecaseModal';
import useSelectUsecaseModal from './select-usecase-modal/useSelectUsecaseModal';
import SuccessStoryItem from './success-story-item/SuccessStoryItem';
import styles from './SuccessStoriesStep.module.css';

const { Spin } = FeedbackModule;
const { Title, Text } = TypographyModule;
const { Button } = ButtonsModule;

interface SuccessStoriesStepProps {
  user: User.Public;
}

export default function SuccessStoriesStep({
  user,
}: SuccessStoriesStepProps) {
  const createSuccessStoryFormRef = useRef<HTMLFormElement>(null);

  const {
    isVisible: isUsecaseModalVisible,
    closeModal: closeCreaseUsecaseModal,
    openModal: openCreateUsecaseModal,
  } = useCreateUsecaseModal();
  const {
    isVisible: isSelectUsecaseModalVisible,
    closeModal: closeSelectUsecaseModal,
    openModal: openSelectUsecaseModal,
  } = useSelectUsecaseModal();
  const {
    isVisible: isCreateSuccessStoryModalVisible,
    selectedUsecaseId,
    closeModal: closeCreateSuccessStoryModal,
    openModal: openCreateSuccessStoryModal,
  } = useCreateSuccessStoryModal();

  const {
    data: successStories,
    isLoading: isSuccessStoriesLoading,
  } = useGetUserSuccessStoriesQuery({ id: user.id });
  const {
    data: usecases,
    isLoading: isUseCasesLoading,
  } = useGetUserUsecasesQuery({ id: user.id });
  const {
    isLoading: isCreateSuccessStoryLoading,
    createSuccessStory,
  } = useCreateSuccessStory({
    onSuccess: () => {
      closeCreateSuccessStoryModal();
      createSuccessStoryFormRef.current?.dispatchEvent(new Event('reset', { cancelable: true, bubbles: true }));
    },
  });

  const onSelectUsecase = (usecaseId: string) => {
    closeSelectUsecaseModal();
    openCreateSuccessStoryModal(usecaseId);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title_block}>
          <div className={styles.title}>
            <Title level={1}>Success stories & Testimonials</Title>
            {usecases?.length ? (
              <Button type="primary" onClick={openSelectUsecaseModal}>Create</Button>
            ) : null}
          </div>
          <Text className={styles.info_text} type="secondary">Add success stories & testimonials to your use case</Text>
        </div>
        {isUseCasesLoading || isSuccessStoriesLoading ? (
          <Spin className={styles.spin} />
        ) : (
          <>
            {usecases?.length
              ? (
                <div className={styles.success_stories}>
                  {successStories?.map((story) => (
                    <SuccessStoryItem link={SUCCESS_STORY_ROUTES.EDIT(story.id)} successStory={story} key={story.id} />
                  ))}
                </div>
              )
              : (
                <NoUsecasesMessage
                  onCreateCase={openCreateUsecaseModal}
                />
              )}
          </>
        )}
      </div>
      <CreateUsecaseModal defaultValues={{ userId: user.id }} onClose={closeCreaseUsecaseModal} isVisible={isUsecaseModalVisible} />
      <SelectUsecaseModal
        isVisible={isSelectUsecaseModalVisible}
        onClose={closeSelectUsecaseModal}
        usecases={usecases || []}
        onSelectUsecase={onSelectUsecase}
      />
      <CreateSuccessStoryModal
        isVisible={isCreateSuccessStoryModalVisible}
        onClose={closeCreateSuccessStoryModal}
        usecaseId={selectedUsecaseId}
        defaultValues={{ usecaseId: selectedUsecaseId, userId: user.id }}
        isLoading={isCreateSuccessStoryLoading}
        onSubmit={createSuccessStory}
        formRef={createSuccessStoryFormRef}
      />
    </>
  );
}
