import { useState } from 'react';

export default function useCreateProjectModal() {
  const [isVisible, setIsVisible] = useState(false);

  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);
  const toggleModal = () => {
    if (isVisible) {
      closeModal();
    } else {
      openModal();
    }
  };

  return {
    openModal,
    closeModal,
    toggleModal,
    isVisible,
  };
}
