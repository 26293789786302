/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Project } from '@raised/domain';
import cn from 'classnames';
import { ColorsModule, FormsModule, TypographyModule } from '@raised/ui-kit';
import { IconClockHour3 } from '@tabler/icons-react';
import UserAvatar from 'components/user/components/UserAvatar';
import { formatDate } from 'utils/formats';
import styles from './ProjectItem.module.css';

const { Text } = TypographyModule;
const { Colors } = ColorsModule;
const { Checkbox } = FormsModule;

interface ProjectItemProps {
  project: Project.Public;
  isChecked: boolean;
  onClick: () => void;
  isDisabled: boolean;
}

export default function ProjectItem({
  project,
  isChecked,
  onClick,
  isDisabled,
}: ProjectItemProps) {
  return (
    <div
      onClick={!isDisabled ? onClick : undefined}
      className={cn(styles.project_item, {
        [styles.disabled]: isDisabled,
      })}
    >
      <div className={styles.content}>
        <div className={styles.name_block}>
          <UserAvatar role={project.user?.role} size="s" filePath={project.user?.avatar} />
          <Text>{`${project.user?.firstName} ${project.user?.lastName}`}</Text>
        </div>
        <Text strong>{project.title}</Text>
        <div className={styles.time_block}>
          <IconClockHour3 size={14} color={Colors.gray} />
          <Text caption>{formatDate(new Date(project.createdAt))}</Text>
        </div>
      </div>
      <Checkbox checked={isChecked} disabled={isDisabled} />
    </div>
  );
}
