import { Space, Spin, Typography } from 'antd';
import LogMessageComponent from 'components/log-messages/components/LogMessageComponent';
import { useGetLogMessagesByProjectQuery } from 'components/log-messages/storage/logMessageApi';

interface LogTabProps {
  projectId: string;
}

export default function LogTab({
  projectId,
}: LogTabProps) {
  const {
    data: logMessages, isLoading: isMessagesLoading, isFetching: isMessagesFetching,
  } = useGetLogMessagesByProjectQuery({ projectId }, { refetchOnMountOrArgChange: true });

  const isLoading = isMessagesLoading || isMessagesFetching;

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <Space direction="vertical">
          <>
            {logMessages?.length ? (
              <>
                {logMessages.map((message) => (
                  <LogMessageComponent key={message.id} message={message} />
                ))}
              </>
            ) : (
              <Typography.Text>No log messages yet</Typography.Text>
            )}
          </>
        </Space>
      )}
    </>
  );
}
