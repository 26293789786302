import { Ref } from 'react';
import AttachFiles from 'components/files/attach-files/AttachFiles';
import useCreateTemplate from 'components/templates/hooks/useCreateTemplate';
import { TEMPLATES_ROUTES } from 'components/templates/templates-routes';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import TemplateForm from './TemplateForm';

interface CreateTemplatePageRrops {
  isModal?: boolean;
  formRef?: Ref<HTMLFormElement>;
  onSuccess?: () => void;
}

export default function CreateTemplatePage({
  isModal,
  formRef,
  onSuccess,
}: CreateTemplatePageRrops) {
  const navigate = useNavigate();
  const onBack = () => navigate(-1);

  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
      return;
    }

    navigate(TEMPLATES_ROUTES.TEMPLATES());
  };

  const {
    isLoading,
    createTemplate,
    files,
    addFiles,
    removeFile,
    uploadedFiles,
  } = useCreateTemplate({
    onSuccess: handleSuccess,
  });

  return (
    <>
      {!isModal && (
        <Helmet>
          <title>Raised - Create Template</title>
        </Helmet>
      )}
      <TemplateForm
        formRef={formRef}
        title={isModal ? undefined : 'Create Template'}
        isLoading={isLoading}
        onSubmit={createTemplate}
        onBack={onBack}
        isControls={!isModal}
        filesInput={(
          <AttachFiles
            files={files}
            addFiles={addFiles}
            uploadedFiles={uploadedFiles}
            removeFile={removeFile}
            subLabel="Provide a description of the desired expert, including their skills, level of experience, and any other relevant qualifications"
            label="Additional documents (optional)"
          />
      )}
      />
    </>
  );
}
