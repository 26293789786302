import { MatchStatuses } from '@raised/domain';
import { Input, Modal, Typography } from 'antd';
import { ChangeEvent, useState } from 'react';

interface ConfirmModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (text: string) => void;
  status: MatchStatuses;
}

export default function ConfirmModal({
  isOpen,
  onCancel,
  onSubmit,
  status,
}: ConfirmModalProps) {
  const [msg, setMsg] = useState('');

  const onMsgChange = (e: ChangeEvent<HTMLTextAreaElement>) => setMsg(e.target.value);

  const handleOk = () => {
    onSubmit(msg);
    setMsg('');
    onCancel();
  };
  const handleCancel = () => {
    setMsg('');
    onCancel();
  };

  const titleText = status === MatchStatuses.INTERSTED_BY_EXPERT || status === MatchStatuses.CALL_OVER ? 'Confirm project' : 'Decline project';

  return (
    <Modal
      title={titleText}
      open={isOpen}
      onOk={() => {
        handleOk();
      }}
      onCancel={() => {
        handleCancel();
      }}
      data-testid="modal_expertConfirm"
    >
      {status === MatchStatuses.INTERSTED_BY_EXPERT ? (
        <Typography.Text style={{ marginBottom: 8, display: 'block' }}>You are welcome to provide an additional comment explaining why you are the best fit for this project. Referrals to previous similar projects or relevant experience are usually effective.</Typography.Text>
      ) : null}
      <Input.TextArea placeholder="Message" value={msg} onChange={onMsgChange} />
    </Modal>
  );
}
