import { Organisation } from '@raised/domain';
import { Space, Typography } from 'antd';
import UserLink from 'components/user/components/UserLink';

interface OrganisationInfoProps {
  organisation: Organisation.Public;
}

export default function OrganisationInfo({
  organisation,
}: OrganisationInfoProps) {
  return (
    <Space direction="vertical">
      <Space>
        <Typography.Text type="secondary">Name: </Typography.Text>
        <Typography.Text>{organisation.companyName}</Typography.Text>
      </Space>
      {organisation?.aboutCompany ? (
        <Space>
          <Typography.Text type="secondary">About: </Typography.Text>
          <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>{organisation.aboutCompany}</Typography.Text>
        </Space>
      ) : null}
      {organisation?.companyWebsite ? (
        <Space>
          <Typography.Text type="secondary">Website: </Typography.Text>
          <UserLink href={organisation.companyWebsite}>{organisation.companyWebsite}</UserLink>
        </Space>
      ) : null}
    </Space>
  );
}
