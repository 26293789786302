import { ButtonsModule, DataModule } from '@raised/ui-kit';
import { IconArchive, IconPencil, IconTrash } from '@tabler/icons-react';
import { ComponentProps, useState } from 'react';
import cn from 'classnames';
import { ProjectStatuses } from '@raised/domain';
import ArchiveProject from 'components/project/components/archive-project/ArchiveProject';
import styles from './ProjectActions.module.css';

const { ActionButton } = ButtonsModule;
const { Actions } = DataModule;

function ArchiveButton(props: ComponentProps<typeof ActionButton>) {
  return (
    <ActionButton className={styles.action_button} {...props}>
      <IconArchive size={16} />
      {' '}
      Archive
    </ActionButton>
  );
}

interface ProjectActionsProps {
  onEditClick: () => void;
  onDeleteClick: () => void;
  onArchiveClick: (status: ProjectStatuses, message?: string) => void;
  isLoading: boolean;
}

export default function ProjectActions({
  onEditClick,
  onDeleteClick,
  onArchiveClick,
  isLoading,
}: ProjectActionsProps) {
  const [isActionsVisible, setIsActionsVisible] = useState(false);

  const actionItems = [
    {
      content: (
        <ActionButton className={styles.action_button} type="text" onClick={onEditClick}>
          <IconPencil size={16} />
          Edit
        </ActionButton>
      ),
    },
    {
      content: (
        <ArchiveProject
          PropsButton={ArchiveButton}
          type="text"
          onSubmit={onArchiveClick}
        />
      ),
    },
    {
      content: (
        <ActionButton className={styles.action_button} type="text" onClick={onDeleteClick}>
          <IconTrash size={16} />
          Delete
        </ActionButton>
      ),
    },
  ];

  return (
    <Actions
      className={cn('project_actions', {
        project_actions_active: isActionsVisible,
      })}
      trigger="click"
      items={actionItems}
      onOpenChange={setIsActionsVisible}
      loading={isLoading}
    />
  );
}
