import { User, UserRole } from '@raised/domain';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { excludeNullFields } from 'utils/forms';
import UpdateUserForm from '../components/forms/update-user/UpdateUserForm';
import useEditUserProfile from '../hooks/useEditUserProfile';
import { useGetProfileQuery, useGetUserByIdQuery } from '../storage/userApi';

export default function EditUserProfilePage() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const { id } = useParams();
  const {
    data: profile,
    isSuccess: isProfileSuccess,
  } = useGetProfileQuery();
  const { editUserProfile, isLoading } = useEditUserProfile({
    onSuccess: goBack,
  });
  const { data, isSuccess } = useGetUserByIdQuery({ id: id || '' }, { skip: !id });

  const onSubmit = (values: User.Edit) => {
    if (!id) return;

    const { email, ...rest } = values;

    editUserProfile({ ...rest, id, email: email || undefined });
  };

  const getDefaultValues = (userData: User.Public) => {
    const formattedData = excludeNullFields(userData, ['to']);

    if (!formattedData.email.includes('@')) {
      formattedData.email = '';
    }

    return formattedData;
  };

  return (
    <>
      <Helmet>
        <title>Raised - Edit profile</title>
      </Helmet>
      {isSuccess && isProfileSuccess && (
        <UpdateUserForm
          user={data}
          onSubmit={onSubmit}
          isLoading={isLoading}
          defaultValues={getDefaultValues(data)}
          title={`Edit ${data.firstName}'s profile`}
          onCancel={goBack}
          isAdmin={profile.role === UserRole.ADMIN}
          isGhost={data.isGhost}
        />
      )}
    </>
  );
}
