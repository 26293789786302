/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import {
  Checkbox, InputNumber, Select, Space, Typography,
} from 'antd';
import { Control, useController, useWatch } from 'react-hook-form';
import { ProjectPaymentPeriods, Proposal } from '@raised/domain';
import { PROJECT_PAYMENT_PERIODS_LABELS } from 'components/project/constants/project-payment-periods';
import styles from './ProjectLengthInput.module.css';

interface ProjectLengthInputProps {
  control: Control<Proposal.Create>;
  isNullable?: boolean;
  isPeriodSelectable?: boolean;
}

export default function ProjectLengthInput({
  control,
  isNullable = true,
  isPeriodSelectable = true,
}: ProjectLengthInputProps) {
  const { field } = useController({
    control,
    name: 'projectLength',
  });
  const selectedPaymentPeriod = useWatch({
    control,
    name: 'paymentPeriod',
  });

  const onNotSpecifiedClick = () => field.onChange(null);
  const onChangeLength = (value: number | null) => field.onChange({ period: field.value?.period || ProjectPaymentPeriods.DAYS, value });
  const onChangePeriod = (period: ProjectPaymentPeriods) => field.onChange({
    period,
    value: field.value?.value,
  });

  return (
    <Space direction="vertical">
      <Typography.Text strong>Project length</Typography.Text>
      <div
        className={cn(styles.option, {
          [styles.option_selected]: isNullable && typeof field.value?.value === 'number',
          [styles.option_single]: !isNullable,
        })}
      >
        {isNullable && (
          <Checkbox checked={typeof field.value?.value === 'number'} />
        )}
        <InputNumber
          value={field.value?.value}
          onChange={onChangeLength}
          addonAfter={isPeriodSelectable ? (
            <Select style={{ minWidth: '100px' }} defaultValue={ProjectPaymentPeriods.DAYS} onChange={onChangePeriod}>
              <Select.Option value={ProjectPaymentPeriods.DAYS}>Days</Select.Option>
              <Select.Option value={ProjectPaymentPeriods.WEEKS}>Weeks</Select.Option>
              <Select.Option value={ProjectPaymentPeriods.MONTHLY}>Monthly</Select.Option>
            </Select>
          ) : PROJECT_PAYMENT_PERIODS_LABELS[selectedPaymentPeriod || ProjectPaymentPeriods.DAYS]}
        />
      </div>
      {isNullable && (
        <div
          onClick={onNotSpecifiedClick}
          className={cn(styles.option, {
            [styles.option_selected]: field.value === null,
          })}
          style={{ cursor: 'pointer' }}
        >
          <Checkbox checked={field.value === null} />
          <Typography.Text>Length is not specified / ongoing project</Typography.Text>
        </div>
      )}
    </Space>
  );
}
