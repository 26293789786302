import type { ComponentProps } from 'react';
import { Input } from 'antd';
import { InputWrapper, type InputWrapperProps } from '../input-wrapper';

interface TextAreaProps extends ComponentProps<typeof Input.TextArea>, InputWrapperProps {}

export default function TextArea({
  label,
  subLabel,
  ...rest
}: TextAreaProps) {
  return (
    <InputWrapper label={label} subLabel={subLabel}>
      <Input.TextArea {...rest} />
    </InputWrapper>
  );
}
