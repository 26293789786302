import { PaymentStatuses } from '@raised/domain';
import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { PAYMENT_STATUS_LABELS } from 'components/payments/constants/payment-status-labels';
import { formatCurrency, formatDate } from 'utils/formats';
import PaymentStatus from '../../components/PaymentStatus';

export interface PaymentsTableRow {
  key: string;
  createdAt: string;
  amount: number;
  status: PaymentStatuses,
  projectTitle: string;
  projectId: string;
  proposalId: string;
  clientId: string;
  clientName: string;
}

interface GetPaymentsConfigProps {
  onProjectClick: (projectId: string) => void;
  onViewProposalClick: (proposalId: string) => void;
  onClientClick: (clientId: string) => void;
  projectFilters: { text: string, value: string }[];
}

const statusFilters = Object.entries(PAYMENT_STATUS_LABELS).map(([status, { value }]) => ({
  text: value,
  value: status,
}));

export const getPaymentsTableConfig = ({
  onProjectClick,
  onViewProposalClick,
  onClientClick,
  projectFilters,
}: GetPaymentsConfigProps): ColumnsType<PaymentsTableRow> => [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (value) => <PaymentStatus paymentStatus={value} />,
    filters: statusFilters,
    onFilter: (value, record) => record.status.startsWith(value as string),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    sorter: (a, b) => (b.amount - a.amount),
    render: (value: number) => formatCurrency(value / 100),
  },
  {
    title: 'Client',
    dataIndex: 'clientId',
    key: 'clientId',
    render: (value, record) => (
      <Typography.Link onClick={(e) => {
        e.stopPropagation();
        onClientClick(value);
      }}
      >
        {record.clientName}
      </Typography.Link>
    ),
  },
  {
    title: 'Project',
    dataIndex: 'projectTitle',
    key: 'projectTitle',
    filters: projectFilters,
    onFilter: (value, record) => record.projectTitle.startsWith(value as string),
    render: (value, record) => (
      <Typography.Link onClick={(event) => {
        event.stopPropagation();
        onProjectClick(record.projectId);
      }}
      >
        {value}
      </Typography.Link>
    ),
  },
  {
    title: 'Proposal',
    dataIndex: 'proposalId',
    key: 'proposalId',
    render: (value) => (
      <Typography.Link onClick={(event) => {
        event.stopPropagation();
        onViewProposalClick(value);
      }}
      >
        View proposal
      </Typography.Link>
    ),
  },
  // {
  //   title: 'Invoice',
  //   dataIndex: 'invoiceUrl',
  //   key: 'invoiceUrl',
  //   render: (value) => <InvoiceLink invoiceUrl={value} />,
  // },
  {
    title: 'Creation date',
    dataIndex: 'createdAt',
    sorter: (a, b) => {
      const opA = new Date(a.createdAt).getTime();
      const opB = new Date(b.createdAt).getTime();
      return opB - opA;
    },
    render: (value) => formatDate(new Date(value)),
    key: 'createdAt',
    width: '15%',
  },
];
