import { Template } from '@raised/domain';
import { ButtonsModule, ColorsModule, FormsModule } from '@raised/ui-kit';
import { IconTrash } from '@tabler/icons-react';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import styles from './ProjectPartsForm.module.css';

const { Colors } = ColorsModule;
const { Input, TextArea } = FormsModule;
const { Button } = ButtonsModule;

interface ProjectPartsFormProps {
  control: Control<Template.Create>;
}

export default function ProjectPartsForm({
  control,
}: ProjectPartsFormProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parts',
  });

  const handleAdd = () => {
    append({ title: '', description: '' });
  };

  return (
    <div className={styles.container}>
      {fields.map((fieldItem, index) => (
        <div className={styles.content_item} key={fieldItem.id}>
          <Controller
            control={control}
            name={`parts.${index}.title`}
            render={({ field, fieldState }) => (
              <Input
                {...field}
                status={fieldState.error ? 'error' : undefined}
                placeholder="Part of the Project"
                label="Part of the Project"
                style={{ marginBottom: 24 }}
              />
            )}
          />
          <Controller
            control={control}
            name={`parts.${index}.description`}
            render={({ field, fieldState }) => (
              <TextArea
                {...field}
                status={fieldState.error ? 'error' : undefined}
                rows={5}
                placeholder="Project Approaches"
                label="Project Approaches"
              />
            )}
          />
          <Button
            type="link"
            icon={<IconTrash color={Colors.gray} size={20} />}
            className={styles.remove_btn}
            onClick={() => remove(index)}
          />
        </div>
      ))}
      <Button className={styles.add_btn} type="link" onClick={handleAdd}>Add Part of the Project</Button>
    </div>
  );
}
