import { MatchStatuses } from '@raised/domain';
import { ColorsModule, TypographyModule } from '@raised/ui-kit';
import { EXPERT_MATCH_STATUS_LABELS } from 'components/match/constants/match-status-lables';
import styles from './MatchStatus.module.css';

const { Text } = TypographyModule;
const { Colors } = ColorsModule;

interface MatchStatusProps {
  status: MatchStatuses;
}

const backgroundColors: Record<MatchStatuses, string> = {
  [MatchStatuses.INTERSTED_BY_CLIENT]: Colors['light-grayish-cyan'],
  [MatchStatuses.INTERSTED_BY_EXPERT]: Colors['light-grayish-cyan'],
  [MatchStatuses.RECOMMENDED_TO_CLIENT]: Colors['light-grayish-cyan'],
  [MatchStatuses.RECOMMENDED_TO_EXPERT]: Colors['light-grayish-cyan'],
  [MatchStatuses.FINISHED]: '#F9F9F9',
  [MatchStatuses.DECLINED_BY_CLIENT]: Colors.pink,
  [MatchStatuses.DECLINED_BY_EXPERT]: Colors.pink,
  [MatchStatuses.PAYMENT_RECEIVED]: Colors['light-grayish-cyan'],
  [MatchStatuses.CALL_SCHEDULED]: '#F9F9F9',
  [MatchStatuses.CALL_OVER]: '#F9F9F9',
  [MatchStatuses.INVITED_TO_INTERVIEW]: '#F9F9F9',
  [MatchStatuses.PAUSE]: '#F9F9F9',
};

export default function MatchStatus({
  status,
}: MatchStatusProps) {
  return (
    <div className={styles.match_status} style={{ background: backgroundColors[status] }}>
      {EXPERT_MATCH_STATUS_LABELS[status].icon}
      <Text>{EXPERT_MATCH_STATUS_LABELS[status].secondaryText || EXPERT_MATCH_STATUS_LABELS[status].text}</Text>
    </div>
  );
}
