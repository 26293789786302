import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEditProfileMutation } from '../storage/userApi';
import { USER_ROUTES } from '../user-routes';

export default function useEditProfile() {
  const navigate = useNavigate();
  const [editProfile, info] = useEditProfileMutation();
  const { isError, isSuccess } = info;

  useEffect(() => {
    if (isError) {
      message.error(getErrorMessage(info.error));
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      message.success('Profile successfully changed');
      navigate(USER_ROUTES.PERSONAL_PROFILE());
    }
  }, [isSuccess]);

  return {
    editProfile,
    ...info,
  };
}
