import { useGetNewMessagesCountByChatQuery, useGetSupportChatQuery } from 'components/messages/storage/messageApi';
import { useState } from 'react';

export default function useMobileSupportChat() {
  const [isSupportVisible, setIsSupportVisible] = useState(false);

  const data = useGetSupportChatQuery();
  const countData = useGetNewMessagesCountByChatQuery({ chatId: data.data?.id || '' }, { skip: !data.data?.id, pollingInterval: 3000 });

  const openSupport = () => setIsSupportVisible(true);
  const closeSupport = () => setIsSupportVisible(false);

  return {
    isSupportVisible,
    openSupport,
    closeSupport,
    chat: {
      ...data,
    },
    count: {
      ...countData,
    },
  };
}
