import { User } from '@raised/domain';
import { ColorsModule, TypographyModule } from '@raised/ui-kit';
import { IconLink } from '@tabler/icons-react';
import UserAvatar from 'components/user/components/UserAvatar';
import UserLink from 'components/user/components/UserLink';
import styles from './InfoTab.module.css';

const { Title, Text } = TypographyModule;
const { Colors } = ColorsModule;

interface InfoTabProps {
  author?: User.Public;
}

export default function InfoTab({
  author,
}: InfoTabProps) {
  if (!author) return null;

  return (
    <div className={styles.container}>
      <div className={styles.user}>
        <UserAvatar role={author.role} size="m" filePath={author.avatar} />
        <Title level={4}>{`${author.firstName} ${author.lastName}`}</Title>
      </div>
      <div className={styles.organisation}>
        <Title level={4}>{author.organisation?.companyName}</Title>
        {author.organisation?.companyWebsite ? (
          <UserLink className={styles.organisation_link} href={author.organisation?.companyWebsite}>
            <IconLink size={20} color={Colors.blue} />
            {author.organisation?.companyWebsite}
          </UserLink>
        ) : null}
        {author.organisation?.aboutCompany ? (
          <Text className={styles.organisation_description}>{author.organisation?.aboutCompany}</Text>
        ) : null}
      </div>
    </div>

  );
}
