import { Typography } from 'antd';
import { User } from '@raised/domain';
import { Control, FieldValues } from 'react-hook-form';
import FulltimeCareerForm from '../../../form-components/expert-career/FulltimeCareerForm';
import ParttimeCareerForm from '../../../form-components/expert-career/PartimeCareerForm';
import ExpertTestimonialsForm from '../../../form-components/expert-testimonials/ExpertTestimonialsForm';

interface CareerHistoryStepProps {
  control: Control<User.Create>;
}

export default function CareerHistoryStep({
  control,
}: CareerHistoryStepProps) {
  return (
    <>
      <Typography.Title level={2} style={{ margin: '40px 0 0' }}>Career highlights</Typography.Title>
      <FulltimeCareerForm control={control as unknown as Control<FieldValues>} />
      <ParttimeCareerForm control={control as unknown as Control<FieldValues>} />
      <ExpertTestimonialsForm control={control as unknown as Control<FieldValues>} />
    </>
  );
}
