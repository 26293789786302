import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './FilterOption.module.css';

interface FilterOptionProps {
  isSelected: boolean;
  children: ReactNode;
  onClick: () => void;
}

export default function FilterOption({
  isSelected,
  children,
  onClick,
}: FilterOptionProps) {
  return (
    <button
      onClick={onClick}
      type="button"
      className={cn(styles.filter_option, {
        [styles.selected]: isSelected,
      })}
    >
      {children}
    </button>
  );
}
