import { FormsModule } from '@raised/ui-kit';
import { Control } from 'react-hook-form';
import { EXPERT_TAGS } from 'components/user/constants/expert-tags';
import { PROJECT_CATEGORIES_VALUES } from 'components/project/constants/project-categories';

const { Select } = FormsModule;

const getOptions = (values: string[]) => values.map((value) => ({ label: value, value }));
const functionOptions = Object.entries(PROJECT_CATEGORIES_VALUES).map(([value, { label }]) => ({ label, value }));

export const getExpertTagsFormConfig = (control: Control) => [
  {
    controller: {
      name: 'tags.function',
      control,
    },
    component: Select,
    label: 'Function',
    componentProps: {
      placeholder: 'Select Type',
      mode: 'multiple',
      options: functionOptions,
    },
  },
  {
    controller: {
      name: 'tags.type',
      control,
    },
    component: Select,
    label: 'Type',
    componentProps: {
      placeholder: 'Select Type',
      mode: 'multiple',
      options: getOptions(EXPERT_TAGS.type.values),
    },
  },
  {
    controller: {
      name: 'tags.stage',
      control,
    },
    component: Select,
    label: 'Stage',
    componentProps: {
      placeholder: 'Select Type',
      mode: 'multiple',
      options: getOptions(EXPERT_TAGS.stage.values),
    },
  },
  {
    controller: {
      name: 'tags.businessModel',
      control,
    },
    component: Select,
    label: 'Business model',
    componentProps: {
      placeholder: 'Select Type',
      mode: 'multiple',
      options: getOptions(EXPERT_TAGS.businessModel.values),
    },
  },
  {
    controller: {
      name: 'tags.industry',
      control,
    },
    component: Select,
    label: 'Industry',
    componentProps: {
      placeholder: 'Select Type',
      mode: 'multiple',
      options: getOptions(EXPERT_TAGS.industry.values),
    },
  },
  {
    controller: {
      name: 'tags.markets',
      control,
    },
    component: Select,
    label: 'Markets',
    componentProps: {
      placeholder: 'Select Type',
      mode: 'multiple',
      options: getOptions(EXPERT_TAGS.markets.values),
    },
  },
  {
    controller: {
      name: 'tags.countries',
      control,
    },
    component: Select,
    label: 'Countries',
    componentProps: {
      placeholder: 'Select Type',
      mode: 'multiple',
      options: getOptions(EXPERT_TAGS.countries.values),
    },
  },
];
