import { MatchStatuses, Proposal } from '@raised/domain';
import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { IconThumbUp } from '@tabler/icons-react';
import { getClientMatchStatusLabel } from 'components/match/constants/match-status-lables';
import { DetailedHTMLProps, HTMLAttributes, MouseEvent } from 'react';
import styles from './ActionsCell.module.css';

const { Button } = ButtonsModule;
const { Text, Title } = TypographyModule;

interface ActionsCellProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  status: MatchStatuses;
  proposals: Proposal.Public[];
  onInviteToInterviewClick: () => void;
  onDeclineClick: () => void;
  onInterstedClick: () => void;
}

export default function ActionsCell({
  status,
  proposals,
  onDeclineClick,
  onInviteToInterviewClick,
  onInterstedClick,
  ...props
}: ActionsCellProps) {
  const matchStatusConfig = getClientMatchStatusLabel(status, proposals);

  const handleClick = (handler: () => void) => (event: MouseEvent) => {
    event.preventDefault();
    handler();
  };

  return (
    <div {...props} className={styles.container}>
      {matchStatusConfig.icon}
      <div className={styles.info}>
        <Title level={5}>{matchStatusConfig.text}</Title>
        <Text>{matchStatusConfig.description}</Text>
      </div>
      <div className={styles.buttons}>
        {status === MatchStatuses.INTERSTED_BY_EXPERT ? (
          <>
            <Button className={styles.btn} data-testid="button_clientDecline" onClick={handleClick(onDeclineClick)} danger>Decline</Button>
            <Button className={styles.btn} data-testid="button_clientInviteToInterview" type="primary" onClick={handleClick(onInviteToInterviewClick)}>
              <IconThumbUp size={18} />
              Invite to interview
            </Button>
          </>
        ) : null}
        {status === MatchStatuses.RECOMMENDED_TO_CLIENT ? (
          <>
            <Button className={styles.btn} data-testid="button_clientDecline" onClick={handleClick(onDeclineClick)} danger>Decline</Button>
            <Button className={styles.btn} data-testid="button_clientIntersted" onClick={handleClick(onInterstedClick)}>
              <IconThumbUp size={18} />
              I&apos;m interested
            </Button>
          </>
        ) : null}
      </div>
      {status === MatchStatuses.INVITED_TO_INTERVIEW ? (
        <Text>-</Text>
      ) : null}
      {status === MatchStatuses.DECLINED_BY_CLIENT ? (
        <Text>-</Text>
      ) : null}
      {status === MatchStatuses.DECLINED_BY_EXPERT ? (
        <Text>-</Text>
      ) : null}
    </div>
  );
}
