import { TypographyModule } from '@raised/ui-kit';
import { Control } from 'react-hook-form';
import ProfilePublictyInput from './profile-publicty-input/ProfilePublictyInput';
import ProfilePublishedLinkInput from './profile-published-link-input/ProfilePublishedLinkInput';
import styles from './ProfilePrivacy.module.css';

const { Title } = TypographyModule;

interface ProfilePrivacyProps {
  control: Control<any>;
}

export default function ProfilePrivacy({
  control,
}: ProfilePrivacyProps) {
  return (
    <div className={styles.profile_privacy}>
      <Title level={2} className={styles.title}>Profile Privacy</Title>
      <ProfilePublictyInput control={control} />
      <ProfilePublishedLinkInput control={control} />
    </div>
  );
}
