/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Spin } from 'src/ui-kit/feedback';
import { ActionItem } from './types';
import styles from './ActionsContent.module.css';

interface ActionsContentProps {
  items: ActionItem[];
  loading?: boolean;
  onClose: () => void;
}

export default function ActionsContent({
  items,
  loading,
  onClose,
}: ActionsContentProps) {
  return (
    <div onClick={onClose} className={styles.actions_content}>
      {items.map((item) => (
        item.content
      ))}
      {loading ? (
        <div className={styles.overlay}>
          <Spin />
        </div>
      ) : null}
    </div>
  );
}
