import { MatchStatuses, Project, User } from '@raised/domain';
import { Button, Space } from 'antd';
import { ADMIN_MATCH_STATUS_LABELS } from 'components/match/constants/match-status-lables';
import RecommendUserRow from 'components/project/components/RecommendUserRow';

interface RecommendTabProps {
  onSubmitRecommend: (userId: string) => void;
  onSubmitUnrecommend: (userId: string) => void;
  project: Project.Public;
  experts: User.Public[];
  isLoading: boolean;
}

export default function RecommendTab({
  onSubmitRecommend,
  onSubmitUnrecommend,
  project,
  experts,
  isLoading,
}: RecommendTabProps) {
  const getUserStatus = (user: User.Public): string => {
    const prevMatch = user.matches?.find((match) => match.projectId === project.id);

    if (prevMatch?.status && prevMatch.status !== MatchStatuses.RECOMMENDED_TO_EXPERT) {
      return ADMIN_MATCH_STATUS_LABELS[prevMatch.status];
    }

    return '';
  };

  const getRecommendedMatch = (userId: string) => project.matches?.find((match) => match.userId === userId && match.status === MatchStatuses.RECOMMENDED_TO_EXPERT);

  const onButtonClickHandler = (userId: string) => {
    const match = getRecommendedMatch(userId);

    if (match) {
      onSubmitUnrecommend(match.id);
    } else {
      onSubmitRecommend(userId);
    }
  };

  return (
    <Space style={{ width: 500 }} direction="vertical">
      {experts.map((user) => (
        <RecommendUserRow
          key={user.id}
          user={user}
          status={getUserStatus(user)}
          controls={!getUserStatus(user) && (
            <Button
              loading={isLoading}
              onClick={() => onButtonClickHandler(user.id)}
              danger={!!getRecommendedMatch(user.id)}
              data-testid="button_adminInvite"
            >
              {getRecommendedMatch(user.id) ? (
                'Delete invitation'
              ) : (
                'Invite'
              )}
            </Button>
          )}
        />
      ))}
    </Space>
  );
}
