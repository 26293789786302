import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/formats';
import DeletedByCell from './DeletedByCell';

export interface DeletedProjectsTableRow {
  key: string;
  createdAt: string;
  deletedAt: string;
  deletedBy: string;
  title: string;
  description: string;
  projectLink: string;
  userLink: string;
}

export const getDeletedProjectTableConfig = (): ColumnsType<DeletedProjectsTableRow> => [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: (a, b) => a.title.length - b.title.length,
    render: (value, record) => <Link to={record.projectLink}>{value}</Link>,
  },
  {
    title: 'Creation date',
    dataIndex: 'createdAt',
    sorter: (a, b) => {
      const opA = new Date(a.createdAt).getTime();
      const opB = new Date(b.createdAt).getTime();
      return opB - opA;
    },
    render: (value) => formatDate(new Date(value)),
    key: 'createdAt',
    width: '15%',
  },
  {
    title: 'Deletion date',
    dataIndex: 'deletedAt',
    sorter: (a, b) => {
      const opA = new Date(a.deletedAt).getTime();
      const opB = new Date(b.deletedAt).getTime();
      return opB - opA;
    },
    render: (value) => formatDate(new Date(value)),
    key: 'deletedAt',
    width: '15%',
  },
  {
    title: 'Deleted by',
    dataIndex: 'deletedBy',
    render: (value, record) => (
      <DeletedByCell
        userId={value}
        userLink={record.userLink}
      />
    ),
    key: 'deletedBy',
    width: '15%',
  },
];
