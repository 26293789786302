import { ORGANISATION_ROUTES } from 'components/organisation/organisation-routes';
import { Helmet } from 'react-helmet';
import { useGetAllUsersQuery, useGetProfileQuery } from '../storage/userApi';
import { USER_ROUTES } from '../user-routes';
import AdminUsersTable from './admin-users-table/AdminUsersTable';
import AdminUserListComponent from './AdminUserListComponent';

export default function AdminUserListPage() {
  const {
    data: profile,
  } = useGetProfileQuery();
  const {
    data: users,
    isLoading: isUsersLoading,
  } = useGetAllUsersQuery();

  const getUserLink = (userId: string) => {
    if (userId === profile?.id) {
      return USER_ROUTES.PERSONAL_PROFILE();
    }

    return USER_ROUTES.USER_PROFILE(userId);
  };
  const getOrganizationLink = (orgId: string) => ORGANISATION_ROUTES.ADMIN_ORGANIZATION(orgId);

  return (
    <AdminUserListComponent>
      <Helmet>
        <title>Raised - User list</title>
      </Helmet>
      <AdminUsersTable
        isLoading={isUsersLoading}
        users={users || []}
        getUserLink={getUserLink}
        getOrganizationLink={getOrganizationLink}
      />
    </AdminUserListComponent>
  );
}
