import { UserRole } from '@raised/domain';
import { useEffect, useMemo } from 'react';
import { Control, FieldValues, UseFormResetField } from 'react-hook-form';
import { renderFormConfig } from 'utils/forms';
import { getCompanyDetailsFormConfig } from './companyDetailsFormConfig';

interface CompnayDetailsFormProps<T extends FieldValues> {
  control: Control<T>
  role: UserRole;
  resetField: UseFormResetField<any>;
}

export default function CompanyDetailsFieldsForm<T extends FieldValues>({
  control,
  role,
  resetField,
}: CompnayDetailsFormProps<T>) {
  const formConfig = useMemo(() => getCompanyDetailsFormConfig(control), []);

  useEffect(() => {
    resetField('companyName');
    resetField('companyWebsite');
    resetField('aboutCompany');
  }, [role]);

  return (
    <>
      {renderFormConfig(formConfig)}
    </>
  );
}
