import { Project } from '@raised/domain';
import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import useAttachedFiles from 'components/files/hooks/useAttachedFiles';
import useUploadFiles from 'components/files/hooks/useUploadFiles';
import { useEffect } from 'react';
import { useCreateProjectMutation } from '../storage/projectApi';
import { successProjectMessages } from '../storage/successProjectMessages';

interface UseCreateProjectProps {
  onSuccess?: (project: Project.Public) => void;
  isNotify?: boolean;
}

export default function useCreateProject({
  onSuccess,
  isNotify = true,
}: UseCreateProjectProps) {
  const [mutate, data] = useCreateProjectMutation({
    fixedCacheKey: 'create-project',
  });
  const { uploadFiles, isLoading: isFilesLoading } = useUploadFiles();
  const {
    files, uploadedFiles, addFiles, removeFile,
  } = useAttachedFiles({});

  const create = async (values: Project.Create) => {
    const attachedFiles = [...uploadedFiles];

    if (files.length) {
      const result = await uploadFiles(files);
      const fileUrls = result?.map((file) => file.url);

      if (fileUrls) {
        attachedFiles.push(...fileUrls);
      }
    }

    mutate({
      ...values,
      attachedFile: attachedFiles,
    });
  };

  useEffect(() => {
    if (data.isSuccess) {
      if (isNotify) {
        message.success(successProjectMessages.CREATE);
      }

      if (onSuccess) {
        onSuccess(data.data);
      }
    }
  }, [data.isSuccess]);

  useEffect(() => {
    if (data.isError && isNotify) {
      message.error(getErrorMessage(data.error));
    }
  }, [data.isError]);

  return {
    create,
    files,
    addFiles,
    removeFile,
    uploadedFiles,
    ...data,
    isLoading: data.isLoading || isFilesLoading,
  };
}
