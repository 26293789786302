export const Colors = {
  blue: '#0057FF',
  base: '#fff',
  accent: '#00BABA',
  black: '#000',
  'solid-green': '#00B57E',
  green: 'rgba(0, 181, 126, 0.50)',
  'light-green': '#E6FDF2',
  'light-grayish-cyan': '#E0F8F8',
  pink: '#FFF3F3',
  gray: 'rgba(0, 0, 0, 0.45)',
  'medium-gray': '#D9D9D9',
  'light-gray': '#F4F4F4',
  'green-outline': 'rgba(0, 181, 126, 0.50)',
  'pink-outline': 'rgba(255, 165, 174, 0.50)',
  greyblue: '#E1E9ED',
  'light-beige': '#FBF9F3',
  liner: '#EAFF6C',
  'light-blue': '#DAE9FF',
  biege: '#FFDFB9',
  'light-purple': '#F2D6FF',
  red: '#F51D2C',
};
