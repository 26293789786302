import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { IconX } from '@tabler/icons-react';
import { ReactNode } from 'react';
import styles from './File.module.css';

const { Button } = ButtonsModule;
const { Link } = TypographyModule;

interface FileProps {
  name: ReactNode;
  link: string;
  onDelete?: () => void;
}

export default function File({
  name,
  link,
  onDelete,
}: FileProps) {
  return (
    <div className={styles.file}>
      <Link href={link} target="_blank" className={styles.name}>{name}</Link>
      {onDelete ? (
        <Button className={styles.btn} type="link" icon={<IconX size={14} />} onClick={onDelete} />
      ) : null}
    </div>
  );
}
