import { DataModule, FeedbackModule, TypographyModule } from '@raised/ui-kit';
import { useGetNotificationsQuery } from 'components/notifications/storage/notificationsApi';
import { NotificationLinkType } from '../../../../hooks/useNotificationLinks';
import styles from './NotificationLink.module.css';

const { Text } = TypographyModule;
const { Badge } = DataModule;
const { Spin } = FeedbackModule;

interface NotificationLinkProps {
  link: NotificationLinkType;
  personalProfileId: string;
}

export default function NotificationLink({
  link,
  personalProfileId,
}: NotificationLinkProps) {
  const { data, isLoading } = useGetNotificationsQuery({ projectId: link.value }, { skip: link.value === 'all', pollingInterval: 3500 });
  const newNotifications = data?.allNewNotifications.filter((notification) => !notification.viewedBy.includes(personalProfileId)) || [];

  return (
    <div className={styles.container}>
      <Text className={styles.label}>{link.title}</Text>
      <Badge
        className={styles.badge}
        count={isLoading ? <Spin size="small" /> : newNotifications.length}
      />
    </div>
  );
}
