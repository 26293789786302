import { Typography } from 'antd';
import { TextProps } from 'antd/es/typography/Text';
import { ReactNode } from 'react';

interface ProposalFieldLabelProps extends TextProps {
  children: ReactNode;
}

export default function ProposalFieldLabel({
  children,
  ...props
}: ProposalFieldLabelProps) {
  return (
    <Typography.Text
      type="secondary"
      {...props}
      style={{ ...(props.style || {}), whiteSpace: 'pre-wrap' }}
    >
      {children}
    </Typography.Text>
  );
}
