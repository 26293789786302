import cn from 'classnames';
import { Link } from 'react-router-dom';
import { SuccessStory } from '@raised/domain';
import { FeedbackModule, TypographyModule } from '@raised/ui-kit';
import { IconAward } from '@tabler/icons-react';
import styles from './SuccessStories.module.css';

const { Title, Text, Paragraph } = TypographyModule;
const { Spin } = FeedbackModule;

interface SuccessStoriesProps {
  successStories: SuccessStory.Public[];
  isOpen: boolean;
  isMoreStories: boolean;
  className?: string;
  isLoading?: boolean;
  getSuccessStoryLink: (id: string) => string;
}

export default function SuccessStories({
  successStories,
  isOpen,
  isMoreStories,
  className,
  isLoading,
  getSuccessStoryLink,
}: SuccessStoriesProps) {
  return (
    <section className={cn(styles.success_stories, {
      [styles.closed]: !isOpen && isMoreStories,
      [styles.open]: isOpen && isMoreStories,
    }, className)}
    >
      <div className={cn(styles.title_block, 'success_stories_title_block')}>
        <IconAward size={24} />
        <Title level={1} className={styles.title}>Recent Success Stories</Title>
      </div>
      <div className={cn(styles.list, 'success_stories_list')}>
        {isLoading ? <Spin /> : successStories.map((story) => (
          <Link to={getSuccessStoryLink(story.id)} className={styles.item} key={story.id}>
            <Title className={styles.item__title} level={2}>{story.successStoryName}</Title>
            <Text className={styles.item__company_name} strong>{story.companyName}</Text>
            <Paragraph className={styles.item__company_description}>{story.companyDescription}</Paragraph>
            <Text caption className={styles.item__brief}>Brief</Text>
            <Paragraph>{story.brief}</Paragraph>
          </Link>
        ))}
      </div>
    </section>
  );
}
