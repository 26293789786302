import { User } from '@raised/domain';
import cn from 'classnames';
import {
  ButtonsModule, DataModule, LayoutModule, TypographyModule,
} from '@raised/ui-kit';
import { EXPERT_TAGS } from 'components/user/constants/expert-tags';
import useExpertTags, { type TagGroups } from 'components/user/hooks/useExpertTags';
import React, { ReactNode, useState } from 'react';
import { PROJECT_CATEGORIES_VALUES } from 'components/project/constants/project-categories';
import styles from './ExpertFilters.module.css';
import FilterItem from './filter-item/FilterItem';
import FilterButton from './mobile-filters/filter-button/FilterButton';
import FiltersModal from './mobile-filters/filters-modal/FiltersModal';

const { Space } = LayoutModule;
const { IconTag } = DataModule;
const { Text } = TypographyModule;
const { Button } = ButtonsModule;

interface ExpertFiltersProps {
  filters: Required<User.Tags>;
  onChangeFilter: (category: keyof User.Tags, values: string[]) => void;
  resetFilters: () => void;
  additionalFilters?: ReactNode;
}

export default function ExpertFilters({
  filters,
  onChangeFilter,
  resetFilters,
  additionalFilters,
}: ExpertFiltersProps) {
  const [selectedGroup, setSelectedGroup] = useState<TagGroups | null>(null);
  const { icons, groupTags } = useExpertTags();
  const groupedTags = groupTags(filters, true);

  const closeFiltersModal = () => setSelectedGroup(null);
  const openFiltersModal = (tagKey: TagGroups) => setSelectedGroup(tagKey);
  const onChangeMobileFilter = (category: keyof User.Tags, value: string) => {
    if (filters[category].includes(value)) {
      onChangeFilter(category, filters[category].filter((v) => v !== value));
    } else {
      onChangeFilter(category, [...filters[category], value]);
    }
  };

  const getCount = (group: TagGroups) => Object.values(groupedTags[group]).reduce((acc, item) => {
    // eslint-disable-next-line no-param-reassign
    acc += item.length;
    return acc;
  }, 0);

  const getFilterOptions = (name: string) => {
    if (name === 'function') {
      return Object.entries(PROJECT_CATEGORIES_VALUES).map(([value, { label }]) => ({ label, value }));
    }

    return EXPERT_TAGS[name as keyof User.Tags].values.map((v) => ({ label: v, value: v }));
  };

  return (
    <>
      <div className={cn(styles.container, styles.desktop)}>
        {Object.entries(groupedTags).map(([tagKey, tagsObject]) => (
          <React.Fragment key={tagKey}>
            {Object.keys(tagsObject).length ? (
              <Space size={10} direction="vertical">
                <div className={styles.label_row}>
                  <Space size={4}>
                    <IconTag icon={icons[tagKey as TagGroups].icon} color={icons[tagKey as TagGroups].color} />
                    <Text style={{ fontWeight: '500' }}>{icons[tagKey as TagGroups].label}</Text>
                  </Space>
                  {tagKey === 'function' ? (
                    <Button onClick={resetFilters} className={styles.clear_btn} type="link">Clear all</Button>
                  ) : null}
                </div>
                {Object.entries(tagsObject).map(([name, values]) => (
                  <FilterItem
                    key={name}
                    name={name as keyof User.Tags}
                    selectedValues={values}
                    options={getFilterOptions(name)}
                    onChangeFilter={onChangeFilter}
                  />
                ))}
              </Space>
            ) : null}
          </React.Fragment>
        ))}
        {additionalFilters}
      </div>
      <div className={styles.mobile_filters}>
        {Object.entries(groupedTags).map(([tagKey]) => (
          <FilterButton
            onClick={() => openFiltersModal(tagKey as TagGroups)}
            key={tagKey}
          >
            <div className={styles.mobile_filters_header}>
              <IconTag icon={icons[tagKey as TagGroups].icon} color={icons[tagKey as TagGroups].color} />
              <Text style={{ fontWeight: '500' }}>{icons[tagKey as TagGroups].label}</Text>
              <Text className={styles.count}>{getCount(tagKey as TagGroups)}</Text>
            </div>
          </FilterButton>
        ))}
        <FiltersModal
          onClear={resetFilters}
          isVisible={Boolean(selectedGroup)}
          onClose={closeFiltersModal}
          onTagClick={onChangeMobileFilter}
          header={(
            <>
              {selectedGroup ? (
                <div className={styles.mobile_filters_header}>
                  <IconTag icon={icons[selectedGroup as TagGroups].icon} color={icons[selectedGroup as TagGroups].color} />
                  <Text style={{ fontWeight: '500' }}>{icons[selectedGroup as TagGroups].label}</Text>
                  <Text className={styles.count}>{selectedGroup ? getCount(selectedGroup) : 0}</Text>
                </div>
              ) : null}
            </>
          )}
          selectedTags={selectedGroup ? groupedTags[selectedGroup] : {}}
        />
      </div>
    </>
  );
}
