import { useGetProfileQuery } from 'components/user/storage/userApi';
import { USER_ROUTES } from 'components/user/user-routes';
import { Helmet } from 'react-helmet';
import { MESSAGE_ROUTES } from '../messages-routes';
import { useGetAllSupportChatsQuery } from '../storage/messageApi';
import AdminSupportChatsListComponent from './AdminSupportChatsListComponent';
import SupportListTable from './support-list-table/SupportListTable';

export default function AdminSupportChatsListPage() {
  const { data: profile, isSuccess: isProfileSuccess } = useGetProfileQuery();
  const { data: chats, isLoading: isChatsLoading, isFetching: isChatsFetching } = useGetAllSupportChatsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const getUserLink = (userId: string) => USER_ROUTES.USER_PROFILE(userId);
  const getChatLink = (chatId: string) => MESSAGE_ROUTES.CHAT_PAGE(chatId);

  return (
    <AdminSupportChatsListComponent>
      <Helmet>
        <title>Raised - Support chat list</title>
      </Helmet>
      {isProfileSuccess ? (
        <SupportListTable
          getChatLink={getChatLink}
          getUserLink={getUserLink}
          personalProfileId={profile.id}
          chats={chats || []}
          isLoading={isChatsLoading || isChatsFetching}
        />
      ) : null}
    </AdminSupportChatsListComponent>
  );
}
