import { SuccessStory } from '@raised/domain';
import Table from 'components/layout/components/table/Table';
import { useMemo } from 'react';
import { getSuccessStoriesTableConfig } from './successStoriesTableConfig';

interface SuccessStoriesTableProps {
  isLoading: boolean;
  successStories: SuccessStory.Public[];
  getSuccessStoryLink: (id: string) => string;
  getUserLink: (id: string) => string;
}

export default function SuccessStoriesTable({
  isLoading,
  successStories,
  getSuccessStoryLink,
  getUserLink,
}: SuccessStoriesTableProps) {
  const columns = useMemo(() => getSuccessStoriesTableConfig(), []);
  const dataSource = useMemo(() => successStories.map((story) => ({
    key: story.id,
    name: story.successStoryName,
    successStoryLink: getSuccessStoryLink(story.id),
    expertName: `${story.user?.firstName} ${story.user?.lastName}`,
    expertLink: getUserLink(story.userId),
    createdAt: story.createdAt,
  })), [successStories]);

  return (
    <Table
      tableName="successStoriesList"
      loading={isLoading}
      columns={columns}
      dataSource={dataSource}
    />
  );
}
