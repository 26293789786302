import { User, UserRole } from '@raised/domain';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { PROJECT_ROUTES } from 'components/project/project-routes';
import useCreateUser from '../hooks/useCreateUser';
import { USER_ROUTES } from '../user-routes';
import CreateUserForm from '../components/forms/create-user/CreateUserForm';

export default function CreateUserPage() {
  const navigate = useNavigate();
  const onSuccess = (role: UserRole) => {
    if (role === UserRole.CLIENT) {
      navigate(PROJECT_ROUTES.PROJECTS());
      return;
    }

    navigate(USER_ROUTES.PERSONAL_PROFILE());
  };

  const { createUser, isLoading } = useCreateUser({
    onSuccess: (user) => onSuccess(user.role),
  });

  const onSubmit = (values: User.Create) => {
    createUser(values);
  };

  return (
    <>
      <Helmet>
        <title>Raised - Create account</title>
      </Helmet>
      <CreateUserForm
        isLoading={isLoading}
        onSubmit={onSubmit}
        title="Create account"
        isUploadAvatar={false}
      />
    </>
  );
}
