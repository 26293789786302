import { Proposal, ProposalStatuses } from '@raised/domain';
import {
  Button, Collapse, Space, Typography,
} from 'antd';
import ViewProposal from 'components/proposal/components/view-proposal/ViewProposal';

const { Panel } = Collapse;

interface ProposalsComponentProps {
  proposals: Proposal.Public[];
  isLoading: boolean;
  onAccept: (matchId: string, proposalId: string) => void;
  onDecline: (matchId: string, proposalId: string) => void;
  onEdit: (proposalId: string) => void;
}

export default function ProposalsComponent({
  proposals,
  onAccept,
  onDecline,
  isLoading,
  onEdit,
}: ProposalsComponentProps) {
  return (
    <div style={{ marginBottom: 16 }}>
      <Typography.Title level={5}>Proposals</Typography.Title>
      {proposals.length ? (
        <Collapse style={{ maxWidth: 600 }}>
          {proposals.map((proposal) => (
            <Panel
              key={proposal.id}
              header={`From ${proposal.expert?.email}`}
            >
              <ViewProposal proposal={proposal} />
              {proposal.status === ProposalStatuses.PENDING ? (
                <Space.Compact block style={{ marginTop: 16 }}>
                  <Button onClick={() => onEdit(proposal.id)} type="dashed">
                    Edit
                  </Button>
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => onAccept(proposal.matchId, proposal.id)}
                    type="primary"
                  >
                    Accept
                  </Button>
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => onDecline(proposal.matchId, proposal.id)}
                    type="primary"
                    danger
                  >
                    Decline
                  </Button>
                </Space.Compact>
              ) : null}
            </Panel>
          ))}
        </Collapse>
      ) : (
        <Typography.Text>No proposals yet</Typography.Text>
      )}
    </div>
  );
}
