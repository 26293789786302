import { Space, Typography } from 'antd';
import { useGetAdminSupportNotificationsQuery } from 'components/notifications/storage/notificationsApi';

export default function AdminSupportMenuLabel() {
  const { data, isSuccess } = useGetAdminSupportNotificationsQuery(undefined, {
    pollingInterval: 3500,
  });

  const isNewNotifications = isSuccess ? Boolean(data.length) : false;

  return (
    <Space style={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography.Text>Support</Typography.Text>
      {isNewNotifications ? (
        <div style={{
          width: 5, height: 5, background: 'red', borderRadius: '50%',
        }}
        />
      ) : null}
    </Space>
  );
}
