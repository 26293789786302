import { TEMPLATES_ROUTES } from 'components/templates/templates-routes';
import { useGetAllUsecasesQuery } from 'components/usecases/storage/usecasesApi';
import { USECASE_ROUTES } from 'components/usecases/usecase-routes';
import { USER_ROUTES } from 'components/user/user-routes';
import AdminUsecasesListContent from './AdminUsecasesListContent';
import UsecasesTable from './usecases-table/UsecasesTable';

export default function AdminUsecasesListPage() {
  const {
    data: usecases,
    isLoading: isUsecasesLoading,
  } = useGetAllUsecasesQuery();

  return (
    <AdminUsecasesListContent
      isLoading={isUsecasesLoading}
      count={usecases?.length || 0}
    >
      <UsecasesTable
        isLoading={isUsecasesLoading}
        usecases={usecases || []}
        getUserLink={(id) => USER_ROUTES.USER_PROFILE(id)}
        getUsecaseLink={(id) => USECASE_ROUTES.EDIT(id)}
        getTemplateLink={(id) => TEMPLATES_ROUTES.TEMPLATE(id)}
      />
    </AdminUsecasesListContent>
  );
}
