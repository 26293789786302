import { User } from '@raised/domain';
import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useChangeOrganisationMutation } from '../storage/userApi';

interface UseChangeOrganisationProps {
  onSuccess: (user: User.Public) => void;
  onError: () => void;
}

export default function useChangeOrganisation({
  onSuccess,
  onError,
}: UseChangeOrganisationProps) {
  const [mutate, info] = useChangeOrganisationMutation();
  const {
    isError, error, isSuccess, data,
  } = info;

  const changeOrganisation = (changeData: User.ChangeOrganisation) => mutate(changeData);

  useEffect(() => {
    if (!isSuccess) return;

    onSuccess(data);
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;

    message.error(getErrorMessage(error));
    onError();
  }, [isError]);

  return {
    changeOrganisation,
    ...info,
  };
}
