/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  MatchStatuses, Project, ProjectActivityTypes, ProjectStatuses, User, UserRole,
} from '@raised/domain';
import { ClientMatchOverviewTabs } from 'components/match/types/client-match-overview-tabs';
import { useGetPersonalMatchByProjectQuery } from 'components/match/storage/matchApi';
import ChatView from 'components/messages/components/chat-view/ChatView';
import cn from 'classnames';
import useChatWindow from 'components/messages/hooks/useChatWindow';
import MobileChatHeader from 'components/messages/components/chat-view/mobile-chat-header/MobileChatHeader';
import { ButtonsModule, LayoutModule, NavigationModule } from '@raised/ui-kit';
import { IconUserShare } from '@tabler/icons-react';
import styles from './ProjectComponent.module.css';
import ProjectInfo from './project-info/ProjectInfo';
import ClientProjectMatchInfo from './project-match-info/client/ClientProjectMatchInfo';
import ExpertProjectMatchInfo from './project-match-info/expert/ExpertProjectMatchInfo';
import AdminProjectMatchInfo from './project-match-info/admin/AdminProjectMatchInfo';
import SelectProjectStatusComponent from './select-project-status/SelectProjectStatusComponent';
import ProjectStatus from './project-info/project-status/ProjectStatus';
import ArchiveProject from '../components/archive-project/ArchiveProject';
import ExpertMatchActions from './project-match-info/expert/expert-match-actions/ExpertMatchActions';

const { Breadcrumb } = NavigationModule;
const { Button } = ButtonsModule;
const { Divider } = LayoutModule;

interface ProjectComponentProps {
  project: Project.Public;
  isPersonal: boolean;
  onSubmitMatch: (status: MatchStatuses, text: string, matchId: string, chatId?: string) => void;
  userData?: User.Public;
  author?: User.Public;
  onEditProjectClick: () => void;
  onCreateProposalClick: (matchId: string) => void;
  onClientMatchOverviewClick: (matchId: string, tab?: ClientMatchOverviewTabs) => void;
  getClientMatchOverviewLink: (matchId: string, tab?: ClientMatchOverviewTabs) => string;
  onUserClick: (userId: string) => void;
  isUpdateStatusLoading: boolean;
  onUpdateProjectStatus: (status: ProjectStatuses, message?: string) => void;
  isUpdateMatchLoading: boolean;
  onBack: () => void;
  onFindProfessionalsClick: () => void;
  isFindProfessionalsLoading: boolean;
}

export default function ProjectComponent({
  project,
  isPersonal,
  onSubmitMatch,
  userData,
  author,
  onEditProjectClick,
  onCreateProposalClick,
  onClientMatchOverviewClick,
  getClientMatchOverviewLink,
  onUserClick,
  isUpdateStatusLoading,
  onUpdateProjectStatus,
  isUpdateMatchLoading,
  onBack,
  onFindProfessionalsClick,
  isFindProfessionalsLoading,
}: ProjectComponentProps) {
  const { isChatVisible, toggleChat, openChat } = useChatWindow();

  const isAdmin = userData?.role === UserRole.ADMIN;
  const isExpert = userData?.role === UserRole.EXPERT;
  const isClient = userData?.role === UserRole.CLIENT;
  const isDeleted = !!project.deletedAt;
  const isRecommended = project.projectActivityType === ProjectActivityTypes.RECOMMENDED;
  const isProjectEditable = ((isPersonal && !isRecommended) || isAdmin) && !project.deletedAt;
  const isRequestMoreProfessionals = project.status !== ProjectStatuses.IN_PROGRESS_NEED_EXPERTS && project.status !== ProjectStatuses.ARCHIVED && project.status !== ProjectStatuses.FINISHED && !isRecommended;
  const isArchive = isClient && isPersonal && !isDeleted;

  const { data: expertMatch } = useGetPersonalMatchByProjectQuery({ projectId: project.id }, { skip: !isExpert });

  return (
    <div className={cn(styles.wrapper, {
      [styles.client_wrapper]: !isExpert,
    })}
    >
      <div className={cn(styles.container, {
        [styles.client_container]: !isExpert,
      })}
      >
        <Breadcrumb
          className={styles.breadcrumb}
          items={[
            {
              title: <a onClick={onBack}>Projects</a>,
            },
            {
              title: project.title,
            },
          ]}
        />
        <ProjectInfo
          project={project}
          onUserClick={onUserClick}
          isProjectEditable={isProjectEditable}
          onEditClick={onEditProjectClick}
          author={author}
          organisation={author?.organisation}
          isPersonal={isPersonal}
          archiveButton={isArchive ? (
            <ArchiveProject
              onSubmit={onUpdateProjectStatus}
              disabled={isUpdateStatusLoading || project.status === ProjectStatuses.ARCHIVED || project.status === ProjectStatuses.FINISHED}
              loading={isUpdateStatusLoading}
              buttonText={isRecommended ? 'Not Relevant' : 'Archive'}
            />
          ) : null}
          statusContent={(
            <>
              {isClient && isPersonal && !isDeleted ? (
                <ProjectStatus
                  status={project.status}
                  projectActivityType={project.projectActivityType}
                  actions={(
                    <>
                      {isRequestMoreProfessionals ? (
                        <Button
                          disabled={isUpdateStatusLoading}
                          onClick={() => onUpdateProjectStatus(ProjectStatuses.IN_PROGRESS_NEED_EXPERTS)}
                          loading={isUpdateStatusLoading}
                        >
                          <IconUserShare size={18} />
                          Request more professionals
                        </Button>
                      ) : null}
                      {isRecommended ? (
                        <Button
                          disabled={isFindProfessionalsLoading}
                          loading={isFindProfessionalsLoading}
                          onClick={onFindProfessionalsClick}
                        >
                          <IconUserShare size={18} />
                          Find Professionals
                        </Button>
                      ) : null}
                    </>
              )}
                />
              ) : null}
              {isExpert && expertMatch && !isDeleted ? (
                <ExpertMatchActions
                  onSubmit={onSubmitMatch}
                  onCreateProposalClick={() => onCreateProposalClick(expertMatch.id)}
                  author={author}
                  personalProjectMatch={expertMatch}
                  projectTitle={project.title}
                  isUpdateMatchLoading={isUpdateMatchLoading}
                />
              ) : null}
            </>
          )}
        />
        {isAdmin && !isDeleted ? (
          <SelectProjectStatusComponent
            defaultValue={project.status}
            onChange={onUpdateProjectStatus}
            isLoading={isUpdateStatusLoading}
          />
        ) : null}
        {isClient || isAdmin ? (
          <Divider className={styles.divider} />
        ) : null}
        {isClient && isPersonal && !isDeleted ? (
          <ClientProjectMatchInfo
            onSubmitMatch={onSubmitMatch}
            onMatchOverviewClick={onClientMatchOverviewClick}
            isUpdateMatchLoading={isUpdateMatchLoading}
            getMatchLink={getClientMatchOverviewLink}
            projectTitle={project.title}
            projectActivityType={project.projectActivityType}
          />
        ) : null}
        {isAdmin && !isDeleted ? (
          <AdminProjectMatchInfo
            project={project}
          />
        ) : null}
        {isExpert && expertMatch && !isDeleted ? (
          <ExpertProjectMatchInfo
            author={author}
            projectMatch={expertMatch}
          />
        ) : null}
      </div>
      {isExpert && expertMatch?.chat?.[0] ? (
        <ChatView
          isVisible={isChatVisible}
          onClose={toggleChat}
          onOpen={openChat}
          withPreview
          className={styles.chat}
          chatId={expertMatch.chat[0].id}
          chatHeader={(
            <MobileChatHeader
              title="Chat"
              newMessagesCount={0}
              isLoading={false}
              onClick={toggleChat}
              isChatVisible={isChatVisible}
              chatMembers={expertMatch.chat[0].participants}
            />
          )}
        />
      ) : null}
    </div>
  );
}
