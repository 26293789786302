import { Usecase, User } from '@raised/domain';
import {
  ButtonsModule,
  ColorsModule, DataModule, FeedbackModule, LayoutModule, TypographyModule,
} from '@raised/ui-kit';
import { IconCalendarStats, IconCloudStorm } from '@tabler/icons-react';
import AttachedFile from 'components/layout/components/attached-file/AttachedFile';
import { PROJECT_CATEGORIES } from 'components/project/constants/project-categories';
import { PROJECT_TYPES } from 'components/project/constants/project-types';
import ContentBlock from 'components/success-stories/pages/view-success-story/components/content-block/ContentBlock';
import ExpertInfo from 'components/success-stories/pages/view-success-story/components/expert-info/ExpertInfo';
import { useGetSuccessStoriesByUsecaseQuery } from 'components/success-stories/storage/successStoriesApi';
import { SUCCESS_STORY_ROUTES } from 'components/success-stories/success-story-routes';
import SuccessStories from 'components/user/public-expert-profile/components/success-stories/SuccessStories';
import styles from './ViewUsecaseModal.module.css';

const { Modal } = FeedbackModule;
const { Text, Title, Paragraph } = TypographyModule;
const { Tag } = DataModule;
const { Colors } = ColorsModule;
const { Divider } = LayoutModule;
const { PublicButton } = ButtonsModule;

interface ViewUsecaseModalProps {
  usecase?: Usecase.Public;
  expert?: User.Public;
  isVisible: boolean;
  onClose: () => void;
  onExpertPageClick: () => void;
}

export default function ViewUsecaseModal({
  usecase,
  isVisible,
  onClose,
  expert,
  onExpertPageClick,
}: ViewUsecaseModalProps) {
  const {
    data: successStories,
    isLoading: isSuccessStoriesLoading,
  } = useGetSuccessStoriesByUsecaseQuery({ id: usecase?.id || '' }, { skip: !usecase });

  const handleExpertClick = () => {
    onClose();
    onExpertPageClick();
  };

  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      className={styles.usecase_modal}
      footer={null}
      title={null}
      closeIcon={null}
      wrapClassName={styles.usecase_wrap}
    >
      <div className={styles.content}>
        <Text className={styles.caption}>Use Case</Text>
        <Title level={1} className={styles.title}>{usecase?.title}</Title>
        <div className={styles.tags}>
          {usecase?.category?.map((category) => <Tag key={category} className={styles.tag} label={PROJECT_CATEGORIES[category]} />)}
        </div>
        <div className={styles.type_block}>
          <IconCalendarStats size={15} color={Colors.gray} />
          <Text type="secondary">{usecase?.type?.map((t) => PROJECT_TYPES[t]).join(', ')}</Text>
        </div>
        <Paragraph className={styles.description}>{usecase?.description}</Paragraph>
        <div className={styles.attached_files}>
          <Text type="secondary" className={styles.files_label}>Attached File</Text>
          <div className={styles.files}>
            {usecase?.attachedFile?.map((f) => <AttachedFile key={f} fileName={f} />)}
          </div>
        </div>
        <Divider className={styles.divider} />
        <ContentBlock
          icon={<IconCloudStorm size={20} />}
          title="Challenge"
          content={usecase?.challenge || ''}
        />
        <SuccessStories
          isOpen
          isMoreStories={false}
          successStories={successStories?.slice(0, 3) || []}
          isLoading={isSuccessStoriesLoading}
          className={styles.success_stories}
          getSuccessStoryLink={(id) => SUCCESS_STORY_ROUTES.PUBLIC_SUCCESS_STORY(id)}
        />
        <ExpertInfo
          onExpertPageClick={handleExpertClick}
          expert={expert}
        />
      </div>
      <PublicButton className={styles.close_btn} onClick={onClose}>Close</PublicButton>
    </Modal>
  );
}
