import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { AUTH_ROUTES } from '../auth-routes';
import useResetPassword from '../hooks/useResetPassword';
import ResetPasswordComponent from './ResetPasswordComponent';

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const onSuccess = () => navigate(AUTH_ROUTES.LOGIN());

  const {
    resetPassword,
    isLoading,
  } = useResetPassword({
    onSuccess,
  });

  return (
    <>
      <Helmet>
        <title>Raised - Reset password</title>
      </Helmet>
      <ResetPasswordComponent isLoading={isLoading} onSubmit={resetPassword} />
    </>
  );
}
