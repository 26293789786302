import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import PublicFooter from './public-footer/PublicFooter';
import PublicHeader from './public-header/PublicHeader';
import styles from './PublicLayout.module.css';

const { Content } = Layout;

export default function PublicLayout() {
  return (
    <Layout className={styles.layout}>
      <PublicHeader />
      <Content className={styles.content}>
        <Outlet />
      </Content>
      <PublicFooter />
    </Layout>
  );
}
