import { Usecase, User } from '@raised/domain';
import { LayoutModule } from '@raised/ui-kit';
import ExpertInfo from './expert-info/ExpertInfo';
import styles from './ExpertCard.module.css';
import UseCases from './use-cases/UseCases';

const { Divider } = LayoutModule;

interface ExpertCardProps {
  expert?: User.Public;
  onUsecaseClick: (usecase: Usecase.Public) => void;
}

export default function ExpertCard({
  expert,
  onUsecaseClick,
}: ExpertCardProps) {
  if (!expert) return null;

  return (
    <section className={styles.expert_card}>
      <ExpertInfo expert={expert} />
      <Divider className={styles.divider} />
      <UseCases onUsecaseClick={onUsecaseClick} usecases={expert?.usecases || []} />
    </section>
  );
}
