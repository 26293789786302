import { Chat, User } from '@raised/domain';
import cn from 'classnames';
import { ThemeEnum } from 'components/layout/theme/themeConfig';
import { ThemeContext } from 'components/layout/theme/themeContext';
import useChatHeight from 'components/messages/hooks/useChatHeight';
import {
  DetailedHTMLProps, HTMLAttributes, ReactNode, useContext,
} from 'react';
import ChatInput from './chat-input/ChatInput';
import ChatMessages from './chat-messages/ChatMessages';
import styles from './ChatView.module.css';

interface ChatViewProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  personalProfileId?: string;
  onSendMessage: (text: string) => void;
  entryDate: string;
  uploadFile: (file: File) => void;
  isFileUploading: boolean;
  isFileLoaded: boolean;
  chat: Chat.Public;
  profile: User.Public;
  chatHeader?: ReactNode;
  onClose?: () => void;
  onInputFocus?: () => void;
  isMessagesVisible?: boolean;
}

export default function ChatView({
  personalProfileId,
  onSendMessage,
  entryDate,
  uploadFile,
  isFileUploading,
  isFileLoaded,
  className,
  chatHeader,
  chat,
  profile,
  onClose,
  onInputFocus,
  isMessagesVisible = true,
  ...rest
}: ChatViewProps) {
  const { themeName } = useContext(ThemeContext);
  const isDarkTheme = themeName === ThemeEnum.DARK;

  useChatHeight();

  return (
    <div
      {...rest}
      className={cn(styles.container, className, {
        [styles.dark_container]: isDarkTheme,
      })}
    >
      <div className={styles.header_container}>
        {chatHeader}
      </div>
      <div className={styles.content}>
        <ChatMessages
          personalProfileId={personalProfileId}
          chat={chat}
          entryDate={entryDate}
          isVisible={isMessagesVisible}
        />
        <ChatInput
          onSendMessage={onSendMessage}
          uploadFile={uploadFile}
          isFileUploading={isFileUploading}
          isFileLoaded={isFileLoaded}
          onInputFocus={onInputFocus}
        />
      </div>
    </div>
  );
}
