import { User } from '@raised/domain';
import { TypographyModule } from '@raised/ui-kit';
import styles from './ProposalSent.module.css';

const { Title, Text } = TypographyModule;

interface ProposalSentProps {
  expert: User.Public;
  client: User.Public;
}

export default function ProposalSent({
  expert,
  client,
}: ProposalSentProps) {
  return (
    <div className={styles.container}>
      <Title level={4}>{`${expert.firstName} ${expert.lastName} made a proposal`}</Title>
      <Text>{`${client.firstName} ${client.lastName}, please accept or decline a proposal`}</Text>
    </div>
  );
}
