import { Project, User } from '@raised/domain';
import { DataModule, TypographyModule } from '@raised/ui-kit';
import { PROJECT_CATEGORIES_VALUES } from 'components/project/constants/project-categories';
import UserAvatar from 'components/user/components/UserAvatar';
import useExpertTags, { TagGroups } from 'components/user/hooks/useExpertTags';
import React from 'react';
import styles from './ExpertInfo.module.css';

const { Title, Text, Paragraph } = TypographyModule;
const { Tag, IconTag } = DataModule;

interface ExpertInfoProps {
  expert: User.Public;
}

export default function ExpertInfo({
  expert,
}: ExpertInfoProps) {
  const { icons, groupTags } = useExpertTags();
  const groupedTags = groupTags(expert.tags || {});

  return (
    <div className={styles.expert_info}>
      <div className={styles.avatar_block}>
        <UserAvatar
          size={200}
          filePath={expert.avatar}
          role={expert.role}
          style={{ flexShrink: 0 }}
          className={styles.avatar}
        />
        <Title level={1} className={styles.mobile}>{expert.headline}</Title>
      </div>
      <div className={styles.content}>
        <Title className={styles.desktop} level={1}>{expert.headline}</Title>
        <Paragraph>{expert.biography}</Paragraph>

        <div className={styles.tags}>
          {Object.entries(groupedTags).map(([tagKey, tagsObject]) => (
            <React.Fragment key={tagKey}>
              {Object.values(tagsObject).length ? (
                <div className={styles.tags_group}>
                  <div className={styles.tags_group__title}>
                    <IconTag icon={icons[tagKey as TagGroups].icon} color={icons[tagKey as TagGroups].color} />
                    <Text strong>{icons[tagKey as TagGroups].label}</Text>
                  </div>
                  <div className={styles.tags_group__tags}>
                    {Object.values(tagsObject).map((tags) => (
                      <>
                        {tagKey === 'function' && tags.filter((tag) => Object.keys(PROJECT_CATEGORIES_VALUES).includes(tag)).map((tag) => (
                          <Tag className={styles.tag} key={tag} label={PROJECT_CATEGORIES_VALUES[tag as keyof Project.Categories]?.label || ''} />
                        ))}
                        {tagKey !== 'function' && tags.map((tag) => (
                          <Tag className={styles.tag} key={tag} label={tag} />
                        ))}
                      </>
                    ))}
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          ))}
          {Object.values(expert.projectCategories || {}).length ? (
            <div className={styles.tags_group}>
              <div className={styles.tags_group__title}>
                <IconTag icon={icons.projectCategories.icon} color={icons.projectCategories.color} />
                <Text strong>{icons.projectCategories.label}</Text>
              </div>
              <div className={styles.tags_group__tags}>
                {Object.values(expert.projectCategories || {}).map((values) => (
                  <>
                    {values?.map((value) => (
                      <Tag key={value} label={value} className={styles.tag} />
                    ))}
                  </>
                ))}
              </div>
            </div>
          ) : null}

        </div>

      </div>
    </div>
  );
}
