import { User } from '@raised/domain';
import { DataModule, TypographyModule } from '@raised/ui-kit';
import { IconHourglassLow } from '@tabler/icons-react';
import styles from './ExpertRoles.module.css';
import { getCompanyName, getLabel } from './utils';

const { Title } = TypographyModule;
const { WorkTimeline } = DataModule;

interface ExpertPartimeRolesProps {
  parttimeRoles: User.ParttimeRole[];
}

export default function ExpertParttimeRoles({
  parttimeRoles,
}: ExpertPartimeRolesProps) {
  const items = parttimeRoles.map((role) => ({
    label: getLabel(role.from, role.to),
    companyName: getCompanyName(role.name, role.position),
    position: role.info,
    challenge: role.challenge,
    action: role.action,
    result: role.result,
    completedOnRaised: role.completedOnRaised,
  }));

  return (
    <div className={styles.container}>
      <div className={styles.title_block}>
        <IconHourglassLow size={18} color="#000" />
        <Title level={4}>Part time roles</Title>
      </div>
      <WorkTimeline items={items} />
    </div>
  );
}
