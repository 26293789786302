import {
  ColorsModule, DataModule, FeedbackModule, TypographyModule,
} from '@raised/ui-kit';
import { IconSpeakerphone } from '@tabler/icons-react';
import { ReactNode } from 'react';
import styles from './ConfirmMatchContainer.module.css';

const { Title, Text } = TypographyModule;
const { Spin } = FeedbackModule;
const { Empty } = DataModule;
const { Colors } = ColorsModule;

interface ConfirmMatchContainerProps {
  children: ReactNode;
  invitationCount: number;
  loading: boolean;
}

export default function ConfirmMatchContainer({
  children,
  invitationCount,
  loading,
}: ConfirmMatchContainerProps) {
  return (
    <div className={styles.container}>
      <div className={styles.top_content}>
        <div className={styles.title_block}>
          <Title level={1}>Project invitations</Title>
          <Title level={1} type="secondary">
            {loading ? (<Spin size="small" />) : invitationCount}
          </Title>
        </div>
        {/* <TimezoneMessage /> */}
      </div>
      <div className={styles.content}>
        {!loading && !invitationCount ? (
          <Empty
            height={300}
            style={{ gridColumn: 'span 2' }}
            icon={<IconSpeakerphone size={80} stroke={0.6} strokeOpacity={0.5} color={Colors.gray} />}
          >
            <Text type="secondary">You don’t have Invitations yet</Text>
          </Empty>
        ) : null}
        {loading ? (
          <Spin />
        ) : null}
        {!loading && invitationCount ? children : null}
      </div>
    </div>
  );
}
