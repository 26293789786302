import { ReactNode } from 'react';
import styles from './NotificationContent.module.css';

interface NotificationContentProps {
  header: ReactNode;
  children: ReactNode;
}

export default function NotificationContent({
  header,
  children,
}: NotificationContentProps) {
  return (
    <div className={styles.container}>
      {header}
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}
