import { SuccessStory } from '@raised/domain';
import { FeedbackModule } from '@raised/ui-kit';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import useUploadFile from 'components/files/hooks/useUploadFIle';
import { useCreateSuccessStoryMutation } from '../storage/successStoriesApi';

const { Message } = FeedbackModule;

interface UseCreateSuccessStoryProps {
  onSuccess?: (story: SuccessStory.Public) => void;
}

export default function useCreateSuccessStory({
  onSuccess,
}: UseCreateSuccessStoryProps) {
  const [mutate, info] = useCreateSuccessStoryMutation();
  const {
    isSuccess, isError, error, data,
  } = info;
  const { uploadFile, isLoading: isFilesLoading } = useUploadFile();

  const handleUploadFile = async (file: SuccessStory.CreateFileInfo): Promise<SuccessStory.FileInfo> => {
    let link = typeof file.filePath === 'string' ? file.filePath : undefined;

    if (file.filePath instanceof File) {
      link = await uploadFile(file.filePath);
    }

    return {
      filePath: link || '',
      description: file.description,
    };
  };

  const createSuccessStory = async (values: SuccessStory.CreateForm) => {
    const { files, ...rest } = values;
    let uploadedFiles: SuccessStory.Create['files'];

    if (values.files?.length) {
      uploadedFiles = await Promise.all(values.files.map((f) => handleUploadFile(f)));
    }

    mutate({
      ...rest,
      files: uploadedFiles,
    });
  };

  useEffect(() => {
    if (!isSuccess) return;

    Message.success('Success Story successfully created');
    if (onSuccess) {
      onSuccess(data);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;

    Message.error(getErrorMessage(error));
  }, [isError]);

  return {
    ...info,
    createSuccessStory,
    isLoading: info.isLoading || isFilesLoading,
  };
}
