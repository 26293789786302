import { IconDots } from '@tabler/icons-react';
import { Popover, PopoverProps } from 'antd';
import { Button } from 'src/ui-kit/buttons';
import cn from 'classnames';
import { useState } from 'react';
import { ActionItem } from './types';
import ActionsContent from './ActionsContent';
import styles from './Actions.module.css';

interface ActionsProps extends Omit<PopoverProps, 'content'> {
  items: ActionItem[];
  loading?: boolean;
}

export default function Actions({
  items,
  loading,
  className,
  ...rest
}: ActionsProps) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Popover
      arrow={false}
      placement="bottomRight"
      content={<ActionsContent onClose={() => setIsVisible(false)} loading={loading} items={items} />}
      className={cn({
        [styles.actions]: true,
        [styles.actions_active]: isVisible,
      }, className)}
      {...rest}
      open={isVisible}
      onOpenChange={(isVis) => {
        setIsVisible(isVis);
        rest.onOpenChange?.(isVis);
      }}
      overlayClassName={styles.overlay}
    >
      <Button type="link"><IconDots size={24} /></Button>
    </Popover>
  );
}
