import { Input, Modal } from 'antd';
import { ChangeEvent, useState } from 'react';

interface MessageModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (message: string) => void;
  title: string;
}

export default function MessageModal({
  isVisible,
  onClose,
  onSubmit,
  title,
}: MessageModalProps) {
  const [message, setMessage] = useState('');

  const handleClose = () => {
    setMessage('');
    onClose();
  };
  const onChangeMessage = (e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value);
  const handleSubmit = () => {
    onSubmit(message);
    handleClose();
  };

  return (
    <Modal
      centered
      open={isVisible}
      title={title}
      onCancel={() => {
        handleClose();
      }}
      onOk={() => {
        handleSubmit();
      }}
    >
      <Input.TextArea placeholder="Message" value={message} onChange={onChangeMessage} />
    </Modal>
  );
}
