import { Typography } from 'antd';
import { ReactNode } from 'react';

interface AdminCreatePaymentsComponentProps {
  children: ReactNode;
}

export default function AdminCreatePaymentsComponent({
  children,
}: AdminCreatePaymentsComponentProps) {
  return (
    <div>
      <Typography.Title level={2}>Create payment</Typography.Title>
      {children}
    </div>
  );
}
