import { Project } from '@raised/domain';
import { useState } from 'react';

export default function useSelectProject() {
  const [selectedProjects, setSelectedProjects] = useState<Project.Public[]>([]);

  const projectClick = (project: Project.Public) => {
    if (selectedProjects.find((proj) => proj.id === project.id)) {
      setSelectedProjects((proj) => proj.filter((p) => p.id !== project.id));
    } else {
      setSelectedProjects((proj) => [...proj, project]);
    }
  };
  const resetProjects = () => setSelectedProjects([]);

  return {
    selectedProjects,
    projectClick,
    resetProjects,
  };
}
