import { User } from '@raised/domain';
import { useDeferredValue, useState } from 'react';
import { EXPERT_TAGS } from '../constants/expert-tags';
import { useGetExpertsQuery } from '../storage/userApi';

export type ExpertAdditionalFilters = {
  activated: string | null;
  public: string | null;
  activityType: string | null;
  publishedOnPublicWebsites: string | null;
  search: string;
  page: number;
  perPage: number;
};
export type ExpertFilters = Required<User.Tags> & ExpertAdditionalFilters;

const getDefaultFilters = () => Object.keys(EXPERT_TAGS).reduce((acc: ExpertFilters, item) => {
  acc[item as keyof User.Tags] = [];

  return acc;
}, {
  activated: null, public: null, search: '', activityType: null, publishedOnPublicWebsites: null, page: 1, perPage: 10,
} as ExpertFilters);

export default function useGetExperts() {
  const [filters, setFilters] = useState(getDefaultFilters);
  const deferredFilters = useDeferredValue(filters);
  const data = useGetExpertsQuery(deferredFilters);

  const changeFilter = <T extends keyof ExpertFilters>(field: T, value: ExpertFilters[T]) => {
    setFilters((state) => ({ ...state, [field]: value }));
  };
  const resetFilters = () => setFilters(getDefaultFilters);

  return {
    ...data,
    data: data.data?.results,
    total: data.data?.total,
    changeFilter,
    resetFilters,
    filters,
  };
}
