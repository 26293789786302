import { USER_ROUTES } from 'components/user/user-routes';
import { Helmet } from 'react-helmet';
import { PROJECT_ROUTES } from '../project-routes';
import { useGetDeletedProjectsQuery } from '../storage/projectApi';
import DeletedProjectsTable from './deleted-projects-table/DeletedProjectsTable';
import DeletedProjectsComponent from './DeletedProjectsComponent';

export default function DeletedProjectsPage() {
  const {
    data: projects,
    isLoading: isProjectsLoading,
    isFetching: isProjectsFetching,
  } = useGetDeletedProjectsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const getProjectLink = (projectId: string) => PROJECT_ROUTES.PROJECT(projectId);
  const getUserLink = (userId: string) => USER_ROUTES.USER_PROFILE(userId);

  return (
    <DeletedProjectsComponent>
      <Helmet>
        <title>Raised - Deleted projects</title>
      </Helmet>
      <DeletedProjectsTable
        isLoading={isProjectsLoading || isProjectsFetching}
        deletedProjects={projects}
        getProjectLink={getProjectLink}
        getUserLink={getUserLink}
      />
    </DeletedProjectsComponent>
  );
}
