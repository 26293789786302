import { Project, User } from '@raised/domain';
import {
  ButtonsModule, ColorsModule, FeedbackModule, TypographyModule,
} from '@raised/ui-kit';
import { IconFileBroken } from '@tabler/icons-react';
import UserAvatar from 'components/user/components/UserAvatar';
import ProjectItem from './project-item/ProjectItem';
import styles from './SelectProjectStep.module.css';

const { Button } = ButtonsModule;
const { Colors } = ColorsModule;
const { Title, Text } = TypographyModule;
const { Spin } = FeedbackModule;

interface SelectProjecStepProps {
  projects: Project.Public[];
  isLoading: boolean;
  onProjectClick: (project: Project.Public) => void;
  selectedProjects: Project.Public[];
  onCreateProjectClick: () => void;
  selectedExpert?: User.Public;
}

export default function SelectProjecStep({
  projects,
  isLoading,
  onProjectClick,
  selectedProjects,
  onCreateProjectClick,
  selectedExpert,
}: SelectProjecStepProps) {
  const getIsDisabled = (project: Project.Public) => (project.matches?.length ? project.matches.some((match) => match.userId === selectedExpert?.id) : false);

  return (
    <div className={styles.select_project_step}>
      <div className={styles.title_block}>
        <Title level={2}>Your Projects</Title>
        <Button onClick={onCreateProjectClick} type="primary">Create Project</Button>
      </div>

      <div className={styles.expert_block}>
        <Text caption>Chosen Expert</Text>
        <div className={styles.expert_details}>
          <UserAvatar role={selectedExpert?.role} filePath={selectedExpert?.avatar} size="s" />
          <div className={styles.expert_info}>
            <Text className={styles.name}>{`${selectedExpert?.firstName} ${selectedExpert?.lastName}`}</Text>
            <Text className={styles.work}>{selectedExpert?.headline}</Text>
          </div>
        </div>
      </div>

      <div className={styles.projects}>
        {isLoading ? (
          <Spin />
        ) : (
          <>
            {projects.length ? (
              <>
                {projects.map((project) => (
                  <ProjectItem
                    onClick={() => onProjectClick(project)}
                    isChecked={Boolean(selectedProjects.find((proj) => proj.id === project.id))}
                    key={project.id}
                    project={project}
                    isDisabled={getIsDisabled(project)}
                  />
                ))}
              </>
            ) : (
              <div className={styles.empty_projects}>
                <IconFileBroken size={80} color={Colors.gray} stroke={1} />
                <Text className={styles.empty_text}>You don’t have any Projects yet</Text>
                <Button onClick={onCreateProjectClick} type="primary">Create Project</Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
