import { FormsModule } from '@raised/ui-kit';
import { useGetOrganisationByIdQuery } from 'components/organisation/storage/organisationApi';
import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';

const { Select } = FormsModule;

interface AdminUserSelectorProps {
  control: Control;
  organisationId?: string;
}

export default function AdminUserSelector({
  control,
  organisationId = '',
}: AdminUserSelectorProps) {
  const { field } = useController({
    control,
    name: 'clientId',
  });
  const { data: organisation, isLoading, isFetching } = useGetOrganisationByIdQuery({ id: organisationId }, { skip: !organisationId });

  const selectOptions = useMemo(() => organisation?.users.map((client) => ({
    value: client.id,
    label: `${client.firstName} ${client.lastName}`,
  })), [organisation]);

  return (
    <Select
      label="Select the user for whom to create the project"
      style={{ width: '100%' }}
      disabled={!organisationId}
      loading={isLoading || isFetching}
      value={field.value}
      onChange={field.onChange}
      placeholder="Select the user"
      options={selectOptions}
    />
  );
}
