import AdminRouteLayout from 'components/layout/containers/admin-route/AdminRouteLayout';
import MainLayout from 'components/layout/containers/main-layout/MainLayout';
import ProtectedRouteLayout from 'components/layout/containers/protected-route-layout/ProtectedRouteLayout';
import { RouteObject } from 'react-router-dom';
import CreateTemplatePage from './pages/create-template/CreateTemplatePage';
import EditTemplatePage from './pages/edit-template/EditTemplatePage';
import TemplatePage from './pages/template-page/TemplatePage';
import TemplatesPage from './pages/templates-page/TemplatesPage';

export const TEMPLATES_ROUTES = {
  CREATE: () => '/template/create',
  EDIT: (id: string) => `/template/${id}/edit`,
  TEMPLATES: () => '/templates',
  TEMPLATE: (id: string) => `/template/${id}`,
};

const templatesRoutes: Array<RouteObject> = [
  {
    element: <MainLayout />,
    children: [
      {
        element: <AdminRouteLayout />,
        children: [
          {
            path: TEMPLATES_ROUTES.CREATE(),
            element: <CreateTemplatePage />,
          },
          {
            path: TEMPLATES_ROUTES.EDIT(':id'),
            element: <EditTemplatePage />,
          },
        ],
      },
      {
        element: <ProtectedRouteLayout />,
        children: [
          {
            path: TEMPLATES_ROUTES.TEMPLATES(),
            element: <TemplatesPage />,
          },
          {
            path: TEMPLATES_ROUTES.TEMPLATE(':id'),
            element: <TemplatePage />,
          },
        ],
      },
    ],
  },
];

export default templatesRoutes;
