import { Tabs as AntTabs, TabsProps as AntTabsProps } from 'antd';
import cn from 'classnames';
import styles from './Tabs.module.css';

interface TabsProps extends AntTabsProps {}

export default function Tabs({
  className,
  ...props
}: TabsProps) {
  return (
    <AntTabs {...props} className={cn(styles.tabs, className)} />
  );
}
