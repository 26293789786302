import { Project, User } from '@raised/domain';
import { FormsModule, TypographyModule } from '@raised/ui-kit';
import UserAvatar from 'components/user/components/UserAvatar';
import styles from './ConfirmStep.module.css';

const { Title, Text } = TypographyModule;
const { TextArea } = FormsModule;

interface ConfirmStepProps {
  selectedProjects: Project.Public[];
  message: string;
  onChangeMessage: (msg: string) => void;
  selectedExpert?: User.Public;
}

export default function ConfirmStep({
  selectedProjects,
  message,
  onChangeMessage,
  selectedExpert,
}: ConfirmStepProps) {
  return (
    <div className={styles.confirm_step}>
      <div className={styles.content}>
        <Title level={2} className={styles.title}>Invitation</Title>

        <div className={styles.project_block}>
          <Text caption>Project</Text>
          {selectedProjects.map((project) => <Text key={project.id} strong>{project.title}</Text>)}
        </div>

        <div className={styles.expert_block}>
          <Text caption>Chosen Expert</Text>
          <div className={styles.expert_details}>
            <UserAvatar role={selectedExpert?.role} filePath={selectedExpert?.avatar} size="s" />
            <div className={styles.expert_info}>
              <Text className={styles.name}>{`${selectedExpert?.firstName} ${selectedExpert?.lastName}`}</Text>
              <Text className={styles.work}>{`${selectedExpert?.headline}`}</Text>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.comment_block}>
        <TextArea
          value={message}
          onChange={(e) => onChangeMessage(e.target.value)}
          label="Please share why you want to proceed with this expert (optional)"
          rows={5}
          placeholder="Message"
        />
      </div>
    </div>
  );
}
