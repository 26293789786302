import { TypographyModule } from '@raised/ui-kit';
import { Space } from 'antd';
import { Control } from 'react-hook-form';
import { renderFormConfig } from 'utils/forms';
import { getExpertTagsFormConfig } from './expertTagsFormConfig';

const { Title, Text } = TypographyModule;

interface ExpertTagsProps {
  control: Control;
}

export default function ExpertTags({
  control,
}: ExpertTagsProps) {
  const formConfig = getExpertTagsFormConfig(control);

  return (
    <Space size={16} direction="vertical" style={{ marginBottom: 40 }}>
      <Title level={2} style={{ margin: 0 }}>Professional Areas and Skills</Title>
      <Space size={0} direction="vertical">
        <Title level={3} style={{ margin: 0 }}>Tags</Title>
        <Text type="secondary">Select Tags to Help Us Find the Most Relevant Projects for You</Text>
      </Space>
      {renderFormConfig(formConfig)}
    </Space>
  );
}
