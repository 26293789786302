import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useSetPaymentAsPaidMutation } from '../storage/paymentsApi';

export default function useSetProposalAsPaid() {
  const [setAsPaid, data] = useSetPaymentAsPaidMutation();
  const {
    isError,
    error,
    isSuccess,
  } = data;

  useEffect(() => {
    if (!isError) return;

    message.error(getErrorMessage(error));
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;

    message.success('Proposal successfully paid');
  }, [isSuccess]);

  return {
    setAsPaid,
    ...data,
  };
}
