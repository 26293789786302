/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import useUploadFile from 'components/files/hooks/useUploadFIle';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import {
  DetailedHTMLProps, HTMLAttributes, ReactNode, useState,
} from 'react';
import cn from 'classnames';
import ChatComponent from '../../chat/components/chat-view/ChatView';
import useCreateMessage from '../../hooks/useCreateMessage';
import { useGetChatByIdQuery } from '../../storage/messageApi';
import styles from './ChatView.module.css';
import ChatLoading from './ChatLoading';

interface ChatViewProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  chatId: string;
  isVisible?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  chatHeader?: ReactNode;
  withPreview?: boolean;
}

export default function ChatView({
  chatId: id,
  isVisible = true,
  onClose,
  onOpen,
  className,
  chatHeader,
  withPreview = false,
  ...rest
}: ChatViewProps) {
  const [entryDate] = useState(() => new Date().toISOString());
  const { data: profile, isSuccess: isProfileSuccess, isLoading: isProfileLoading } = useGetProfileQuery();
  const { data: chat, isSuccess: isChatSuccess, isLoading: isChatLoading } = useGetChatByIdQuery({ chatId: id as string }, { skip: !id });
  const {
    uploadFile, uploadedFilePath, isLoading: isFileUploading, resetState,
  } = useUploadFile();

  const { createMessage } = useCreateMessage();

  const onSendMessage = (text: string) => {
    if (!profile) return;

    createMessage({
      text, chatId: id, fromDate: entryDate, fromId: profile.id, fileUrl: uploadedFilePath,
    }).then(() => {
      resetState();
    });
  };

  const onFocus = () => {
    if (!isVisible && onOpen) {
      onOpen();
    }
  };

  return (
    <>
      <div
        onClick={onClose}
        className={cn(styles.overlay, {
          [styles.overlay_visible]: isVisible,
        })}
      />
      {isProfileSuccess && isChatSuccess ? (
        <ChatComponent
          personalProfileId={profile?.id}
          onSendMessage={onSendMessage}
          entryDate={entryDate}
          uploadFile={uploadFile}
          isFileUploading={isFileUploading}
          isFileLoaded={!!uploadedFilePath}
          chat={chat}
          profile={profile}
          className={cn(className, styles.chat, {
            [styles.chat_hidden]: !isVisible && !withPreview,
            [styles.chat_preview]: withPreview && !isVisible,
          })}
          onClose={onClose}
          chatHeader={chatHeader}
          isMessagesVisible={withPreview ? isVisible : true}
          onInputFocus={onFocus}
          {...rest}
        />
      ) : null}
      {isProfileLoading || isChatLoading ? (
        <ChatLoading />
      ) : null}
    </>
  );
}
