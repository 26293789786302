import Table from 'components/layout/components/table/Table';
import { useGetExpertMatchesQuery } from 'components/match/storage/matchApi';
import ViewProposalsModal from 'components/proposal/components/ViewProposalsModal';
import { useMemo, useState } from 'react';
import { getExpertProjectsTableConfig } from './expertProjectsTableConfig';

interface ExpertProjectsListProps {
  userId: string;
  getProjectLink: (projectId: string) => string;
  getOrganizationLink: (orgId: string) => string;
}

export default function ExpertProjectsList({
  userId,
  getProjectLink,
  getOrganizationLink,
}: ExpertProjectsListProps) {
  const [viewProposals, setViewProposals] = useState('');

  const onViewProposalsClick = (matchId: string) => setViewProposals(matchId);
  const onCloseModal = () => setViewProposals('');

  const {
    data: matches,
    isLoading: isMatchesLoading,
  } = useGetExpertMatchesQuery({ expertId: userId });

  const tableConfig = useMemo(() => getExpertProjectsTableConfig({
    onViewProposalsClick,
  }), []);
  const tableData = useMemo(() => matches?.map((match) => ({
    key: match.id,
    projectTitle: match.project?.title || '',
    status: match.status,
    organizationLink: getOrganizationLink(match.project?.organisationId || ''),
    organizationName: match.project?.organisation?.companyName || '',
    isProposals: !!match.proposal?.length,
    projectLink: getProjectLink(match.projectId),
  })), [matches]);

  return (
    <>
      <Table
        tableName="expertProjects"
        dataSource={tableData}
        columns={tableConfig}
        loading={isMatchesLoading}
      />
      <ViewProposalsModal
        isVisible={!!viewProposals}
        onClose={onCloseModal}
        matchId={viewProposals}
      />
    </>
  );
}
