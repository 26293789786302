import cn from 'classnames';
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import { Space, Typography } from 'antd';
import { InputWrapperProps as WrapperProps } from './types';
import styles from './InputWrapper.module.css';

interface InputWrapperProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, WrapperProps {
  children: ReactNode;
}

export default function InputWrapper({
  children,
  label,
  subLabel,
  className,
  ...rest
}: InputWrapperProps) {
  return (
    <div className={cn(styles.container, className)} {...rest}>
      {label || subLabel ? (
        <Space size={0} direction="vertical" className={styles.labels}>
          {label ? (
            <Typography.Text className={styles.label}>{label}</Typography.Text>
          ) : null}
          {subLabel ? (
            <Typography.Text className={styles.subLabel} type="secondary">{subLabel}</Typography.Text>
          ) : null}
        </Space>
      ) : null}
      {children}
    </div>
  );
}
