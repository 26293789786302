import { TypographyModule } from '@raised/ui-kit';
import { IconMoodDollar } from '@tabler/icons-react';
import styles from './ProposalPaid.module.css';

const { Title } = TypographyModule;

export default function ProposalPaid() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <IconMoodDollar size={32} stroke={1.5} />
        <Title level={5}>Proposal is paid</Title>
      </div>
    </div>
  );
}
