enum LogMessageEvents {
  USER_CREATE_ACCOUNT = 'USER_CREATE_ACCOUNT',
  MATCH_CREATED = 'MATCH_CREATED',
  MATCH_STATUS_CHANGED = 'MATCH_STATUS_CHANGED',
  MATCH_RESTORE = 'MATCH_RESTORE',
  PROPOSAL_SENT = 'PROPOSAL_SENT',
  PROPOSAL_ACCEPTED = 'PROPOSAL_ACCEPTED',
  PROPOSAL_DECLINED = 'PROPOSAL_DECLINED',
  PROPOSAL_PAID = 'PROPOSAL_PAID',
  GHOST_ACTIVATE_ACCOUNT = 'GHOST_ACTIVATE_ACCOUNT',
  FEEDBACK = 'FEEDBACK',
  ADMIN_CREATE_PROJECT = 'ADMIN_CREATE_PROJECT',
  PROJECT_STATUS_CHANGED = 'PROJECT_STATUS_CHANGED',
  GHOST_CREATED = 'GHOST_CREATED',
}

export default LogMessageEvents
