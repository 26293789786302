import { rootApi } from 'api/rootApi';
import type { Common, User } from '@raised/domain';
import { GetExpertsParamsType } from './types';
import { ExpertFilters } from '../hooks/useGetExperts';

const getExpertsFilterParams = (params: Partial<ExpertFilters>) => {
  const query = new URLSearchParams();

  Object.entries(params).forEach(([key, values]) => {
    if (Array.isArray(values)) {
      values.forEach((value: string) => {
        query.append(key, value);
      });
    } else if ((typeof values === 'string' || typeof values === 'number') && values) {
      query.append(key, values.toString());
    }
  });

  return query.toString();
};

export const userApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query<User.Public, void>({
      providesTags: (res) => (res ? [{ type: 'USER', id: res.id } as const] : ['USER']),
      query: () => ({
        method: 'GET',
        url: '/user/profile',
      }),
    }),
    editProfile: build.mutation<User.Public, User.Edit>({
      invalidatesTags: ['USER'],
      query: (body) => ({
        url: '/user',
        method: 'PATCH',
        body,
      }),
    }),
    editProfileById: build.mutation<User.Public, User.Edit & { id: string, comment?: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'USER', id: args.id }],
      query: ({ id, ...body }) => ({
        url: `/user/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    createUser: build.mutation<User.Public, Common.WithRecaptcha<User.Create>>({
      query: (body) => ({
        method: 'POST',
        url: '/user',
        body,
      }),
    }),
    createGhost: build.mutation<User.Public, User.CreateGhost>({
      query: (body) => ({
        method: 'POST',
        url: '/user/ghost',
        body,
      }),
    }),
    activateGhost: build.mutation<User.Public, User.ActivateGhost>({
      query: (body) => ({
        method: 'PUT',
        url: '/user/activate',
        body,
      }),
    }),
    getExperts: build.query<{ results: User.Public[], total: number }, GetExpertsParamsType | void>({
      providesTags: (res) => (res ? [...res.results.map(({ id }) => ({ type: 'USER', id }) as const), 'USER'] : ['USER']),
      query: (params) => ({
        url: `/user/experts?${getExpertsFilterParams(params || {})}`,
      }),
    }),
    getClients: build.query<User.Public[], void>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'USER', id }) as const)] : ['USER']),
      query: () => ({
        url: '/user/clients',
      }),
    }),
    getUserById: build.query<User.Public, { id: string }>({
      providesTags: (_r, _e, { id }) => [{ type: 'USER', id }],
      query: ({ id }) => ({
        url: `/user/${id}`,
      }),
    }),
    getAllUsers: build.query<User.Public[], void>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'USER', id }) as const)] : ['USER']),
      query: () => ({
        url: '/user',
      }),
    }),
    getPublicExpertProfile: build.query<User.Public, { id: string }>({
      providesTags: (_r, _e, { id }) => [{ type: 'PUBLIC_USER', id }],
      query: ({ id }) => ({
        url: `/user/public-expert/${id}`,
      }),
    }),
    removeFromOrganisation: build.mutation<User.Public, { organisationId: string, userId: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'USER', id: args.userId }, { type: 'ORGANISATION', id: args.organisationId }],
      query: ({ organisationId, userId }) => ({
        url: `/user/${userId}/remove-from-organisation`,
        method: 'PATCH',
        body: {
          organisationId,
        },
      }),
    }),
    joinOrganisation: build.mutation<User.Public, Common.WithRecaptcha<User.JoinOrganisation>>({
      invalidatesTags: (_r, _e, args) => ['USER', { type: 'ORGANISATION', id: args.organisationId }],
      query: (body) => ({
        url: '/user/join-organisation',
        body,
        method: 'POST',
      }),
    }),
    inviteToOrganisation: build.mutation<void, { organisationId: string, email: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'ORGANISATION', id: args.organisationId }],
      query: ({ organisationId, email }) => ({
        url: `/user/${organisationId}/invite`,
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
    changeOrganisation: build.mutation<User.Public, User.ChangeOrganisation>({
      invalidatesTags: (res, _e, args) => (res ? [{ type: 'USER', id: res.id }, { type: 'ORGANISATION', id: args.organisationId }] : [{ type: 'ORGANISATION', id: args.organisationId }]),
      query: (body) => ({
        url: '/user/change-organisation',
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useCreateUserMutation,
  useGetExpertsQuery,
  useGetUserByIdQuery,
  useGetClientsQuery,
  useCreateGhostMutation,
  useActivateGhostMutation,
  useEditProfileMutation,
  useGetAllUsersQuery,
  useEditProfileByIdMutation,
  useRemoveFromOrganisationMutation,
  useJoinOrganisationMutation,
  useInviteToOrganisationMutation,
  useChangeOrganisationMutation,
  useGetPublicExpertProfileQuery,
} = userApi;
