import { ButtonsModule, FeedbackModule } from '@raised/ui-kit';
import { useNavigate } from 'react-router-dom';
import styles from './Error500Page.module.css';

const { Result } = FeedbackModule;
const { Button } = ButtonsModule;

export default function Error500Page() {
  const navigate = useNavigate();

  const onBackClick = () => navigate('/');

  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      className={styles.result}
      extra={(
        <Button type="primary" onClick={onBackClick}>Back Home</Button>
      )}
    />
  );
}
