import Table from 'components/layout/components/table/Table';
import { useGetUserProjectsQuery } from 'components/project/storage/projectApi';
import { useMemo } from 'react';
import { getUserProjectsTableConfig } from './userProjectsTableConfig';

interface UserProjectsListProps {
  userId: string;
  getProjectLink: (projectId: string) => string;
}

export default function UserProjectsList({
  userId,
  getProjectLink,
}: UserProjectsListProps) {
  const {
    data: projects,
    isLoading: isProjectsLoading,
  } = useGetUserProjectsQuery({ userId });

  const tableData = useMemo(() => (projects || []).map((project) => ({
    key: project.id,
    title: project.title,
    description: project.description,
    createdAt: `${project.createdAt}`,
    projectLink: getProjectLink(project.id),
  })), [projects]);
  const columns = useMemo(() => getUserProjectsTableConfig(), []);

  return (
    <Table
      tableName="profileProjectsList"
      columns={columns}
      dataSource={tableData}
      loading={isProjectsLoading}
    />
  );
}
