import cn from 'classnames';
import {
  ColorsModule, DataModule, LayoutModule, TypographyModule,
} from '@raised/ui-kit';
import { IconSpeakerphone } from '@tabler/icons-react';
import { NotificationLinkType } from 'components/notifications/hooks/useNotificationLinks';
import { ReactNode } from 'react';
import styles from './NotificationTabs.module.css';

const { Colors } = ColorsModule;
const { Empty } = DataModule;
const { Tabs } = LayoutModule;
const { Text } = TypographyModule;

interface MobileNotificationTabsProps {
  links: Array<NotificationLinkType>;
  onTabChange: (value: NotificationLinkType['value']) => void;
  listContent: ReactNode;
}

export default function MobileNotificationTabs({
  links,
  onTabChange,
  listContent,
}: MobileNotificationTabsProps) {
  const tabs = links.map((link) => ({
    label: link.title,
    key: link.value,
    children: listContent,
  }));

  return (
    <div className={cn(styles.container, styles.mobile_container)}>
      {links.length ? (
        <Tabs
          onChange={(tab) => onTabChange(tab)}
          className={styles.mobile_tabs}
          items={tabs}
        />
      ) : (
        <Empty
          height="100%"
          style={{ gridColumn: 'span 2' }}
          icon={<IconSpeakerphone size={80} stroke={0.6} strokeOpacity={0.5} color={Colors.gray} />}
        >
          <Text type="secondary">You don’t have Notifications yet</Text>
        </Empty>
      )}
    </div>
  );
}
