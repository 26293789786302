import { Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/formats';

export interface AssignProjectsTableRow {
  key: string;
  createdAt: string;
  title: string;
  description: string;
  clientId: string;
  clientName: string;
  isGhost: boolean;
  rowLink: string;
  userLink: string;
  organization: string;
  organizationLink?: string;
}

export const getAssignProjectsTableConfig = (): ColumnsType<AssignProjectsTableRow> => [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: (a, b) => a.title.length - b.title.length,
    render: (value, record) => <Link to={record.rowLink}>{value}</Link>,
  },
  {
    title: 'Client',
    dataIndex: 'clientId',
    key: 'clientId',
    render: (_v, record) => (
      <Space size={4}>
        <Link to={record.userLink}>{record.clientName}</Link>
        <Typography.Text style={{ whiteSpace: 'nowrap' }} type="secondary">
          (
          {record.isGhost ? 'ghost' : 'activated'}
          )
        </Typography.Text>
      </Space>
    ),
  },
  {
    title: 'Organization',
    dataIndex: 'organization',
    render: (value, record) => (record.organizationLink ? <Link to={record.organizationLink}>{value}</Link> : value),
  },
  {
    title: 'Creation date',
    dataIndex: 'createdAt',
    sorter: (a, b) => {
      const opA = new Date(a.createdAt).getTime();
      const opB = new Date(b.createdAt).getTime();
      return opA - opB;
    },
    render: (value) => formatDate(new Date(value)),
    key: 'createdAt',
    width: '16%',
  },
];
