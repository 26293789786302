/* eslint-disable react/no-danger */
import { chatLinkRegExp } from 'utils/regExps';
import styles from './MessageText.module.css';

interface MessageTextProps {
  message: string;
}

const getMessageWithLinks = (message: string) => {
  const messageWithLinks = message.replace(chatLinkRegExp, (url) => `<a href="${url}" target="_blank">${url}</a>`);

  return `<div style="white-space: pre-wrap; word-break: break-word;">${messageWithLinks}</div>`;
};

export default function MessageText({
  message,
}: MessageTextProps) {
  return (
    <div className={styles.text} dangerouslySetInnerHTML={{ __html: getMessageWithLinks(message) }} />
  );
}
