import { User } from '@raised/domain';
import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { IconMessageChatbot } from '@tabler/icons-react';
import styles from './Testimonials.module.css';

const { Title, Text, Paragraph } = TypographyModule;
const { PublicButton } = ButtonsModule;

interface TestimonialsProps {
  testimonials: User.Public['testimonials'];
  isMoreStories: boolean;
  onMoreClick: () => void;
  isOpen: boolean;
}

export default function Testimonials({
  testimonials,
  isMoreStories,
  onMoreClick,
  isOpen,
}: TestimonialsProps) {
  return (
    <section className={styles.testimonials}>
      <div className={styles.glow} />
      {isMoreStories ? (
        <PublicButton onClick={onMoreClick} className={styles.open_btn}>{isOpen ? 'Less stories' : 'More stories'}</PublicButton>
      ) : null}
      <Title level={1} className={styles.title}>
        <IconMessageChatbot size={24} />
        Testimonials
      </Title>
      <div className={styles.list}>
        {testimonials?.map((testimonial) => (
          <div key={testimonial.name} className={styles.item}>
            <Text className={styles.item__title}>{testimonial.name}</Text>
            <Text className={styles.item__position}>{`${testimonial.position} at ${testimonial.company}`}</Text>
            <Paragraph>{testimonial.review}</Paragraph>
          </div>
        ))}
      </div>
    </section>
  );
}
