import { Organisation } from '@raised/domain';
import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useUpdateOrganisationByIdMutation } from '../storage/organisationApi';

interface UseUpdateOrganisation {
  onSuccess: (organisation: Organisation.Public) => void;
}

export default function useUpdateOrganisation({
  onSuccess,
}: UseUpdateOrganisation) {
  const [mutate, info] = useUpdateOrganisationByIdMutation();
  const {
    isSuccess, isError, error, data,
  } = info;

  useEffect(() => {
    if (!isSuccess) return;

    message.success('Organisation successfully updated');
    onSuccess(data);
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;

    message.error(getErrorMessage(error));
  }, [isError]);

  const updateOrganisation = (formData: Organisation.Edit & { organisationId: string }) => mutate(formData);

  return {
    updateOrganisation,
    ...info,
  };
}
