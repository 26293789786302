import { LogMessage } from '@raised/domain';
import { Space, Spin, Typography } from 'antd';
import LogMessageComponent from 'components/log-messages/components/LogMessageComponent';

interface LogTabProps {
  logMessages?: LogMessage.Public[];
  isLogMessagesLoading: boolean;
}

export default function LogTab({
  logMessages,
  isLogMessagesLoading,
}: LogTabProps) {
  return (
    <>
      {isLogMessagesLoading ? (
        <Spin />
      ) : (
        <Space direction="vertical">
          {logMessages?.length ? (
            <>
              {logMessages?.map((message) => (
                <LogMessageComponent
                  key={message.id}
                  message={message}
                />
              ))}
            </>
          ) : (
            <Typography.Text>No log messages yet</Typography.Text>
          )}
        </Space>
      )}
    </>
  );
}
