import { FormsModule } from '@raised/ui-kit';
import { Control, FieldValues } from 'react-hook-form';
import { FormConfig } from 'utils/forms';

const { Input, TextArea } = FormsModule;

export const getCompanyDetailsFormConfig = <T extends FieldValues>(control: Control<T>): FormConfig<any> => [
  {
    controller: {
      name: 'companyName',
      control,
    },
    component: Input,
    label: 'Company name',
    componentProps: {
      placeholder: 'Company name',
      'data-testid': 'form_createUser__company_name',
    },
  },
  {
    controller: {
      name: 'aboutCompany',
      control,
    },
    component: TextArea,
    label: 'About company',
    componentProps: {
      placeholder: 'About company',
      'data-testid': 'form_createUser__about_company',
      rows: 4,
    },
  },
  {
    controller: {
      name: 'companyWebsite',
      control,
    },
    component: Input,
    label: 'Company website',
    componentProps: {
      placeholder: 'Company website',
      'data-testid': 'form_createUser__company_website',
    },
  },
];
