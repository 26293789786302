import { SuccessStory } from '@raised/domain';
import { FormsModule } from '@raised/ui-kit';
import { Control } from 'react-hook-form';
import { FormConfig } from 'utils/forms';

const { Input, TextArea } = FormsModule;

export const getCreateSuccessStoryFormConfigPart1 = (control: Control<any>): FormConfig<SuccessStory.CreateForm> => [
  {
    controller: {
      name: 'companyName',
      control,
    },
    label: 'Company Name',
    component: Input,
    componentProps: {
      placeholder: 'Company name',
    },
  },
  {
    controller: {
      name: 'companyDescription',
      control,
    },
    label: 'Company description',
    component: TextArea,
    componentProps: {
      placeholder: 'Describe your Company',
      rows: 4,
    },
  },
  {
    controller: {
      name: 'successStoryName',
      control,
    },
    label: 'Success Story name',
    component: Input,
    componentProps: {
      placeholder: 'Success Story name',
    },
  },
  {
    controller: {
      name: 'brief',
      control,
    },
    label: 'Brief',
    component: TextArea,
    componentProps: {
      placeholder: 'Brief',
      rows: 4,
    },
  },
  {
    controller: {
      name: 'challenge',
      control,
    },
    label: 'Challenge',
    component: TextArea,
    componentProps: {
      placeholder: 'Challenge',
      rows: 4,
    },
  },
  {
    controller: {
      name: 'solutions',
      control,
    },
    label: 'Solutions',
    component: TextArea,
    componentProps: {
      placeholder: 'Solutions',
      rows: 4,
    },
  },
  {
    controller: {
      name: 'results',
      control,
    },
    label: 'Results',
    component: TextArea,
    componentProps: {
      placeholder: 'Results',
      rows: 4,
    },
  },
];

export const getCreateSuccessStoryFormConfigPart2 = (control: Control<any>): FormConfig<SuccessStory.CreateForm> => [
  {
    controller: {
      name: 'clientReview',
      control,
    },
    label: 'Client review',
    component: TextArea,
    componentProps: {
      placeholder: 'Quote',
      rows: 4,
    },
  },
  {
    controller: {
      name: 'clientName',
      control,
    },
    label: 'Client name',
    component: Input,
    componentProps: {
      placeholder: 'Client name',
    },
  },
  {
    controller: {
      name: 'clientPosition',
      control,
    },
    label: 'Client position',
    component: Input,
    componentProps: {
      placeholder: 'Client position',
    },
  },
];
