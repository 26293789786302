import { ButtonsModule, FormsModule, TypographyModule } from '@raised/ui-kit';
import { IconHourglassLow, IconTrash } from '@tabler/icons-react';
import { Space } from 'antd';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { renderFormConfig } from 'utils/forms';
import { getCareerInfoFormConfig } from './careerInfoFormConfig';
import { getParttimeInfoConfig } from './parttimeInfoConfig';
import PeriodInput from './period-input/PeriodInput';
import styles from './PartimeCareerForm.module.css';

const { Button } = ButtonsModule;
const { Title, Text } = TypographyModule;
const { Checkbox } = FormsModule;

interface ParttimeCareerFormProps {
  control: Control;
}

export default function ParttimeCareerForm({
  control,
}: ParttimeCareerFormProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parttimeRoles',
  });

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <Space direction="vertical" size={8}>
      <Space size={16} direction="vertical" style={{ width: '100%' }}>
        {fields.map((item, index) => (
          <div key={item.id} className={styles.form_item}>
            <div className={styles.message}>
              <Title level={4}>{`Part-time role #${index + 1} (advising, part-time, etc)`}</Title>
              {index === 0 ? (
                <Text type="secondary">
                  Clients consider past project experience to be the most important of all criteria when selecting Experts
                </Text>
              ) : null}
            </div>
            {renderFormConfig(getCareerInfoFormConfig(control, `parttimeRoles.${index}`))}
            <Controller
              control={control}
              name={`parttimeRoles.${index}.completedOnRaised`}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onChange={field.onChange}
                >
                  Completed on Rasied
                </Checkbox>
              )}
            />
            <PeriodInput control={control} prefix={`parttimeRoles.${index}`} />
            <Space style={{ width: '100%', marginTop: 16 }} size={24} direction="vertical">
              {renderFormConfig(getParttimeInfoConfig(control, `parttimeRoles.${index}`))}
            </Space>
            <Button onClick={() => handleRemove(index)} danger className={styles.delete_btn}>
              <IconTrash size={18} />
              {`Delete part time role #${index + 1}`}
            </Button>
          </div>
        ))}
      </Space>
      <Button type="link" onClick={append} style={{ marginTop: fields.length ? 25 : 0 }}>
        <IconHourglassLow size={18} />
        {`Add Part Time Role #${fields.length + 1}`}
      </Button>
    </Space>
  );
}
