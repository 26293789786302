import { type User } from '@raised/domain';
import { ColorsModule, LayoutModule, TypographyModule } from '@raised/ui-kit';
import { IconBrandLinkedin, IconCashBanknote } from '@tabler/icons-react';
import UserAvatar from 'components/user/components/UserAvatar';
import UserLink from 'components/user/components/UserLink';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './ExpertItem.module.css';

const { Title, Text, Paragraph } = TypographyModule;
const { Space } = LayoutModule;
const { Colors } = ColorsModule;

interface ExpertItemProps {
  expert: User.Public;
  linkToExpert?: string;
  onExpertClick?: () => void;
  tagsContent?: ReactNode;
  isHideContacts?: boolean;
  invitationStatus?: ReactNode;
  additionalContent?: ReactNode;
  editExpertLink?: string;
}

export default function ExpertItem({
  expert,
  linkToExpert,
  tagsContent,
  onExpertClick,
  isHideContacts = false,
  invitationStatus,
  additionalContent,
  editExpertLink,
}: ExpertItemProps) {
  return (
    <div className={cn('expert_item', styles.expert_item)}>
      <div className={styles.info_block}>
        <div className={styles.info_block__main}>
          <UserAvatar role={expert.role} size={64} filePath={expert.avatar} />
          <div className={styles.info_block__main__info}>
            <div className={styles.row_item}>
              {linkToExpert ? (
                <Link to={linkToExpert}>
                  <Title level={5} className={styles.name}>{`${expert.firstName} ${expert.lastName}`}</Title>
                </Link>
              ) : null}
              {onExpertClick ? (
                <Title style={{ cursor: 'pointer' }} onClick={onExpertClick} level={5} className={styles.name}>{`${expert.firstName} ${expert.lastName}`}</Title>
              ) : null}
              {!isHideContacts && expert.externalLink ? (
                <UserLink
                  href={expert.externalLink}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  target="_blank"
                >
                  <IconBrandLinkedin size={18} />
                </UserLink>
              ) : null}
            </div>
            {expert.rate ? (
              <div className={styles.row_item}>
                <IconCashBanknote color={Colors.blue} size={18} />
                <Text className={styles.rate}>{`$${expert.rate}/h`}</Text>
              </div>
            ) : null}
            {editExpertLink ? (
              <Link to={editExpertLink}>Edit</Link>
            ) : null}
          </div>
        </div>
        <div className={styles.info_block__work}>
          {expert.biography ? (
            <Space direction="vertical" size={4} style={{ marginBottom: 12 }}>
              <Text type="secondary">Biography:</Text>
              <Paragraph>{expert.biography}</Paragraph>
            </Space>
          ) : null}
          {expert.headline ? (
            <Space direction="vertical" size={4}>
              <Text type="secondary">Headline:</Text>
              <Paragraph>{expert.headline}</Paragraph>
            </Space>
          ) : null}
        </div>
        {invitationStatus ? (
          <div className={styles.invitation_status}>
            {invitationStatus}
          </div>
        ) : null}
      </div>
      {tagsContent}
      {additionalContent}
    </div>
  );
}
