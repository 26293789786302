import { User } from '@raised/domain';
import { ButtonsModule, FeedbackModule, TypographyModule } from '@raised/ui-kit';
import { EXPERT_TAGS } from 'components/user/constants/expert-tags';
import { ReactNode } from 'react';
import FilterOption from '../filter-option/FilterOption';
import styles from './FiltersModal.module.css';

const { Modal } = FeedbackModule;
const { Button } = ButtonsModule;
const { Text } = TypographyModule;

interface FiltersModalProps {
  isVisible: boolean;
  header: ReactNode;
  onClose: () => void;
  onTagClick: (group: keyof User.Tags, tag: string) => void;
  selectedTags: Record<string, string[]>;
  onClear: () => void;
}

export default function FiltersModal({
  isVisible,
  onClose,
  header,
  onTagClick,
  selectedTags,
  onClear,
}: FiltersModalProps) {
  return (
    <Modal
      className={styles.filters_modal}
      open={isVisible}
      onCancel={onClose}
      width="100%"
      closeIcon={null}
      footer={null}
      title={(
        <div className={styles.header}>
          {header}
          <Button onClick={onClear} type="link" className={styles.clear_btn}>Clear All</Button>
        </div>
      )}
    >
      <div className={styles.content}>
        {Object.entries(selectedTags).map(([key, values]) => (
          <div key={key} className={styles.group}>
            <Text>{`${EXPERT_TAGS[key as keyof User.Tags].label}: `}</Text>
            {EXPERT_TAGS[key as keyof User.Tags].values.map((value) => <FilterOption isSelected={values.includes(value)} onClick={() => onTagClick(key as keyof User.Tags, value)} key={value}>{value}</FilterOption>)}
          </div>
        ))}
      </div>
    </Modal>
  );
}
