import { LogMessage } from '@raised/domain';
import { Space, Spin } from 'antd';
import LogMessageComponent from 'components/log-messages/components/LogMessageComponent';

interface LogsTabProps {
  logMessages: LogMessage.Public[]
  isLoading: boolean;
}

export default function LogsTab({
  logMessages,
  isLoading,
}: LogsTabProps) {
  if (!logMessages) return null;

  return (
    <Space direction="vertical">
      {isLoading ? (
        <Spin />
      ) : (
        <>
          {logMessages.map((message) => (
            <LogMessageComponent
              key={message.id}
              message={message}
            />
          ))}
        </>
      )}
    </Space>
  );
}
