import { User } from '@raised/domain';
import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useEditProfileByIdMutation } from '../storage/userApi';

interface UseEditUserProfileProps {
  onSuccess: (user: User.Public) => void;
}

export default function useEditUserProfile({
  onSuccess,
}: UseEditUserProfileProps) {
  const [editUserProfile, info] = useEditProfileByIdMutation();
  const {
    isError, error, isSuccess, data,
  } = info;

  useEffect(() => {
    if (isError) {
      message.error(getErrorMessage(error));
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      message.success('Profile successfully changed');
      onSuccess(data);
    }
  }, [isSuccess]);

  return {
    editUserProfile,
    ...info,
  };
}
