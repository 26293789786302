import { ProjectPayments } from '@raised/domain';

export const PROPOSAL_PROJECT_PAYMENTS: { [key in ProjectPayments]: { title: string; description: string } } = {
  CONNECT_WITH_RAISED: {
    title: 'Connect with Raised',
    description: 'If you didn’t find relevant payment option, please reach out to us.',
  },
  FIXED_SCOPE_PROJECT: {
    title: 'Fixed scope project / fixed number of hours',
    description: 'For consulting projects with fixed number of hours. It’s good to start with such project and then continue on a pay-as-you-go basis (or continue with pre-paid packages).',
  },
  FIXED_SUBSCRIPTION: {
    title: 'Fixed subscription',
    description: 'For cases with fixed amount of calls/hours per week/month',
  },
  PAY_AS_YOU_GO: {
    title: 'Pay as you go',
    description: 'Post-payment method. Clients get bills every 1/2/4 weeks with the amount of hours you spent on the project. The method is best for long term projects where scope is not clear.',
  },
} as const;
