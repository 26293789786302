import { SuccessStory, UserRole } from '@raised/domain';
import { FeedbackModule } from '@raised/ui-kit';
import EditSuccessStoryForm from 'components/success-stories/components/forms/success-story/EditSuccessStoryForm';
import useEditSuccessStory from 'components/success-stories/hooks/useEditSuccessStory';
import { useGetSuccessStoryByIdQuery } from 'components/success-stories/storage/successStoriesApi';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

const { Spin } = FeedbackModule;

export default function EditSuccessStoryPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const onBack = () => navigate(-1);

  const {
    data: profile,
    isLoading: isProfileLoading,
    isSuccess: isProfileSuccess,
  } = useGetProfileQuery();
  const {
    data: successStory,
    isLoading: isSuccessStoryLoading,
    isSuccess: isSuccessStorySuccess,
  } = useGetSuccessStoryByIdQuery({ id: id || '' }, { skip: !id });

  const {
    editSuccessStory,
    isLoading: isEditSuccessStoryLoading,
  } = useEditSuccessStory({
    onSuccess: onBack,
  });

  useEffect(() => {
    if (!isSuccessStorySuccess || !isProfileSuccess) return;

    if (successStory.userId !== profile.id && profile.role !== UserRole.ADMIN) {
      navigate(-1);
    }
  }, [isSuccessStorySuccess, isProfileSuccess]);

  const handleSubmit = (values: SuccessStory.EditForm) => {
    if (!id) return;

    editSuccessStory(values, id);
  };

  return (
    <>
      <Helmet>
        <title>Raised - Edit success story</title>
      </Helmet>
      {isSuccessStoryLoading || isProfileLoading ? (
        <Spin />
      ) : null}
      {isSuccessStorySuccess && isProfileSuccess ? (
        <EditSuccessStoryForm
          title="Edit Success Story"
          onCancel={onBack}
          isControls
          defaultValues={successStory}
          onSubmit={handleSubmit}
          isLoading={isEditSuccessStoryLoading}
        />
      ) : null}
    </>
  );
}
