import { ColorsModule, TypographyModule } from '@raised/ui-kit';
import { IconMailCheck } from '@tabler/icons-react';
import styles from './InvitationStatus.module.css';

const { Colors } = ColorsModule;
const { Text } = TypographyModule;

interface InvitationStatusProps {
  invitedExperts: string[];
  expertId: string;
}
export default function InvitationStatus({
  invitedExperts,
  expertId,
}: InvitationStatusProps) {
  if (!invitedExperts.includes(expertId)) return null;

  return (
    <div className={styles.invitation_sent}>
      <IconMailCheck color={Colors['solid-green']} size={16} />
      <Text>Invitation sent</Text>
    </div>
  );
}
