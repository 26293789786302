import { Payment, Proposal, ProposalStatuses } from '@raised/domain';
import { rootApi } from 'api/rootApi';

export const proposalApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    createProposal: build.mutation<Proposal.Public, Proposal.Create>({
      invalidatesTags: (res) => (res ? [{ type: 'MATCH', id: res.matchId }, 'PROPOSAL'] : ['PROPOSAL']),
      query: (body) => ({
        url: '/proposal',
        method: 'POST',
        body,
      }),
    }),
    getProposalsByProject: build.query<Proposal.Public[], { projectId: string }>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'PROPOSAL', id }) as const), 'PROPOSAL'] : ['PROPOSAL']),
      query: ({ projectId }) => ({
        url: `/proposal/project/${projectId}`,
      }),
    }),
    getProposalsByMatch: build.query<Proposal.Public[], { matchId: string }>({
      providesTags: (res) => (res?.length ? [...res.map((proposal) => ({ type: 'PROPOSAL', id: proposal.id }) as const), 'PROPOSAL'] : ['PROPOSAL']),
      query: ({ matchId }) => ({
        url: `/proposal/match/${matchId}`,
      }),
    }),
    updateProposalStatus: build.mutation<Proposal.Public, { proposalId: string, status: ProposalStatuses, matchId: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'PROPOSAL', id: args.proposalId }],
      query: ({ proposalId, status }) => ({
        url: `/proposal/status/${proposalId}`,
        method: 'PATCH',
        body: {
          status,
        },
      }),
    }),
    getProposalById: build.query<Proposal.Public, { id: string }>({
      providesTags: (res) => (res?.id ? [{ type: 'PROPOSAL', id: res.id }] : ['PROPOSAL']),
      query: ({ id }) => ({
        url: `/proposal/${id}`,
      }),
    }),
    getProposalPaymentLink: build.query<Payment.PaymentLinkResponse, { proposalId: string }>({
      query: ({ proposalId }) => ({
        url: `/proposal/${proposalId}/payment-link`,
      }),
    }),
    getAcceptedProposals: build.query<Proposal.Public[], void>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'PROPOSAL', id }) as const), 'PROPOSAL'] : ['PROPOSAL']),
      query: () => ({
        url: '/proposal/accepted',
      }),
    }),
    editProposalById: build.mutation<Proposal.Public, Proposal.Edit & { proposalId: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'PROPOSAL', id: args.proposalId }, 'MATCH'],
      query: ({ proposalId, ...body }) => ({
        method: 'PUT',
        url: `/proposal/${proposalId}`,
        body,
      }),
    }),
    calculatePaymentInfo: build.mutation<Proposal.PaymentInfo, Pick<Proposal.Create, 'paymentRate' | 'projectLength'>>({
      query: (body) => ({
        method: 'POST',
        url: '/proposal/payment-info',
        body,
      }),
    }),
  }),
});

export const {
  useCreateProposalMutation,
  useGetProposalsByProjectQuery,
  useUpdateProposalStatusMutation,
  useGetProposalsByMatchQuery,
  useGetProposalByIdQuery,
  useLazyGetProposalPaymentLinkQuery,
  useGetAcceptedProposalsQuery,
  useEditProposalByIdMutation,
  useCalculatePaymentInfoMutation,
} = proposalApi;
