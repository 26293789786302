import { Proposal } from '@raised/domain';
import { DatePicker } from 'antd';
import { Control, useController } from 'react-hook-form';
import dayjs from 'dayjs';
import { formatDate } from 'utils/formats';

interface ProjectDateInputProps {
  control: Control<Proposal.Create>;
  fieldName: keyof Proposal.Create;
}

export default function ProjectDateInput({
  control,
  fieldName,
}: ProjectDateInputProps) {
  const { field, fieldState: { error } } = useController({
    control,
    name: fieldName,
  });

  return (
    <DatePicker
      style={error ? { border: '1px solid red' } : {}}
      allowClear
      format={(value) => formatDate(value.toDate())}
      onChange={(value) => field.onChange(value?.toISOString())}
      value={field.value ? dayjs(field.value as string) : null}
    />
  );
}
