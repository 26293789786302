enum ProjectCategories {
  CEO_AND_FOUNDERS = "CEO_AND_FOUNDERS",
  MARKETING = "MARKETING",
  PRODUCT = "PRODUCT",
  SALES_AND_BUSINESS_DEVELOPMENT = "SALES_AND_BUSINESS_DEVELOPMENT",
  SOFTWARE_DEVELOPMENT = "SOFTWARE_DEVELOPMENT",
  PEOPLE = "PEOPLE",
  OPERATIONS = "OPERATIONS",
  STRATEGY = "STRATEGY",
  FINANCE = "FINANCE",
  CUSTOMER_SUCCESS = "CUSTOMER_SUCCESS",
  LEGAL = "LEGAL",
}

export default ProjectCategories;
