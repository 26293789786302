import { Input } from 'antd';
import { ComponentProps } from 'react';
import cn from 'classnames';
import { InputWrapper, InputWrapperProps } from '../input-wrapper';
import styles from '../Input.module.css';

interface PasswordProps extends ComponentProps<typeof Input.Password>, InputWrapperProps {}

export default function Password({
  label,
  subLabel,
  className,
  ...rest
}: PasswordProps) {
  return (
    <InputWrapper label={label} subLabel={subLabel}>
      <Input.Password {...rest} className={cn(styles.input, className)} />
    </InputWrapper>
  );
}
