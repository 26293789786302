import {
  ColorsModule, DataModule, FeedbackModule, TypographyModule,
} from '@raised/ui-kit';
import cn from 'classnames';
import { IconSpeakerphone } from '@tabler/icons-react';
import { ReactNode } from 'react';
import styles from './ClientExpertsContainer.module.css';

const { Title, Text } = TypographyModule;
const { Spin } = FeedbackModule;
const { Empty } = DataModule;
const { Colors } = ColorsModule;

interface ClientExpertsContainerProps {
  children: ReactNode;
  isLoading: boolean;
  isEmpty: boolean;
  sidebarContent: ReactNode;
}

export default function ClientExpertsContainer({
  children,
  isLoading,
  isEmpty,
  sidebarContent,
}: ClientExpertsContainerProps) {
  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.sidebar, styles.sidebar_desktop)}>
        {sidebarContent}
      </div>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.wip_msg}>
            <Text>{'This is a beta version, <2% of our professionals are presented in this section'}</Text>
          </div>
          <Title level={1}>Relevant Professionals</Title>
        </div>
        <div className={cn(styles.sidebar, styles.sidebar_mobile)}>
          {sidebarContent}
        </div>
        <div className={styles.content}>
          {isLoading ? <Spin /> : (
            <>
              {isEmpty
                ? (
                  <Empty
                    height={300}
                    icon={<IconSpeakerphone size={80} stroke={0.6} strokeOpacity={0.5} color={Colors.gray} />}
                  >
                    <Text type="secondary">Experts not found</Text>
                  </Empty>
                )
                : children}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
