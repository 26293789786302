import { Match, ProjectActivityTypes } from '@raised/domain';
import { ClientMatchOverviewTabs } from 'components/match/types/client-match-overview-tabs';
import cn from 'classnames';
import { IconCashBanknote, IconUserSearch } from '@tabler/icons-react';
import { ColorsModule, TypographyModule } from '@raised/ui-kit';
import { Spin } from 'antd';
import UserAvatar from 'components/user/components/UserAvatar';
import { Link } from 'react-router-dom';
import styles from './ClientMatchesTable.module.css';
import ExpertBio from './expert-bio/ExpertBio';
import ActionsCell from './actions-cell/ActionsCell';

const { Text, Title } = TypographyModule;
const { Colors } = ColorsModule;

interface ClientMatchesTableProps {
  matches: Match.Public[];
  isLoading: boolean;
  onDeclineClick: (matchId: string) => void;
  onInviteToInterviewClick: (matchId: string) => void;
  onInterstedClick: (matchId: string) => void;
  onMatchOverviewClick: (matchId: string, tab?: ClientMatchOverviewTabs) => void;
  getMatchLink: (matchid: string, tab?: ClientMatchOverviewTabs) => string;
  projectActivityType: ProjectActivityTypes;
}

export default function ClientMatchesTable({
  matches,
  isLoading,
  onDeclineClick,
  onInviteToInterviewClick,
  onInterstedClick,
  onMatchOverviewClick,
  getMatchLink,
  projectActivityType,
}: ClientMatchesTableProps) {
  return (
    <div className={cn(styles.container, {
      [styles.empty_container]: !matches.length || isLoading,
    })}
    >
      {isLoading ? (
        <Spin />
      ) : null}
      {!isLoading && !matches.length ? (
        <div className={styles.empty_msg}>
          <IconUserSearch size={80} stroke={0.5} color={Colors.gray} />
          <Text type="secondary">
            {projectActivityType === ProjectActivityTypes.RECOMMENDED ? ('We’ll start an expert search after you indicate that you are interested') : ('Raised is looking for professionals')}
          </Text>
        </div>
      ) : null}
      {!isLoading && matches.length ? (
        <div className={styles.content}>
          <Title level={2} className={styles.title}>Professionals for You</Title>
          <div className={styles.table}>
            {matches.map((match) => (
              <Link to={getMatchLink(match.id)} key={match.id} className={styles.table__row}>
                <div className={styles.user}>
                  <UserAvatar style={{ flexShrink: 0 }} size="s" filePath={match.user?.avatar} role={match.user?.role} />
                  <div className={styles.user_info}>
                    <Title level={5} className={styles.title}>{`${match.user?.firstName} ${match.user?.lastName}`}</Title>
                    {match.user?.headline ? (
                      <Text className={styles.headline}>{match.user.headline}</Text>
                    ) : null}
                    {match.user?.rate ? (
                      <div className={styles.user_rate}>
                        <IconCashBanknote color={Colors.blue} size={20} />
                        {`${match.user?.rate}`}
                      </div>
                    ) : null}
                  </div>
                </div>
                <ExpertBio
                  text={match.user?.biography || ''}
                  onMoreClick={() => onMatchOverviewClick(match.id)}
                  isMore={false}
                />
                <ActionsCell
                  status={match.status}
                  onDeclineClick={() => onDeclineClick(match.id)}
                  onInviteToInterviewClick={() => onInviteToInterviewClick(match.id)}
                  onInterstedClick={() => onInterstedClick(match.id)}
                  proposals={match.proposal}
                />
              </Link>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
