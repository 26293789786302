import { Space, Typography } from 'antd';
import { useGetUserByIdQuery } from 'components/user/storage/userApi';
import { formatDate } from 'utils/formats';

interface DeletedProjectMessageProps {
  deletedAt: string;
  deletedBy: string;
  onUserClick: () => void;
}

export default function DeletedProjectMessage({
  deletedAt,
  deletedBy,
  onUserClick,
}: DeletedProjectMessageProps) {
  const { data: user } = useGetUserByIdQuery({ id: deletedBy });

  return (
    <Space size={4}>
      <Typography.Text strong type="danger">
        {`Project was deleted at ${formatDate(new Date(deletedAt))}${user ? ' by ' : ''}`}
      </Typography.Text>
      {user ? (
        <Typography.Link onClick={onUserClick} strong type="danger" underline>
          {`${user.email}`}
        </Typography.Link>
      ) : null}
    </Space>
  );
}
