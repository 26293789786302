import { Typography } from 'antd';
import styles from './RecaptchaMessage.module.css';

export default function RecaptchaMessage() {
  return (
    <Typography.Text type="secondary" className={styles.container}>
      This site is protected by reCAPTCHA and the Google
      {' '}
      <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>
      {' '}
      and
      {' '}
      <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a>
      {' '}
      apply.
    </Typography.Text>
  );
}
