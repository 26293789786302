/* eslint-disable react/no-array-index-key */
import { ButtonsModule, ColorsModule, FormsModule } from '@raised/ui-kit';
import { IconX } from '@tabler/icons-react';
import { Space } from 'antd';
import {
  Control, Controller, useController, useWatch,
} from 'react-hook-form';
import styles from './AchievmentsForm.module.css';

const { TextArea } = FormsModule;
const { Button } = ButtonsModule;
const { Colors } = ColorsModule;

interface AchievmentsFormProps {
  control: Control;
  fieldName: string;
}

export default function AchievmentsForm({
  control,
  fieldName,
}: AchievmentsFormProps) {
  const { field } = useController({
    control,
    name: fieldName,
  });
  const fields: string[] = useWatch({
    control,
    name: fieldName,
  });

  const handleAppend = () => field.onChange([...(fields || []), '']);
  const handleRemove = (index: number) => {
    const newArr = [...fields.slice(0, index), ...fields.slice(index + 1)];
    field.onChange(newArr);
  };

  return (
    <Space direction="vertical" size={8} style={{ width: '100%', marginTop: 46 }}>
      {fields?.map((_, index) => (
        <Controller
          key={index}
          control={control}
          name={`${fieldName}.${index}`}
          render={({ field: renderField, fieldState }) => (
            <div className={styles.input_block}>
              <TextArea
                {...renderField}
                rows={2}
                label={index === 0 ? 'Key Achievement 1' : `Key Achievement ${index + 1}`}
                placeholder={index === 0 ? 'Key Achievement 1' : `Key Achievement ${index + 1}`}
                status={fieldState.error ? 'error' : undefined}
              />
              <Button className={styles.remove_btn} type="text" onClick={() => handleRemove(index)}>
                <IconX color={Colors.gray} size={20} />
              </Button>
            </div>
          )}
        />
      ))}
      <TextArea
        rows={2}
        onFocus={handleAppend}
        label={`Key Achievment ${(fields?.length || 0) + 1}`}
        placeholder={`Key Achievement ${(fields?.length || 0) + 1}`}
        classNames={{ textarea: styles.dashed_textarea }}
      />
    </Space>
  );
}
