/* eslint-disable jsx-a11y/no-static-element-interactions */
import { SearchOutlined } from '@ant-design/icons';
import { Input, Space, Button } from 'antd';
import { FilterConfirmProps } from 'antd/es/table/interface';

interface SearchTooltipProps {
  setSelectedKeys: (selectedKeys: React.Key[]) => void;
  selectedKeys: React.Key[];
  confirm: (param?: FilterConfirmProps | undefined) => void;
  close: () => void;
  clearFilters?: () => void;
}

export default function SearchTooltip({
  setSelectedKeys,
  selectedKeys,
  confirm,
  close,
  clearFilters,
}: SearchTooltipProps) {
  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        placeholder="Search name"
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        style={{ marginBottom: 8, display: 'block' }}
        onPressEnter={() => {
          confirm({ closeDropdown: false });
        }}
      />
      <Space>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
          onClick={() => {
            confirm({ closeDropdown: false });
          }}
        >
          Search
        </Button>
        <Button
          onClick={clearFilters}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </Space>
    </div>
  );
}
