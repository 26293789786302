import { FeedbackModule } from '@raised/ui-kit';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useDeleteTemplateMutation } from '../storage/templatesApi';

const { Message } = FeedbackModule;

export default function useDeleteTemplate() {
  const [mutate, info] = useDeleteTemplateMutation();
  const { isError, error, isSuccess } = info;

  const deleteTemplate = (id: string) => mutate({ id });

  useEffect(() => {
    if (!isError) return;

    Message.error(getErrorMessage(error));
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;

    Message.success('Template successfully deleted');
  }, [isSuccess]);

  return {
    ...info,
    deleteTemplate,
  };
}
