import { Project, UserRole } from '@raised/domain';
import { useGetOrganisationByIdQuery } from 'components/organisation/storage/organisationApi';
import AttachFiles from 'components/files/attach-files/AttachFiles';
import CreateProjectForm from 'components/project/create-project/CreateProjectForm';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { Ref, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import useCreateProject from '../hooks/useCreateProject';
import { PROJECT_ROUTES } from '../project-routes';

interface CreateProjectPageProps {
  isModal?: boolean;
  formRef?: Ref<HTMLFormElement>;
  onSuccess?: () => void;
}

export default function CreateProjectPage({
  isModal = false,
  formRef,
  onSuccess,
}: CreateProjectPageProps) {
  const navigate = useNavigate();
  const { data } = useGetProfileQuery();
  const { data: organisation } = useGetOrganisationByIdQuery({ id: data?.organisationId || '' }, { skip: !data?.organisationId });

  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess();
      return;
    }

    if (data?.role === UserRole.ADMIN) {
      navigate(PROJECT_ROUTES.ASSIGN_PROJECTS());
    } else {
      navigate(PROJECT_ROUTES.PROJECTS());
    }
  };

  const {
    create, isLoading, addFiles, removeFile, files, uploadedFiles,
  } = useCreateProject({
    onSuccess: handleSuccess,
  });

  const onSubmit = (values: Project.Create) => create(values);
  const onBack = () => navigate(-1);
  const isAdmin = data?.role === UserRole.ADMIN;
  const formDefaultValues: Partial<Project.Create> = useMemo(() => {
    if (organisation?.users?.length === 1) {
      return {
        usersToNotifyId: [organisation?.users[0].id],
      };
    }

    return {};
  }, [organisation]);

  return (
    <>
      <Helmet>
        <title>Raised - Create project</title>
      </Helmet>
      <CreateProjectForm
        title={isModal ? undefined : 'Create project'}
        isControls={!isModal}
        isLoading={isLoading}
        onSubmit={onSubmit}
        isAdmin={isAdmin}
        organisationId={data?.organisationId}
        defaultValues={formDefaultValues}
        onBack={onBack}
        formRef={formRef}
        filesInput={(
          <AttachFiles
            label="Additional documents (optional)"
            subLabel="Provide a description of the desired expert, including their skills, level of experience, and any other relevant qualifications"
            addFiles={addFiles}
            removeFile={removeFile}
            uploadedFiles={uploadedFiles}
            files={files}
          />
        )}
      />
    </>
  );
}
