import { ReactNode } from 'react';
import styles from './TemplatesContainer.module.css';

interface TemplatesContainerProps {
  sidebarContent: ReactNode;
  titleBlock: ReactNode;
  children: ReactNode;
}

export default function TemplatesContainer({
  sidebarContent,
  titleBlock,
  children,
}: TemplatesContainerProps) {
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        {sidebarContent}
      </div>
      <div className={styles.content}>
        {titleBlock}
        {children}
      </div>
    </div>
  );
}
