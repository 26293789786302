import { User } from '@raised/domain';
import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import useLogin from 'components/auth/hooks/useLogin';
import useRecaptcha from 'components/auth/hooks/useRecaptcha';
import { useCallback, useEffect } from 'react';
import { useJoinOrganisationMutation } from '../storage/userApi';

interface UseJoinOrganisationProps {
  onSuccess: (user: User.Public) => void;
}

export default function useJoinOrganisation({
  onSuccess,
}: UseJoinOrganisationProps) {
  const { handleRecaptchaVerify } = useRecaptcha();
  const [mutate, info] = useJoinOrganisationMutation();
  const {
    isSuccess, originalArgs, isError, error, isLoading,
  } = info;
  const { login, isLoading: isLoginLoading } = useLogin({
    onSuccess: (user: User.Public) => {
      onSuccess(user);
    },
  });

  const joinOrganisation = useCallback(async (data: User.JoinOrganisation) => {
    const recaptchaToken = await handleRecaptchaVerify('joinOrganisation');

    if (!recaptchaToken) {
      message.error('Failed reCAPTCHA check');
      return;
    }

    mutate({ ...data, recaptchaToken });
  }, [handleRecaptchaVerify]);

  useEffect(() => {
    if (!isSuccess || !originalArgs) return;

    message.success('Profile successfully activated');
    login({ email: originalArgs.email, password: originalArgs.password });
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      message.error(getErrorMessage(error));
    }
  }, [isError]);

  return {
    ...info,
    isLoading: isLoading || isLoginLoading,
    joinOrganisation,
  };
}
