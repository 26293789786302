import { User } from '@raised/domain';

export const getLabel = (from: User.FulltimeRole['from'], to: User.FulltimeRole['to']) => {
  const fromLabel = new Date(from).getFullYear();
  const toLabel = to ? new Date(to).getFullYear() : 'current';

  return `${fromLabel} - ${toLabel}`;
};

export const getCompanyName = (companyName: User.FulltimeRole['name'], position: User.FulltimeRole['position']) => {
  if (position) {
    return `${position} at ${companyName}`;
  }

  return companyName;
};
