import { ProjectCategories, ProjectTypes } from '@raised/domain';
import { useState } from 'react';
import { useGetTemplatesQuery } from '../storage/templatesApi';
import { GetTemplatesParamsType } from '../storage/types';

export default function useGetTemplates() {
  const [filters, setFilters] = useState<GetTemplatesParamsType>({ type: [], category: [] });
  const data = useGetTemplatesQuery(filters);

  const handleTypeClick = (type: ProjectTypes) => {
    if (filters.type.includes(type)) {
      setFilters((stateFilters) => ({ ...stateFilters, type: stateFilters.type.filter((t) => t !== type) }));
    } else {
      setFilters((stateFilters) => ({ ...stateFilters, type: [...stateFilters.type, type] }));
    }
  };
  const handleCategoryClick = (category: ProjectCategories) => {
    if (filters.category?.includes(category)) {
      setFilters((stateFilters) => ({ ...stateFilters, category: stateFilters.category.filter((cat) => cat !== category) }));
    } else {
      setFilters((stateFilters) => ({ ...stateFilters, category: [...stateFilters.category, category] }));
    }
  };
  const handleAllTypesClick = () => {
    if (filters.type.length === Object.keys(ProjectTypes).length) {
      setFilters((stateFilters) => ({ ...stateFilters, type: [] }));
    } else {
      setFilters((stateFilters) => ({ ...stateFilters, type: Object.values(ProjectTypes) }));
    }
  };
  const handleAllCategoriesClick = () => {
    if (filters.category.length === Object.keys(ProjectCategories).length) {
      setFilters((stateFilters) => ({ ...stateFilters, category: [] }));
    } else {
      setFilters((stateFilters) => ({ ...stateFilters, category: Object.values(ProjectCategories) }));
    }
  };

  return {
    ...data,
    isLoading: data.isLoading || data.isFetching,
    filters,
    handleTypeClick,
    handleAllTypesClick,
    handleCategoryClick,
    handleAllCategoriesClick,
  };
}
