import { User } from '@raised/domain';
import { TypographyModule } from '@raised/ui-kit';
import { IconAlignBoxLeftMiddle, IconMessageChatbot, IconMessageStar } from '@tabler/icons-react';
import styles from './ExpertTestimonials.module.css';

const { Title, Text, Paragraph } = TypographyModule;

interface ExpertTestimonialsProps {
  testimonials: User.Testimonial[];
}

export default function ExpertTestimonials({
  testimonials,
}: ExpertTestimonialsProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title_block}>
        <IconMessageChatbot size={24} color="#000" />
        <Title level={2}>Testimonials</Title>
      </div>
      {testimonials.map((testimonial) => (
        <div key={testimonial.name} className={styles.container}>
          <div className={styles.testimonial_title}>
            <Title level={2}>{testimonial.name}</Title>
            <Text>{`${testimonial.position} @ ${testimonial.company}`}</Text>
          </div>
          <div className={styles.content_block}>
            <div className={styles.content_block__title}>
              <IconAlignBoxLeftMiddle size={20} color="#000" />
              <Title level={3}>Project description</Title>
            </div>
            <Paragraph>{testimonial.projectDescription}</Paragraph>
          </div>
          <div className={styles.content_block}>
            <div className={styles.content_block__title}>
              <IconMessageStar size={20} color="#000" />
              <Title level={3}>Review</Title>
            </div>
            <Paragraph>{testimonial.review}</Paragraph>
          </div>
        </div>
      ))}
    </div>
  );
}
