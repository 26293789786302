import { User } from '@raised/domain';
import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import UserAvatar from 'components/user/components/UserAvatar';
import styles from './ExpertInfo.module.css';

const { Text, Title, Paragraph } = TypographyModule;
const { PublicButton } = ButtonsModule;

interface ExpertInfoProps {
  expert?: User.Public;
  onExpertPageClick: () => void;
}

export default function ExpertInfo({
  expert,
  onExpertPageClick,
}: ExpertInfoProps) {
  if (!expert) return null;

  return (
    <div className={styles.expert_info}>
      <div className={styles.avatar_block}>
        <UserAvatar className={styles.avatar} size={140} filePath={expert.avatar} role={expert.role} />
        <div className={styles.name_block}>
          <Text className={styles.caption}>Expert</Text>
          <Title level={1} className={styles.title}>{`${expert.firstName} ${expert.lastName}`}</Title>
        </div>
      </div>
      <Paragraph className={styles.paragraph}>{expert.biography}</Paragraph>
      <PublicButton onClick={onExpertPageClick} className={styles.btn}>Expert’s page</PublicButton>
    </div>
  );
}
