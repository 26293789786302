/* eslint-disable consistent-return */
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useCallback, useState } from 'react';
import { useUploadFilesMutation } from 'components/files/storage/filesApi';
import { UploadFilesResponseType } from '../storage/types';

export default function useUploadFiles() {
  const [upload, result] = useUploadFilesMutation();
  const [uploadedFiles, setUploadedFiles] = useState<UploadFilesResponseType>([]);

  const uploadFiles = useCallback(async (fileList: File[]) => {
    if (!fileList.length) return;

    try {
      const data = await upload({ fileList }).unwrap();
      setUploadedFiles(data);
      return data;
    } catch (error) {
      message.error(getErrorMessage(error as FetchBaseQueryError));
    }
  }, []);

  const resetState = useCallback(() => {
    setUploadedFiles([]);
  }, []);

  return {
    uploadFiles,
    uploadedFiles,
    resetState,
    ...result,
  };
}
