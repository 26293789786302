import { ColorsModule, TypographyModule } from '@raised/ui-kit';
import {
  IconFileStack,
  IconHelp, IconHome, IconUsersGroup,
} from '@tabler/icons-react';
import { ORGANISATION_ROUTES } from 'components/organisation/organisation-routes';
import { PROJECT_ROUTES } from 'components/project/project-routes';
import { TEMPLATES_ROUTES } from 'components/templates/templates-routes';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../Links.module.css';

const { Text } = TypographyModule;
const { Colors } = ColorsModule;

const getIconProps = (isActive: boolean) => (isActive ? { color: Colors.accent, stroke: 2 } : { color: Colors.black, stroke: 1.5 });

interface ClientLinksProps {
  isSupportOpened: boolean;
  onSupportClick: () => void;
}

export default function ClientLinks({
  isSupportOpened,
  onSupportClick,
}: ClientLinksProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className={styles.client_links}>
      <button type="button" className={styles.button} onClick={() => navigate(PROJECT_ROUTES.PROJECTS())}>
        <IconHome size={24} {...getIconProps(pathname === PROJECT_ROUTES.PROJECTS())} />
        <Text className={styles.button_text}>Home</Text>
      </button>
      <button type="button" className={styles.button} onClick={() => navigate(ORGANISATION_ROUTES.ORGANISATION_DASHBOARD())}>
        <IconUsersGroup size={24} {...getIconProps(pathname === ORGANISATION_ROUTES.ORGANISATION_DASHBOARD())} />
        <Text className={styles.button_text}>+Teammates</Text>
      </button>
      <button type="button" className={styles.button} onClick={() => navigate(TEMPLATES_ROUTES.TEMPLATES())}>
        <IconFileStack size={24} {...getIconProps(pathname === TEMPLATES_ROUTES.TEMPLATES())} />
        <Text className={styles.button_text}>Templates</Text>
      </button>
      {/* <button type="button" className={styles.button} onClick={() => navigate(USER_ROUTES.CLIENT_EXPERTS_LIST())}> */}
      {/*   <IconUsers size={24} {...getIconProps(pathname === USER_ROUTES.CLIENT_EXPERTS_LIST())} /> */}
      {/*   <Text className={styles.button_text}>Professionals</Text> */}
      {/* </button> */}
      <button type="button" className={styles.button} onClick={onSupportClick}>
        <IconHelp size={24} {...getIconProps(isSupportOpened)} />
        <Text className={styles.button_text}>Support</Text>
      </button>
    </div>
  );
}
