import AttachFiles from 'components/files/attach-files/AttachFiles';
import { Helmet } from 'react-helmet';
import { Usecase } from '@raised/domain';
import useEditUsecase from 'components/usecases/hooks/useEditUsecase';
import { useGetUsecaseByIdQuery } from 'components/usecases/storage/usecasesApi';
import { useNavigate, useParams } from 'react-router-dom';
import TemplateForm from 'components/templates/pages/create-template/TemplateForm';
import { FeedbackModule } from '@raised/ui-kit';

const { Spin } = FeedbackModule;

export default function EditUsecasePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const onBack = () => navigate(-1);

  const {
    data: usecase,
    isSuccess: isUsecaseSuccess,
    isLoading: isUsecaseLoading,
  } = useGetUsecaseByIdQuery({ id: id || '' }, { skip: !id });
  const {
    editUsecase,
    isLoading,
    addFiles,
    removeFile,
    files,
    uploadedFiles,
  } = useEditUsecase({
    onSuccess: onBack,
    initialFiles: usecase?.attachedFile,
  });

  const onSubmit = (values: Usecase.Create) => {
    if (!id) return;

    editUsecase({ ...values, id });
  };

  return (
    <>
      <Helmet>
        <title>Raised - Edit Template</title>
      </Helmet>
      {isUsecaseLoading ? (
        <Spin />
      ) : null}
      {isUsecaseSuccess ? (
        <TemplateForm
          title="Edit Usecase"
          onBack={onBack}
          isLoading={isLoading}
          onSubmit={onSubmit}
          defaultValues={usecase}
          submitButtonText="Save"
          backLinkText="Usecases"
          filesInput={(
            <AttachFiles
              files={files}
              addFiles={addFiles}
              uploadedFiles={uploadedFiles}
              removeFile={removeFile}
              subLabel="Provide a description of the desired expert, including their skills, level of experience, and any other relevant qualifications"
              label="Additional documents (optional)"
            />
      )}
        />
      ) : null}
    </>
  );
}
