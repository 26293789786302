import { User } from '@raised/domain';
import { DataModule, TypographyModule } from '@raised/ui-kit';

const { Paragraph } = TypographyModule;
const { Collapse, Panel } = DataModule;

interface ExpertCommentProps {
  comment: User.Public['adminComment']
}

export default function ExpertComment({
  comment,
}: ExpertCommentProps) {
  if (!comment) {
    return null;
  }

  return (
    <Collapse>
      <Panel header="Admin Comment" key="comment">
        <Paragraph>{comment.comment}</Paragraph>
      </Panel>
    </Collapse>
  );
}
