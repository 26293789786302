import { PaymentStatuses } from '@raised/domain';
import { TypographyModule } from '@raised/ui-kit';
import { PAYMENT_STATUS_LABELS } from 'components/payments/constants/payment-status-labels';
import styles from './PaymentStatus.module.css';

const { Text } = TypographyModule;

interface PaymentStatusProps {
  paymentStatus: PaymentStatuses;
}

export default function PaymentStatus({
  paymentStatus,
}: PaymentStatusProps) {
  return (
    <Text className={styles.status}>
      {PAYMENT_STATUS_LABELS[paymentStatus].value}
      {PAYMENT_STATUS_LABELS[paymentStatus].icon}
    </Text>
  );
}
