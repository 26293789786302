import { SuccessStory } from '@raised/domain';
import { FeedbackModule } from '@raised/ui-kit';
import { getErrorMessage } from 'api/helpers';
import useUploadFile from 'components/files/hooks/useUploadFIle';
import { useEffect } from 'react';
import { useEditSuccessStoryMutation } from '../storage/successStoriesApi';

const { Message } = FeedbackModule;

interface UseEditSuccessStoryProps {
  onSuccess?: (story: SuccessStory.Public) => void;
}

export default function useEditSuccessStory({
  onSuccess,
}: UseEditSuccessStoryProps) {
  const [mutate, info] = useEditSuccessStoryMutation();
  const {
    isSuccess, isError, error, data,
  } = info;
  const { uploadFile, isLoading: isFilesLoading } = useUploadFile();

  const handleUploadFile = async (file: SuccessStory.CreateFileInfo): Promise<SuccessStory.FileInfo> => {
    let link = typeof file.filePath === 'string' ? file.filePath : undefined;

    if (file.filePath instanceof File) {
      link = await uploadFile(file.filePath);
    }

    return {
      filePath: link || '',
      description: file.description,
    };
  };

  const editSuccessStory = async (values: SuccessStory.EditForm, id: string) => {
    const { files, ...rest } = values;
    const updatedFiles = files?.length ? await Promise.all(files.map((file) => handleUploadFile(file))) : undefined;

    mutate({ ...rest, id, files: updatedFiles });
  };

  useEffect(() => {
    if (!isSuccess) return;

    Message.success('Success story successfully updated!');

    if (onSuccess) {
      onSuccess(data);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;

    Message.error(getErrorMessage(error));
  }, [isError]);

  return {
    ...info,
    isLoading: info.isLoading || isFilesLoading,
    editSuccessStory,
  };
}
