import { Template } from '@raised/domain';
import { ButtonsModule, ColorsModule, TypographyModule } from '@raised/ui-kit';
import { IconCalendarStats } from '@tabler/icons-react';
import { PROJECT_CATEGORIES } from 'components/project/constants/project-categories';
import { PROJECT_TYPES } from 'components/project/constants/project-types';
import styles from './RecommendedTemplateItem.module.css';

const { Colors } = ColorsModule;
const { Text, Title, Paragraph } = TypographyModule;
const { Button } = ButtonsModule;

interface RecommendedTemplateItemProps {
  template: Template.Public;
  onAddClick: () => void;
}

export default function RecommendedTemplateItem({
  template,
  onAddClick,
}: RecommendedTemplateItemProps) {
  return (
    <div className={styles.container}>
      <div className={styles.title_block}>
        <div className={styles.title_block__icon}>
          <IconCalendarStats size={15} color={Colors.gray} className={styles.icon} />
          <Text type="secondary" caption>{template.type?.map((t) => PROJECT_TYPES[t]).join(', ')}</Text>
        </div>
        <Title level={3}>{template.title}</Title>
        <Text type="secondary">{template.category?.map((cat) => PROJECT_CATEGORIES[cat]).join(', ')}</Text>
      </div>
      <Paragraph>{template.description}</Paragraph>
      <Button onClick={onAddClick} className={styles.btn}>Add to your use cases & customise</Button>
    </div>
  );
}
