import { User, UserRole } from '@raised/domain';
import { Button, Divider, Typography } from 'antd';
import UserInfo from '../components/UserInfo';
import AdminComment from './admin-comment/AdminComment';
import ExpertProjectsList from './expert-projects/ExpertProjectsList';
import InviteLinkComponent from './InviteLinkComponent';
import UserProjectsList from './user-projects/UserProjectsList';

interface UserProfileComponentProps {
  profileData: User.Public;
  isAdmin: boolean;
  getProjectLink: (projectId: string) => string;
  getOrganizationLink: (orgId: string) => string;
  onEditProfileClick: () => void;
}

export default function UserProfileComponent({
  profileData,
  isAdmin,
  getProjectLink,
  getOrganizationLink,
  onEditProfileClick,
}: UserProfileComponentProps) {
  const isLink = isAdmin && profileData.isGhost;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography.Title style={{ marginBottom: 24 }} level={4}>Profile details</Typography.Title>
      <UserInfo isShowHidden={isAdmin} profileData={profileData} />
      {isAdmin && profileData.adminComment?.comment ? (
        <AdminComment comment={profileData.adminComment?.comment} />
      ) : null}
      {isLink ? (
        <InviteLinkComponent profileId={profileData.id} />
      ) : null}
      {isAdmin ? (
        <>
          <Button
            type="primary"
            style={{ alignSelf: 'flex-start', marginTop: 16 }}
            onClick={onEditProfileClick}
          >
            Edit user profile
          </Button>
          <Divider />
        </>
      ) : null}
      {profileData.role === UserRole.CLIENT && isAdmin ? (
        <>
          <Typography.Title level={4}>User projects</Typography.Title>
          <UserProjectsList
            userId={profileData.id}
            getProjectLink={getProjectLink}
          />
        </>
      ) : null}
      {profileData.role === UserRole.EXPERT && isAdmin ? (
        <>
          <Typography.Title level={4}>Expert matches</Typography.Title>
          <ExpertProjectsList
            userId={profileData.id}
            getProjectLink={getProjectLink}
            getOrganizationLink={getOrganizationLink}
          />
        </>
      ) : null}
    </div>
  );
}
