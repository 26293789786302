import { PROJECT_ROUTES } from 'components/project/project-routes';
import { USER_ROUTES } from 'components/user/user-routes';
import { Helmet } from 'react-helmet';
import { useGetLastActinosQuery } from '../storage/logMessageApi';
import LastActionsTable from './last-actions-table/LastActionsTable';
import LastActionsComponent from './LastActionsComponent';

export default function LastActionsPage() {
  const {
    data: actions,
    isLoading,
  } = useGetLastActinosQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const getUserLink = (userId: string) => USER_ROUTES.USER_PROFILE(userId);
  const getProjectLink = (projectId: string) => PROJECT_ROUTES.PROJECT(projectId);

  return (
    <LastActionsComponent>
      <Helmet>
        <title>Raised - Last actions</title>
      </Helmet>
      <LastActionsTable
        isLoading={isLoading}
        actions={actions || []}
        getUserLink={getUserLink}
        getProjectLink={getProjectLink}
      />
    </LastActionsComponent>
  );
}
