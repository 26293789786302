import cn from 'classnames';
import { User, UserRole } from '@raised/domain';
import {
  ReactNode, useState,
} from 'react';
import { LayoutModule, TypographyModule } from '@raised/ui-kit';
import MainInfo from './steps/main-info/MainInfo';
import CareerHistoryStep from './steps/career-history/CareerHistoryStep';
import styles from './UpdateUserForm.module.css';
import UseCasesStep from './steps/use-cases/UseCasesStep';
import SuccessStoriesStep from './steps/success-stories/SuccessStoriesStep';

const { Title } = TypographyModule;
const { Tabs } = LayoutModule;

enum Steps {
  MAIN_INFO = 'MAIN_INFO',
  CAREER_INFO = 'CAREER_INFO',
  USE_CASES = 'USE_CASES',
  SUCCESS_STORIES = 'SUCCESS_STORIES',
}

interface UpdateUserFormProps {
  onSubmit: (values: User.Edit) => void;
  onCancel: () => void;
  isLoading: boolean;
  defaultValues: User.Public;
  title: string;
  isAdmin?: boolean;
  isGhost?: boolean;
  messageComponent?: ReactNode;
  user: User.Public;
}

export default function UpdateUserForm({
  onSubmit,
  isLoading,
  defaultValues,
  title,
  onCancel,
  isAdmin = false,
  isGhost = false,
  messageComponent,
  user,
}: UpdateUserFormProps) {
  const [step, setStep] = useState(Steps.MAIN_INFO);

  const handleSubmit = (values: User.Edit) => {
    onSubmit(values);
  };

  const getTabs = () => {
    const t = [
      {
        label: 'Main Info',
        key: Steps.MAIN_INFO,
        children: <MainInfo
          onSubmit={handleSubmit}
          onCancel={onCancel}
          defaultValues={defaultValues}
          isGhost={isGhost}
          isUploadAvatar
          isAdmin={isAdmin}
          isLoading={isLoading}
        />,
      },
    ];

    if (defaultValues.role === UserRole.EXPERT) {
      t.push(
        {
          label: 'Career History',
          key: Steps.CAREER_INFO,
          children: <CareerHistoryStep
            onSubmit={handleSubmit}
            onCancel={onCancel}
            defaultValues={defaultValues}
            isLoading={isLoading}
          />,
        },
      );

      if (isAdmin) {
        t.push(
          {
            label: 'Use Cases',
            key: Steps.USE_CASES,
            children: <UseCasesStep user={user} />,
          },
          {
            label: 'Succsess stories & testimonials',
            key: Steps.SUCCESS_STORIES,
            children: <SuccessStoriesStep user={user} />,
          },
        );
      }
    }

    return t;
  };

  return (
    <div className={cn('form_container', styles.container)}>
      <Title level={1} style={{ textAlign: 'center' }}>{title}</Title>
      {messageComponent}
      <Tabs
        activeKey={step}
        onChange={(s) => setStep(s as Steps)}
        items={getTabs()}
      />
    </div>
  );
}
