import { Avatar as AntAvatar, AvatarProps as AntAvatarProps } from 'antd';
import cn from 'classnames';
import styles from './Avatar.module.css';

interface AvatarProps extends Omit<AntAvatarProps, 'size'> {
  size: 's' | 'm' | 'l' | number;
  color?: string;
  textColor?: string;
}

export default function Avatar({
  className,
  size,
  style,
  color,
  textColor,
  ...props
}: AvatarProps) {
  return (
    <AntAvatar
      {...props}
      className={cn(styles.avatar, {
        [styles.small]: size === 's',
        [styles.medium]: size === 'm',
        [styles.large]: size === 'l',
      }, className)}
      style={{
        ...style, backgroundColor: color, color: textColor, width: size, height: size, fontSize: typeof size === 'number' ? size / 2 : undefined,
      }}
    />
  );
}
