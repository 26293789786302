import {
  ButtonsModule, ColorsModule, DataModule, FeedbackModule,
} from '@raised/ui-kit';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import cn from 'classnames';
import { useState } from 'react';
import styles from './TemplateItemControls.module.css';

const { Popconfirm } = FeedbackModule;
const { Button, ActionButton } = ButtonsModule;
const { Actions } = DataModule;
const { Colors } = ColorsModule;

interface TemplateItemControlsProps {
  isEdit: boolean;
  onCreateProject: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export default function TemplateItemControls({
  isEdit,
  onCreateProject,
  onEdit,
  onDelete,
}: TemplateItemControlsProps) {
  const [isActive, setIsActive] = useState(false);

  const actionItems = [
    {
      content: (
        <ActionButton type="text" onClick={onEdit}>
          <IconPencil color={Colors.gray} size={20} />
          Edit
        </ActionButton>
      ),
    },
    {
      content: (
        <Popconfirm
          title="Delete template"
          description="Are you sure to delete this template?"
          onConfirm={onDelete}
          okText="Yes"
          cancelText="No"
        >
          <ActionButton type="text">
            <IconTrash color={Colors.gray} size={20} />
            Delete
          </ActionButton>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className={styles.controls}>
      <Button className={styles.create_btn} onClick={onCreateProject}>Create similar project</Button>
      {isEdit ? (
        <Actions
          className={cn(styles.actions, 'template_actions', {
            template_actions_active: isActive,
          })}
          trigger="click"
          items={actionItems}
          onOpenChange={setIsActive}
        />
      ) : null}
    </div>
  );
}
