import { ProjectStatuses } from '@raised/domain';
import { ColorsModule } from '@raised/ui-kit';
import {
  IconArchive, IconCircleCheck, IconProgress, IconUserCheck, IconUserSearch,
} from '@tabler/icons-react';
import { ReactNode } from 'react';
import ProgressIcon from '../components/progress-icon/ProgressIcon';

const { Colors } = ColorsModule;

export const PROJECT_STATUS_LABELS: Record<ProjectStatuses, string> = {
  [ProjectStatuses.LOOKING_FOR_PROFESSIONALS]: 'Raised is looking for professionals',
  [ProjectStatuses.IN_PROGRESS_NO_NEED_EXPERTS]: 'Project in progress',
  [ProjectStatuses.IN_PROGRESS_NEED_EXPERTS]: 'Project in progress & Raised is looking for more professionals',
  [ProjectStatuses.FINISHED]: 'Completed',
  [ProjectStatuses.ARCHIVED]: 'Archived',
  [ProjectStatuses.FOUND_AN_EXPERT]: 'Raised has found a relevant professional. Please accept or decline for a free introductory call.',
};

export const PROJECT_STATUS_CONFIG: { [key in ProjectStatuses]: { icon: ReactNode; background: string; } } = {
  [ProjectStatuses.ARCHIVED]: {
    icon: <IconArchive size={32} stroke={1.5} />,
    background: '#F9F9F9',
  },
  [ProjectStatuses.FINISHED]: {
    icon: <IconCircleCheck size={32} stroke={1.5} />,
    background: Colors['light-grayish-cyan'],
  },
  [ProjectStatuses.FOUND_AN_EXPERT]: {
    icon: <IconUserCheck size={32} stroke={1.5} />,
    background: Colors['light-grayish-cyan'],
  },
  [ProjectStatuses.IN_PROGRESS_NEED_EXPERTS]: {
    icon: <ProgressIcon size={32} />,
    background: '#F9F9F9',
  },
  [ProjectStatuses.IN_PROGRESS_NO_NEED_EXPERTS]: {
    icon: <IconProgress size={32} stroke={1.5} />,
    background: '#F9F9F9',
  },
  [ProjectStatuses.LOOKING_FOR_PROFESSIONALS]: {
    icon: <IconUserSearch size={32} stroke={1.5} />,
    background: '#F9F9F9',
  },
};
