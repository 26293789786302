import { ColumnsType } from 'antd/es/table';
import UserLink from 'components/user/components/UserLink';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/formats';

interface OrganisationsTableRow {
  key: string;
  organisationLink: string;
  name: string;
  website: string;
  createdAt: string;
}

export const getOrganizationsTableConfig = (): ColumnsType<OrganisationsTableRow> => [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (value, record) => <Link to={record.organisationLink}>{value}</Link>,
  },
  {
    title: 'Website',
    dataIndex: 'website',
    render: (value) => <UserLink href={value}>{value}</UserLink>,
  },
  {
    title: 'Creation date',
    dataIndex: 'createdAt',
    sorter: (a, b) => {
      const opA = new Date(a.createdAt).getTime();
      const opB = new Date(b.createdAt).getTime();
      return opA - opB;
    },
    render: (value) => formatDate(new Date(value)),
  },
];
