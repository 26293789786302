import { Template } from '@raised/domain';
import { FormsModule, TypographyModule } from '@raised/ui-kit';
import { Control, Controller } from 'react-hook-form';
import ProjectPartsForm from './project-parts-form/ProjectPartsForm';
import styles from './ProjectDescriptionForm.module.css';

const { Title, Text } = TypographyModule;
const { TextArea } = FormsModule;

interface ProjectDescriptionFormProps {
  control: Control<Template.Create>;
  title?: string;
  descriptionPlaceholder?: string;
  challengePlaceholder?: string;
  challengeLabel?: string;
}

export default function ProjectDescriptionForm({
  control,
  title,
  descriptionPlaceholder,
  challengePlaceholder,
  challengeLabel,
}: ProjectDescriptionFormProps) {
  return (
    <div className={styles.container}>
      <Title level={3}>{title || 'Project Description'}</Title>
      <Text type="secondary" className={styles.text}>Describe the challenge you are facing and the work you need the professional to do</Text>
      <Controller
        control={control}
        name="description"
        render={({ field, fieldState }) => (
          <TextArea
            className={styles.description_input}
            {...field}
            status={fieldState.error ? 'error' : undefined}
            placeholder={descriptionPlaceholder || 'Project Description'}
            showCount
            maxLength={500}
            rows={5}
          />
        )}
      />
      <Controller
        control={control}
        name="challenge"
        render={({ field, fieldState }) => (
          <TextArea
            className={styles.challenge_input}
            {...field}
            label={challengeLabel}
            status={fieldState.error ? 'error' : undefined}
            placeholder={challengePlaceholder || 'Project Challenge'}
            rows={5}
          />
        )}
      />
      <ProjectPartsForm control={control} />
    </div>
  );
}
