/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FeedbackModule, NavigationModule } from '@raised/ui-kit';
import { ReactNode } from 'react';
import styles from './TemplateContainer.module.css';

const { Breadcrumb } = NavigationModule;
const { Spin } = FeedbackModule;

interface TemplateContainerProps {
  children: ReactNode;
  controls: ReactNode;
  isLoading: boolean;
  onBack: () => void;
  templateTitle?: string,
}

export default function TemplateContainer({
  children,
  isLoading,
  onBack,
  templateTitle,
  controls,
}: TemplateContainerProps) {
  return (
    <div className={styles.container}>
      {templateTitle ? (
        <Breadcrumb
          className={styles.breadcrumb}
          items={[
            {
              title: <a onClick={onBack}>Templates</a>,
            },
            {
              title: templateTitle,
            },
          ]}
        />
      ) : null}
      <div className={styles.content}>
        {isLoading ? <Spin /> : children}
      </div>
      {controls}
    </div>
  );
}
