import { ButtonsModule, FormsModule, TypographyModule } from '@raised/ui-kit';
import { useState } from 'react';
import { ForgotPasswordRequestType } from '../storage/types';
import styles from './ForgotPasswordComponent.module.css';

const { Button } = ButtonsModule;
const { Input } = FormsModule;
const { Text } = TypographyModule;

interface ForgotPasswordComponentProps {
  isLoading: boolean;
  onSubmit: (data: ForgotPasswordRequestType) => void;
}

export default function ForgotPasswordComponent({
  isLoading,
  onSubmit,
}: ForgotPasswordComponentProps) {
  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    if (!email) return;

    onSubmit({ email });
    setEmail('');
  };

  return (
    <div className={styles.container}>
      <Text style={{ textAlign: 'center' }}>
        Enter the email address associated with your account and we&apos;ll send you a link to reset your password
      </Text>
      <Input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        placeholder="Email"
      />
      <Button
        onClick={handleSubmit}
        type="primary"
        disabled={isLoading}
        loading={isLoading}
        htmlType="submit"
      >
        Submit
      </Button>
    </div>
  );
}
