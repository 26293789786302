import { Modal, Spin } from 'antd';
import ViewProposal from 'components/proposal/components/view-proposal/ViewProposal';
import { useGetProposalByIdQuery } from 'components/proposal/storage/proposalApi';

interface ViewProposalModalProps {
  isVisible: boolean;
  onClose: () => void;
  proposalId: string;
}

export default function VlewProposalModal({
  isVisible,
  onClose,
  proposalId,
}: ViewProposalModalProps) {
  const {
    data,
    isLoading,
    isSuccess,
  } = useGetProposalByIdQuery({ id: proposalId }, { skip: !isVisible });

  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null}
    >
      {isLoading ? (
        <Spin />
      ) : null}
      {isSuccess ? (
        <ViewProposal proposal={data} />
      ) : null}
    </Modal>
  );
}
