import { ProjectStatuses } from '@raised/domain';
import { FeedbackModule } from '@raised/ui-kit';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useUpdateProjectStatusMutation } from '../storage/projectApi';

const { Message } = FeedbackModule;

export default function useUpdateProjectStatus() {
  const [updateStatus, info] = useUpdateProjectStatusMutation();
  const {
    isSuccess, isError, error, originalArgs,
  } = info;

  useEffect(() => {
    if (!isError) return;

    Message.error(getErrorMessage(error));
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;

    const text = originalArgs?.status === ProjectStatuses.IN_PROGRESS_NEED_EXPERTS ? 'More professionals are requested. Raised will return with relevant professionals shortly.' : 'Project status successfully updated';
    Message.success(text);
  }, [isSuccess]);

  return {
    updateStatus,
    ...info,
  };
}
