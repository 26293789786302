import { Project, UserRole } from '@raised/domain';
import { Spin } from 'antd';
import AttachFiles from 'components/files/attach-files/AttachFiles';
import { useGetProfileQuery, useGetUserByIdQuery } from 'components/user/storage/userApi';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import useGetProjectById from '../hooks/useGetProjectById';
import useUpdateProject from '../hooks/useUpdateProject';
import { PROJECT_ROUTES } from '../project-routes';
import EditProjectForm from './EditProjectForm';

export default function EditProjectPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: profile,
    isLoading: isProfileLoading,
    isSuccess: isProfileSuccess,
  } = useGetProfileQuery();
  const {
    data: project,
    isLoading: isProjectLoading,
    isSuccess: isProjectSuccess,
  } = useGetProjectById({ id });
  const {
    data: client,
  } = useGetUserByIdQuery({ id: project?.userId || '' }, { skip: !project?.userId || profile?.role !== UserRole.ADMIN });
  const {
    update,
    addFiles,
    removeFile,
    files,
    uploadedFiles,
    isLoading: isUpdateLoading,
  } = useUpdateProject({
    onSuccess: () => {
      if (!id) return;

      navigate(PROJECT_ROUTES.PROJECT(id));
    },
    initialFiles: project?.attachedFile,
  });

  const isLoading = isProjectLoading || isProfileLoading;
  const isAdmin = profile?.role === UserRole.ADMIN;
  const organisationId = isAdmin ? client?.organisationId : profile?.organisationId;

  useEffect(() => {
    if (!isProjectSuccess || !isProfileSuccess) return;

    if (project.organisationId !== profile.organisationId && profile.role !== UserRole.ADMIN) {
      navigate(-1);
    }
  }, [isProjectSuccess, isProfileSuccess]);

  const onFormSubmit = (data: Project.Create) => {
    if (!id) return;

    update({
      data,
      id,
    });
  };
  const onBack = () => navigate(-1);

  return (
    <>
      <Helmet>
        <title>Raised - Edit project</title>
      </Helmet>
      {isLoading ? (
        <Spin />
      ) : null}
      {isProjectSuccess && isProfileSuccess ? (
        <EditProjectForm
          initialData={project}
          onSubmit={onFormSubmit}
          isAdmin={isAdmin}
          isLoading={isUpdateLoading}
          organisationId={organisationId}
          onBack={onBack}
          filesInput={(
            <AttachFiles
              label="Additional documents (optional)"
              subLabel="Provide a description of the desired expert, including their skills, level of experience, and any other relevant qualifications"
              addFiles={addFiles}
              removeFile={removeFile}
              files={files}
              uploadedFiles={uploadedFiles}
            />
          )}
        />
      ) : null}
    </>
  );
}
