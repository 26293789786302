import { useViewAllNotificationsMutation } from '../storage/notificationsApi';

export default function viewAllNotifications() {
  const [viewAll, info] = useViewAllNotificationsMutation();

  return {
    viewAllNotifications: viewAll,
    ...info,
  };
}
