import { Payment } from '@raised/domain';
import { rootApi } from 'api/rootApi';

export const paymentsApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getPaymentById: build.query<Payment.Public, { paymentId: string }>({
      providesTags: (res) => (res ? [{ type: 'PAYMENT', id: res.id }] : ['PAYMENT']),
      query: ({ paymentId }) => ({
        url: `/payment/${paymentId}`,
      }),
    }),
    getClientPayments: build.query<Payment.Public[], void>({
      providesTags: (res) => (
        res?.length
          ? [...res.map((payment) => ({ id: payment.id, type: 'PAYMENT' }) as const), 'PAYMENT']
          : ['PAYMENT']
      ),
      query: () => ({
        url: '/payment/client',
      }),
    }),
    getPaymentsToExpert: build.query<Payment.Public[], void>({
      providesTags: (res) => (
        res?.length
          ? [...res.map((payment) => ({ id: payment.id, type: 'PAYMENT' }) as const), 'PAYMENT']
          : ['PAYMENT']
      ),
      query: () => ({
        url: '/payment/expert',
      }),
    }),
    getPaymentsByMatch: build.query<Payment.Public[], { matchId: string }>({
      providesTags: (res) => (
        res?.length
          ? [...res.map((payment) => ({ id: payment.id, type: 'PAYMENT' }) as const), 'PAYMENT']
          : ['PAYMENT']
      ),
      query: ({ matchId }) => ({
        url: `/payment/match/${matchId}`,
      }),
    }),
    setPaymentAsPaid: build.mutation<Payment.Public, { proposalId: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'PROPOSAL', id: args.proposalId }],
      query: ({ proposalId }) => ({
        url: `/payment/paid/proposal/${proposalId}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetPaymentByIdQuery,
  useGetClientPaymentsQuery,
  useGetPaymentsByMatchQuery,
  useGetPaymentsToExpertQuery,
  useSetPaymentAsPaidMutation,
} = paymentsApi;
