import { ButtonsModule, ColorsModule, TypographyModule } from '@raised/ui-kit';
import { IconMessageChatbot } from '@tabler/icons-react';
import styles from './NoUsecasesMessage.module.css';

const { Colors } = ColorsModule;
const { Text } = TypographyModule;
const { Button } = ButtonsModule;

interface NoUsecasesMessageProps {
  onCreateCase: () => void;
}

export default function NoUsecasesMessage({
  onCreateCase,
}: NoUsecasesMessageProps) {
  return (
    <div className={styles.no_usecases_message}>
      <IconMessageChatbot size={80} color={Colors.gray} stroke={1} />
      <Text type="secondary">First create use cases to add success stories</Text>
      <Button onClick={onCreateCase}>Create Case</Button>
    </div>
  );
}
