import { Project, ProjectCategories } from '@raised/domain';

export const PROJECT_CATEGORIES: { [key in ProjectCategories]: string } = {
  CEO_AND_FOUNDERS: 'CEO & Founders',
  MARKETING: 'Marketing',
  SALES_AND_BUSINESS_DEVELOPMENT: 'Sales & Business Development',
  PRODUCT: 'Product',
  SOFTWARE_DEVELOPMENT: 'Software Development',
  PEOPLE: 'People',
  OPERATIONS: 'Operations',
  STRATEGY: 'Strategy',
  FINANCE: 'Finance',
  CUSTOMER_SUCCESS: 'Customer Success',
  LEGAL: 'Legal',
} as const;

export const PROJECT_CATEGORIES_KEYS: { [key in ProjectCategories]: keyof Project.Categories } = {
  CEO_AND_FOUNDERS: 'ceoAndFounders',
  MARKETING: 'marketing',
  SALES_AND_BUSINESS_DEVELOPMENT: 'salesAndBusinessDevelopment',
  PRODUCT: 'product',
  SOFTWARE_DEVELOPMENT: 'softwareDevelopment',
  PEOPLE: 'people',
  OPERATIONS: 'operations',
  STRATEGY: 'strategy',
  FINANCE: 'finance',
  CUSTOMER_SUCCESS: 'customerSuccess',
  LEGAL: 'legal',
} as const;

export const PROJECT_CATEGORIES_VALUES: { [key in keyof Required<Project.Categories>]: { values: string[], label: string } } = {
  ceoAndFounders: {
    label: 'CEO & Founders',
    values: ['Board', 'C-Level Team Coaching', 'CEO / Founder Mentoring & Coaching', 'Fundraising'],
  },
  marketing: {
    label: 'Marketing',
    values: ['Branding', 'Content Marketing', 'Growth Marketing', 'Influencer Marketing', 'Lead Generation', 'Marketing Automation & Analytics', 'Marketing Strategy', 'Marketing Team Leadership & Hiring', 'Performance Marketing', 'PR', 'Product Marketing', 'SEO/SEM', 'Social Media Marketing', 'User Acquisition (mobile apps)'],
  },
  salesAndBusinessDevelopment: {
    label: 'Sales & Business Development',
    values: ['Account Management', 'Business Development', 'Channel Sales', 'Cold Outreach & ABM (Account-Based Marketing)', 'Inbound Sales', 'Outbound Sales', 'Partnerships', 'Sales Enablement', 'Sales Strategy', 'Sales Team Leadership & Hiring', 'Sales Training'],
  },
  product: {
    label: 'Product',
    values: ['Product Management', 'Product Strategy', 'Product Team Leadership & Hiring', 'Product-Led-Growth (PLG)', 'UX/UI Design'],
  },
  softwareDevelopment: {
    label: 'Software Development',
    values: ['Project Management', 'Software Development Consulting', 'Software Engineering Team Hiring & Leadership'],
  },
  people: {
    label: 'People',
    values: ['Organizational Development', 'People Team Leadership & Hiring', 'Talent Acquisition'],
  },
  operations: {
    label: 'Operations',
    values: ['Operations Team Leadership & Hiring', 'Process Improvement & Optimization', 'Scalability & Transformation'],
  },
  strategy: {
    label: 'Strategy',
    values: ['Market Research', 'Strategy Development'],
  },
  finance: {
    label: 'Finance',
    values: ['Finance Team Leadership & Hiring', 'Financial Consulting'],
  },
  customerSuccess: {
    label: 'Customer Success',
    values: ['Customer Success', 'Customer Support'],
  },
  legal: {
    label: 'Legal',
    values: ['Legal Team Leadership & Hiring', 'Legal Consulting'],
  },
};
