import { Usecase } from '@raised/domain';
import { SUCCESS_STORY_ROUTES } from 'components/success-stories/success-story-routes';
import ViewUsecaseModal from 'components/usecases/components/view-usecase-modal/ViewUsecaseModal';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetPublicExpertProfileQuery } from '../storage/userApi';
import { USER_ROUTES } from '../user-routes';
import ExpertCard from './components/expert-card/ExpertCard';
import SuccessStories from './components/success-stories/SuccessStories';
import Testimonials from './components/testimonials/Testimonials';
import PublicExpertProfileContent from './PublicExpertProfileContent';

export default function PublicExpertProfilePage() {
  const [isStoriesOpen, setIsStoriesOpen] = useState(false);
  const [selectedUsecase, setSelectedUsecase] = useState<Usecase.Public | undefined>();

  const navigate = useNavigate();
  const { id } = useParams();
  const {
    data: expert,
    isLoading: isExpertLoading,
  } = useGetPublicExpertProfileQuery({ id: id || '' }, { skip: !id });

  const onExpertPageClick = () => {
    if (!expert) return;

    navigate(USER_ROUTES.PUBLIC_EXPERT_PROFILE(expert.id));
  };

  const isMoreSuccessStories = (expert?.successStories?.length || 0) > 3;
  const storiesToRender = () => {
    if (window.innerWidth > 500) {
      if (isStoriesOpen) {
        return expert?.successStories;
      }
      return expert?.successStories?.slice(0, 6);
    }

    if (isStoriesOpen) {
      return expert?.successStories;
    }

    return expert?.successStories?.slice(0, 3);
  };

  return (
    <PublicExpertProfileContent isLoading={isExpertLoading}>
      <ExpertCard expert={expert} onUsecaseClick={setSelectedUsecase} />
      <SuccessStories
        isOpen={isStoriesOpen}
        isMoreStories={isMoreSuccessStories}
        successStories={storiesToRender() || []}
        getSuccessStoryLink={(storyId) => SUCCESS_STORY_ROUTES.PUBLIC_SUCCESS_STORY(storyId)}
      />
      <Testimonials
        testimonials={expert?.testimonials}
        isMoreStories={isMoreSuccessStories}
        onMoreClick={() => setIsStoriesOpen(!isStoriesOpen)}
        isOpen={isStoriesOpen}
      />
      <ViewUsecaseModal
        usecase={selectedUsecase}
        expert={expert}
        isVisible={Boolean(selectedUsecase)}
        onClose={() => setSelectedUsecase(undefined)}
        onExpertPageClick={onExpertPageClick}
      />
    </PublicExpertProfileContent>
  );
}
