import ExpertTags from 'components/user/components/ExpertTags';
import useGetExperts from '../../hooks/useGetExperts';
import { USER_ROUTES } from '../../user-routes';
import AdminExpertsListContainer from './AdminExpertsListContainer';
import ExpertFilters from './expert-filters/ExpertFilters';
import ExpertItem from '../expert-item/ExpertItem';
import ExpertPublicityFilters from './expert-publicity-filters/ExpertPublicityFilters';
import ExpertsPagination from '../experts-pagination/ExpertsPagination';
import ExpertComment from './expert-comment/ExpertComment';

export default function AdminExpertsListPage() {
  const {
    data: experts,
    total: totalExperts,
    isLoading: isExpertsLoading,
    isFetching: isExpertsFetching,
    isSuccess: isExpertsSuccess,
    changeFilter,
    filters,
    resetFilters,
  } = useGetExperts();

  const getLinkToExpert = (expertId: string) => USER_ROUTES.USER_PROFILE(expertId);
  const getEditExpertLink = (expertId: string) => USER_ROUTES.EDIT_USER_PROFILE(expertId);

  return (
    <AdminExpertsListContainer
      isLoading={isExpertsLoading || isExpertsFetching}
      isEmpty={Boolean(experts && !experts.length)}
      totalExperts={totalExperts || 0}
      sidebarContent={(
        <ExpertFilters
          resetFilters={resetFilters}
          filters={filters}
          onChangeFilter={changeFilter}
          additionalFilters={(
            <ExpertPublicityFilters
              filters={filters}
              onChangeFilter={changeFilter}
            />
          )}
        />
      )}
      pagination={isExpertsSuccess && (
        <ExpertsPagination
          page={filters.page}
          perPage={filters.perPage}
          onPageChange={(p) => changeFilter('page', p)}
          onPerPageChange={(perPage) => changeFilter('perPage', perPage)}
          total={totalExperts || 0}
        />
      )}
    >
      {experts?.map((expert) => (
        <ExpertItem
          key={expert.id}
          expert={expert}
          linkToExpert={getLinkToExpert(expert.id)}
          editExpertLink={getEditExpertLink(expert.id)}
          tagsContent={(
            <ExpertTags expert={expert} />
          )}
          additionalContent={(
            <ExpertComment comment={expert.adminComment} />
          )}
        />
      ))}
    </AdminExpertsListContainer>
  );
}
