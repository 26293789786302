import { User } from '@raised/domain';
import { useMemo, useState } from 'react';

interface UseInviteExpertModalProps {
  experts?: User.Public[];
}

export default function useInviteExpertModal({
  experts,
}: UseInviteExpertModalProps) {
  const [expertId, setExpertId] = useState('');

  const openModal = (userId: string) => setExpertId(userId);
  const closeModal = () => setExpertId('');
  const isVisible = Boolean(expertId);
  const selectedExpert = useMemo(() => experts?.find((expert) => expert.id === expertId), [experts, expertId]);

  return {
    openModal,
    closeModal,
    isVisible,
    selectedExpert,
  };
}
