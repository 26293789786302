import { message } from 'antd';
import { useEffect } from 'react';
import { useUpdateProposalStatusMutation } from '../storage/proposalApi';

export default function useUpdateProposalStatus() {
  const [updateProposalStatus, info] = useUpdateProposalStatusMutation();

  useEffect(() => {
    if (!info.isSuccess) return;

    message.success('Proposal status changed');
  }, [info.isSuccess]);

  return {
    updateProposalStatus,
    ...info,
  };
}
