import { UserRole } from '@raised/domain';
import { Spin } from 'antd';
import useRemoveFromOrganisation from 'components/user/hooks/useRemoveFromOrganisation';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import useInviteToOrganisation from '../hooks/useInviteToOrganisation';
import { ORGANISATION_ROUTES } from '../organisation-routes';
import { useGetOrganisationByIdQuery } from '../storage/organisationApi';
import OrganisationDashboardComponent from './OrganisationDashboardComponent';

export default function OrganisationDashboardPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: profile,
    isSuccess: isSuccessProfile,
  } = useGetProfileQuery();
  const {
    data: organisation,
    isLoading: isOrganisationLoading,
    isSuccess: isSuccessOrganisation,
  } = useGetOrganisationByIdQuery({ id: id || profile?.organisationId || '' }, { skip: !profile?.organisationId && !id });
  const {
    removeFromOrganisation,
    isLoading: isRemoveLoading,
  } = useRemoveFromOrganisation();
  const {
    inviteToOrganisation,
    isLoading: isInviteLoading,
  } = useInviteToOrganisation();

  const isOwner = profile?.role === UserRole.ADMIN ? true : profile?.id === organisation?.createdById;

  const onRemoveFromOrganisation = (userId: string) => {
    if (!organisation) return;

    removeFromOrganisation({ userId, organisationId: organisation.id });
  };
  const onInviteToOrganisation = (email: string) => {
    if (!organisation) return;

    inviteToOrganisation({ email, organisationId: organisation.id });
  };
  const onEditClick = () => {
    if (!organisation?.id) return;

    navigate(ORGANISATION_ROUTES.EDIT_ORGANISATION(organisation.id));
  };

  return (
    <>
      <Helmet>
        <title>Raised - Teammates</title>
      </Helmet>
      {isOrganisationLoading ? (
        <Spin />
      ) : null}
      {isSuccessOrganisation && isSuccessProfile ? (
        <OrganisationDashboardComponent
          organisation={organisation}
          onInviteToOrganisation={onInviteToOrganisation}
          onRemoveFromOrganisation={onRemoveFromOrganisation}
          isInviteLoading={isInviteLoading}
          isRemoveLoading={isRemoveLoading}
          isOwner={isOwner}
          personalId={profile.id}
          onEditClick={onEditClick}
        />
      ) : null}
    </>
  );
}
