import { Message } from '@raised/domain';
import { useCallback } from 'react';
import { useGetLastViewedByChatQuery } from '../storage/messageApi';

interface UseLastViewedMessageProps {
  pollingInterval?: number;
  chatId: string;
}

export default function useLastViewedMessage({
  pollingInterval,
  chatId,
}: UseLastViewedMessageProps) {
  const result = useGetLastViewedByChatQuery({
    chatId,
  }, {
    pollingInterval,
  });

  const getIsMessageViewed = useCallback((message: Message.Public) => {
    if (!result.data?.[0]) return false;

    const lastMessageTime = new Date(result.data[0].createdAt).getTime();

    return new Date(message.createdAt).getTime() <= lastMessageTime;
  }, [result.data]);

  return {
    ...result,
    getIsMessageViewed,
  };
}
