import { createBrowserRouter } from 'react-router-dom';
import userRoutes from 'components/user/user-routes';
import projectRoutes from 'components/project/project-routes';
import authRoutes from 'components/auth/auth-routes';
import matchRoutes from 'components/match/match-routes';
import messagesRoutes from 'components/messages/messages-routes';
import InitialRoute from 'components/layout/containers/initial-route/InitialRoute';
import proposalRoutes from 'components/proposal/proposal-routes';
import paymentsRoutes from 'components/payments/payments-routes';
import logMessagesRoutes from 'components/log-messages/log-messages-routes';
import organisationRoutes from 'components/organisation/organisation-routes';
import templatesRoutes from 'components/templates/templates-routes';
import errorRoutes from 'components/errors/errors-routes';
import usecaseRoutes from 'components/usecases/usecase-routes';
import successStoryRoutes from 'components/success-stories/success-story-routes';

const router = createBrowserRouter([
  {
    path: '/',
    element: <InitialRoute />,
  },
  ...userRoutes,
  ...projectRoutes,
  ...authRoutes,
  ...matchRoutes,
  ...messagesRoutes,
  ...proposalRoutes,
  ...paymentsRoutes,
  ...logMessagesRoutes,
  ...organisationRoutes,
  ...templatesRoutes,
  ...usecaseRoutes,
  ...successStoryRoutes,
  ...errorRoutes,
]);

export default router;
