import { FormsModule, TypographyModule } from '@raised/ui-kit';
import { Space } from 'antd';
import { PROJECT_TYPES } from 'components/project/constants/project-types';
import { Control, Controller } from 'react-hook-form';

const { Select } = FormsModule;
const { Title } = TypographyModule;

interface ExpertAvailabilityProps {
  control: Control;
}

const availabilityTypes = Object.entries(PROJECT_TYPES).map(([value, label]) => ({ value, label }));

export default function ExpertAvailability({ control }: ExpertAvailabilityProps) {
  return (
    <Space size={8} direction="vertical" style={{ width: '100%', marginBottom: 16 }}>
      <Title level={2} style={{ margin: 0 }}>Availability</Title>
      <Controller
        control={control}
        name="availabilityTypes"
        render={({ field, fieldState }) => (
          <Select
            label="Availability types"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select one or more availability types"
            {...field}
            status={fieldState.error ? 'error' : undefined}
            options={availabilityTypes}
          />
        )}
      />
    </Space>
  );
}
