import { Proposal } from '@raised/domain';
import Table from 'components/layout/components/table/Table';
import { useMemo } from 'react';
import { getCreatePaymentsTableConfig } from './createPaymentsTableConfig';

interface CreatePaymentsTableProps {
  proposals: Proposal.Public[];
  onPayClick: (proposalId: string) => void;
  isLoading: boolean;
  getExpertLink: (id: string) => string;
  onViewProposalClick: (proposalId: string) => void;
}

export default function CreatePaymentsTable({
  proposals,
  onPayClick,
  isLoading,
  getExpertLink,
  onViewProposalClick,
}: CreatePaymentsTableProps) {
  const tableConfig = useMemo(() => getCreatePaymentsTableConfig({
    onPayClick,
    onViewProposalClick,
  }), []);
  const tableData = useMemo(() => proposals.map((proposal) => ({
    key: proposal.id,
    proposalTitle: proposal.title,
    status: proposal.status,
    expertName: `${proposal.expert?.firstName} ${proposal.expert?.lastName}`,
    expertLink: getExpertLink(proposal.expertId),
  })), [proposals]);

  return (
    <Table
      tableName="createPaymentsTable"
      loading={isLoading}
      dataSource={tableData}
      columns={tableConfig}
    />
  );
}
