import { UserRole } from '@raised/domain';

export const getRoleLabel = (role: string) => {
  switch (role) {
    case UserRole.ADMIN:
      return 'Raised Admin';
    case UserRole.CLIENT:
      return 'Client';
    case UserRole.EXPERT:
      return 'Expert';
    default:
      return '';
  }
};
