import useViewAllNotifications from 'components/notifications/hooks/useViewAllNotifications';
import { User } from '@raised/domain';
import NotificationContentHeader from './components/notification-content-header/NotificationContentHeader';
import NotificationContent from './components/notification-content/NotificationContent';
import NotificationLinks from './components/notification-links/NotificationLinks';
import NotificationTabs from './components/notification-tabs/NotificationTabs';
import NotificationsLayout from './components/notifications-layout/NotificationsLayout';
import NotificationsList from './components/notifications-list/NotificationsList';
import NotificationDrawer from './components/notification-drawer/NotificationDrawer';
import useNotificationLinks from '../../hooks/useNotificationLinks';
import useNotifications from '../../hooks/useNotifications';
import useViewNotifications from '../../hooks/useViewNotifications';
import MobileNotificationTabs from './components/notification-tabs/MobileNotificationTabs';

interface NotificationCenterProps {
  isVisible: boolean;
  onClose: () => void;
  profile: User.Public;
}

export default function NotificationCenter({
  isVisible,
  onClose,
  profile,
}: NotificationCenterProps) {
  const {
    onLinkClick,
    links,
    selectedLink,
    resetLink,
  } = useNotificationLinks();
  const {
    allNotifications,
    messageNotifications,
    projectActionsNotifications,
    onNotificationClick,
    isLoading: isNotificationsLoading,
  } = useNotifications({
    selectedLink: selectedLink?.id,
    userRole: profile.role,
  });
  const { setObserverForNotification } = useViewNotifications();
  const { viewAllNotifications, isLoading: isViewAllLoading } = useViewAllNotifications();

  const handleClose = () => {
    resetLink();
    onClose();
  };

  return (
    <NotificationDrawer
      isVisible={isVisible}
      onClose={handleClose}
    >
      <NotificationsLayout
        navigation={(
          <NotificationLinks
            onLinkClick={onLinkClick}
            links={links}
            selectedLink={selectedLink?.id || 'all'}
            onBack={onClose}
            personalProfileId={profile.id}
          />
        )}
      >
        <NotificationContent
          header={(
            <NotificationContentHeader
              isLoading={isViewAllLoading}
              title={selectedLink?.title || 'All projects'}
              onButtonClick={viewAllNotifications}
            />
          )}
        >
          <NotificationTabs
            onTabChange={() => {}}
            allContent={(
              <NotificationsList
                notifications={allNotifications}
                onNotificationClick={onNotificationClick}
                isLoading={isNotificationsLoading}
                setObserverForNotification={setObserverForNotification}
                personalProfileId={profile.id}
              />
            )}
            projectActionsContent={(
              <NotificationsList
                notifications={projectActionsNotifications}
                onNotificationClick={onNotificationClick}
                isLoading={isNotificationsLoading}
                setObserverForNotification={setObserverForNotification}
                personalProfileId={profile.id}
              />
            )}
            messagesContent={(
              <NotificationsList
                notifications={messageNotifications}
                onNotificationClick={onNotificationClick}
                isLoading={isNotificationsLoading}
                setObserverForNotification={setObserverForNotification}
                personalProfileId={profile.id}
              />
            )}
          />
          <MobileNotificationTabs
            links={links}
            onTabChange={onLinkClick}
            listContent={(
              <NotificationsList
                notifications={allNotifications}
                onNotificationClick={onNotificationClick}
                isLoading={isNotificationsLoading}
                setObserverForNotification={setObserverForNotification}
                personalProfileId={profile.id}
              />
            )}
          />
        </NotificationContent>
      </NotificationsLayout>
    </NotificationDrawer>
  );
}
