import { MatchStatuses } from '@raised/domain';
import { FormsModule, LayoutModule, TypographyModule } from '@raised/ui-kit';
import { Modal } from 'antd';
import { ChangeEvent, useState } from 'react';
import cn from 'classnames';
import styles from './CandidateModal.module.css';

const { Space } = LayoutModule;
const { TextArea } = FormsModule;
const { Text } = TypographyModule;

interface CandidateModalProps {
  onSubmit: (msg: string) => void;
  onClose: () => void;
  decision?: MatchStatuses;
  isVisible: boolean;
}

export default function CandidateModal({
  onSubmit,
  onClose,
  decision,
  isVisible,
}: CandidateModalProps) {
  const [msg, setMsg] = useState('');

  const onChangeMsg = (e: ChangeEvent<HTMLTextAreaElement>) => setMsg(e.target.value);

  const handleOk = () => {
    onSubmit(msg);
    setMsg('');
    onClose();
  };
  const handleCancel = () => {
    setMsg('');
    onClose();
  };
  const getTitle = () => {
    switch (decision) {
      case MatchStatuses.INVITED_TO_INTERVIEW:
        return 'Invite expert to interview';
      case MatchStatuses.DECLINED_BY_CLIENT:
        return 'Decline this Expert';
      case MatchStatuses.INTERSTED_BY_CLIENT:
        return 'Accept this Expert';
      case MatchStatuses.FINISHED:
        return 'Mark as finished';
      default:
        return '';
    }
  };

  return (
    <Modal
      title={getTitle()}
      centered
      open={isVisible}
      onOk={() => {
        handleOk();
      }}
      onCancel={() => {
        handleCancel();
      }}
      okText="Ok"
      data-testid="modal_confirmCandidate"
      className={cn(styles.modal, {
        [styles.decline_modal]: decision === MatchStatuses.DECLINED_BY_CLIENT,
      })}
    >
      {decision === MatchStatuses.DECLINED_BY_CLIENT ? (
        <Space direction="vertical" size={0} style={{ marginBottom: 16 }}>
          <Text type="secondary">Your comment will only be visible to Raised</Text>
          <Text style={{ marginBottom: 8, display: 'block' }}>Please feel free to provide us with feedback on the candidate. Your input will assist us in finding better candidates in the future. (Your comment will only be visible to Raised)</Text>
        </Space>
      ) : null}
      <TextArea rows={4} placeholder="Message" value={msg} onChange={onChangeMsg} />
    </Modal>
  );
}
