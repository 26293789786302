import LoginForm from 'components/auth/login/LoginForm';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getInitialRoute } from '../utils';
import useLogin from '../hooks/useLogin';

export default function LoginPage() {
  const navigate = useNavigate();
  const { login, isLoading } = useLogin({
    onSuccess: (profile) => navigate(getInitialRoute(profile.role)),
  });

  return (
    <>
      <Helmet>
        <title>Raised - Login</title>
      </Helmet>
      <LoginForm
        isLoading={isLoading}
        onSubmit={login}
        title="Sign In"
      />
    </>
  );
}
