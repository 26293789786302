import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import styles from './NotificationContentHeader.module.css';

const { Title } = TypographyModule;
const { Button } = ButtonsModule;

interface NotificationContentHeaderProps {
  onButtonClick: () => void;
  title: string;
  isLoading: boolean;
}

export default function NotificationContentHeader({
  onButtonClick,
  title,
  isLoading,
}: NotificationContentHeaderProps) {
  return (
    <div className={styles.container}>
      <Title level={2} className={styles.title}>{title}</Title>
      <Button className={styles.btn} loading={isLoading} disabled={isLoading} onClick={onButtonClick}>Mark All As Read</Button>
    </div>
  );
}
