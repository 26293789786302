import { Payment } from '@raised/domain';
import { ButtonsModule, FeedbackModule, TypographyModule } from '@raised/ui-kit';
import { formatCurrency } from 'utils/formats';
import PaymentStatus from '../PaymentStatus';
import styles from './MatchPaymentsTable.module.css';

const { Button } = ButtonsModule;
const { Title } = TypographyModule;
const { Spin } = FeedbackModule;

interface MatchPaymentsTableProps {
  payments: Payment.Public[];
  onViewProposalClick: (proposalId: string) => void;
  loading: boolean;
}

export default function MatchPaymentsTable({
  payments,
  onViewProposalClick,
  loading,
}: MatchPaymentsTableProps) {
  return (
    <div className={styles.match_payments_table}>
      {loading ? (
        <div className={styles.loading}>
          <Spin />
        </div>
      ) : null}
      {!loading ? payments.map((payment) => (
        <div key={payment.id} className={styles.table_row}>
          <div className={styles.proposal_info}>
            <Title level={5}>
              {payment.proposal?.title}
            </Title>
            <Button
              type="link"
              onClick={() => onViewProposalClick(payment.proposalId)}
              className={styles.view_button}
            >
              View proposal
            </Button>
          </div>
          <div className={styles.payment_info}>
            <Title level={5}>{formatCurrency(payment.amount / 100)}</Title>
            <PaymentStatus paymentStatus={payment.status} />
          </div>
        </div>
      )) : null}
    </div>
  );
}
