import { Helmet } from 'react-helmet';
import useVerifyToken from '../hooks/useVerifyToken';
import TokenLoginComponent from './TokenLoginComponent';

export default function TokenLoginPage() {
  const { isLoading } = useVerifyToken();

  return (
    <>
      <Helmet>
        <title>Raised - Login</title>
      </Helmet>
      {isLoading ? (
        <TokenLoginComponent />
      ) : null}
    </>
  );
}
