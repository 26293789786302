import { useState } from 'react';

export default function useCreateSuccessStoryModal() {
  const [usecaseId, setUsecaseId] = useState('');

  const openModal = (id: string) => setUsecaseId(id);
  const closeModal = () => setUsecaseId('');

  return {
    isVisible: Boolean(usecaseId),
    openModal,
    closeModal,
    selectedUsecaseId: usecaseId,
  };
}
