import { Tag } from 'antd';

interface NewMessagesCelProps {
  isNewMessages: boolean;
}

export default function NewMessagesCell({
  isNewMessages,
}: NewMessagesCelProps) {
  return (
    <>
      {isNewMessages ? (
        <Tag color="success">New messages</Tag>
      ) : (
        <Tag color="processing">No new messages</Tag>
      )}
    </>
  );
}
