import { User } from '@raised/domain';
import {
  ButtonsModule, ColorsModule, TypographyModule,
} from '@raised/ui-kit';
import { IconTrash } from '@tabler/icons-react';
import UserAvatar from 'components/user/components/UserAvatar';
import InviteStatus from './invite-status/InviteStatus';
import styles from './TeammatesList.module.css';

const { Title, Text } = TypographyModule;
const { Colors } = ColorsModule;
const { Button } = ButtonsModule;

interface TeammatesListProps {
  members: User.Public[];
  invites: string[];
  onDelete: (userId: string) => void;
  isDeleteLoading: boolean;
}

export default function TeammatesList({
  members,
  invites,
  onDelete,
  isDeleteLoading,
}: TeammatesListProps) {
  return (
    <div className={styles.container}>
      <Title className={styles.title} level={4}>Your Teammates</Title>
      <Text type="secondary" className={styles.description}>Your teammate will be able to create new projects, see all your company&apos;s projects and have access to all chat conversations</Text>
      <div className={styles.table}>
        {members.map((member) => (
          <div className={styles.table_row} key={member.id}>
            <div className={styles.user_details}>
              <UserAvatar role={member.role} size="s" filePath={member.avatar} />
              <div className={styles.user_info}>
                <Title level={5}>{`${member.firstName} ${member.lastName}`}</Title>
                <a href={`mailto:${member.email}`}><Text className={styles.email}>{member.email}</Text></a>
                <InviteStatus className={styles.mobile_status} status="success" />
              </div>
            </div>
            <InviteStatus className={styles.desktop_status} status="success" />
            <Button
              disabled={isDeleteLoading}
              loading={isDeleteLoading}
              onClick={() => onDelete(member.id)}
              className={styles.remove_btn}
              type="text"
            >
              <IconTrash color={Colors.gray} size={16} />
              <span>Remove</span>
            </Button>
          </div>
        ))}
        {invites.map((invite) => (
          <div className={styles.table_row} key={invite}>
            <div className={styles.user_details}>
              <div className={styles.avatar_placeholder} />
              <div className={styles.user_info}>
                <Title level={5} type="secondary">{invite}</Title>
              </div>
            </div>
            <InviteStatus status="pending" />
          </div>
        ))}
      </div>
    </div>
  );
}
