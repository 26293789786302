import { ORGANISATION_ROUTES } from '../organisation-routes';
import { useGetAllOrganisationsQuery } from '../storage/organisationApi';
import AdminOrganizationsListContainer from './AdminOrganizationsListContainer';
import OrganizationsTable from './organizations-table/OrganizationsTable';

export default function AdminOrganizationsListPage() {
  const {
    data: organizations,
    isLoading: isOrganizationsLoading,
  } = useGetAllOrganisationsQuery();
  const getOrganizationLink = (orgId: string) => ORGANISATION_ROUTES.ADMIN_ORGANIZATION(orgId);

  return (
    <AdminOrganizationsListContainer>
      <OrganizationsTable
        organizations={organizations || []}
        getOrganizationLink={getOrganizationLink}
        isLoading={isOrganizationsLoading}
      />
    </AdminOrganizationsListContainer>
  );
}
