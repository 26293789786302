import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './Empty.module.css';

interface EmptyProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: ReactNode;
  children?: ReactNode;
  height?: number | string;
}

export default function Empty({
  icon,
  children,
  height,
  style,
  className,
  ...rest
}: EmptyProps) {
  return (
    <div {...rest} className={cn(styles.empty, className)} style={{ ...style, height }}>
      {icon}
      <div className={styles.content}>{children}</div>
    </div>
  );
}
