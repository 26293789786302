import { FormsModule } from '@raised/ui-kit';
import { Control, Controller } from 'react-hook-form';

const { TextArea } = FormsModule;

interface ExpertAdminCommentProps {
  control: Control;
}

export default function ExpertAdminComment({
  control,
}: ExpertAdminCommentProps) {
  return (
    <Controller
      control={control}
      name="adminComment"
      render={({ field, fieldState }) => (
        <TextArea
          {...field}
          rows={4}
          status={fieldState?.error ? 'error' : undefined}
          placeholder="Comment"
          label="Comment"
        />
      )}
    />
  );
}
