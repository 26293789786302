import { Button } from 'antd';

interface PaymentCellProps {
  onPayClick: () => void;
}

export default function PaymentCell({
  onPayClick,
}: PaymentCellProps) {
  return (
    <Button
      onClick={onPayClick}
    >
      Set as paid
    </Button>
  );
}
