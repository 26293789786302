import { Chat } from '@raised/domain';
import { Typography, Space } from 'antd';
import { Link } from 'react-router-dom';

interface ChatsComponentProps {
  chats: Chat.Public[];
  getChatLink: (matchId: string) => string;
}

export default function ChatsComponent({
  chats,
  getChatLink,
}: ChatsComponentProps) {
  return (
    <div style={{ marginBottom: 16 }}>
      <Typography.Title level={5}>Chats</Typography.Title>
      {!chats.length ? (
        <Typography.Text>There are no chats for this project</Typography.Text>
      ) : (
        <Space direction="vertical" size={4}>
          {chats.map((chat) => (
            <Link
              key={chat.id}
              to={getChatLink(chat.id)}
            >
              {`Chat with expert ${chat.match?.user?.firstName} ${chat.match?.user?.lastName}`}
            </Link>
          ))}
        </Space>
      )}
    </div>
  );
}
