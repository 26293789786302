import { FileOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { getFileName } from 'components/files/utils';
import styles from './ChatFile.module.css';

interface ChatFileProps {
  fileUrl: string;
}

export default function ChatFile({ fileUrl }: ChatFileProps) {
  return (
    <a className={styles.chat_file} href={fileUrl} target="_blank" rel="noreferrer">
      <Space>
        <FileOutlined />
        <Typography.Text>{getFileName(fileUrl) ?? 'Attached file'}</Typography.Text>
      </Space>
    </a>
  );
}
