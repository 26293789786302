import { Usecase } from '@raised/domain';
import { FeedbackModule } from '@raised/ui-kit';
import { getErrorMessage } from 'api/helpers';
import useAttachedFiles from 'components/files/hooks/useAttachedFiles';
import useUploadFiles from 'components/files/hooks/useUploadFiles';
import { useEffect } from 'react';
import { useUpdateUsecaseMutation } from '../storage/usecasesApi';

const { Message } = FeedbackModule;

interface UseEditUsecaseProps {
  onSuccess: (template: Usecase.Public) => void;
  initialFiles?: string[];
}

export default function useEditUsecase({
  onSuccess,
  initialFiles,
}: UseEditUsecaseProps) {
  const [mutate, info] = useUpdateUsecaseMutation();
  const {
    isError, error, isSuccess, data,
  } = info;
  const { uploadFiles, isLoading: isFilesLoading } = useUploadFiles();
  const {
    files,
    uploadedFiles,
    addFiles,
    removeFile,
  } = useAttachedFiles({
    initialValues: initialFiles,
  });

  const editUsecase = async (formData: Usecase.Create & { id: string }) => {
    const attachedFiles = [...uploadedFiles];

    if (files.length) {
      const result = await uploadFiles(files);

      if (result) {
        attachedFiles.push(...result.map((file) => file.url));
      }
    }

    mutate({
      ...formData,
      attachedFile: attachedFiles,
    });
  };

  useEffect(() => {
    if (!isSuccess) return;

    Message.success('Use case successfully updated');
    onSuccess(data);
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;

    Message.error(getErrorMessage(error));
  }, [isError]);

  return {
    ...info,
    isLoading: info.isLoading || isFilesLoading,
    addFiles,
    removeFile,
    uploadedFiles,
    files,
    editUsecase,
  };
}
