import {
  useCallback, useEffect, useRef,
} from 'react';
import { useViewAllMessagesByChatMutation, useViewMessagesMutation } from '../storage/messageApi';

interface UseViewMessagesProps {
  chatId: string;
}

export default function useViewMessages({
  chatId,
}: UseViewMessagesProps) {
  const isViewed = useRef(false);
  const [view] = useViewMessagesMutation();
  const [viewAll] = useViewAllMessagesByChatMutation();
  const observerRef = useRef<IntersectionObserver>();

  const intersectionCallback: IntersectionObserverCallback = (entries) => {
    const messageIds: string[] = [];

    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;

      if (typeof (entry.target as HTMLDivElement).dataset.messageid === 'string') {
        messageIds.push((entry.target as HTMLDivElement).dataset.messageid as string);
        observerRef.current?.unobserve(entry.target);
      }
    });

    view({ messageIds });
  };

  useEffect(() => {
    if (!isViewed.current) {
      viewAll({ chatId });
      isViewed.current = true;
    }

    if (!observerRef.current) {
      observerRef.current = new IntersectionObserver(intersectionCallback, {
        root: null,
        threshold: 1,
      });
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, []);

  const setObserveForMessage = useCallback((element: HTMLDivElement) => {
    if (!observerRef.current || !element) return;

    observerRef.current.observe(element);
  }, [observerRef]);

  return {
    setObserveForMessage,
  };
}
