import { useRef } from 'react';

export default function useChatScroll() {
  const chatMessagesRef = useRef<HTMLDivElement>(null);

  const onNewMessage = () => {
    if (!chatMessagesRef.current) return;

    setTimeout(() => {
      chatMessagesRef.current?.scrollTo({ top: chatMessagesRef.current.scrollHeight, behavior: 'smooth' });
    }, 300);
  };

  return {
    chatMessagesRef,
    onNewMessage,
  };
}
