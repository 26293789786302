import { FormsModule } from '@raised/ui-kit';
import { Control } from 'react-hook-form';

const { Input, TextArea } = FormsModule;

export const getExpertTestimonialsFormConfig = (control: Control, prefix: string) => [
  {
    controller: {
      name: `${prefix}.name`,
      control,
      defaultValue: '',
    },
    component: Input,
    label: 'Name',
    componentProps: {
      placeholder: 'Name',
    },
  },
  {
    controller: {
      name: `${prefix}.company`,
      control,
      defaultValue: '',
    },
    component: Input,
    label: 'Company',
    componentProps: {
      placeholder: 'Company',
    },
  },
  {
    controller: {
      name: `${prefix}.position`,
      control,
      defaultValue: '',
    },
    component: Input,
    label: 'Position',
    componentProps: {
      placeholder: 'Position',
    },
  },
  {
    controller: {
      name: `${prefix}.projectDescription`,
      control,
      defaultValue: '',
    },
    component: TextArea,
    label: 'Project description Position duration',
    componentProps: {
      rows: 5,
      placeholder: 'Project description Position duration',
    },
  },
  {
    controller: {
      name: `${prefix}.review`,
      control,
      defaultValue: '',
    },
    component: TextArea,
    label: 'Review',
    componentProps: {
      rows: 5,
      placeholder: 'Review',
    },
  },
];
