import { z } from 'zod';
import { linkRule, slugRule } from '../common/schemas';
import { createOrganisationSchema } from '../organisation/schemas';
import { ActivateGhost, Create, Edit, CreateGhost, Login, Info, JoinOrganisation, FulltimeRole, ParttimeRole, Testimonial, MainInfo, CareerInfo } from './user';
import UserRole from './user-role'
import UserActivityType from './user-activity-type';

const expertCategoriesSchema = z.record(z.string().array().optional())

const fulltimeRoleSchema: z.ZodSchema<FulltimeRole> = z.object({
  website: linkRule,
  name: z.string().min(1),
  position: z.string().optional(),
  info: z.string().optional(),
  from: z.string().datetime(),
  to: z.string().datetime().nullable(),
  keyAchievments: z.string().array().optional(),
});

const parttimeRoleSchema: z.ZodSchema<ParttimeRole> = z.object({
  website: linkRule,
  name: z.string().min(1),
  position: z.string().optional(),
  info: z.string().optional(),
  from: z.string().datetime(),
  to: z.string().datetime().nullable(),
  challenge: z.string(),
  action: z.string(),
  result: z.string(),
  completedOnRaised: z.boolean().optional(),
});

const expertTagsSchema: z.ZodSchema<Info['tags']> = z.object({
  function: z.string().array().optional(),
  type: z.string().array().optional(),
  stage: z.string().array().optional(),
  businessModel: z.string().array().optional(),
  industry: z.string().array().optional(),
  markets: z.string().array().optional(),
  countries: z.string().array().optional(),
});

const testimonialsSchema: z.ZodSchema<Testimonial> = z.object({
  name: z.string().min(1),
  company: z.string().min(1),
  position: z.string().min(1),
  projectDescription: z.string().min(1),
  review: z.string().min(1),
});

export const mainInfoSchema: z.ZodSchema<MainInfo> = z.object({
  avatar: z.string().optional(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  biography: z.string().optional(),
  externalLink: linkRule,
  rate: z.string().optional(),
  calendlyLink: linkRule,
  tags: expertTagsSchema.optional(),
  availabilityTypes: z.string().array().optional(),
  isProfileVisible: z.boolean().optional(),
  headline: z.string().optional(),
  isProfilePublic: z.boolean().optional(),
  activityType: z.nativeEnum(UserActivityType).optional(),
  publishedOnPublicWebsites: slugRule,
  projectCategories: expertCategoriesSchema.optional(),
})

export const editMainInfoSchema: z.ZodSchema<MainInfo & { email?: string, adminComment?: string }> = z.object({
  email: z.string().email().optional().or(z.literal('')),
  adminComment: z.string().optional(),
}).and(mainInfoSchema);

export const createMainInfoSchema: z.ZodSchema<MainInfo & { email: string, password: string, role: UserRole } & Partial<z.infer<typeof createOrganisationSchema>>> = z.object({
  email: z.string().email(),
  password: z.string().min(5),
  role: z.enum([UserRole.CLIENT, UserRole.EXPERT]),
}).and(mainInfoSchema).and(createOrganisationSchema.partial());

export const createGhostMainInfoSchema: z.ZodSchema<MainInfo & { email?: string, password?: string, role: UserRole } & Partial<z.infer<typeof createOrganisationSchema>>> = z.object({
  email: z.string().email().optional().or(z.literal('')),
  role: z.enum([UserRole.CLIENT, UserRole.EXPERT]),
}).and(mainInfoSchema).and(createOrganisationSchema.partial());

export const careerInfoSchema: z.ZodSchema<CareerInfo> = z.object({
  fulltimeRoles: fulltimeRoleSchema.array().optional(),
  parttimeRoles: parttimeRoleSchema.array().optional(),
  testimonials: testimonialsSchema.array().optional(),
})

const userInfoSchema: z.ZodSchema<Info> = z.object({
}).and(mainInfoSchema).and(careerInfoSchema)

export const loginSchema: z.ZodSchema<Login> = z.object({
  email: z.string().email(),
  password: z.string().min(5),
});

export const resetPasswordSchema = z.object({
  password: z.string().min(5),
  confirmPassword: z.string(),
}).refine((data) => data.password === data.confirmPassword, {
  message: 'Password dont`t match',
  path: ['confirmPassword'],
});

export const joinOrganisationSchema: z.ZodSchema<JoinOrganisation> = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  organisationId: z.string(),
  token: z.string(),
}).and(loginSchema);

export const createUserSchema: z.ZodSchema<Create> = z.object({
}).and(createMainInfoSchema).and(careerInfoSchema).refine((val) => {
  if (val.role === UserRole.CLIENT) {
    return !!val.companyName;
  }

  return true;
}, {
    path: ['companyName'],
});

export const createGhostUserSchema: z.ZodSchema<CreateGhost> = z.object({
}).and(createGhostMainInfoSchema).and(careerInfoSchema).refine((val) => {
  if (val.role === UserRole.CLIENT) {
    return !!val.companyName;
  }

  return true;
}, {
    path: ['companyName'],
});

export const editUserSchema: z.ZodSchema<Edit> = z.object({
}).and(editMainInfoSchema).and(careerInfoSchema);

export const activateGhostSchema: z.ZodSchema<ActivateGhost> = z.object({
  password: z.string().min(5),
  email: z.string().email(),
  userId: z.string(),
}).and(userInfoSchema);
