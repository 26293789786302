import { Chat, Message } from '@raised/domain';
import Table from 'components/layout/components/table/Table';
import { useMemo } from 'react';
import { getAdminChatListTableConfig } from './adminChatListTableConfig';

interface AdminChatListTableProps {
  chats: Chat.Public[];
  isLoading: boolean;
  personalProfileId: string;
  getChatLink: (chatId: string) => string;
  getProjectLink: (projectId: string) => string;
}

export default function AdminChatListTable({
  chats,
  isLoading,
  personalProfileId,
  getProjectLink,
  getChatLink,
}: AdminChatListTableProps) {
  const getIsNewMessages = (messages: Message.Public[], profileId: string) => !!messages.find((message) => {
    if (message.fromId === personalProfileId) {
      return false;
    }

    return !message.viewedBy.includes(profileId);
  });

  const tableData = useMemo(() => chats.map((chat) => ({
    key: chat.id,
    clientName: `${chat.match?.project?.user?.firstName} ${chat.match?.project?.user?.lastName}`,
    expertName: `${chat.match?.user?.firstName} ${chat.match?.user?.lastName}`,
    projectTitle: chat.match?.project?.title || '',
    lastMessageTime: chat.messages?.[0]?.createdAt || chat.match?.createdAt || '',
    isNewMessages: getIsNewMessages(chat.messages, personalProfileId),
    projectLink: getProjectLink(chat.match?.projectId || ''),
    chatLink: getChatLink(chat.id),
  })), [chats, personalProfileId]);
  const columns = useMemo(() => getAdminChatListTableConfig(), []);

  return (
    <Table
      tableName="adminChatList"
      loading={isLoading}
      columns={columns}
      dataSource={tableData}
    />
  );
}
