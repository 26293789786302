import { MatchStatuses } from '@raised/domain';
import { Button, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ADMIN_MATCH_STATUS_LABELS } from 'components/match/constants/match-status-lables';
import { Link } from 'react-router-dom';

export interface ExpertProjectsTableRow {
  key: string;
  projectTitle: string;
  status: MatchStatuses;
  isProposals: boolean;
  projectLink: string;
  organizationLink: string;
  organizationName: string;
}

interface GetConfigProps {
  onViewProposalsClick: (matchId: string) => void;
}

export const getExpertProjectsTableConfig = ({
  onViewProposalsClick,
}: GetConfigProps): ColumnsType<ExpertProjectsTableRow> => [
  {
    title: 'Project title',
    dataIndex: 'projectTitle',
    key: 'projectTitle',
    render: (value, record) => <Link to={record.projectLink}>{value}</Link>,
  },
  {
    title: 'Organization',
    dataIndex: 'organizationLink',
    key: 'organizationLink',
    render: (value, record) => <Link to={value}>{record.organizationName}</Link>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (value) => <Typography.Text>{ADMIN_MATCH_STATUS_LABELS[value as MatchStatuses]}</Typography.Text>,
  },
  {
    title: 'Proposal',
    dataIndex: 'isProposals',
    key: 'isProposals',
    render: (value, record) => (value ? (
      <Button onClick={(e) => {
        e.stopPropagation();
        onViewProposalsClick(record.key);
      }}
      >
        View proposals
      </Button>
    ) : '-'),
  },
];
