import { Proposal } from '@raised/domain';
import { Space, Typography } from 'antd';
import { useCalculatePaymentInfoMutation } from 'components/proposal/storage/proposalApi';
import { useEffect, useRef } from 'react';
import { Control, useWatch } from 'react-hook-form';

interface CalculatePaymentInfoProps {
  control: Control<Proposal.Create>;
}

export default function CalculatePaymentInfo({
  control,
}: CalculatePaymentInfoProps) {
  const timerRef = useRef<NodeJS.Timeout>();
  const [calculate, { data }] = useCalculatePaymentInfoMutation();

  const selectedPaymentRate = useWatch({
    control,
    name: 'paymentRate',
    defaultValue: 0,
  });
  const selectedProjectLength = useWatch({
    control,
    name: 'projectLength',
  });

  useEffect(() => {
    if (!selectedPaymentRate || !selectedProjectLength) return;

    timerRef.current = setTimeout(() => {
      calculate({ projectLength: selectedProjectLength, paymentRate: selectedPaymentRate });
    }, 300);

    // eslint-disable-next-line consistent-return
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [selectedPaymentRate, selectedProjectLength]);

  return (
    <Space direction="vertical">
      <>
        <Space>
          <Typography.Text type="secondary">Total Gross Amount:</Typography.Text>
          <Typography.Text strong>{data ? `${data.total_amount_gross}$` : '-'}</Typography.Text>
        </Space>
        <Space style={{ alignItems: 'flex-end' }}>
          <Typography.Text type="secondary">
            Payment Net Rate for Expert
            <br />
            (Experts share only, 85% of Gross rate):
          </Typography.Text>
          <Typography.Text strong>{data ? `${data.payment_net}$` : '-'}</Typography.Text>
        </Space>
        <Space style={{ alignItems: 'flex-end' }}>
          <Typography.Text style={{ maxWidth: 50 }} type="secondary">
            Total Net Amount for Expert
            <br />
            (Experts share only, 85% of Gross Amount):
          </Typography.Text>
          <Typography.Text strong>{data ? `${data.total_amount_net}$` : '-'}</Typography.Text>
        </Space>
      </>
    </Space>
  );
}
