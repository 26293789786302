import { ButtonsModule } from '@raised/ui-kit';
import styles from './DefaultFooter.module.css';

const { Button } = ButtonsModule;

interface DefaultFooterProps {
  onCancel: () => void;
  onSubmit: () => void;
  isLoading: boolean;
}

export default function DefaultFooter({
  onCancel,
  onSubmit,
  isLoading,
}: DefaultFooterProps) {
  return (
    <div className={styles.default_controls}>
      <Button className={styles.cancel} loading={isLoading} disabled={isLoading} onClick={onCancel} type="text">Cancel</Button>
      <Button className={styles.create} loading={isLoading} disabled={isLoading} onClick={onSubmit} type="primary">Create</Button>
    </div>
  );
}
