import { UserRole } from '@raised/domain';
import { FeedbackModule } from '@raised/ui-kit';
import { PROJECT_ROUTES } from 'components/project/project-routes';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import ActivateUserForm from '../components/forms/activate-user/ActivateUserForm';
import UserNotFound from '../components/UserNotFound';
import { EditProfileMessages } from '../edit-profile/messages/types';
import useActivateGhost from '../hooks/useActivateGhost';
import useGhostUser from '../hooks/useGhostUser';
import { useGetProfileQuery } from '../storage/userApi';
import { USER_ROUTES } from '../user-routes';

const { Spin } = FeedbackModule;

export default function ActivateGhostPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const onSuccess = (role: UserRole) => {
    if (role === UserRole.CLIENT) {
      navigate(PROJECT_ROUTES.PROJECTS());
      return;
    }

    navigate(USER_ROUTES.EDIT_PERSONAL_PROFILE(), {
      state: {
        message: EditProfileMessages.LAST_THING,
      },
    });
  };

  const {
    data: userData,
    isError: isUserError,
    isSuccess: isUserSuccess,
    isLoading: isUserLoading,
  } = useGhostUser({ id: id as string });
  const {
    isSuccess: isProfileSuccess,
  } = useGetProfileQuery();

  const { activate, isLoading } = useActivateGhost({
    onSuccess: (user) => onSuccess(user.role),
  });

  useEffect(() => {
    if (isUserSuccess && !userData?.isGhost) {
      navigate('/');
    }
  }, [isUserSuccess]);

  useEffect(() => {
    if (isProfileSuccess) {
      navigate('/');
    }
  }, [isProfileSuccess]);

  return (
    <>
      <Helmet>
        <title>Raised - Activate account</title>
      </Helmet>
      {isUserLoading ? (
        <Spin />
      ) : null}
      {isUserError ? (
        <UserNotFound />
      ) : null}
      {isUserSuccess && userData?.isGhost ? (
        <ActivateUserForm
          onSubmit={activate}
          defaultValues={userData}
          isLoading={isLoading}
        />
      ) : null}
    </>
  );
}
