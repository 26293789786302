import {
  useCallback, useMemo, useState,
} from 'react';
import { useGetTotalMessagesByChatQuery } from '../storage/messageApi';

const BULK_SIZE = 30;

interface Page {
  skip: number;
  take: number;
  isLoaded: boolean;
}

interface UseChatHistoryProps {
  chatId: string;
}

export default function useChatHistory({
  chatId,
}: UseChatHistoryProps) {
  const [pages, setPages] = useState<Page[]>([
    {
      take: BULK_SIZE,
      skip: 0,
      isLoaded: false,
    },
  ]);
  const result = useGetTotalMessagesByChatQuery({
    id: chatId,
  });

  const amountOfPages = useMemo(() => {
    if (result.isSuccess) {
      return Math.ceil(result.data / BULK_SIZE);
    }

    return 0;
  }, [result.isSuccess]);

  const onPageLoaded = useCallback((index: number) => {
    setPages((statePages) => [...statePages.slice(0, index), { ...statePages[index], isLoaded: true }, ...statePages.slice(index + 1)]);
  }, []);
  const addNewPage = useCallback(() => {
    const lastPage = pages[pages.length - 1];

    if (!lastPage.isLoaded || pages.length >= amountOfPages) return;

    setPages((statePages) => [...statePages, { take: BULK_SIZE, isLoaded: false, skip: lastPage.skip + BULK_SIZE }]);
  }, [pages]);

  return {
    ...result,
    onPageLoaded,
    pages,
    addNewPage,
  };
}
