import { TypographyModule } from '@raised/ui-kit';
import { Link } from 'react-router-dom';
import styles from './RecommendedProject.module.css';

const { Title, Text } = TypographyModule;

interface RecommendedProjectProps {
  title: string;
  description: string;
  projectLink: string;
}

export default function RecommendedProject({
  title,
  description,
  projectLink,
}: RecommendedProjectProps) {
  return (
    <div className={styles.recommended_project}>
      <Link to={projectLink}><Title className={styles.title} level={4}>{title}</Title></Link>
      <Text>{description}</Text>
    </div>
  );
}
