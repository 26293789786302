import { useCreateMessageMutation } from '../storage/messageApi';

export default function useCreateMessage() {
  const [createMessage, data] = useCreateMessageMutation();

  return {
    createMessage,
    ...data,
  };
}
