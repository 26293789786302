/* eslint-disable @typescript-eslint/no-unused-vars */
import cn from 'classnames';
import { UserRole } from '@raised/domain';
import {
  ButtonsModule, ColorsModule, FeedbackModule,
} from '@raised/ui-kit';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { getErrorMessage } from 'api/helpers';
import UserAvatar from 'components/user/components/UserAvatar';
import {
  ChangeEvent, MouseEventHandler, useEffect, useRef, useState,
} from 'react';
import useUploadFile from '../hooks/useUploadFIle';
import { useGetFileQuery } from '../storage/filesApi';
import styles from './UploadAvatar.module.css';

const { Message } = FeedbackModule;
const { Button } = ButtonsModule;
const { Colors } = ColorsModule;

interface UploadAvatarProps {
  onSuccess: (fileId: string) => void;
  onDelete: () => void;
  userRole: UserRole;
  initialValue?: string;
}

export default function UploadAvatar({
  onSuccess,
  onDelete,
  initialValue,
  userRole,
}: UploadAvatarProps) {
  const isMobile = window.innerWidth < 500;
  const [selectedFile, setSelectedFile] = useState<File>();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const {
    uploadFile, uploadedFilePath, resetState, isLoading, isError, error,
  } = useUploadFile();

  const avatarUrl = uploadedFilePath || initialValue || '';

  const { data: fileUrl, isLoading: isFileLoading } = useGetFileQuery({ fileName: avatarUrl }, { skip: !avatarUrl });

  const isUploading = isLoading || isFileLoading;

  useEffect(() => {
    if (uploadedFilePath) {
      onSuccess(uploadedFilePath);
    }
  }, [uploadedFilePath]);

  useEffect(() => {
    if (isError) {
      Message.error(getErrorMessage(error));
    }
  }, [isError]);

  const onButtonClick: MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    fileUploadRef.current?.click();
  };
  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setSelectedFile(e.target.files[0]);
      uploadFile(e.target.files[0]);
    }
  };
  const handleDelete = () => {
    onDelete();
    resetState();
  };

  return (
    <>
      <input
        onChange={onChangeFile}
        type="file"
        ref={fileUploadRef}
        style={{ display: 'none' }}
        alt=""
        accept="image/*"
      />
      <div className={styles.container}>
        <UserAvatar role={userRole} size={isMobile ? 80 : 'l'} src={fileUrl?.url} style={{ flexShrink: 0 }} />
        <div className={styles.buttons}>
          <Button className={styles.desktop} disabled={isUploading} loading={isUploading} onClick={onButtonClick} type="primary">
            <IconPlus size={18} color="#fff" />
            New photo
          </Button>
          <Button className={cn(styles.add_btn, styles.mobile)} disabled={isUploading} loading={isUploading} onClick={onButtonClick}>
            <IconPlus size={18} color="#000" />
            Photo
          </Button>
          <Button className={styles.desktop} onClick={handleDelete} danger>
            <IconTrash size={18} color={Colors.red} />
            Delete
          </Button>
          <Button className={cn(styles.remove_btn, styles.mobile)} onClick={handleDelete} danger>
            <IconTrash size={18} color={Colors.red} />
          </Button>
        </div>
      </div>
    </>
  );
}
