import { FeedbackModule } from '@raised/ui-kit';
import { useGetFileQuery } from 'components/files/storage/filesApi';
import styles from './Image.module.css';

const { Spin } = FeedbackModule;

interface ImageProps {
  fileName: string;
}

export default function Image({
  fileName,
}: ImageProps) {
  const { data, isLoading } = useGetFileQuery({ fileName });

  console.log('image data: ', data);

  return (
    <div className={styles.image_container}>
      {isLoading ? <Spin /> : (
        <img src={data?.url || ''} alt="" className={styles.image} />
      )}
    </div>
  );
}
