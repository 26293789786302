import { User } from '@raised/domain';
import { message } from 'antd';
import { getErrorMessage, setToken } from 'api/helpers';
import useRecaptcha from 'components/auth/hooks/useRecaptcha';
import { useLoginMutation } from 'components/auth/storage/authApi';
import { useCallback, useEffect } from 'react';
import { useCreateUserMutation, useLazyGetProfileQuery } from '../storage/userApi';

interface UseCreateUserProps {
  onSuccess: (user: User.Public) => void;
}

export default function useCreateUser({
  onSuccess,
}: UseCreateUserProps) {
  const [mutate, {
    isSuccess: isCreateUserSuccess,
    originalArgs: createArgs,
    isLoading: isCreateLoading,
    error: createError,
    data: createData,
  }] = useCreateUserMutation();
  const [login, {
    data: loginData,
    isSuccess: isLoginSuccess,
    isLoading: isLoginLoading,
    error: loginError,
  }] = useLoginMutation();
  const [getProfile] = useLazyGetProfileQuery();
  const { handleRecaptchaVerify } = useRecaptcha();

  useEffect(() => {
    if (!isCreateUserSuccess || !createArgs) return;

    const { email, password } = createArgs;
    handleRecaptchaVerify('login').then((recaptchaToken) => {
      if (!recaptchaToken) return;
      login({ email, password, recaptchaToken });
    });
  }, [isCreateUserSuccess]);

  useEffect(() => {
    if (!isLoginSuccess || !loginData || !createData) return;

    setToken(loginData.access_token);
    getProfile().unwrap().then(() => {
      onSuccess(createData);
    });
  }, [isLoginSuccess]);

  useEffect(() => {
    if (createError) {
      message.error(getErrorMessage(createError));
    }

    if (loginError) {
      message.error(getErrorMessage(loginError));
    }
  }, [createError, loginError]);

  const createUser = useCallback(async (formData: User.Create) => {
    const recaptchaToken = await handleRecaptchaVerify('registration');

    if (!recaptchaToken) {
      message.error('Failed reCAPTCHA check');
      return;
    }

    mutate({ ...formData, recaptchaToken });
  }, [handleRecaptchaVerify]);

  const isLoading = isLoginLoading || isCreateLoading;

  return {
    createUser,
    isLoading,
  };
}
