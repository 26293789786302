import { Project, User } from '@raised/domain';
import { ColorsModule, DataModule, TypographyModule } from '@raised/ui-kit';
import { Space } from 'antd';
import { PROJECT_CATEGORIES_VALUES } from 'components/project/constants/project-categories';
import useExpertTags, { type TagGroups } from 'components/user/hooks/useExpertTags';
import React from 'react';
import { camelCaseToSeparated } from 'utils/formats';
import { excludeNullFields } from 'utils/forms';

const { Tag, IconTag } = DataModule;
const { Text } = TypographyModule;
const { Colors } = ColorsModule;

interface ExpertTagsProps {
  expert: User.Public;
}

export default function ExpertTags({
  expert,
}: ExpertTagsProps) {
  const {
    icons,
    groupTags,
  } = useExpertTags();

  const groupedTags = groupTags(expert.tags ? excludeNullFields(expert.tags) : {});

  return (
    <Space direction="vertical" style={{ marginBottom: 40 }} size={16}>
      {Object.entries(groupedTags).map(([tagKey, tagsObject]) => (
        <React.Fragment key={tagKey}>
          {Object.keys(tagsObject).length ? (
            <Space size={10} direction="vertical">
              <Space size={4}>
                <IconTag icon={icons[tagKey as TagGroups].icon} color={icons[tagKey as TagGroups].color} />
                <Text style={{ fontWeight: '500' }}>{icons[tagKey as TagGroups].label}</Text>
              </Space>
              {Object.entries(tagsObject).map(([name, values]) => (
                <Space key={name} size={4} wrap>
                  {name !== 'function' && (
                    <Text>{`${camelCaseToSeparated(name)}:`}</Text>
                  )}
                  {name !== 'function' && values.map((value) => (
                    <Tag key={value} style={{ color: Colors.blue, fontWeight: '500' }} label={value} />
                  ))}
                  {name === 'function' && values.filter((v) => Object.keys(PROJECT_CATEGORIES_VALUES).includes(v)).map((v) => (
                    <Tag key={v} style={{ color: Colors.blue, fontWeight: '500' }} label={PROJECT_CATEGORIES_VALUES[v as keyof Project.Categories]?.label || ''} />
                  ))}
                </Space>
              ))}
            </Space>
          ) : null}
        </React.Fragment>
      ))}
      {Object.values(expert.projectCategories || {}).length ? (
        <Space size={10} direction="vertical">
          <Space size={4}>
            <IconTag icon={icons.projectCategories.icon} color={icons.projectCategories.color} />
            <Text style={{ fontWeight: '500' }}>{icons.projectCategories.label}</Text>
          </Space>
          {Object.entries(expert.projectCategories || {}).map(([name, values]) => (
            <Space key={name} size={4} wrap>
              <Text>{`${camelCaseToSeparated(name)}:`}</Text>
              {values?.map((value) => (
                <Tag key={value} style={{ color: Colors.blue, fontWeight: '500' }} label={value} />
              ))}
            </Space>
          ))}
        </Space>
      ) : null}
    </Space>
  );
}
