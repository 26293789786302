import { User, UserRole } from '@raised/domain';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form';
import { renderFormConfig } from 'utils/forms';
import UploadAvatar from 'components/files/upload-avatar/UploadAvatar';
import {
  getCompanyDetailsFormConfig, getExpertAdditionalFieldsFormConfig, getGhostMainInfoFormConfig, getMainInfoFormConfig,
} from './mainInfoFormConfig';
import styles from './MainInfo.module.css';
import ExpertTags from '../../../form-components/expert-tags/ExpertTags';
import ExpertRate from '../../../form-components/ExpertRate';
import ProfileVisibility from '../../../form-components/ProfileVisibility';
import ExpertAvailability from '../../../form-components/ExpertAvailability';
import ExpertActivityType from '../../../form-components/ExpertActivityType';
import ProfilePrivacy from '../../../form-components/profile-privacy/ProfilePrivacy';
import ExpertCategories from '../../../form-components/expert-categories/ExpertCategories';

interface MainInfoProps {
  control: Control<User.Create>;
  isAdmin: boolean;
  isGhost: boolean;
  role: UserRole;
  setValue: UseFormSetValue<User.Create>;
  isUploadAvatar: boolean;
}

export default function MainInfo({
  control,
  role,
  isAdmin,
  isGhost,
  setValue,
  isUploadAvatar,
}: MainInfoProps) {
  const formConfig = isGhost ? getGhostMainInfoFormConfig(control) : getMainInfoFormConfig(control);
  const companyDetails = getCompanyDetailsFormConfig(control);
  const expertAdditionalFields = getExpertAdditionalFieldsFormConfig(control);

  const onAvatarUpload = (avatar: string) => setValue('avatar', avatar);
  const onAvatarDelete = () => setValue('avatar', '');

  return (
    <div className={styles.main_info}>
      {isUploadAvatar && (
        <UploadAvatar
          userRole={role}
          onSuccess={onAvatarUpload}
          onDelete={onAvatarDelete}
        />
      )}
      {renderFormConfig(formConfig)}
      {role === UserRole.CLIENT ? (
        renderFormConfig(companyDetails)
      ) : (
        <>
          {renderFormConfig(expertAdditionalFields)}
          <ExpertTags control={control as unknown as Control<FieldValues>} />
          <ExpertCategories control={control} setValue={setValue} />
          <ExpertRate control={control as unknown as Control<FieldValues>} />
          {isAdmin ? (
            <ProfileVisibility control={control as unknown as Control<FieldValues>} />
          ) : null}
          <ExpertAvailability control={control as unknown as Control<FieldValues>} />
          {isAdmin ? (
            <>
              <ExpertActivityType control={control as unknown as Control<FieldValues>} />
              <ProfilePrivacy control={control} />
            </>
          ) : null}
        </>
      )}
    </div>
  );
}
