import {
  ButtonsModule, FormsModule, LayoutModule, TypographyModule,
} from '@raised/ui-kit';
import { useState } from 'react';
import { z } from 'zod';
import styles from './InviteToOrganisation.module.css';

const { Space } = LayoutModule;
const { Button } = ButtonsModule;
const { Input } = FormsModule;
const { Text } = TypographyModule;

interface InviteToOrganisationProps {
  onInviteToOrganisation: (email: string) => void;
  isLoading: boolean;
}

const emailRule = z.string().email();

export default function InviteToOrganisation({
  onInviteToOrganisation,
  isLoading,
}: InviteToOrganisationProps) {
  const [email, setEmail] = useState('');

  const onSubmit = () => {
    const data = emailRule.safeParse(email);
    if (!data.success) return;

    onInviteToOrganisation(email);
    setEmail('');
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={4}>
      <Text>Invite Teammates</Text>
      <Text type="secondary">Your teammate will get the invite via email</Text>
      <div className={styles.input_block}>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Invite by Email"
          className={styles.input}
        />
        <Button
          onClick={onSubmit}
          disabled={isLoading}
          loading={isLoading}
          type="primary"
        >
          Send
        </Button>
      </div>
    </Space>
  );
}
