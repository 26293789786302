import { Match, MatchStatuses, User } from '@raised/domain';
import { ButtonsModule, ColorsModule, TypographyModule } from '@raised/ui-kit';
import { IconRestore, IconThumbUp } from '@tabler/icons-react';
import ConfirmModal from 'components/match/components/confirm-modal/ConfirmModal';
import { EXPERT_MATCH_STATUS_LABELS } from 'components/match/constants/match-status-lables';
import useRestoreMatch from 'components/match/hooks/useRestoreMatch';
import { useState } from 'react';
import styles from './ExpertMatchActions.module.css';

const { Button } = ButtonsModule;
const { Title, Text } = TypographyModule;
const { Colors } = ColorsModule;

interface ExpertMatchActionsProps {
  onCreateProposalClick: () => void;
  isUpdateMatchLoading: boolean;
  personalProjectMatch: Match.Public;
  onSubmit: (status: MatchStatuses, text: string, matchId: string, chatId?: string) => void;
  author?: User.Public;
  supportChatId?: string;
  projectTitle: string;
}

export default function ExpertMatchActions({
  onCreateProposalClick,
  isUpdateMatchLoading,
  personalProjectMatch,
  onSubmit,
  author,
  supportChatId,
  projectTitle,
}: ExpertMatchActionsProps) {
  const { restore, isLoading: isRestoreLoading } = useRestoreMatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [decision, setDecision] = useState<MatchStatuses>(MatchStatuses.INTERSTED_BY_EXPERT);

  const { status } = personalProjectMatch;
  const isRestore = !!personalProjectMatch.oldStatus;
  const isDeclineAvailable = status !== MatchStatuses.PAYMENT_RECEIVED && status !== MatchStatuses.DECLINED_BY_EXPERT && status !== MatchStatuses.DECLINED_BY_CLIENT && status !== MatchStatuses.RECOMMENDED_TO_EXPERT && status !== MatchStatuses.INTERSTED_BY_CLIENT;
  const isRestoreAvailable = isRestore && status === MatchStatuses.DECLINED_BY_EXPERT;
  const isCommunication = status === MatchStatuses.INVITED_TO_INTERVIEW || status === MatchStatuses.CALL_SCHEDULED || status === MatchStatuses.CALL_OVER;
  const matchStatus = EXPERT_MATCH_STATUS_LABELS[personalProjectMatch.status];

  const getContainerColors = () => {
    if (status === MatchStatuses.DECLINED_BY_CLIENT || status === MatchStatuses.DECLINED_BY_EXPERT) {
      return Colors.pink;
    }

    return Colors['light-green'];
  };

  const onRestoreClickHandler = () => restore({ matchId: personalProjectMatch.id });
  const onConfirmInvite = () => {
    setIsModalOpen(true);
    setDecision(MatchStatuses.INTERSTED_BY_EXPERT);
  };
  const onConfirmAssign = () => {
    setIsModalOpen(true);
    setDecision(MatchStatuses.CALL_OVER);
  };
  const onDeclineClickHandler = () => {
    setIsModalOpen(true);
    setDecision(MatchStatuses.DECLINED_BY_EXPERT);
  };
  const onModalClose = () => {
    setIsModalOpen(false);
  };
  const handleSubmit = (text: string) => {
    if (!personalProjectMatch) return;

    const message = decision === MatchStatuses.DECLINED_BY_EXPERT
      ? `Declining ${author?.firstName} ${author?.lastName} for project ${projectTitle}: ${text}`
      : text;
    const chatId = decision === MatchStatuses.DECLINED_BY_EXPERT ? supportChatId : personalProjectMatch?.chat?.[0]?.id;

    onSubmit(decision, message, personalProjectMatch.id, chatId);
  };

  return (
    <>
      <div
        className={styles.container}
        style={{ background: getContainerColors() }}
      >
        <div className={styles.status_block}>
          {matchStatus.icon}
          <div className={styles.status_block__content}>
            <Title level={4}>{matchStatus.text}</Title>
            {matchStatus?.description ? (
              <Text>{matchStatus.description}</Text>
            ) : null}
          </div>
        </div>
        <div className={styles.buttons}>
          {status === MatchStatuses.RECOMMENDED_TO_EXPERT && (
          <>
            <Button
              data-testid="button_expertDecline"
              onClick={onDeclineClickHandler}
              loading={isUpdateMatchLoading}
              disabled={isUpdateMatchLoading}
              danger
            >
              Decline
            </Button>
            <Button
              data-testid="button_expertConfirm"
              onClick={onConfirmInvite}
              loading={isUpdateMatchLoading}
              disabled={isUpdateMatchLoading}
              type="primary"
            >
              <IconThumbUp size={18} />
              Confirm
            </Button>
          </>
          )}
          {status === MatchStatuses.INTERSTED_BY_CLIENT && (
          <>
            <Button
              data-testid="button_expertDecline"
              onClick={onDeclineClickHandler}
              loading={isUpdateMatchLoading}
              disabled={isUpdateMatchLoading}
              danger
            >
              Decline
            </Button>
            <Button
              data-testid="button_expertConfirm"
              onClick={onConfirmAssign}
              loading={isUpdateMatchLoading}
              disabled={isUpdateMatchLoading}
              type="primary"
            >
              Accept
            </Button>
          </>
          )}
          {isCommunication ? (
            <Button onClick={onCreateProposalClick} type="primary">Create a Proposal</Button>
          ) : null}
          {status === MatchStatuses.PAYMENT_RECEIVED && (
            <Button onClick={onCreateProposalClick} type="primary">Create a Proposal</Button>
          )}
          {isDeclineAvailable ? (
            <Button
              loading={isUpdateMatchLoading}
              disabled={isUpdateMatchLoading}
              onClick={onDeclineClickHandler}
              danger
            >
              Decline Project
            </Button>
          ) : null}
          {isRestoreAvailable ? (
            <Button
              onClick={onRestoreClickHandler}
              loading={isRestoreLoading}
              disabled={isRestoreLoading}
            >
              <IconRestore size={18} />
              Restore Project
            </Button>
          ) : null}
        </div>
      </div>
      <ConfirmModal
        isOpen={isModalOpen}
        onCancel={onModalClose}
        onSubmit={handleSubmit}
        status={decision}
      />
    </>
  );
}
