import { FeedbackModule } from '@raised/ui-kit';
import { ReactNode } from 'react';
import BackgroundLogo from './components/background-logo/BackgroundLogo';
import styles from './ViewSuccessStoryContent.module.css';

const { Spin } = FeedbackModule;

interface ViewSuccessStoryContentProps {
  children: ReactNode;
  isLoading: boolean;
}

export default function ViewSuccessStoryContent({
  isLoading,
  children,
}: ViewSuccessStoryContentProps) {
  return (
    <main className={styles.container}>
      {isLoading ? <Spin /> : (
        <>
          <BackgroundLogo />
          {children}
        </>
      )}
    </main>
  );
}
