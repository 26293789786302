/* eslint-disable consistent-return */
import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function useRecaptcha() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleRecaptchaVerify = useCallback(async (action: string) => {
    if (!executeRecaptcha) return;

    const token = await executeRecaptcha(action);
    return token;
  }, [executeRecaptcha]);

  return {
    handleRecaptchaVerify,
  };
}
