import { ButtonsModule, ColorsModule, TypographyModule } from '@raised/ui-kit';
import { IconBrandLinkedin } from '@tabler/icons-react';
import { Layout } from 'antd';
import styles from './PublicFooter.module.css';

const { Footer } = Layout;
const { PublicButton } = ButtonsModule;
const { Link } = TypographyModule;
const { Colors } = ColorsModule;

export default function PublicFooter() {
  return (
    <Footer className={styles.public_footer}>
      <div className={styles.buttons}>
        <PublicButton className={styles.btn}>Apply as an expert</PublicButton>
        <PublicButton type="primary" className={styles.btn}>Find a professional</PublicButton>
      </div>
      <div className={styles.contacts_first_row}>
        <Link href="/" className={styles.link}>Contact Us</Link>
        <IconBrandLinkedin size={18} color={Colors.blue} />
      </div>
      <div className={styles.contacts_second_row}>
        <Link href="/" className={styles.link}>Privacy policy</Link>
        <Link href="/" className={styles.link}>Contact terms</Link>
        <Link href="/" className={styles.link}>Terms of use</Link>
      </div>
    </Footer>
  );
}
