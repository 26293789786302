import { zodResolver } from '@hookform/resolvers/zod';
import { User, UserSchemas } from '@raised/domain';
import { Button, Typography } from 'antd';
import RecaptchaMessage from 'components/layout/components/recaptcha-message/RecaptchaMessage';
import { useForm } from 'react-hook-form';
import { renderFormConfig } from 'utils/forms';
import { getJoinOrganisationFormConfig } from './joinOrganisationFormConfig';

interface JoinOrganisationFormProps {
  organisationId: string;
  token: string;
  onSubmit: (data: User.JoinOrganisation) => void;
  isLoading: boolean;
}

export default function JoinOrganisationForm({
  organisationId,
  token,
  onSubmit,
  isLoading,
}: JoinOrganisationFormProps) {
  const { control, handleSubmit } = useForm<User.JoinOrganisation>({
    defaultValues: {
      token,
      organisationId,
    },
    resolver: zodResolver(UserSchemas.joinOrganisationSchema),
  });

  const formConfig = getJoinOrganisationFormConfig(control);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form_container">
      <Typography.Title level={2} style={{ textAlign: 'center' }}>Join organisation</Typography.Title>
      {renderFormConfig(formConfig)}
      <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>Join</Button>
      <RecaptchaMessage />
    </form>
  );
}
