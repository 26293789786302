import { ColorsModule, TypographyModule } from '@raised/ui-kit';
import { IconCircleCheck, IconMailCheck } from '@tabler/icons-react';
import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './InviteStatus.module.css';

const { Text } = TypographyModule;
const { Colors } = ColorsModule;

type Status = 'success' | 'pending';

interface InviteStatusProps {
  status: Status;
  className?: string;
}

const config: { [key in Status]: { text: string; icon: ReactNode; } } = {
  pending: {
    text: 'Invite sent',
    icon: <IconMailCheck size={16} />,
  },
  success: {
    text: 'Invitation accepted',
    icon: <IconCircleCheck size={16} color={Colors['solid-green']} />,
  },
};

export default function InviteStatus({
  status,
  className,
}: InviteStatusProps) {
  return (
    <div className={cn(styles.invite_status, className)}>
      <Text className={styles.text}>{config[status].text}</Text>
      {config[status].icon}
    </div>
  );
}
