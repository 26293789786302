import { getToken } from 'api/helpers';
import { BASE_URL, rootApi } from 'api/rootApi';
import { UploadFileResponseType, UploadFilesResponseType } from './types';

export const filesApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    uploadFile: build.mutation<UploadFileResponseType, { file: File }>({
      queryFn: async ({ file }) => {
        const formData = new FormData();
        formData.append('file', file);
        const token = getToken();

        try {
          const response = await fetch(`${BASE_URL}/files`, {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();

          return { data };
        } catch (error) {
          return { error: { status: 501, data: 'something gone wrong' } };
        }
      },
    }),
    uploadFiles: build.mutation<UploadFilesResponseType, { fileList: File[] }>({
      queryFn: async ({ fileList }) => {
        const formData = new FormData();
        fileList.forEach((file, i) => formData.append(`file-${i}`, file));
        const token = getToken();

        try {
          const response = await fetch(`${BASE_URL}/files/multiple`, {
            method: 'POST',
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();

          return { data };
        } catch (error) {
          return { error: { status: 501, data: 'something gone wrong' } };
        }
      },
    }),
    getFile: build.query<{ url: string }, { fileName: string }>({
      providesTags: (res) => (res ? [{ type: 'FILE', id: res.url }] : ['FILE']),
      query: ({ fileName }) => ({
        url: `/files/${fileName}`,
      }),
    }),
  }),
});

export const {
  useGetFileQuery,
  useUploadFileMutation,
  useUploadFilesMutation,
} = filesApi;
