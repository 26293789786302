import { MatchStatuses } from '@raised/domain';

export const successApiMessages: { [key: string]: Record<MatchStatuses, string> } = {
  CREATE: {
    [MatchStatuses.RECOMMENDED_TO_EXPERT]: 'Expert has been successfully invited',
    [MatchStatuses.RECOMMENDED_TO_CLIENT]: 'Expert has been successfully assigned',
    [MatchStatuses.INTERSTED_BY_CLIENT]: '',
    [MatchStatuses.DECLINED_BY_CLIENT]: '',
    [MatchStatuses.INTERSTED_BY_EXPERT]: '',
    [MatchStatuses.DECLINED_BY_EXPERT]: '',
    [MatchStatuses.INVITED_TO_INTERVIEW]: '',
    [MatchStatuses.CALL_SCHEDULED]: '',
    [MatchStatuses.CALL_OVER]: '',
    [MatchStatuses.PAYMENT_RECEIVED]: '',
    [MatchStatuses.PAUSE]: '',
    [MatchStatuses.FINISHED]: '',
  },
  DELETE: {
    [MatchStatuses.RECOMMENDED_TO_EXPERT]: '',
    [MatchStatuses.RECOMMENDED_TO_CLIENT]: '',
    [MatchStatuses.INTERSTED_BY_CLIENT]: '',
    [MatchStatuses.DECLINED_BY_CLIENT]: '',
    [MatchStatuses.DECLINED_BY_CLIENT]: '',
    [MatchStatuses.INTERSTED_BY_EXPERT]: '',
    [MatchStatuses.DECLINED_BY_EXPERT]: '',
    [MatchStatuses.INVITED_TO_INTERVIEW]: '',
    [MatchStatuses.CALL_SCHEDULED]: '',
    [MatchStatuses.CALL_OVER]: '',
    [MatchStatuses.PAYMENT_RECEIVED]: '',
    [MatchStatuses.PAUSE]: '',
    [MatchStatuses.FINISHED]: '',
  },
  UPDATE: {
    [MatchStatuses.RECOMMENDED_TO_EXPERT]: 'Invitation successfully deleted',
    [MatchStatuses.RECOMMENDED_TO_CLIENT]: 'Assignment successfully deleted',
    [MatchStatuses.INTERSTED_BY_CLIENT]: 'You successfully responded to the project',
    [MatchStatuses.DECLINED_BY_CLIENT]: 'Expert has been successfully declined',
    [MatchStatuses.DECLINED_BY_EXPERT]: 'Project was successfully declined',
    [MatchStatuses.INTERSTED_BY_EXPERT]: 'You successfully responded to the project',
    [MatchStatuses.INVITED_TO_INTERVIEW]: 'Expert has been successfully invited to interview',
    [MatchStatuses.CALL_SCHEDULED]: 'Call was successfully scheduled',
    [MatchStatuses.CALL_OVER]: 'You successfully responded to the project',
    [MatchStatuses.PAYMENT_RECEIVED]: '',
    [MatchStatuses.PAUSE]: 'Project was successfully paused',
    [MatchStatuses.FINISHED]: 'Match successfully finished',
  },
};
