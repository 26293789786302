import { PROJECT_ROUTES } from 'components/project/project-routes';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { Helmet } from 'react-helmet';
import { MESSAGE_ROUTES } from '../messages-routes';
import { useGetAllMatchChatsQuery } from '../storage/messageApi';
import AdminChatListTable from './admin-chat-list-table/AdminChatListTable';
import AdminChatListComponent from './AdminChatListComponent';

export default function AdminChatListPage() {
  const {
    data: chats,
    isLoading: isChatsLoading,
    isFetching: isChatsFetching,
  } = useGetAllMatchChatsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: profile,
    isLoading: isProfileLoading,
  } = useGetProfileQuery();

  const getChatLink = (chatId: string) => MESSAGE_ROUTES.CHAT_PAGE(chatId);
  const getProjectLink = (projectId: string) => PROJECT_ROUTES.PROJECT(projectId);

  return (
    <AdminChatListComponent>
      <Helmet>
        <title>Raised - Chat list</title>
      </Helmet>
      <AdminChatListTable
        isLoading={isChatsLoading || isProfileLoading || isChatsFetching}
        chats={chats || []}
        getChatLink={getChatLink}
        getProjectLink={getProjectLink}
        personalProfileId={profile?.id || ''}
      />
    </AdminChatListComponent>
  );
}
