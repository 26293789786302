import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/formats';

export interface UsecasesTableRow {
  key: string;
  name: string;
  usecaseLink: string;
  createdAt: string;
  templateLink?: string;
  templateName?: string;
  expertName: string;
  expertLink: string;
}

export const getUsecasesTableConfig = (): ColumnsType<UsecasesTableRow> => [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (value, record) => <Link to={record.usecaseLink}>{value}</Link>,
  },
  {
    title: 'Expert',
    dataIndex: 'expertName',
    render: (value, record) => <Link to={record.expertLink}>{value}</Link>,
  },
  {
    title: 'Template',
    dataIndex: 'templateName',
    render: (value, record) => (value ? <Link to={record.templateLink || ''}>{value}</Link> : '-'),
  },
  {
    title: 'Creation date',
    dataIndex: 'createdAt',
    sorter: (a, b) => {
      const opA = new Date(a.createdAt).getTime();
      const opB = new Date(b.createdAt).getTime();
      return opA - opB;
    },
    render: (value) => formatDate(new Date(value)),
  },
];
