import { Organisation, UserRole } from '@raised/domain';
import { Spin } from 'antd';
import { getInitialRoute } from 'components/auth/utils';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { excludeNullFields } from 'utils/forms';
import useUpdateOrganisation from '../hooks/useUpdateOrganisation';
import { ORGANISATION_ROUTES } from '../organisation-routes';
import { useGetOrganisationByIdQuery } from '../storage/organisationApi';
import EditOrganisationComponent from './EditOrganisationComponent';

export default function EditOrganisationPage() {
  const navigate = useNavigate();
  const {
    data: profile,
    isSuccess: isProfileSuccess,
    isLoading: isProfileLoading,
  } = useGetProfileQuery();

  const { id } = useParams();
  const onSuccess = () => {
    if (!id) return;

    if (profile?.role === UserRole.ADMIN) {
      navigate(ORGANISATION_ROUTES.ADMIN_ORGANIZATION(id));
    } else {
      navigate(ORGANISATION_ROUTES.ORGANISATION_DASHBOARD());
    }
  };

  const {
    data: organisation,
    isSuccess: isOrganisationSuccess,
    isLoading: isOrganisationLoading,
  } = useGetOrganisationByIdQuery({ id: id || '' }, { skip: !id });
  const {
    updateOrganisation,
    isLoading: isUpdateLoading,
  } = useUpdateOrganisation({
    onSuccess,
  });

  useEffect(() => {
    if (!profile || !organisation) return;

    if (organisation.createdById !== profile.id && profile.role !== UserRole.ADMIN) {
      navigate(getInitialRoute(profile.role));
    }
  }, [profile, organisation]);

  const handleSubmit = (data: Organisation.Edit) => {
    if (!organisation?.id) return;

    updateOrganisation({ ...data, organisationId: organisation.id });
  };

  return (
    <>
      <Helmet>
        <title>Raised - Edit organisation</title>
      </Helmet>
      {isProfileLoading || isOrganisationLoading ? (
        <Spin />
      ) : null}
      {isOrganisationSuccess && isProfileSuccess ? (
        <EditOrganisationComponent
          onSubmit={handleSubmit}
          isLoading={isUpdateLoading}
          defaultValues={excludeNullFields(organisation)}
        />
      ) : null}
    </>
  );
}
