import { IconProgress, IconUserSearch } from '@tabler/icons-react';
import styles from './ProgressIcon.module.css';

interface ProgressIconProps {
  size: number;
}

export default function ProgressIcon({
  size,
}: ProgressIconProps) {
  return (
    <div style={{ width: size, height: size }} className={styles.container}>
      <IconProgress className={styles.circle} size={size} stroke={1.5} />
      <IconUserSearch className={styles.user} size={size / 2} stroke={2} />
    </div>
  );
}
