import { Typography } from 'antd';
import { ReactNode } from 'react';

interface AdminSupportChatsListComponentProps {
  children: ReactNode;
}

export default function AdminSupportChatsListComponent({
  children,
}: AdminSupportChatsListComponentProps) {
  return (
    <div>
      <Typography.Title level={2}>Support</Typography.Title>
      {children}
    </div>
  );
}
