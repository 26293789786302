import { SuccessStory } from '@raised/domain';
import { DataModule, TypographyModule } from '@raised/ui-kit';
import Image from './Image';
import styles from './SuccessStorySlider.module.css';

const { Carousel } = DataModule;
const { Paragraph } = TypographyModule;

interface SuccessStorySliderProps {
  images: SuccessStory.Public['files'];
}

export default function SuccessStorySlider({
  images,
}: SuccessStorySliderProps) {
  if (!images) return null;

  return (
    <Carousel className={styles.carousel}>
      {images.map((image) => (
        <div key={image.filePath} className={styles.image_item}>
          <div className={styles.content}>
            <Image fileName={image.filePath} />
            {image.description ? (
              <Paragraph className={styles.description}>{image.description}</Paragraph>
            ) : null}
          </div>
        </div>
      ))}
    </Carousel>
  );
}
