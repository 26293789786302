enum MatchStatuses {
  DECLINED_BY_EXPERT = "DECLINED_BY_EXPERT",
  DECLINED_BY_CLIENT = "DECLINED_BY_CLIENT",
  RECOMMENDED_TO_EXPERT = "RECOMMENDED_TO_EXPERT",
  RECOMMENDED_TO_CLIENT = "RECOMMENDED_TO_CLIENT",
  INTERSTED_BY_EXPERT = "INTERSTED_BY_EXPERT",
  INTERSTED_BY_CLIENT = "INTERSTED_BY_CLIENT",
  INVITED_TO_INTERVIEW = "INVITED_TO_INTERVIEW",
  CALL_SCHEDULED = "CALL_SCHEDULED",
  CALL_OVER = "CALL_OVER",
  PAYMENT_RECEIVED = "PAYMENT_RECEIVED",
  PAUSE = "PAUSE",
  FINISHED = "FINISHED",
}

export default MatchStatuses;
