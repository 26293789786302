import { LayoutModule } from '@raised/ui-kit';
import { ProjectsGroups } from 'components/project/hooks/usePersonalProjects';
import { ReactNode, useState } from 'react';
import LoadingPlaceholder from './LoadingPlaceholder';
import ProjectsTabLabel from './ProjectsTabLabel';
import styles from './ProjectsTabs.module.css';

const { Tabs } = LayoutModule;

interface ProjectsTabsProps {
  activeProjects: ReactNode;
  activeProjectsCount: number;
  completedProjects: ReactNode;
  completedProjectsCount: number;
  loading: boolean;
}

export default function ProjectsTabs({
  activeProjects,
  activeProjectsCount,
  completedProjects,
  completedProjectsCount,
  loading,
}: ProjectsTabsProps) {
  const [tab, setTab] = useState<ProjectsGroups>(ProjectsGroups.ACTIVE);

  return (
    <Tabs
      defaultActiveKey={ProjectsGroups.ACTIVE}
      onChange={(newTab) => setTab(newTab as ProjectsGroups)}
      className={styles.tabs}
      items={[
        {
          key: ProjectsGroups.ACTIVE,
          label: <ProjectsTabLabel
            isActive={tab === ProjectsGroups.ACTIVE}
            count={activeProjectsCount}
            title="Active"
          />,
          children: loading ? <LoadingPlaceholder /> : activeProjects,
        },
        {
          key: ProjectsGroups.COMPLETED,
          label: <ProjectsTabLabel
            isActive={tab === ProjectsGroups.COMPLETED}
            count={completedProjectsCount}
            title="Archived"
          />,
          children: loading ? <LoadingPlaceholder /> : completedProjects,
        },
      ]}
    />
  );
}
