import { useMemo } from 'react';
import { Usecase } from '@raised/domain';
import Table from 'components/layout/components/table/Table';
import { getUsecasesTableConfig } from './usecasesTableConfig';

interface UsecasesTableProps {
  isLoading: boolean;
  usecases: Usecase.Public[];
  getUserLink: (userId: string) => string;
  getUsecaseLink: (usecaseId: string) => string;
  getTemplateLink: (templateId: string) => string;
}

export default function UsecasesTable({
  isLoading,
  usecases,
  getUserLink,
  getUsecaseLink,
  getTemplateLink,
}: UsecasesTableProps) {
  const dataSource = useMemo(() => usecases.map((usecase) => ({
    key: usecase.id,
    name: usecase.title || '',
    usecaseLink: getUsecaseLink(usecase.id),
    createdAt: usecase.createdAt,
    templateLink: usecase.templateId ? getTemplateLink(usecase.templateId) : undefined,
    templateName: usecase.template ? (usecase.template.title || '') : undefined,
    expertName: `${usecase.user?.firstName} ${usecase.user?.lastName}`,
    expertLink: getUserLink(usecase.userId),
  })), [usecases]);
  const columns = useMemo(() => getUsecasesTableConfig(), []);

  return (
    <Table
      tableName="usecasesList"
      loading={isLoading}
      columns={columns}
      dataSource={dataSource}
    />
  );
}
