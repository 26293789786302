import { useGetAllSuccessStoriesQuery } from 'components/success-stories/storage/successStoriesApi';
import { SUCCESS_STORY_ROUTES } from 'components/success-stories/success-story-routes';
import { USER_ROUTES } from 'components/user/user-routes';
import AdminSuccessStoryListContent from './AdminSuccessStoryListContent';
import SuccessStoriesTable from './success-stories-table/SuccessStoriesTable';

export default function AdminSuccessStoryListPage() {
  const {
    data: successStories,
    isLoading: isSuccessStoriesLoading,
  } = useGetAllSuccessStoriesQuery();

  return (
    <AdminSuccessStoryListContent
      isLoading={isSuccessStoriesLoading}
      count={successStories?.length || 0}
    >
      <SuccessStoriesTable
        isLoading={isSuccessStoriesLoading}
        successStories={successStories || []}
        getSuccessStoryLink={(id) => SUCCESS_STORY_ROUTES.EDIT(id)}
        getUserLink={(id) => USER_ROUTES.USER_PROFILE(id)}
      />
    </AdminSuccessStoryListContent>
  );
}
