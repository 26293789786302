export enum PaymentStatuses {
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_ACTION = 'requires_action',
  PROCESSING = 'processing',
  REQUIRES_CAPTURE = 'requires_capture',
  CANCELED = 'canceled',
  SUCCEEDED = 'succeeded',
  CREATED = 'created',
  FAILED = 'failed',
  UNPAID = 'unpaid',
  PAID = 'paid',
}

export default PaymentStatuses;
