import { SuccessStory } from '@raised/domain';
import {
  ButtonsModule, ColorsModule, FormsModule,
} from '@raised/ui-kit';
import { IconUpload, IconX } from '@tabler/icons-react';
import { getFileName } from 'components/files/utils';
import { ChangeEvent, useRef } from 'react';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import AttachedStoryFile from './AttachedStoryFile';
import styles from './FilesInput.module.css';

const { Button } = ButtonsModule;
const { TextArea } = FormsModule;
const { Colors } = ColorsModule;

interface FilesInputProps {
  control: Control<SuccessStory.CreateForm | SuccessStory.EditForm>;
}

export default function FilesInput({
  control,
}: FilesInputProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'files',
  });

  const getFileLink = (file: File) => window.URL.createObjectURL(file);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const onButtonClick = () => fileUploadRef.current?.click();

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      append({ filePath: e.target.files[0], description: '' });
    }
  };

  return (
    <div className={styles.container}>
      <input
        onChange={onChangeFile}
        type="file"
        ref={fileUploadRef}
        style={{ display: 'none' }}
      />
      <Button className={styles.add_btn} onClick={onButtonClick}>
        <IconUpload size={14} />
        Click to attach photo (JPG,PNG)
      </Button>
      {fields.map((f, index) => (
        <div key={f.id} className={styles.file_form}>
          <Button onClick={() => remove(index)} className={styles.img_name} type="link">
            {typeof f.filePath === 'string' ? f.filePath : getFileName(f.filePath?.name)}
            <IconX size={10} color={Colors.gray} />
          </Button>
          <div className={styles.img_container}>
            {typeof f.filePath === 'string' ? (
              <AttachedStoryFile fileName={f.filePath} />
            ) : (
              <img className={styles.img} src={getFileLink(f.filePath)} alt="" />
            )}
          </div>
          <Controller
            control={control}
            name={`files.${index}.description`}
            render={({ field, fieldState }) => (
              <TextArea
                {...field}
                status={fieldState.error ? 'error' : undefined}
                placeholder="Photo description"
                rows={4}
              />
            )}
          />
        </div>
      ))}
    </div>
  );
}
