import { ProjectCategories } from '@raised/domain';
import { FormsModule, TypographyModule } from '@raised/ui-kit';
import { PROJECT_CATEGORIES, PROJECT_CATEGORIES_KEYS, PROJECT_CATEGORIES_VALUES } from 'components/project/constants/project-categories';
import { useEffect } from 'react';
import {
  Control, Controller, UseFormSetValue, useWatch,
} from 'react-hook-form';
import styles from './ProjectCategoriesForm.module.css';

const { Title } = TypographyModule;
const { Select } = FormsModule;

interface ProjectCategoriesFormProps {
  control: Control;
  setValue: UseFormSetValue<any>;
}

export default function ProjectCategoriesForm({
  control,
  setValue,
}: ProjectCategoriesFormProps) {
  const selectedFunctions = useWatch({
    control,
    name: 'category',
  });
  const validSelectedFunctions = selectedFunctions?.filter((func: ProjectCategories) => Object.values(ProjectCategories).includes(func));

  useEffect(() => {
    const unselectedFunctions = (Object.keys(PROJECT_CATEGORIES_KEYS) as ProjectCategories[]).filter((key) => !selectedFunctions?.includes(key));
    unselectedFunctions.forEach((field) => {
      setValue(`projectCategories.${PROJECT_CATEGORIES_KEYS[field]}`, undefined);
    });
  }, [selectedFunctions]);

  if (!selectedFunctions?.length) return null;

  return (
    <div className={styles.container}>
      <Title level={4}>Project Categories</Title>
      {validSelectedFunctions?.map((func: ProjectCategories) => (
        <Controller
          control={control}
          name={`projectCategories.${PROJECT_CATEGORIES_KEYS[func]}`}
          render={({ field, fieldState }) => (
            <Select
              label={`Select ${PROJECT_CATEGORIES[func]}`}
              placeholder={`Select ${PROJECT_CATEGORIES[func]}`}
              {...field}
              style={{ width: '100%' }}
              mode="multiple"
              status={fieldState.error ? 'error' : undefined}
              options={PROJECT_CATEGORIES_VALUES[PROJECT_CATEGORIES_KEYS[func]].values.map((value) => ({ label: value, value }))}
            />
          )}
        />
      ))}
    </div>
  );
}
