import { Space } from 'src/ui-kit/layout';
import { Paragraph, Text, Title } from 'src/ui-kit/typography';
import {
  IconChevronsRight, IconCloudStorm, IconMilitaryAward, IconTargetArrow,
} from '@tabler/icons-react';
import React from 'react';
import { WorkTimelineItem as WorkTimelineItemType } from './types';
import styles from './WorkTimelineItem.module.css';
import MiniLogo from '../../../assets/icons/logo_mini.png';

type WorkTimelinItemProps = Omit<WorkTimelineItemType, 'dot' | 'color'>;

export default function WorkTimelineItem({
  companyName,
  label,
  position,
  achievments,
  action,
  result,
  challenge,
  completedOnRaised,
}: WorkTimelinItemProps) {
  return (
    <Space direction="vertical" size={0}>
      <Text>{label}</Text>
      <div className={styles.title_block}>
        <Title level={4}>{companyName}</Title>
        {completedOnRaised ? (
          <div className={styles.completed_on_raised}>
            <img src={MiniLogo} alt="" className={styles.mini_logo} />
            Completed on Raised
          </div>
        ) : null}
      </div>
      {position ? (
        <Text className={styles.position}>{position}</Text>
      ) : null}
      {achievments?.length ? (
        <Space className={styles.block_container}>
          <IconMilitaryAward size={20} color="#000" className={styles.icon} />
          <Space direction="vertical" size={10}>
            <Title level={4}>Key achievements</Title>
            <Paragraph>
              <ul className={styles.achievments_list}>
                {achievments.map((ach) => (
                  <React.Fragment key={ach}>
                    {ach ? (
                      <li>{ach}</li>
                    ) : null}
                  </React.Fragment>
                ))}
              </ul>
            </Paragraph>
          </Space>
        </Space>
      ) : null}
      {challenge ? (
        <Space className={styles.block_container}>
          <IconCloudStorm size={20} color="#000" className={styles.icon} />
          <Space direction="vertical" size={10}>
            <Title level={4}>Challenge</Title>
            <Paragraph>{challenge}</Paragraph>
          </Space>
        </Space>
      ) : null}
      {action ? (
        <Space className={styles.block_container}>
          <IconChevronsRight size={20} color="#000" className={styles.icon} />
          <Space direction="vertical" size={10}>
            <Title level={4}>Action</Title>
            <Paragraph>{action}</Paragraph>
          </Space>
        </Space>
      ) : null}
      {result ? (
        <Space className={styles.block_container}>
          <IconTargetArrow size={20} color="#000" className={styles.icon} />
          <Space direction="vertical" size={10}>
            <Title level={4}>Result</Title>
            <Paragraph>{result}</Paragraph>
          </Space>
        </Space>
      ) : null}
    </Space>
  );
}
