import { ButtonsModule } from '@raised/ui-kit';
import styles from './ExpertModalControls.module.css';

const { Button } = ButtonsModule;

interface ExpertModalControlsProps {
  onCancel: () => void;
  onInvite: () => void;
}

export default function ExpertModalControls({
  onCancel,
  onInvite,
}: ExpertModalControlsProps) {
  return (
    <div className={styles.expert_modal_controls}>
      <Button className={styles.cancel} onClick={onCancel} type="text">Cancel</Button>
      <Button className={styles.invite} onClick={onInvite} type="primary">Invite to Project</Button>
    </div>
  );
}
