import { ProjectTypes, User } from '@raised/domain';
import { ColorsModule, LayoutModule, TypographyModule } from '@raised/ui-kit';
import { IconCalendarStats, IconCashBanknote, IconChartArrowsVertical } from '@tabler/icons-react';
import { PROJECT_TYPES } from 'components/project/constants/project-types';
import ExpertTags from 'components/user/components/ExpertTags';
import ExpertFulltimeRoles from './expert-roles/ExpertFulltimeRoles';
import ExpertParttimeRoles from './expert-roles/ExpertParttimeRoles';
import ExpertTestimonials from './expert-testimonials/ExpertTestimonials';
import styles from './BioTab.module.css';

const { Colors } = ColorsModule;
const { Space } = LayoutModule;
const {
  Text, Paragraph, Title,
} = TypographyModule;

interface BioTabProps {
  expert?: User.Public;
}

export default function BioTab({
  expert,
}: BioTabProps) {
  if (!expert) return null;

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {expert?.rate ? (
        <Space size={4} style={{ alignItems: 'flex-start' }}>
          <IconCashBanknote color={Colors.blue} size={18} />
          <Space size={4} direction="vertical">
            <Title level={3}>{`${expert.rate}`}</Title>
            <Text>Estimated rate inclusive Raised fee</Text>
          </Space>
        </Space>
      ) : null}
      {expert?.availabilityTypes?.length ? (
        <Space size={4} style={{ alignItems: 'center' }}>
          <IconCalendarStats style={{ display: 'flex' }} size={18} color={Colors.blue} />
          <Text>{expert.availabilityTypes.map((type) => PROJECT_TYPES?.[type as ProjectTypes] || '').join(', ')}</Text>
        </Space>
      ) : null}
      <Paragraph className={styles.paragraph}>{expert?.biography || '-'}</Paragraph>
      <ExpertTags expert={expert} />
      {expert?.fulltimeRoles?.length || expert?.parttimeRoles?.length ? (
        <Space style={{ marginBottom: 16, alignItems: 'center' }}>
          <IconChartArrowsVertical size={24} color="#000" />
          <Title level={2}>Career Highlights</Title>
        </Space>
      ) : null}
      <div style={{ marginBottom: 40 }}>
        {expert?.fulltimeRoles?.length ? (
          <ExpertFulltimeRoles fulltimeRoles={expert?.fulltimeRoles} />
        ) : null}
        {expert?.parttimeRoles?.length ? (
          <ExpertParttimeRoles parttimeRoles={expert.parttimeRoles} />
        ) : null}
      </div>
      {expert?.testimonials?.length ? (
        <ExpertTestimonials testimonials={expert.testimonials} />
      ) : null}
    </Space>
  );
}
