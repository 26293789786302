import { Template } from '@raised/domain';
import { FeedbackModule } from '@raised/ui-kit';
import AttachFiles from 'components/files/attach-files/AttachFiles';
import useEditTemplate from 'components/templates/hooks/useEditTemplate';
import { useGetTemplateByIdQuery } from 'components/templates/storage/templatesApi';
import { TEMPLATES_ROUTES } from 'components/templates/templates-routes';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import TemplateForm from '../create-template/TemplateForm';

const { Spin } = FeedbackModule;

export default function EditTemplatePage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: template,
    isSuccess: isTemplateSuccess,
    isLoading: isTemplateLoading,
  } = useGetTemplateByIdQuery({ id: id || '' }, { skip: !id });
  const {
    editTemplate,
    isLoading,
    addFiles,
    removeFile,
    files,
    uploadedFiles,
  } = useEditTemplate({
    onSuccess: () => navigate(TEMPLATES_ROUTES.TEMPLATES()),
    initialFiles: template?.attachedFile,
  });

  const onBack = () => navigate(-1);
  const onSubmit = (values: Template.Create) => {
    if (!id) return;

    editTemplate({ ...values, id });
  };

  return (
    <>
      <Helmet>
        <title>Raised - Edit Template</title>
      </Helmet>
      {isTemplateLoading ? (
        <Spin />
      ) : null}
      {isTemplateSuccess ? (
        <TemplateForm
          title="Edit Template"
          onBack={onBack}
          isLoading={isLoading}
          onSubmit={onSubmit}
          defaultValues={template}
          filesInput={(
            <AttachFiles
              files={files}
              addFiles={addFiles}
              uploadedFiles={uploadedFiles}
              removeFile={removeFile}
              subLabel="Provide a description of the desired expert, including their skills, level of experience, and any other relevant qualifications"
              label="Additional documents (optional)"
            />
      )}
        />
      ) : null}
    </>
  );
}
