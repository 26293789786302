import { Project } from '@raised/domain';
import { rootApi } from 'api/rootApi';

export const projectApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    createProject: build.mutation<Project.Public, Project.Create>({
      invalidatesTags: ['PROJECT'],
      query: (body) => ({
        url: '/project',
        body,
        method: 'POST',
      }),
    }),
    createProjectFromTemplate: build.mutation<Project.Public, Project.CreateFromTemplate>({
      invalidatesTags: ['PROJECT'],
      query: (body) => ({
        url: '/project/from-template',
        body,
        method: 'POST',
      }),
    }),
    getProjects: build.query<Project.Public[], { search?: string }>({
      providesTags: (res) => (res
        ? [...res.map((value) => ({ type: 'PROJECT', id: value.id } as const)), 'PROJECT']
        : ['PROJECT']),
      query: (params) => ({
        url: '/project',
        params,
      }),
    }),
    getProjectById: build.query<Project.Public, { id: string }>({
      providesTags: (_r, _e, args) => [{ type: 'PROJECT', id: args.id }],
      query: (params) => ({
        url: `/project/${params.id}`,
      }),
    }),
    getPersonalProjects: build.query<Project.Public[], void>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'PROJECT', id }) as const), 'PROJECT'] : ['PROJECT']),
      query: () => ({
        url: '/project/personal',
      }),
    }),
    getRecommendedProjects: build.query<Project.Public[], void>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'PROJECT', id }) as const), 'PROJECT'] : ['PROJECT']),
      query: () => ({
        url: '/project/recommended',
      }),
    }),
    getUserProjects: build.query<Project.Public[], { userId: string }>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'PROJECT', id }) as const), 'PROJECT'] : ['PROJECT']),
      query: ({ userId }) => ({
        url: `/project/user/${userId}`,
      }),
    }),
    getDeletedProjects: build.query<Project.Public[], void>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'PROJECT', id }) as const), 'PROJECT'] : ['PROJECT']),
      query: () => ({
        url: '/project/deleted',
      }),
    }),
    updateProject: build.mutation<Project.Public, { data: Partial<Project.Create>, id: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'PROJECT', id: args.id }],
      query: (data) => ({
        url: `/project/${data.id}`,
        body: data.data,
        method: 'PUT',
      }),
    }),
    deleteProject: build.mutation<void, { id: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'PROJECT', id: args.id }],
      query: (data) => ({
        url: `/project/${data.id}`,
        method: 'DELETE',
      }),
    }),
    updateProjectStatus: build.mutation<Project.Public, { status: Project.Public['status'], id: string, message?: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'PROJECT', id: args.id }],
      query: ({ id, ...body }) => ({
        url: `/project/status/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
    acceptProject: build.mutation<Project.Public, { projectId: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'PROJECT', id: args.projectId }],
      query: ({ projectId }) => ({
        url: `/project/accept/${projectId}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useGetProjectByIdQuery,
  useGetProjectsQuery,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  useGetPersonalProjectsQuery,
  useGetRecommendedProjectsQuery,
  useGetDeletedProjectsQuery,
  useGetUserProjectsQuery,
  useUpdateProjectStatusMutation,
  useCreateProjectFromTemplateMutation,
  useAcceptProjectMutation,
} = projectApi;
