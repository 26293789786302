import { useGetNotificationsQuery } from '../storage/notificationsApi';

interface UseNewNotificationsProps {
  profileId?: string;
}

export default function useNewNotifications({
  profileId,
}: UseNewNotificationsProps) {
  const info = useGetNotificationsQuery({});
  const { data } = info;

  const newNotificationsCount = profileId ? data?.allNewNotifications.filter((notification) => !notification.viewedBy.includes(profileId))?.length : 0;
  const isNewNotifications = Boolean(newNotificationsCount);

  return {
    isNewNotifications,
    newNotificationsCount,
    ...info,
  };
}
