import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import cn from 'classnames';
import styles from './PublicButton.module.css';
import '../../colors/colors.module.css';

interface PublicButtonProps extends AntButtonProps {}

export default function PublicButton({
  type = 'default',
  className,
  ...rest
}: PublicButtonProps) {
  return (
    <AntButton
      type={type}
      {...rest}
      className={cn(styles.button, {
        [styles.ghost_btn]: type === 'ghost',
        [styles.default_btn]: type === 'default',
        [styles.primary_btn]: type === 'primary',
      }, className)}
    />
  );
}
