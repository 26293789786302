import { UserRole } from '@raised/domain';
import { PROJECT_ROUTES } from 'components/project/project-routes';
import TemplateItem from 'components/templates/components/template-item/TemplateItem';
import useDeleteTemplate from 'components/templates/hooks/useDeleteTemplate';
import useGetTemplates from 'components/templates/hooks/useGetTemplates';
import { TEMPLATES_ROUTES } from 'components/templates/templates-routes';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import TemplateItemControls from './template-item-controls/TemplateItemControls';
import TemplatesFilters from './templates-filters/TemplatesFilters';
import TemplatesGrid from './templates-grid/TemplatesGrid';
import TemplatesTitle from './templates-title/TemplatesTitle';
import TemplatesContainer from './TemplatesContainer';

export default function TemplatesPage() {
  const navigate = useNavigate();
  const { data: profile } = useGetProfileQuery();
  const {
    data: templates,
    isLoading: isTemplatesLoading,
    handleAllCategoriesClick,
    handleCategoryClick,
    handleAllTypesClick,
    handleTypeClick,
    filters,
  } = useGetTemplates();
  const {
    deleteTemplate,
  } = useDeleteTemplate();

  const getLinkToTemplate = (templateId: string) => TEMPLATES_ROUTES.TEMPLATE(templateId);
  const onCreateClick = () => navigate(TEMPLATES_ROUTES.CREATE());
  const onEditClick = (id: string) => navigate(TEMPLATES_ROUTES.EDIT(id));
  const onCreateProjectClick = (id: string) => navigate(PROJECT_ROUTES.CREATE_PROJECT_FROM_TEMPLATE(id));

  return (
    <>
      <Helmet>
        <title>Raised - Templates</title>
      </Helmet>
      <TemplatesContainer
        sidebarContent={(
          <TemplatesFilters
            handleAllCategoriesClick={handleAllCategoriesClick}
            handleAllTypesClick={handleAllTypesClick}
            handleCategoryClick={handleCategoryClick}
            handleTypeClick={handleTypeClick}
            filters={filters}
          />
        )}
        titleBlock={(
          <TemplatesTitle
            templatesCount={templates?.length}
            isLoading={isTemplatesLoading}
            onCreateClick={onCreateClick}
            isCreateButton={profile?.role === UserRole.ADMIN}
          />
        )}
      >
        <TemplatesGrid>
          {templates?.map((template) => (
            <TemplateItem
              key={template.id}
              template={template}
              linkToTemplate={getLinkToTemplate(template.id)}
              controls={(
                <TemplateItemControls
                  onCreateProject={() => onCreateProjectClick(template.id)}
                  onEdit={() => onEditClick(template.id)}
                  onDelete={() => deleteTemplate(template.id)}
                  isEdit={profile?.role === UserRole.ADMIN}
                />
              )}
            />
          ))}
        </TemplatesGrid>
      </TemplatesContainer>
    </>
  );
}
