import { useGetProjectsWithNotificationsQuery } from 'components/notifications/storage/notificationsApi';
import { useMemo, useState } from 'react';

export interface NotificationLinkType {
  title: string;
  value: string;
  count?: number;
}

export default function useNotificationLinks() {
  const [link, setLink] = useState('all');
  const { data, isLoading } = useGetProjectsWithNotificationsQuery(undefined, {
    pollingInterval: 3500,
  });

  const links: Array<NotificationLinkType> = useMemo(() => (data || []).map((project) => ({
    value: project.id,
    title: project.title,
    // count: 10,
  })), [data]);

  const onLinkClick = (value: string) => {
    setLink(value);
  };

  const selectedLink = useMemo(() => data?.find((project) => project.id === link), [data, link]);
  const resetLink = () => setLink('all');

  return {
    onLinkClick,
    selectedLink,
    isLoading,
    links,
    resetLink,
  };
}
