import { UserRole } from '@raised/domain';
import ChatView from 'components/messages/components/chat-view/ChatView';
import MobileChatHeader from 'components/messages/components/chat-view/mobile-chat-header/MobileChatHeader';
import styles from './MobileMenu.module.css';
import ClientLinks from './links/client-links/ClientLinks';
import useMobileSupportChat from './useMobileSupportChat';
import ExpertLinks from './links/expert-links/ExpertLinks';

interface MobileMenuProps {
  role: UserRole;
}

export default function MobileMenu({
  role,
}: MobileMenuProps) {
  const {
    openSupport,
    closeSupport,
    isSupportVisible,
    chat: {
      isSuccess: isSupportChatSuccess,
      data: supportChat,
    },
    count: {
      data: newMessagesCount,
      isLoading: isNewMessagesCountLoading,
    },
  } = useMobileSupportChat();

  return (
    <div className={styles.mobile_menu}>
      {role === UserRole.CLIENT ? (
        <ClientLinks
          onSupportClick={openSupport}
          isSupportOpened={isSupportVisible}
        />
      ) : null}
      {role === UserRole.EXPERT ? (
        <ExpertLinks
          onSupportClick={openSupport}
          isSupportOpened={isSupportVisible}
        />
      ) : null}
      {isSupportChatSuccess ? (
        <ChatView
          chatId={supportChat?.id || ''}
          isVisible={isSupportVisible}
          onClose={closeSupport}
          chatHeader={(
            <MobileChatHeader
              title="Support"
              newMessagesCount={newMessagesCount || 0}
              isLoading={isNewMessagesCountLoading}
              onClick={closeSupport}
            />
          )}
        />
      ) : null}
    </div>
  );
}
