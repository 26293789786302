import { ButtonsModule, FeedbackModule, TypographyModule } from '@raised/ui-kit';
import { IconThumbUp } from '@tabler/icons-react';
import CheckIcon from 'assets/icons/big-check-icon.svg';
import styles from './InvitationSentModal.module.css';

const { Modal } = FeedbackModule;
const { Title, Text } = TypographyModule;
const { Button } = ButtonsModule;

interface InvitationSentModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export default function InvitationSentModal({
  isVisible,
  onClose,
}: InvitationSentModalProps) {
  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      closeIcon={null}
      footer={null}
      centered
      className={styles.invitation_sent_modal}
    >
      <div className={styles.container}>
        <img alt="" src={CheckIcon} className={styles.icon} />
        <Title className={styles.title} level={1}>Invitation sent!</Title>
        <Text className={styles.text}>An expert will respond soon</Text>
        <Button type="primary" onClick={onClose}>
          <IconThumbUp size={18} color="#fff" />
          Okay
        </Button>
      </div>
    </Modal>
  );
}
