import { zodResolver } from '@hookform/resolvers/zod';
import { Project, ProjectSchemas } from '@raised/domain';
import { ButtonsModule, LayoutModule, TypographyModule } from '@raised/ui-kit';
import { ReactNode, useMemo } from 'react';
import { Control, useForm } from 'react-hook-form';
import { renderFormConfig } from 'utils/forms';
import AdminActivitySelector from '../create-project/admin-activity-selector/AdminActivitySelector';
import { getCreateProjectFormConfigPart1, getCreateProjectFormConfigPart2 } from '../create-project/createProjectFormConfig';
import ProjectBudgetInput from '../create-project/project-budget-input/ProjectBudgetInput';
import ProjectCategoriesForm from '../create-project/project-categories-form/ProjectCategoriesForm';
import UsersToNotifySelector from '../create-project/users-to-notify-selector/UsersToNotifySelector';
import styles from './EditProjectForm.module.css';

const { Button } = ButtonsModule;
const { Title } = TypographyModule;
const { Space } = LayoutModule;

interface EditProjectComponentProps {
  initialData: Project.Public;
  onSubmit: (values: Project.Create) => void;
  isAdmin: boolean;
  isLoading: boolean;
  organisationId?: string;
  filesInput: ReactNode;
  onBack: () => void;
}

export default function EditProjectForm({
  onSubmit,
  initialData,
  isAdmin,
  isLoading,
  organisationId,
  filesInput,
  onBack,
}: EditProjectComponentProps) {
  const getFormData = (formData: Project.Create) => ({
    ...formData,
    attachedFile: formData.attachedFile || undefined,
  });
  const {
    control, handleSubmit, reset, setValue,
  } = useForm<Project.Create>({
    defaultValues: {
      ...getFormData(initialData),
    },
    resolver: zodResolver(ProjectSchemas.createProjectSchema),
  });

  const formConfigPart1 = useMemo(() => getCreateProjectFormConfigPart1(control), []);
  const formConfigPart2 = useMemo(() => getCreateProjectFormConfigPart2(control), []);
  const onRestoreClick = () => {
    if (!initialData.original) return;

    reset({
      ...getFormData(initialData.original),
    });
  };

  return (
    <form className="form_container" onSubmit={handleSubmit(onSubmit)}>
      <Space align="center" size={16} style={{ justifyContent: 'space-between' }}>
        <Title style={{ textAlign: 'center', marginBottom: 0 }} level={1}>Edit project</Title>
        {isAdmin && initialData.original ? (
          <Button onClick={onRestoreClick}>Restore to original</Button>
        ) : null}
      </Space>
      {renderFormConfig(formConfigPart1)}
      <ProjectCategoriesForm setValue={setValue} control={control as unknown as Control} />
      {renderFormConfig(formConfigPart2)}
      <ProjectBudgetInput control={control as unknown as Control} />
      {!isAdmin && organisationId ? (
        <UsersToNotifySelector control={control as unknown as Control} organisationId={organisationId} />
      ) : null}
      {filesInput}
      {isAdmin ? (
        <>
          <UsersToNotifySelector organisationId={organisationId} control={control as unknown as Control} />
          <AdminActivitySelector control={control as unknown as Control} />
        </>
      ) : null}
      <div className={styles.buttons}>
        <Button className={styles.submit_btn} loading={isLoading} disabled={isLoading} type="primary" htmlType="submit">
          Save
        </Button>
        <Button danger onClick={onBack} className={styles.cancel_btn}>Cancel</Button>
      </div>
    </form>
  );
}
