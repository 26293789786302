import { LayoutModule, TypographyModule } from '@raised/ui-kit';
import styles from './AdminComment.module.css';

const { Divider } = LayoutModule;
const { Text } = TypographyModule;

interface AdminCommentProps {
  comment: string;
}

export default function AdminComment({
  comment,
}: AdminCommentProps) {
  return (
    <div className={styles.container}>
      <Divider className={styles.divider} />
      <Text strong>Admin comment:</Text>
      <Text className={styles.text}>{comment}</Text>
    </div>
  );
}
