import { message } from 'antd';
import { getErrorMessage, setToken } from 'api/helpers';
import { useVerifyTokenMutation } from 'components/auth/storage/authApi';
import { useLazyGetProfileQuery } from 'components/user/storage/userApi';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useVerifyOrganisationMutation } from '../storage/organisationApi';

interface UseVerifyJoinProps {
  onError: () => void;
}

export default function useVerifyJoin({
  onError,
}: UseVerifyJoinProps) {
  const [mutate, verifyOrganisationInfo] = useVerifyOrganisationMutation();
  const [verify, verifyUserInfo] = useVerifyTokenMutation();
  const [getProfile, { isLoading: isProfileLoading }] = useLazyGetProfileQuery();
  const {
    isError: isVerifyOrganisationError,
    error: verifyOrganisationError,
    isLoading: isVerifyOrganisationLoading,
    isSuccess: isVerifyOrganisationSuccess,
  } = verifyOrganisationInfo;
  const {
    isError: isVerifyUserError,
    error: verifyUserError,
    isLoading: isVerifyUserLoading,
    isSuccess: isVerifyUserSuccess,
    data: verifyUserData,
  } = verifyUserInfo;

  const { search } = useLocation();
  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return {
      orgToken: searchParams.get('orgToken'),
      organisationId: searchParams.get('organisationId'),
      email: searchParams.get('userEmail'),
      userToken: searchParams.get('userToken'),
    };
  }, [search]);

  const isVerifyUser = params?.email && params?.userToken;

  useEffect(() => {
    if (!params.organisationId || !params.orgToken) return;

    mutate({ token: params.orgToken, organisationId: params.organisationId });

    if (params.email && params.userToken) {
      verify({ email: params.email, token: params.userToken });
    }
  }, []);

  useEffect(() => {
    if (!isVerifyOrganisationError) return;

    message.error(getErrorMessage(verifyOrganisationError));
    onError();
  }, [isVerifyOrganisationError]);

  useEffect(() => {
    if (!isVerifyUserError) return;

    message.error(getErrorMessage(verifyUserError));
    onError();
  }, [isVerifyUserError]);

  useEffect(() => {
    if (!isVerifyUserSuccess) return;

    setToken(verifyUserData.access_token);
    getProfile();
  }, [isVerifyUserSuccess]);

  const isLoading = isVerifyUser ? (isVerifyUserLoading || isVerifyOrganisationLoading || isProfileLoading) : isVerifyOrganisationLoading;
  const isSuccess = isVerifyUser ? (isVerifyUserSuccess && isVerifyOrganisationSuccess) : isVerifyOrganisationSuccess;
  const isError = isVerifyUser ? (isVerifyUserError || isVerifyOrganisationError) : isVerifyOrganisationError;

  return {
    ...verifyOrganisationInfo,
    isLoading,
    isSuccess,
    isError,
    isVerifyUser,
    params,
  };
}
