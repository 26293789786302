import { zodResolver } from '@hookform/resolvers/zod';
import { Project, ProjectSchemas, Template } from '@raised/domain';
import { ButtonsModule, FormsModule } from '@raised/ui-kit';
import AdminOrganisationSelector from 'components/project/create-project/admin-organisation-selector/AdminOrganisationSelector';
import AdminUserSelector from 'components/project/create-project/AdminUserSelector';
import ProjectBudgetInput from 'components/project/create-project/project-budget-input/ProjectBudgetInput';
import ProjectCategoriesForm from 'components/project/create-project/project-categories-form/ProjectCategoriesForm';
import UsersToNotifySelector from 'components/project/create-project/users-to-notify-selector/UsersToNotifySelector';
import { getCreateTemplateFormConfig } from 'components/templates/pages/create-template/createTemplateFormConfig';
import ProjectDescriptionForm from 'components/templates/pages/create-template/project-description-form/ProjectDescriptionForm';
import { useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { renderFormConfig } from 'utils/forms';
import styles from './CreateProjectFromTemplateForm.module.css';

const { Button } = ButtonsModule;
const { TextArea } = FormsModule;

interface CreateProjectFromTemplateFormProps {
  onSubmit: (values: Project.CreateFromTemplate) => void;
  isLoading: boolean;
  isAdmin: boolean;
  onBack: () => void;
  organisationId?: string;
  template: Template.Public;
}

export default function CreateProjectFromTemplateForm({
  onSubmit,
  isLoading,
  onBack,
  isAdmin,
  organisationId,
  template,
}: CreateProjectFromTemplateFormProps) {
  const [selectedOrganisation, setSelectedOrganisation] = useState('');
  const {
    control, handleSubmit, setValue, resetField,
  } = useForm<Project.CreateFromTemplate>({
    resolver: zodResolver(ProjectSchemas.createProjectFromTemplateSchema),
    defaultValues: {
      ...template,
    },
  });

  const onSelectOrganisation = (org: string) => {
    if (org === selectedOrganisation) return;

    setSelectedOrganisation(org);
    resetField('clientId');
    resetField('usersToNotifyId');
  };

  const templateFormConfig = getCreateTemplateFormConfig(control as unknown as Control<Template.Create>);

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      {renderFormConfig(templateFormConfig)}
      <ProjectCategoriesForm setValue={setValue} control={control as unknown as Control} />
      <ProjectDescriptionForm control={control as unknown as Control<Template.Create>} />
      <Controller
        control={control}
        name="expertPreference"
        render={({ field, fieldState }) => (
          <TextArea
            {...field}
            status={fieldState.error ? 'error' : undefined}
            placeholder="Type expert preference"
            label="Expert preference"
            subLabel="Provide a description of the desired expert, including their skills, level of experience, and any other relevant qualifications"
          />
        )}
      />
      <ProjectBudgetInput control={control as unknown as Control} />
      {!isAdmin && organisationId ? (
        <UsersToNotifySelector control={control as unknown as Control} organisationId={organisationId} />
      ) : null}
      {isAdmin ? (
        <>
          <AdminOrganisationSelector
            selectedOrganisation={selectedOrganisation}
            onSelectOrganisation={onSelectOrganisation}
          />
          <AdminUserSelector organisationId={selectedOrganisation} control={control as unknown as Control} />
          <UsersToNotifySelector organisationId={selectedOrganisation} control={control as unknown as Control} />
        </>
      ) : null}
      <div className={styles.buttons}>
        <Button
          type="primary"
          loading={isLoading}
          disabled={isLoading}
          htmlType="submit"
          className={styles.submit_btn}
        >
          Create Project
        </Button>
        <Button
          danger
          onClick={onBack}
          disabled={isLoading}
          className={styles.cancel_btn}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}
