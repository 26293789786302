import { User } from '@raised/domain';
import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import { useGetRecommendedTemplatesQuery } from 'components/templates/storage/templatesApi';
import CreateUsecaseModal from 'components/usecases/components/create-usecase-modal/CreateUsecaseModal';
import useCreateUsecaseModal from 'components/usecases/components/create-usecase-modal/useCreateUsecaseModal';
import { useGetUserUsecasesQuery } from 'components/usecases/storage/usecasesApi';
import { USECASE_ROUTES } from 'components/usecases/usecase-routes';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import RecommendedTemplates from './recommended-templates/RecommendedTemplates';
import UseCases from './use-cases/UseCases';
import styles from './UseCasesStep.module.css';

const { Title } = TypographyModule;
const { Button } = ButtonsModule;

interface UseCasesStepProps {
  user: User.Public;
}

export default function UseCasesStep({
  user,
}: UseCasesStepProps) {
  const navigate = useNavigate();
  const {
    isVisible,
    templateId,
    closeModal,
    openModal,
    openModalWithTemplate,
  } = useCreateUsecaseModal();
  const {
    data: useCases,
    isLoading: isUseCasesLoading,
  } = useGetUserUsecasesQuery({ id: user.id });
  const {
    data: recommendedTemplates,
    isLoading: isRecommendedTemplatesLoading,
  } = useGetRecommendedTemplatesQuery({ userId: user.id });

  const selectedTemplate = useMemo(() => {
    if (templateId && recommendedTemplates?.length) {
      return recommendedTemplates.find((t) => t.id === templateId);
    }

    return {};
  }, [templateId]);

  const onUsecaseClick = (usecaseId: string) => navigate(USECASE_ROUTES.EDIT(usecaseId));

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title_block}>
          <Title level={1}>Your Use Cases</Title>
          <Button onClick={openModal} type="primary">Create from scratch</Button>
        </div>
        <UseCases onUsecaseClick={onUsecaseClick} usecases={useCases || []} isLoading={isUseCasesLoading} />
        <RecommendedTemplates
          onAddClick={openModalWithTemplate}
          templates={recommendedTemplates || []}
          isLoading={isRecommendedTemplatesLoading}
          userCategories={user.projectCategories}
        />
      </div>
      <CreateUsecaseModal defaultValues={{ ...selectedTemplate, userId: user.id }} onClose={closeModal} isVisible={isVisible} />
    </>
  );
}
