import { ReactNode } from 'react';
import styles from './ProjectsGrid.module.css';

interface ProjectsGridProps {
  children: ReactNode;
}

export default function ProjectsGrid({
  children,
}: ProjectsGridProps) {
  return (
    <div className={styles.container}>{children}</div>
  );
}
