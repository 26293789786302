import { Payment } from '@raised/domain';
import { useState } from 'react';
import ViewProposalModal from 'components/proposal/components/ViewProposalModal';
import MatchPaymentsTable from 'components/payments/components/match-payments-table/MatchPaymentsTable';

interface PaymentsTabProps {
  payments: Payment.Public[]
  isLoading: boolean;
}

export default function PaymentsTab({
  payments,
  isLoading,
}: PaymentsTabProps) {
  const [viewProposal, setViewProposal] = useState('');

  const onViewProposalClick = (proposalId: string) => setViewProposal(proposalId);
  const onModalClose = () => setViewProposal('');

  return (
    <>
      <MatchPaymentsTable
        payments={payments}
        loading={isLoading}
        onViewProposalClick={onViewProposalClick}
      />
      <ViewProposalModal
        proposalId={viewProposal}
        isVisible={!!viewProposal}
        onClose={onModalClose}
      />
    </>
  );
}
