import { Project } from '@raised/domain';
import { FormsModule, TypographyModule } from '@raised/ui-kit';
import { PROJECT_CATEGORIES_VALUES } from 'components/project/constants/project-categories';
import { useEffect } from 'react';
import {
  Control, Controller, UseFormSetValue, useWatch,
} from 'react-hook-form';
import styles from './ExpertCategories.module.css';

const { Title } = TypographyModule;
const { Select } = FormsModule;

interface ExpertCategoriesProps {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}

type FunctionKey = keyof Project.Categories;

export default function ExpertCategories({
  control,
  setValue,
}: ExpertCategoriesProps) {
  const selectedFunctions = useWatch({
    control,
    name: 'tags.function',
  });
  const validSelectedFunctions = selectedFunctions?.filter((func: FunctionKey) => Object.keys(PROJECT_CATEGORIES_VALUES).includes(func));

  useEffect(() => {
    const unselectedFunctions = Object.keys(PROJECT_CATEGORIES_VALUES).filter((key) => !selectedFunctions?.includes(key));
    unselectedFunctions.forEach((field) => {
      setValue(`projectCategories.${field}`, undefined);
    });
  }, [selectedFunctions]);

  const getOptions = (func: FunctionKey) => {
    if (func in PROJECT_CATEGORIES_VALUES) {
      return PROJECT_CATEGORIES_VALUES[func].values.map((v) => ({ label: v, value: v }));
    }

    return [];
  };

  if (!selectedFunctions?.length) return null;

  return (
    <div className={styles.container}>
      <Title level={4}>Categories</Title>
      {validSelectedFunctions?.map((func: FunctionKey) => (
        <Controller
          control={control}
          name={`projectCategories.${func}`}
          render={({ field, fieldState }) => (
            <Select
              label={`Select ${PROJECT_CATEGORIES_VALUES[func].label}`}
              placeholder={`Select ${PROJECT_CATEGORIES_VALUES[func].label}`}
              {...field}
              style={{ width: '100%' }}
              mode="multiple"
              status={fieldState.error ? 'error' : undefined}
              options={getOptions(func)}
            />
          )}
        />
      ))}
    </div>
  );
}
