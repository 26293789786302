import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/formats';

export interface UserProjectsTableRow {
  key: string;
  title: string;
  description: string;
  createdAt: string;
  projectLink: string;
}

export const getUserProjectsTableConfig = (): ColumnsType<UserProjectsTableRow> => [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: (a, b) => a.title.length - b.title.length,
    width: '50%',
    render: (value, record) => <Link to={record.projectLink}>{value}</Link>,
  },
  {
    title: 'Creation date',
    dataIndex: 'createdAt',
    sorter: (a, b) => {
      const opA = new Date(a.createdAt).getTime();
      const opB = new Date(b.createdAt).getTime();
      return opA - opB;
    },
    render: (value) => formatDate(new Date(value)),
    key: 'createdAt',
    width: '16%',
  },
];
