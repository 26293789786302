import { User } from '@raised/domain';
import {
  Icon3dCubeSphere, IconBriefcase, IconWorld, IconRocket,
} from '@tabler/icons-react';
import { ReactNode } from 'react';

export type TagGroups = 'function' | 'projectCategories' | 'clients' | 'geo';
type GroupedTags = Record<TagGroups, Record<string, string[]>>;

const functionTags = ['function'];
const clientTags = ['type', 'stage', 'businessModel', 'industry'];
const geoTags = ['markets', 'countries'];

const icons: Record<TagGroups, { icon: ReactNode; color: string; label: string; }> = {
  function: {
    label: 'Function',
    icon: <Icon3dCubeSphere size={18} />,
    color: 'rgba(138, 255, 227, 0.60)',
  },
  projectCategories: {
    label: 'Project Categories',
    icon: <IconRocket size={18} />,
    color: 'rgba(233, 187, 255, 0.60)',
  },
  clients: {
    label: 'Clients',
    icon: <IconBriefcase size={18} />,
    color: '#DAE9FF',
  },
  geo: {
    label: 'Geo',
    icon: <IconWorld size={18} />,
    color: 'rgba(255, 201, 138, 0.60)',
  },
};

export default function useExpertTags() {
  const groupTags = (tags: User.Tags, allowEmpty = false) => Object.entries(tags).reduce((acc: GroupedTags, item) => {
    const [key, value] = item;

    if (allowEmpty ? true : value.length) {
      if (functionTags.includes(key)) {
        acc.function[key] = value;
      } else if (clientTags.includes(key)) {
        acc.clients[key] = value;
      } else if (geoTags.includes(key)) {
        acc.geo[key] = value;
      }
    }

    return acc;
  }, {
    function: {},
    clients: {},
    projectCategories: {},
    geo: {},
  });

  return {
    icons,
    groupTags,
  };
}
