import { InputProps as AntInputProps, Input as AntInput } from 'antd';
import cn from 'classnames';
import { Text } from 'src/ui-kit/typography';
import { InputWrapper, InputWrapperProps } from '../input-wrapper';
import styles from '../Input.module.css';

interface InputProps extends AntInputProps, InputWrapperProps {
  error?: string;
}

export default function Input({
  label,
  subLabel,
  className,
  error,
  ...props
}: InputProps) {
  return (
    <InputWrapper label={label} subLabel={subLabel}>
      <AntInput {...props} className={cn(styles.input, className)} />
      {error ? (
        <Text type="danger">{error}</Text>
      ) : null}
    </InputWrapper>
  );
}
