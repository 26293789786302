import { IconHelp } from '@tabler/icons-react';
import { Menu } from 'antd';
import { MESSAGE_ROUTES } from 'components/messages/messages-routes';
import { useLocation } from 'react-router-dom';
import MenuItem from './MenuItem';
import styles from './BottomSideMenu.module.css';

export default function BottomSideMenu() {
  const { pathname } = useLocation();

  return (
    <Menu
      items={[
        {
          label: <MenuItem to={MESSAGE_ROUTES.SUPPORT_CHAT_PAGE()}>Support</MenuItem>,
          icon: <IconHelp size={24} stroke={1.5} />,
          className: 'side_menu_item',
          key: MESSAGE_ROUTES.SUPPORT_CHAT_PAGE(),
        },
      ]}
      selectedKeys={[pathname]}
      className={styles.container}
    />
  );
}
