import { Template } from '@raised/domain';
import { FormsModule } from '@raised/ui-kit';
import { PROJECT_CATEGORIES } from 'components/project/constants/project-categories';
import { PROJECT_TYPES } from 'components/project/constants/project-types';
import { Control } from 'react-hook-form';
import { FormConfig } from 'utils/forms';

const { TextArea, Select } = FormsModule;

const projectCategoryOptions = Object.entries(PROJECT_CATEGORIES).map(([value, label]) => ({ value, label }));
const projectTypeOptions = Object.entries(PROJECT_TYPES).map(([value, label]) => ({ value, label }));

export const getCreateTemplateFormConfig = (control: Control<Template.Create>): FormConfig<Template.Create> => [
  {
    controller: {
      name: 'title',
      defaultValue: '',
      control,
    },
    component: TextArea,
    label: 'Project Title',
    componentProps: {
      rows: 4,
      placeholder: 'Name Your Project',
    },
  },
  {
    controller: {
      name: 'category',
      control,
    },
    label: 'Function',
    subLabel: 'Which function are the best fit for your project? This will help us provide you with the best expert matches',
    component: Select,
    componentProps: {
      placeholder: 'Select function',
      options: projectCategoryOptions,
      mode: 'multiple',
    },
  },
  {
    controller: {
      name: 'type',
      control,
    },
    label: 'Type of the project',
    component: Select,
    componentProps: {
      placeholder: 'Select category',
      options: projectTypeOptions,
      mode: 'multiple',
    },
  },
];
