import { LogMessage, Message, UserRole } from '@raised/domain';
import { MATCH_ROUTES } from 'components/match/match-routes';
import { MESSAGE_ROUTES } from 'components/messages/messages-routes';
import { useGetNotificationsQuery } from 'components/notifications/storage/notificationsApi';
import { PROJECT_ROUTES } from 'components/project/project-routes';
import { useNavigate } from 'react-router-dom';
import { isMessage } from '../storage/type-guards';

interface UseNotificationsProps {
  userRole: UserRole;
  selectedLink?: string;
}

export default function useNotifications({
  selectedLink,
  userRole,
}: UseNotificationsProps) {
  const navigate = useNavigate();
  const { data, isLoading } = useGetNotificationsQuery({ projectId: selectedLink }, {
    pollingInterval: 3500,
  });

  const onNotificationClick = (notification: LogMessage.Public | Message.Public) => {
    if (isMessage(notification)) {
      if (!notification.chat?.matchId) {
        navigate(MESSAGE_ROUTES.SUPPORT_CHAT_PAGE());
        return;
      }

      if (userRole === UserRole.EXPERT && notification.chat?.match?.projectId) {
        navigate(PROJECT_ROUTES.PROJECT(notification.chat?.match?.projectId));
      }

      if (userRole === UserRole.CLIENT) {
        navigate(MATCH_ROUTES.CLIENT_MATCH_OVERVIEW(notification.chat.matchId));
      }
    } else {
      if (!notification.match) return;

      if (userRole === UserRole.EXPERT) {
        navigate(PROJECT_ROUTES.PROJECT(notification.match.projectId));
      }

      if (userRole === UserRole.CLIENT) {
        navigate(MATCH_ROUTES.CLIENT_MATCH_OVERVIEW(notification.match.id));
      }
    }
  };

  return {
    allNotifications: data?.allNewNotifications || [],
    projectActionsNotifications: data?.newLogMessages || [],
    messageNotifications: data?.newMessages || [],
    onNotificationClick,
    isLoading,
  };
}
