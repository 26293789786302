/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { zodResolver } from '@hookform/resolvers/zod';
import { Template, TemplateSchemas } from '@raised/domain';
import { ButtonsModule, NavigationModule, TypographyModule } from '@raised/ui-kit';
import ProjectCategoriesForm from 'components/project/create-project/project-categories-form/ProjectCategoriesForm';
import ProjectDescriptionForm from 'components/templates/pages/create-template/project-description-form/ProjectDescriptionForm';
import {
  ReactNode, Ref, useEffect, useRef,
} from 'react';
import { useForm } from 'react-hook-form';
import { renderFormConfig, scrollToError } from 'utils/forms';
import styles from './UsecaseForm.module.css';
import { getCreateUsecaseFormConfig } from './usecaseFormConfig';

const { Breadcrumb } = NavigationModule;
const { Title } = TypographyModule;
const { Button } = ButtonsModule;

interface UsecaseFormProps {
  onBack: () => void;
  onSubmit: (data: Template.Create) => void;
  isLoading: boolean;
  filesInput: ReactNode;
  defaultValues?: Partial<Template.Create>;
  title?: string;
  formRef?: Ref<HTMLFormElement>;
  isControls?: boolean;
}

export default function UsecaseForm({
  onBack,
  onSubmit,
  filesInput,
  isLoading,
  defaultValues,
  title,
  formRef,
  isControls = true,
}: UsecaseFormProps) {
  const formKey = useRef(1);
  const {
    handleSubmit, control, setValue, reset,
  } = useForm<Template.Create>({
    resolver: zodResolver(TemplateSchemas.createTemplateSchema), defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
    formKey.current += 1;
  }, [defaultValues]);

  const formConfig = getCreateUsecaseFormConfig(control);

  return (
    <form key={formKey.current} ref={formRef} onSubmit={handleSubmit(onSubmit, scrollToError)} onReset={() => reset()} className="form_container">
      {title ? (
        <div className={styles.title_block}>
          <Breadcrumb
            items={[
              {
                title: <a onClick={onBack}>Usecases</a>,
              },
              {
                title,
              },
            ]}
            style={{ alignSelf: 'center' }}
          />
          <Title level={1} className={styles.title}>{title}</Title>
        </div>
      ) : null}
      {renderFormConfig(formConfig)}
      <ProjectCategoriesForm setValue={setValue} control={control} />
      <ProjectDescriptionForm
        title="Use Case Description"
        descriptionPlaceholder="Describe your Use Case"
        challengePlaceholder="Use Case challenge"
        challengeLabel="Use Case challenge"
        control={control}
      />
      {filesInput}
      {isControls ? (
        <div className={styles.buttons}>
          <Button
            type="primary"
            loading={isLoading}
            disabled={isLoading}
            htmlType="submit"
            className={styles.submit_btn}
          >
            Create
          </Button>
          <Button
            danger
            onClick={onBack}
            disabled={isLoading}
            className={styles.cancel_btn}
          >
            Cancel
          </Button>
        </div>
      ) : null}
    </form>
  );
}
