import { TypographyModule } from '@raised/ui-kit';
import { ReactNode } from 'react';
import styles from './AssignProjectComponent.module.css';

const { Title } = TypographyModule;

interface AssignProjectComponentProps {
  children: ReactNode;
}

export default function AssignProjectComponent({
  children,
}: AssignProjectComponentProps) {
  return (
    <>
      <div className={styles.container}>
        <Title level={1}>Assign an expert to the project</Title>
        {/* <TimezoneMessage /> */}
        {children}
      </div>
    </>
  );
}
