import { InputNumberProps as AntInputNumberProps, InputNumber as AntInputNumber } from 'antd';
import cn from 'classnames';
import { InputWrapper, InputWrapperProps } from '../input-wrapper';
import styles from '../Input.module.css';

interface InputNumberProps extends AntInputNumberProps, InputWrapperProps {}

export default function InputNumber({
  label,
  subLabel,
  className,
  ...rest
}: InputNumberProps) {
  return (
    <InputWrapper label={label} subLabel={subLabel}>
      <AntInputNumber {...rest} className={cn(styles.input, className)} />
    </InputWrapper>
  );
}
