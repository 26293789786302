import { FileDoneOutlined, FileOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { UploadFilesResponseType } from 'components/files/storage/types';
import { ChangeEvent, useRef } from 'react';

interface ProposalFilesInputProps {
  uploadFiles: (fileList: File[]) => void;
  uploadedFiles: UploadFilesResponseType;
  isLoading: boolean;
}

export default function ProposalFilesInput({
  uploadFiles,
  uploadedFiles,
  isLoading,
}: ProposalFilesInputProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onChangeFiles = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    uploadFiles(Array.from(e.target.files));
  };

  const onUploadClick = () => fileInputRef.current?.click();

  return (
    <Space direction="vertical">
      <Typography.Text strong>Attached files</Typography.Text>
      {uploadedFiles.length ? (
        <>
          {uploadedFiles.map((file) => (
            <Space key={file.url}>
              <FileDoneOutlined size={16} color="#1677FF" />
              <Typography.Text>{file.name}</Typography.Text>
            </Space>
          ))}
        </>
      ) : (
        <>
          <input
            onChange={onChangeFiles}
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            multiple
          />
          <Button
            onClick={onUploadClick}
            loading={isLoading}
            disabled={isLoading}
          >
            <FileOutlined />
            Attach files
          </Button>
        </>
      )}
    </Space>
  );
}
