import cn from 'classnames';
import { zodResolver } from '@hookform/resolvers/zod';
import { SuccessStory, SuccessStorySchemas } from '@raised/domain';
import { useForm } from 'react-hook-form';
import { renderFormConfig } from 'utils/forms';
import { Ref } from 'react';
import styles from './successStoryForm.module.css';
import { getCreateSuccessStoryFormConfigPart1, getCreateSuccessStoryFormConfigPart2 } from './successStoryFormConfig';
import FilesInput from './files-input/FilesInput';

interface CreateSuccessStoryFormProps {
  onSubmit: (values: SuccessStory.CreateForm) => void;
  defaultValues?: Partial<SuccessStory.Create>;
  formRef?: Ref<HTMLFormElement>;
}

export default function CreateSuccessStoryForm({
  onSubmit,
  defaultValues,
  formRef,
}: CreateSuccessStoryFormProps) {
  const {
    handleSubmit, control, reset, setValue,
  } = useForm<SuccessStory.CreateForm>({
    defaultValues,
    resolver: zodResolver(SuccessStorySchemas.createSuccessStorySchema),
  });

  const formConfigPart1 = getCreateSuccessStoryFormConfigPart1(control);
  const formConfigPart2 = getCreateSuccessStoryFormConfigPart2(control);

  const handleReset = () => {
    setValue('files', []);
    reset();
  };

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
      className={cn('form_container', styles.container)}
      onReset={handleReset}
    >
      {renderFormConfig(formConfigPart1)}
      <FilesInput control={control as any} />
      {renderFormConfig(formConfigPart2)}
    </form>
  );
}
