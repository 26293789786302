import { Spin } from 'antd';
import { Navigate } from 'react-router-dom';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { AUTH_ROUTES } from 'components/auth/auth-routes';
import { getInitialRoute } from 'components/auth/utils';

export default function InitialRoute() {
  const { isError, isLoading, data } = useGetProfileQuery();

  if (isLoading) {
    return <Spin />;
  }

  if (isError) {
    return <Navigate to={AUTH_ROUTES.LOGIN()} replace />;
  }

  if (data) {
    return <Navigate to={getInitialRoute(data.role)} replace />;
  }

  return null;
}
