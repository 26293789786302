import { Template } from '@raised/domain';
import { ColorsModule, DataModule, TypographyModule } from '@raised/ui-kit';
import { IconAd2, IconClockHour3, IconCloudStorm } from '@tabler/icons-react';
import { PROJECT_CATEGORIES } from 'components/project/constants/project-categories';
import { PROJECT_TYPES } from 'components/project/constants/project-types';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './TemplateItem.module.css';

const { Colors } = ColorsModule;
const { Text, Title } = TypographyModule;
const { Tag } = DataModule;

interface TemplateItemProps {
  template: Template.Public;
  controls?: ReactNode;
  linkToTemplate: string;
}

export default function TemplateItem({
  template,
  controls,
  linkToTemplate,
}: TemplateItemProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          {template.type ? (
            <div className={styles.type}>
              <IconClockHour3 style={{ flexShrink: 0 }} color={Colors.blue} size={20} />
              <Text>{template.type?.map((type) => PROJECT_TYPES[type]).join(', ')}</Text>
            </div>
          ) : null}
          <Link to={linkToTemplate}>
            <Title className={styles.title} level={1}>{template.title}</Title>
          </Link>
          <div className={styles.tags}>
            {template.category?.map((category) => (
              <Tag className={styles.tag} label={PROJECT_CATEGORIES[category]} key={category} />
            ))}
          </div>
        </div>

        {template.challenge ? (
          <div className={styles.challenge_block}>
            <IconCloudStorm size={22} />
            <div className={styles.challenge_content}>
              <Title level={4}>Project Challenge</Title>
              <Text>{template.challenge}</Text>
            </div>
          </div>
        ) : null}

        {template?.description ? (
          <div className={styles.description_block}>
            <div className={styles.description_block__icon}>
              <IconAd2 size={20} />
            </div>
            <Text style={{ whiteSpace: 'pre-wrap' }}>{template?.description}</Text>
          </div>
        ) : null}
      </div>
      {controls ? (
        <div className={styles.controls}>{controls}</div>
      ) : null}
    </div>
  );
}
