import { Proposal, UserRole } from '@raised/domain';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { excludeNullFields } from 'utils/forms';
import useEditProposal from '../hooks/useEditProposal';
import { useGetProposalByIdQuery } from '../storage/proposalApi';
import EditProposalForm from './EditProposalForm';

export default function EditProposalPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const onBack = () => navigate(-1);

  const {
    data: profile,
    isSuccess: isProfileSuccess,
  } = useGetProfileQuery();
  const {
    data: proposal,
    isSuccess: isProposalSuccess,
  } = useGetProposalByIdQuery({ id: id || '' }, { skip: !id });
  const {
    editProposal,
    isLoading: isEditProposalLoading,
  } = useEditProposal({
    onSuccess: onBack,
  });

  useEffect(() => {
    if (!isProfileSuccess || !isProposalSuccess) return;

    if (proposal.expertId !== profile.id && profile.role !== UserRole.ADMIN) {
      onBack();
    }
  }, [isProposalSuccess, isProfileSuccess]);

  const onSubmit = (values: Proposal.Edit) => {
    if (!id) return;

    editProposal({ ...values, proposalId: id });
  };

  return (
    <>
      <Helmet>
        <title>Raised - Edit proposal</title>
      </Helmet>
      {isProposalSuccess && isProfileSuccess && (
        <EditProposalForm
          isLoading={isEditProposalLoading}
          onSubmit={onSubmit}
          initialValues={excludeNullFields(proposal)}
          isAdmin={profile.role === UserRole.ADMIN}
          onBack={onBack}
        />
      )}
    </>
  );
}
