import { Proposal } from '@raised/domain';
import { Space } from 'antd';
import { UploadFilesResponseType } from 'components/files/storage/types';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { renderFormConfig } from 'utils/forms';
import { getCreateProposalFormConfig } from './createProposalFormConfig';
import FixedForm from './proposal-budget-form/forms/FixedForm';
import ProposalFilesInput from './ProposalFilesInput';

interface CreateProposalFormProps {
  control: Control<Proposal.Create>;
  // handleSetValue: UseFormSetValue<Proposal.Create>;
  uploadFiles: (fileList: File[]) => void;
  uploadedFiles: UploadFilesResponseType;
  isFilesLoading: boolean;
  profilePaymentRate?: string;
}

export default function CreateProposalForm({
  control,
  // handleSetValue,
  uploadFiles,
  uploadedFiles,
  isFilesLoading,
  profilePaymentRate,
}: CreateProposalFormProps) {
  const formConfig = useMemo(() => getCreateProposalFormConfig(control), []);

  return (
    <Space direction="vertical" size={16}>
      {renderFormConfig(formConfig)}
      <ProposalFilesInput
        uploadFiles={uploadFiles}
        uploadedFiles={uploadedFiles}
        isLoading={isFilesLoading}
      />
      <FixedForm profilePaymentRate={profilePaymentRate} control={control} />
    </Space>
  );
}
