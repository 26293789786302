import { CopyOutlined } from '@ant-design/icons';
import {
  Button, Divider, Input, message, Space, Typography,
} from 'antd';

interface InviteLinkComponentProps {
  profileId: string;
}

export default function InviteLinkComponent({
  profileId,
}: InviteLinkComponentProps) {
  const getInviteLink = () => {
    const { protocol, host } = window.location;

    return `${protocol}//${host}/user/ghost/activate/${profileId}`;
  };

  const onCopyClick = () => {
    const link = getInviteLink();

    navigator.clipboard.writeText(link);
    message.success('Invite link copied');
  };

  return (
    <Space direction="vertical">
      <Divider />
      <Typography.Text strong>Invite link</Typography.Text>
      <Space>
        <Input readOnly value={getInviteLink()} />
        <Button onClick={onCopyClick} icon={<CopyOutlined />} />
      </Space>
    </Space>
  );
}
