import { FormsModule } from '@raised/ui-kit';
import { useGetOrganisationByIdQuery } from 'components/organisation/storage/organisationApi';
import { useMemo } from 'react';
import { Control, useController } from 'react-hook-form';

const { Select } = FormsModule;

interface UsersToNotifySelectorProps {
  organisationId?: string;
  control: Control;
}

export default function UsersToNotifySelector({
  organisationId = '',
  control,
}: UsersToNotifySelectorProps) {
  const { field } = useController({ control, name: 'usersToNotifyId' });
  const {
    data: organisation,
    isLoading,
  } = useGetOrganisationByIdQuery({ id: organisationId }, { skip: !organisationId });

  const selectOptions = useMemo(() => organisation?.users.map((user) => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
  })), [organisation]);

  return (
    <Select
      label="Choose who to notify about changes in the project"
      disabled={!organisationId}
      style={{ width: '100%' }}
      loading={isLoading}
      value={field.value}
      onChange={field.onChange}
      placeholder="Choose who to notify"
      options={selectOptions}
      mode="multiple"
    />
  );
}
