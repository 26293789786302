import { Template } from '@raised/domain';
import { rootApi } from 'api/rootApi';
import { GetTemplatesParamsType } from './types';

export const templatesApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getTemplates: build.query<Template.Public[], GetTemplatesParamsType>({
      providesTags: (res) => (res?.length ? [
        ...res.map((tmp) => ({ id: tmp.id, type: 'TEMPLATE' }) as const),
        'TEMPLATE',
      ] : ['TEMPLATE']),
      query: (params) => ({
        url: '/template',
        params: {
          type: params.type.length ? params.type : undefined,
          category: params.category.length ? params.category : undefined,
        },
      }),
    }),
    getTemplateById: build.query<Template.Public, { id: string }>({
      providesTags: (res) => (res ? [{ type: 'TEMPLATE', id: res.id }] : ['TEMPLATE']),
      query: ({ id }) => ({
        url: `/template/${id}`,
      }),
    }),
    createTemplate: build.mutation<Template.Public, Template.Create>({
      invalidatesTags: ['TEMPLATE'],
      query: (body) => ({
        url: '/template',
        method: 'POST',
        body,
      }),
    }),
    editTemplate: build.mutation<Template.Public, Template.Create & { id: string }>({
      invalidatesTags: (_r, _e, args) => [{ id: args.id, type: 'TEMPLATE' }],
      query: ({ id, ...body }) => ({
        url: `/template/${id}`,
        body,
        method: 'PUT',
      }),
    }),
    deleteTemplate: build.mutation<void, { id: string }>({
      invalidatesTags: ['TEMPLATE'],
      query: ({ id }) => ({
        url: `/template/${id}`,
        method: 'DELETE',
      }),
    }),
    getRecommendedTemplates: build.query<Template.Public[], { userId: string }>({
      providesTags: (res) => (res?.length ? [
        ...res.map((tmp) => ({ id: tmp.id, type: 'TEMPLATE' }) as const),
        'TEMPLATE',
      ] : ['TEMPLATE']),
      query: ({ userId }) => ({
        url: `/template/recommended/${userId}`,
      }),
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useCreateTemplateMutation,
  useEditTemplateMutation,
  useGetTemplateByIdQuery,
  useDeleteTemplateMutation,
  useGetRecommendedTemplatesQuery,
} = templatesApi;
