import { UserRole } from '@raised/domain';
import { MATCH_ROUTES } from 'components/match/match-routes';
import { PROJECT_ROUTES } from 'components/project/project-routes';

export const getInitialRoute = (userRole: UserRole): string => {
  switch (userRole) {
    case UserRole.ADMIN:
      return PROJECT_ROUTES.ASSIGN_PROJECTS();
    case UserRole.CLIENT:
      return PROJECT_ROUTES.PROJECTS();
    case UserRole.EXPERT:
      return MATCH_ROUTES.CONFIRM_MATCH();
    default:
      return '';
  }
};
