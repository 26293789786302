import { UserRole } from '@raised/domain';
import { Spin } from 'antd';
import { getInitialRoute } from 'components/auth/utils';
import useVerifyJoin from 'components/organisation/hooks/useVerifyJoin';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import useChangeOrganisation from '../hooks/useChangeOrganisation';
import useJoinOrganisation from '../hooks/useJoinOrganisation';
import ChangeOrganisationComponent from './change/ChangeOrganisationComponent';
import JoinOrganisationForm from './create/JoinOrganisationForm';

export default function JoinOrganisationPage() {
  const navigate = useNavigate();

  const onError = () => {
    navigate('/');
  };
  const onSuccess = () => {
    navigate(getInitialRoute(UserRole.CLIENT));
  };

  const {
    isLoading: isVerifyLoading,
    isSuccess: isVerifySuccess,
    isVerifyUser,
    params,
  } = useVerifyJoin({
    onError,
  });
  const {
    joinOrganisation,
    isLoading: isJoinLoading,
  } = useJoinOrganisation({
    onSuccess,
  });
  const {
    changeOrganisation,
    isLoading: isChangeLoading,
  } = useChangeOrganisation({
    onSuccess,
    onError,
  });

  const handleChangeOrganisation = () => {
    if (!params.organisationId || !params.orgToken || !params.userToken || !params.email) return;

    changeOrganisation({
      userEmail: params.email,
      userToken: params.userToken,
      organisationId: params.organisationId,
      organisationToken: params.orgToken,
    });
  };

  return (
    <>
      <Helmet>
        <title>Raised - Join organisation</title>
      </Helmet>
      {isVerifyLoading ? (
        <Spin />
      ) : null}
      {isVerifySuccess && params.orgToken && params.organisationId ? (
        <>
          {isVerifyUser ? (
            <ChangeOrganisationComponent
              onSubmit={handleChangeOrganisation}
              onDecline={onError}
              isLoading={isChangeLoading}
              organisationId={params.organisationId}
            />
          ) : (
            <JoinOrganisationForm
              token={params.orgToken}
              organisationId={params.organisationId}
              onSubmit={joinOrganisation}
              isLoading={isJoinLoading}
            />
          )}
        </>
      ) : null}
    </>
  );
}
