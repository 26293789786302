import { Typography } from 'antd';
import { ReactNode } from 'react';

interface AdminUserListComponentProps {
  children: ReactNode;
}

export default function AdminUserListComponent({
  children,
}: AdminUserListComponentProps) {
  return (
    <div>
      <Typography.Title level={2}>Users list</Typography.Title>
      {children}
    </div>
  );
}
