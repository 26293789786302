import { RouteObject } from 'react-router-dom';
import MainLayout from 'components/layout/containers/main-layout/MainLayout';
import AdminRouteLayout from 'components/layout/containers/admin-route/AdminRouteLayout';
import LogMessagesFeedPage from './log-messages-feed/LogMessagesFeedPage';
import LastActionsPage from './last-actions/LastActionsPage';

export const LOG_MESSAGES_ROUTES = {
  LOG_MESSAGES_FEED: () => '/log-messages/feed',
  LAST_ACTIONS: () => '/last-actions',
};

const logMessagesRoutes: Array<RouteObject> = [
  {
    element: <MainLayout />,
    children: [
      {
        element: <AdminRouteLayout />,
        children: [
          {
            path: LOG_MESSAGES_ROUTES.LOG_MESSAGES_FEED(),
            element: <LogMessagesFeedPage />,
          },
          {
            path: LOG_MESSAGES_ROUTES.LAST_ACTIONS(),
            element: <LastActionsPage />,
          },
        ],
      },
    ],
  },
];

export default logMessagesRoutes;
