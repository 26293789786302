import { FileDoneOutlined } from '@ant-design/icons';
import { Space, Spin, Typography } from 'antd';
import { useGetFileQuery } from 'components/files/storage/filesApi';
import { getFileName } from 'components/files/utils';

interface ViewProposalFileProps {
  fileUrl: string;
}

export default function ViewProposalFile({
  fileUrl,
}: ViewProposalFileProps) {
  const { data, isLoading, isSuccess } = useGetFileQuery({ fileName: fileUrl });

  return (
    <Space align="center">
      <FileDoneOutlined style={{ color: '#1677FF', fontSize: 16 }} />
      {isLoading && (
        <Spin size="small" />
      )}
      {isSuccess && (
        <Typography.Link href={data?.url} target="_blank" rel="noreferrer">
          {getFileName(data?.url || '')}
        </Typography.Link>
      )}
    </Space>
  );
}
