import { Match } from '@raised/domain';
import { rootApi } from 'api/rootApi';

export const matchApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    createMatch: build.mutation<Match.Public[], Match.CreateMany & { message?: string }>({
      invalidatesTags: (_r, _e, args) => ['MATCH', 'USER', 'CHAT', ...args.projectIds.map((id) => ({ type: 'PROJECT', id } as const))],
      query: (body) => ({
        url: '/match',
        method: 'POST',
        body,
      }),
    }),
    updateMatch: build.mutation<Match.Public, { data: Match.Update & { message?: string }, id: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'MATCH', id: args.id }, 'USER', { type: 'PROJECT', id: args.data.projectId }],
      query: ({ data, id }) => ({
        url: `/match/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteMatch: build.mutation<void, { id: string }>({
      invalidatesTags: ['MATCH', 'USER', 'PROJECT', 'CHAT'],
      query: ({ id }) => ({
        url: `/match/${id}`,
        method: 'DELETE',
      }),
    }),
    getPersonalMatches: build.query<Match.Public[], void>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'MATCH', id }) as const), 'MATCH'] : ['MATCH']),
      query: () => ({
        url: '/match/personal',
      }),
    }),
    getPersonalMatchByProject: build.query<Match.Public, { projectId: string }>({
      providesTags: (res) => (res ? [{ type: 'MATCH', id: res.id }] : ['MATCH']),
      query: ({ projectId }) => ({
        url: `/match/personal/${projectId}`,
      }),
      transformResponse: (response: Match.Public[]) => response?.[0],
    }),
    getMatchById: build.query<Match.Public, { id: string }>({
      providesTags: (_r, _e, args) => [{ type: 'MATCH', id: args.id }],
      query: ({ id }) => ({
        url: `/match/${id}`,
      }),
    }),
    getMatchesByProject: build.query<Match.Public[], { id: string }>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({ type: 'MATCH', id }) as const), 'MATCH'] : ['MATCH']),
      query: ({ id }) => ({
        url: `/match/project/${id}`,
      }),
    }),
    getMatchesWithChatsByProject: build.query<Match.Public[], { id: string }>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({
        type: 'MATCH',
        id,
      }) as const), 'MATCH'] : ['MATCH']),
      query: ({ id }) => ({
        url: `/match/chats/project/${id}`,
      }),
    }),
    getAllMatchesWithChats: build.query<Match.Public[], void>({
      query: () => ({
        url: '/match/chats',
      }),
    }),
    getExpertMatches: build.query<Match.Public[], { expertId: string }>({
      providesTags: (res) => (res ? [...res.map(({ id }) => ({
        type: 'MATCH',
        id,
      }) as const), 'MATCH'] : ['MATCH']),
      query: ({ expertId }) => ({
        url: `/match/expert/${expertId}`,
      }),
    }),
    getMatchesWithNoProposals: build.query<Match.Public[], { projectId: string }>({
      providesTags: (res) => (res?.length ? [...res.map(({ id }) => ({ type: 'MATCH', id }) as const), 'MATCH'] : ['MATCH']),
      query: ({ projectId }) => ({
        url: `/match/no-proposals/${projectId}`,
      }),
    }),
    restoreMatch: build.mutation<Match.Public, { matchId: string }>({
      invalidatesTags: (_r, _e, args) => [{ type: 'MATCH', id: args.matchId }],
      query: ({ matchId }) => ({
        url: `/match/restore/${matchId}`,
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useCreateMatchMutation,
  useDeleteMatchMutation,
  useUpdateMatchMutation,
  useGetPersonalMatchesQuery,
  useGetMatchesByProjectQuery,
  useGetMatchByIdQuery,
  useGetMatchesWithChatsByProjectQuery,
  useLazyGetAllMatchesWithChatsQuery,
  useGetExpertMatchesQuery,
  useGetMatchesWithNoProposalsQuery,
  useRestoreMatchMutation,
  useGetPersonalMatchByProjectQuery,
} = matchApi;
