import ProtectedRouteLayout from 'components/layout/containers/protected-route-layout/ProtectedRouteLayout';
import { RouteObject } from 'react-router-dom';
import MainLayout from 'components/layout/containers/main-layout/MainLayout';
import AdminRouteLayout from 'components/layout/containers/admin-route/AdminRouteLayout';
import CreateProjectPage from './create-project/CreateProjectPage';
import ProjectsPage from './projects/ProjectsPage';
import ProjectPage from './project/ProjectPage';
import AssignProjectPage from './assign-project/AssignProjectPage';
import DeletedProjectsPage from './deleted-projects/DeletedProjectsPage';
import EditProjectPage from './edit-project/EditProjectPage';
import CreateProjectFromTemplatePage from './create-project-from-template/CreateProjectFromTemplatePage';

export const PROJECT_ROUTES = {
  CREATE_PROJECT: () => '/project/create',
  CREATE_PROJECT_FROM_TEMPLATE: (id: string) => `/project/create-from-template/${id}`,
  PROJECTS: () => '/projects',
  PROJECT: (id: string) => `/project/${id}`,
  EDIT_PROJECT: (id: string) => `/project/edit/${id}`,
  ASSIGN_PROJECTS: () => '/projects/assign',
  DELETED_PROJECTS: () => '/projects/deleted',
};

const projectRoutes: Array<RouteObject> = [
  {
    element: <MainLayout />,
    children: [
      {
        element: <ProtectedRouteLayout />,
        children: [
          {
            path: PROJECT_ROUTES.CREATE_PROJECT(),
            element: <CreateProjectPage />,
          },
          {
            path: PROJECT_ROUTES.PROJECTS(),
            element: <ProjectsPage />,
          },
          {
            path: PROJECT_ROUTES.EDIT_PROJECT(':id'),
            element: <EditProjectPage />,
          },
          {
            path: PROJECT_ROUTES.CREATE_PROJECT_FROM_TEMPLATE(':id'),
            element: <CreateProjectFromTemplatePage />,
          },
        ],
      },
      {
        element: <AdminRouteLayout />,
        children: [
          {
            path: PROJECT_ROUTES.ASSIGN_PROJECTS(),
            element: <AssignProjectPage />,
          },
          {
            path: PROJECT_ROUTES.DELETED_PROJECTS(),
            element: <DeletedProjectsPage />,
          },
        ],
      },
    ],
  },
  {
    element: <MainLayout withoutSpacing />,
    children: [
      {
        element: <ProtectedRouteLayout />,
        children: [
          {
            path: PROJECT_ROUTES.PROJECT(':id'),
            element: <ProjectPage />,
          },

        ],
      },
    ],
  },
];

export default projectRoutes;
