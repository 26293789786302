import { LeftOutlined } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Match, ProjectPaymentPeriods, ProjectPayments, Proposal, ProposalSchemas,
} from '@raised/domain';
import { Button, Space, Typography } from 'antd';
import useUploadFiles from 'components/files/hooks/useUploadFiles';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CreateProposalForm from './create-proposal-form/CreateProposalForm';
import styles from './CreateProposalComponent.module.css';

interface CreateProposalComponentProps {
  onFormSubmit: (values: Proposal.Create) => void;
  onBack: () => void;
  match: Match.Public;
  matchId: string;
  isLoading: boolean;
  profilePaymentRate?: string;
}

export default function CreateProposalComponent({
  onFormSubmit,
  onBack,
  match,
  matchId,
  isLoading,
  profilePaymentRate,
}: CreateProposalComponentProps) {
  const { handleSubmit, control, setValue } = useForm<Proposal.Create>({
    resolver: zodResolver(ProposalSchemas.createProposalSchema),
    defaultValues: {
      title: match.project?.title,
      description: match.project?.description,
      documentUrls: [],
      matchId,
      paymentPeriod: ProjectPaymentPeriods.DAYS,
      projectPayment: ProjectPayments.FIXED_SCOPE_PROJECT,
    },
  });

  const { uploadFiles, uploadedFiles, isLoading: isFilesLoading } = useUploadFiles();

  useEffect(() => {
    if (uploadedFiles.length) {
      setValue('documentUrls', uploadedFiles.map((file) => file.url));
    }
  }, [uploadedFiles]);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className={styles.container}>
      <Space direction="vertical">
        <Button onClick={onBack} style={{ marginBottom: 16 }}>
          <LeftOutlined />
          {' '}
          Back to project
        </Button>
        <Typography.Title level={3}>Create a proposal</Typography.Title>
        <CreateProposalForm
          control={control}
          uploadFiles={uploadFiles}
          uploadedFiles={uploadedFiles}
          isFilesLoading={isFilesLoading}
          profilePaymentRate={profilePaymentRate}
        />
        <Button
          loading={isLoading || isFilesLoading}
          disabled={isLoading || isFilesLoading}
          style={{ marginTop: 8, width: 120 }}
          type="primary"
          htmlType="submit"
        >
          Create
        </Button>
      </Space>
    </form>
  );
}
