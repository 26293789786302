import { ProjectStatuses } from '@raised/domain';
import { ButtonsModule, FormsModule, LayoutModule } from '@raised/ui-kit';
import { Modal } from 'antd';
import { useState } from 'react';

const { Space } = LayoutModule;
const { Button } = ButtonsModule;
const { TextArea } = FormsModule;

interface ArchiveModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (status: ProjectStatuses, message?: string) => void;
}

export default function ArchiveModal({
  isVisible,
  onSubmit,
  onClose,
}: ArchiveModalProps) {
  const [reason, setReason] = useState('');

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => setReason(event.target.value);
  const handleSubmit = (status: ProjectStatuses, message?: string) => {
    onSubmit(status, message);
    setReason('');
    onClose();
  };

  return (
    <Modal
      centered
      open={isVisible}
      footer={null}
      onCancel={() => {
        onClose();
      }}
    >
      <Space style={{ width: '100%' }} direction="vertical">
        <Button onClick={() => handleSubmit(ProjectStatuses.FINISHED)}>The project has been successfully completed.</Button>
        <Button onClick={() => handleSubmit(ProjectStatuses.ARCHIVED)}>I currently do not have this need.</Button>
        <Button onClick={() => handleSubmit(ProjectStatuses.ARCHIVED)}>I did not find a relevant expert on Raised.</Button>
        <Space style={{ width: '100%', marginTop: 32 }} direction="vertical">
          <TextArea placeholder="Reason..." onChange={onChange} />
          <Button onClick={() => handleSubmit(ProjectStatuses.ARCHIVED, reason)}>Any other reason</Button>
        </Space>
      </Space>
    </Modal>
  );
}
