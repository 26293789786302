import { MatchStatuses } from '@raised/domain';
import { useState } from 'react';

export default function useMessageModal() {
  const [decision, setDecision] = useState<MatchStatuses>(MatchStatuses.RECOMMENDED_TO_EXPERT);
  const [expertId, setExpertId] = useState('');

  const isMessageModalVisible = Boolean(expertId && decision);
  const closeMessageModal = () => {
    setExpertId('');
  };
  const openMessageModal = (expertid: string, status: MatchStatuses) => {
    setDecision(status);
    setExpertId(expertid);
  };
  const modalTitle = decision === MatchStatuses.RECOMMENDED_TO_CLIENT ? 'Assign expert' : 'Invite expert';

  return {
    isMessageModalVisible,
    closeMessageModal,
    openMessageModal,
    modalTitle,
    decision,
    expertId,
  };
}
