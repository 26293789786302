import { Message, Project, LogMessage } from '@raised/domain';
import { rootApi } from 'api/rootApi';
import { GetNotificationsResponseType } from './types';

export const notificationsApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    viewNotifications: build.mutation<void, { notificationIds: string[] }>({
      invalidatesTags: ['NOTIFICATION'],
      query: (body) => ({
        url: '/notifications/view',
        method: 'POST',
        body,
      }),
    }),
    viewAllNotifications: build.mutation<void, void>({
      invalidatesTags: ['NOTIFICATION'],
      query: () => ({
        url: '/notifications/view/all',
        method: 'POST',
      }),
    }),
    getProjectsWithNotifications: build.query<Project.Public[], void>({
      providesTags: (res) => (res?.length ? ([
        ...res.map((project) => ({ id: project.id, type: 'PROJECT' }) as const),
        'PROJECT',
      ]) : ['PROJECT']),
      query: () => ({
        url: '/notifications/projects',
      }),
    }),
    getNotifications: build.query<GetNotificationsResponseType, { projectId?: string }>({
      providesTags: (res) => (res ? [
        ...res.allNewNotifications.map((notification) => ({ type: 'NOTIFICATION', id: notification.id }) as const),
        'NOTIFICATION',
      ] : ['NOTIFICATION']),
      query: (params) => ({
        url: '/notifications',
        params,
      }),
    }),
    getAdminSupportNotifications: build.query<Message.Public[], void>({
      providesTags: (res) => (res?.length ? ([
        ...res.map((notification) => ({ id: notification.id, type: 'NOTIFICATION' }) as const),
        'NOTIFICATION',
      ]) : ['NOTIFICATION']),
      query: () => ({
        url: '/notifications/support',
      }),
    }),
    getNewNotificationsForProject: build.query<Array<LogMessage.Public | Message.Public>, { projectId: string }>({
      providesTags: (res) => (res?.length ? [...res.map((notification) => ({ id: notification.id, type: 'NOTIFICATION' }) as const), 'NOTIFICATION'] : ['NOTIFICATION']),
      query: ({ projectId }) => ({
        url: `/notifications/project/${projectId}`,
      }),
    }),
    getNewNotificationsForMatch: build.query<Array<LogMessage.Public | Message.Public>, { matchId: string }>({
      providesTags: (res) => (res?.length ? [...res.map((notification) => ({ id: notification.id, type: 'NOTIFICATION' }) as const), 'NOTIFICATION'] : ['NOTIFICATION']),
      query: ({ matchId }) => ({
        url: `/notifications/match/${matchId}`,
      }),
    }),
  }),
});

export const {
  useViewNotificationsMutation,
  useViewAllNotificationsMutation,
  useGetNotificationsQuery,
  useGetProjectsWithNotificationsQuery,
  useGetAdminSupportNotificationsQuery,
  useGetNewNotificationsForProjectQuery,
  useGetNewNotificationsForMatchQuery,
} = notificationsApi;
