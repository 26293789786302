import {
  ColorsModule, DataModule, FeedbackModule, LayoutModule, TypographyModule,
} from '@raised/ui-kit';
import { IconSpeakerphone } from '@tabler/icons-react';
import { ReactNode } from 'react';
import styles from './AdminExpertsListContainer.module.css';

const { Space } = LayoutModule;
const { Title, Text } = TypographyModule;
const { Spin } = FeedbackModule;
const { Empty } = DataModule;
const { Colors } = ColorsModule;

interface AdminExpertsListContainerProps {
  children: ReactNode;
  sidebarContent: ReactNode;
  pagination: ReactNode;
  isLoading: boolean;
  isEmpty: boolean;
  totalExperts?: number;
}

export default function AdminExpertsListContainer({
  children,
  sidebarContent,
  isLoading,
  isEmpty,
  pagination,
  totalExperts,
}: AdminExpertsListContainerProps) {
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        {sidebarContent}
      </div>
      <div className={styles.content_container}>
        <Space size={4}>
          <Title level={1}>Experts</Title>
          <Title level={1} className={styles.count}>{isLoading ? <Spin size="small" /> : totalExperts}</Title>
        </Space>
        <div className={styles.content}>
          {isLoading ? <Spin /> : (
            <>
              {isEmpty
                ? (
                  <Empty
                    height={300}
                    icon={<IconSpeakerphone size={80} stroke={0.6} strokeOpacity={0.5} color={Colors.gray} />}
                  >
                    <Text type="secondary">Experts not found</Text>
                  </Empty>
                )
                : children}
            </>
          )}
          {pagination}
        </div>
      </div>
    </div>
  );
}
