import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import cn from 'classnames';
import { InputWrapper, InputWrapperProps } from '../input-wrapper';
import styles from './Select.module.css';

interface SelectProps extends AntSelectProps, InputWrapperProps {}

export default function Select({
  label,
  subLabel,
  className,
  ...rest
}: SelectProps) {
  return (
    <InputWrapper label={label} subLabel={subLabel}>
      <AntSelect {...rest} className={cn(styles.select, className)} />
    </InputWrapper>
  );
}
