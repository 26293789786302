import { ProjectStatuses, UserRole } from '@raised/domain';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import ProjectCard from '../components/project-card/ProjectCard';
import useDeleteProject from '../hooks/useDeleteProject';
import usePersonalProjects from '../hooks/usePersonalProjects';
import useUpdateProjectStatus from '../hooks/useUpdateProjectStatus';
import { PROJECT_ROUTES } from '../project-routes';
import { useGetRecommendedProjectsQuery } from '../storage/projectApi';
import ProjectActions from './project-actions/ProjectActions';
import ProjectStatus from './project-status/ProjectStatus';
import ProjectsGrid from './projects-grid/ProjectsGrid';
import ProjectsTabs from './projects-tabs/ProjectsTabs';
import ProjectsComponent from './ProjectsComponent';
import RecommendedProjects from './recommended-projects/RecommendedProjects';

export default function ProjectsPage() {
  const navigate = useNavigate();
  const {
    filteredProjects,
    isLoading,
  } = usePersonalProjects();
  const { data: recommendedProjects } = useGetRecommendedProjectsQuery();
  const { deleteProject, isLoading: isDeleteLoading } = useDeleteProject();
  const { updateStatus, isLoading: isUpdateStatusLoading } = useUpdateProjectStatus();
  const { data: profile } = useGetProfileQuery();

  const getProjectLink = (projectId: string) => PROJECT_ROUTES.PROJECT(projectId);
  const onCreateProject = () => navigate(PROJECT_ROUTES.CREATE_PROJECT());
  const onDelete = (id: string) => deleteProject({ id });
  const onEdit = (id: string) => navigate(PROJECT_ROUTES.EDIT_PROJECT(id));
  const onArchive = (id: string, status: ProjectStatuses, message?: string) => updateStatus({ status, id, message });

  return (
    <ProjectsComponent onCreateProject={onCreateProject}>
      <Helmet>
        <title>Raised - Projects</title>
      </Helmet>
      {profile?.role === UserRole.CLIENT && recommendedProjects?.length ? (
        <RecommendedProjects
          projects={recommendedProjects}
          getProjectLink={getProjectLink}
        />
      ) : null}
      <ProjectsTabs
        activeProjectsCount={filteredProjects?.ACTIVE?.length || 0}
        completedProjectsCount={filteredProjects?.COMPLETED?.length || 0}
        loading={isLoading}
        activeProjects={(
          <ProjectsGrid>
            {filteredProjects?.ACTIVE?.map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
                linkToProject={getProjectLink(project.id)}
                author={`${project.user?.firstName} ${project.user?.lastName}`}
                statusContent={(
                  <ProjectStatus status={project.status} />
                )}
                actionsContent={(
                  <ProjectActions
                    onDeleteClick={() => onDelete(project.id)}
                    onEditClick={() => onEdit(project.id)}
                    onArchiveClick={(status, message) => onArchive(project.id, status, message)}
                    isLoading={isDeleteLoading || isUpdateStatusLoading}
                  />
                )}
              />
            ))}
          </ProjectsGrid>
        )}
        completedProjects={(
          <ProjectsGrid>
            {filteredProjects?.COMPLETED?.map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
                linkToProject={getProjectLink(project.id)}
                author={`${project.user?.firstName} ${project.user?.lastName}`}
                statusContent={(
                  <ProjectStatus status={project.status} />
                )}
                actionsContent={(
                  <ProjectActions
                    onDeleteClick={() => onDelete(project.id)}
                    onEditClick={() => onEdit(project.id)}
                    onArchiveClick={(status, message) => onArchive(project.id, status, message)}
                    isLoading={isDeleteLoading || isUpdateStatusLoading}
                  />
                )}
              />
            ))}
          </ProjectsGrid>
        )}
      />
    </ProjectsComponent>
  );
}
