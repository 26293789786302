import dayjs from 'dayjs';

export const formatDate = (date: Date) => date.toLocaleDateString('en-EN', {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

export const formatCurrency = (value: number) => new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'USD',
  useGrouping: true,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format(value);

export const getTimezone = () => {
  const timezoneOffset = dayjs().format('Z');
  const timeZone = dayjs.tz.guess();

  const splitTz = timeZone.split('/');
  const tzCity = splitTz.length > 1 ? splitTz[1] : timeZone;

  return `${tzCity}, GMT${timezoneOffset}`;
};

export const camelCaseToSeparated = (str: string) => {
  const res = str.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2');

  return `${res[0].toUpperCase()}${res.slice(1)}`;
};
