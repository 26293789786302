import { Project, User } from '@raised/domain';
import { FeedbackModule } from '@raised/ui-kit';
import { useState } from 'react';
import InviteExpertModalFooter from './invite-expert-modal-footer/InviteExpertModalFooter';
import styles from './InviteExpertModal.module.css';
import ConfirmStep from './steps/confirm-step/ConfirmStep';
import SelectProjecStep from './steps/select-project-step/SelectProjectStep';
import useSelectProject from './steps/select-project-step/useSelectProject';

enum Steps {
  SELECT_PROJECT,
  CONFIRM,
}

const { Modal } = FeedbackModule;

interface InviteExpertModalProps {
  isVisible: boolean;
  onClose: () => void;
  projects: Project.Public[];
  isProjectsLoading: boolean;
  selectedExpert?: User.Public;
  onCreateProject: () => void;
  onSubmit: (projectIds: string[], expertId: string, message?: string) => void;
  isCreateMatchLoading: boolean;
}

export default function InviteExpertModal({
  isVisible,
  onClose,
  projects,
  isProjectsLoading,
  selectedExpert,
  onCreateProject,
  onSubmit,
  isCreateMatchLoading,
}: InviteExpertModalProps) {
  const [message, setMessage] = useState('');
  const [step, setStep] = useState(Steps.SELECT_PROJECT);
  const { projectClick, selectedProjects, resetProjects } = useSelectProject();

  const handleClose = () => {
    setStep(Steps.SELECT_PROJECT);
    resetProjects();
    onClose();
  };
  const onContinue = () => {
    if (!selectedProjects.length) return;

    setStep(Steps.CONFIRM);
  };
  const onCancel = () => {
    if (step === Steps.CONFIRM) {
      setStep(Steps.SELECT_PROJECT);
      return;
    }

    handleClose();
  };
  const onInvite = () => {
    if (!selectedProjects.length || !selectedExpert) return;

    const selectedProjectIds = selectedProjects.map((project) => project.id);

    onSubmit(selectedProjectIds, selectedExpert.id, message);
  };

  return (
    <Modal
      centered={window.innerWidth > 500}
      open={isVisible}
      onCancel={handleClose}
      closeIcon={null}
      footer={(
        <InviteExpertModalFooter
          isLoading={isCreateMatchLoading}
          onCancel={onCancel}
          actionButtonText={step === Steps.SELECT_PROJECT ? 'Continue' : 'Send Invite'}
          onAction={step === Steps.SELECT_PROJECT ? onContinue : onInvite}
        />
      )}
      width={700}
      className={styles.invite_expert_modal}
    >
      {step === Steps.SELECT_PROJECT ? (
        <SelectProjecStep
          projects={projects || []}
          onCreateProjectClick={onCreateProject}
          isLoading={isProjectsLoading}
          selectedProjects={selectedProjects}
          selectedExpert={selectedExpert}
          onProjectClick={projectClick}
        />
      ) : null}
      {step === Steps.CONFIRM ? (
        <ConfirmStep
          selectedExpert={selectedExpert}
          selectedProjects={selectedProjects}
          message={message}
          onChangeMessage={setMessage}
        />
      ) : null}
    </Modal>
  );
}
