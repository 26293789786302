import { LogMessage } from '@raised/domain';
import { Space, Typography } from 'antd';
import { PROJECT_ROUTES } from 'components/project/project-routes';
import { USER_ROUTES } from 'components/user/user-routes';
import { getRoleLabel } from 'components/user/utils';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/formats';

interface LogMessageComponentProps {
  message: LogMessage.Public;
}

export default function LogMessageComponent({
  message,
}: LogMessageComponentProps) {
  const userLink = USER_ROUTES.USER_PROFILE(message.fromId);
  const projectLink = PROJECT_ROUTES.PROJECT(message.match?.project?.id || '');

  return (
    <Space size={4} wrap>
      <Typography.Text>
        {`${getRoleLabel(message.from?.role || '')}`}
      </Typography.Text>
      <Link to={userLink}>{`${message.from?.firstName} ${message.from?.lastName}`}</Link>
      <Typography.Text>
        {`at ${formatDate(new Date(message.createdAt))}`}
      </Typography.Text>
      {message.match?.project?.id ? (
        <Typography.Text>
          (project:
          {' '}
          <Link to={projectLink}>{message.match.project.title}</Link>
          )
        </Typography.Text>
      ) : null}
      <Typography.Text type="secondary">{message.text}</Typography.Text>
    </Space>
  );
}
