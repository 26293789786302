import { User } from '@raised/domain';
import { ColorsModule, TypographyModule } from '@raised/ui-kit';
import { IconLink } from '@tabler/icons-react';
import UserAvatar from 'components/user/components/UserAvatar';
import UserLink from 'components/user/components/UserLink';
import styles from './ClientInvite.module.css';

const {
  Title, Text, Paragraph, Link,
} = TypographyModule;
const { Colors } = ColorsModule;

interface ClientInviteProps {
  client: User.Public;
  expert: User.Public;
}
export default function ClientInvite({
  client,
  expert,
}: ClientInviteProps) {
  return (
    <div className={styles.container}>
      <Title level={4} className={styles.title}>Both Client and Expert have expressed an interest in scheduling a free intro call.</Title>
      {expert.calendlyLink ? (
        <Paragraph className={styles.description}>
          {`${client.firstName} ${client.lastName}, you can select a date and time for the call via the following`}
          <UserLink className={styles.link} href={expert.calendlyLink}>
            <IconLink size={14} color={Colors.blue} />
            link
          </UserLink>
          (provided by expert)
        </Paragraph>
      ) : (
        <Paragraph className={styles.description}>
          {`${client.firstName} ${client.lastName}, ${expert.firstName} ${expert.lastName}, please provide available slots or share your calendly/alternative scheduling options. After you agree on time in the chat, feel free to send an invite via email`}
          <br />
          <Link href={`mailto:${client.email}`}>{`Client email: ${client.email}`}</Link>
          <br />
          <Link href={`mailto:${expert.email}`}>{`Expert email: ${expert.email}`}</Link>
        </Paragraph>
      )}
      <Text>
        Please include
        {' '}
        <Link href="mailto:zakhar@joinraised.com" target="_blank">zakhar@joinraised.com</Link>
        {' '}
        as a guest in the invites.
      </Text>
      <div className={styles.footer}>
        <Text className={styles.thanks}>Thanks!</Text>
        <Text type="secondary">Chat members</Text>
        <div className={styles.members}>
          <UserAvatar size="s" filePath={expert.avatar} role={expert.role} />
          <UserAvatar size="s" filePath={client.avatar} role={client.role} className={styles.second_avatar} />
        </div>
      </div>
    </div>
  );
}
