import { ProjectPaymentPeriods } from '@raised/domain';

export const PROJECT_PAYMENT_PERIODS: { [key in ProjectPaymentPeriods]: string } = {
  HOURS: 'Hourly',
  DAYS: 'Daily',
  MONTHLY: 'Monthly',
  WEEKS: 'Weekly',
} as const;

export const PROJECT_PAYMENT_PERIODS_LABELS: { [key in ProjectPaymentPeriods]: string } = {
  HOURS: 'Hours',
  DAYS: 'Days',
  MONTHLY: 'Months',
  WEEKS: 'Weeks',
} as const;
