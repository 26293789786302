import { useCallback, useEffect } from 'react';
import { useLazyGetProfileQuery } from 'components/user/storage/userApi';
import { getErrorMessage, setToken } from 'api/helpers';
import { message } from 'antd';
import { User } from '@raised/domain';
import { useLoginMutation } from '../storage/authApi';
import useRecaptcha from './useRecaptcha';

interface UseLoginProps {
  onSuccess: (user: User.Public) => void;
}

export default function useLogin({
  onSuccess,
}: UseLoginProps) {
  const [mutate, data] = useLoginMutation();
  const [getProfile, { isLoading }] = useLazyGetProfileQuery();
  const { handleRecaptchaVerify } = useRecaptcha();

  const {
    isSuccess: isLoginSuccess,
    isError: isLoginError,
    data: loginData,
    error: loginError,
    isLoading: isLoginLoading,
  } = data;

  useEffect(() => {
    if (isLoginSuccess && loginData) {
      setToken(loginData.access_token);
      getProfile().unwrap().then((profile) => {
        onSuccess(profile);
      });
    }
  }, [isLoginSuccess]);

  useEffect(() => {
    if (isLoginError) {
      message.error(getErrorMessage(loginError));
    }
  }, [isLoginError]);

  const login = useCallback(async (loginFormData: User.Login) => {
    const recaptchaToken = await handleRecaptchaVerify('login');

    if (!recaptchaToken) {
      message.error('Failed reCAPTCHA check');
      return;
    }

    mutate({ ...loginFormData, recaptchaToken });
  }, [handleRecaptchaVerify]);

  return {
    login,
    ...data,
    isLoading: isLoading || isLoginLoading,
  };
}
