import { Button, Space, Typography } from 'antd';
import OrganisationInfo from 'components/organisation/components/organisation-info/OrganisationInfo';
import { useGetOrganisationByIdQuery } from 'components/organisation/storage/organisationApi';
import styles from './ChangeOrganisationComponent.module.css';

interface JoinOrganisationWithChangePageProps {
  onSubmit: () => void;
  onDecline: () => void;
  isLoading: boolean;
  organisationId: string;
}

export default function JoinOrganisationWithChangePage({
  onSubmit,
  onDecline,
  isLoading,
  organisationId,
}: JoinOrganisationWithChangePageProps) {
  const {
    data,
    isSuccess,
  } = useGetOrganisationByIdQuery({ id: organisationId });

  return (
    <div className={styles.container}>
      <Typography.Title level={3}>You have been invited to join the organisation</Typography.Title>
      {isSuccess ? (
        <OrganisationInfo organisation={data} />
      ) : null}
      <Space.Compact>
        <Button
          disabled={isLoading}
          loading={isLoading}
          onClick={onSubmit}
          type="primary"
        >
          Accept
        </Button>
        <Button
          disabled={isLoading}
          loading={isLoading}
          onClick={onDecline}
          danger
        >
          Decline
        </Button>
      </Space.Compact>
    </div>
  );
}
