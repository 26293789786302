import { ProjectActivityTypes } from '@raised/domain';
import { FormsModule, TypographyModule } from '@raised/ui-kit';
import { Control, Controller } from 'react-hook-form';
import styles from './AdminActivitySelector.module.css';

const { Title } = TypographyModule;
const { Checkbox } = FormsModule;

interface AdminActivitySelectorProps {
  control: Control;
}

export default function AdminActivitySelector({
  control,
}: AdminActivitySelectorProps) {
  return (
    <Controller
      control={control}
      name="projectActivityType"
      render={({ field }) => (
        <div className={styles.admin_activity_selector}>
          <Title level={4}>Project Type</Title>
          <div className={styles.checkboxes}>
            <Checkbox checked={field.value === ProjectActivityTypes.ACTIVE} onChange={() => field.onChange(ProjectActivityTypes.ACTIVE)}>Active Project</Checkbox>
            <Checkbox checked={field.value === ProjectActivityTypes.RECOMMENDED} onChange={() => field.onChange(ProjectActivityTypes.RECOMMENDED)}>Recommended Project</Checkbox>
          </div>
        </div>
      )}
    />
  );
}
