import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useCreateProposalMutation } from '../storage/proposalApi';

export default function useCreateProposal() {
  const [createProposal, info] = useCreateProposalMutation();

  useEffect(() => {
    if (info.isSuccess) {
      message.success('Proposal has been successfully created');
    }
  }, [info.isSuccess]);

  useEffect(() => {
    if (info.isError) {
      message.error(getErrorMessage(info.error));
    }
  }, [info.isError]);

  return {
    createProposal,
    ...info,
  };
}
