import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from './helpers';

export const BASE_URL = process.env.REACT_APP_BACKEND || 'http://localhost:5005';

export const rootApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');

      const token = getToken();

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: 'api',
  tagTypes: ['USER', 'PROJECT', 'MATCH', 'MESSAGE', 'MESSAGE_COUNT', 'NEW_MESSAGE', 'FILE', 'PROPOSAL', 'LOG_MESSAGE', 'PAYMENT', 'ACTION', 'CHAT', 'ORGANISATION', 'NOTIFICATION', 'TEMPLATE', 'NEW_MESSAGES_COUNT', 'LOG_MESSAGES_COUNT', 'USE_CASE', 'SUCCESS_STORY', 'PUBLIC_USER'],
});
