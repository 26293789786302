import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useInviteToOrganisationMutation } from 'components/user/storage/userApi';

export default function useInviteToOrganisation() {
  const [mutate, info] = useInviteToOrganisationMutation();
  const { isSuccess, isError, error } = info;

  useEffect(() => {
    if (!isSuccess) return;

    message.success('User successfully invited to organisation');
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;

    message.error(getErrorMessage(error));
  }, [isError]);

  const inviteToOrganisation = (data: { email: string, organisationId: string }) => mutate(data);

  return {
    inviteToOrganisation,
    ...info,
  };
}
