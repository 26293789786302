import { Proposal } from '@raised/domain';
import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useEffect } from 'react';
import { useEditProposalByIdMutation } from '../storage/proposalApi';

interface UseEditProposalProps {
  onSuccess: (proposal: Proposal.Public) => void;
}

export default function useEditProposal({
  onSuccess,
}: UseEditProposalProps) {
  const [editProposal, info] = useEditProposalByIdMutation();
  const {
    isSuccess, isError, error, data,
  } = info;

  useEffect(() => {
    if (isError) {
      message.error(getErrorMessage(error));
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      message.success('Proposal successfully updated');
      onSuccess(data);
    }
  }, [isSuccess]);

  return {
    editProposal,
    ...info,
  };
}
