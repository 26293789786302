import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';
import styles from './Tag.module.css';
import '../../colors/colors.module.css';

interface TagProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  label: ReactNode;
  value?: ReactNode;
  color?: string;
}

export default function Tag({
  label,
  value,
  className,
  color,
  style,
  ...props
}: TagProps) {
  return (
    <div {...props} className={cn(styles.base_tag, className)} style={{ ...style, background: color }}>
      <span className={styles.label}>{label}</span>
      {value ? (
        <span className={styles.value}>{value}</span>
      ) : null}
    </div>
  );
}
