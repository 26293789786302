import { MatchStatuses } from '@raised/domain';
import useCreateMatch from 'components/match/hooks/useCreateMatch';
import { MESSAGE_ROUTES } from 'components/messages/messages-routes';
import CreateProjectModal from 'components/project/create-project/create-project-modal/CreateProjectModal';
import useCreateProjectModal from 'components/project/create-project/create-project-modal/useCreateProjectModal';
import { useGetPersonalProjectsQuery } from 'components/project/storage/projectApi';
import useInvitedExperts from 'components/user/hooks/useInvitedExperts';
import { useNavigate } from 'react-router-dom';
import useGetExperts from '../../hooks/useGetExperts';
import ExpertFilters from '../admin/expert-filters/ExpertFilters';
import ExpertItem from '../expert-item/ExpertItem';
import ClientExpertsContainer from './ClientExpertsContainer';
import ContactRaised from './contact-raised/ContactRaised';
import ExpertModalControls from './expert-modal/expert-modal-controls/ExpertModalControls';
import ExpertModal from './expert-modal/ExpertModal';
import useExpertModal from './expert-modal/useExpertModal';
import InvitationSentModal from './invitation-sent-modal/InvitationSentModal';
import useInvitationSentModal from './invitation-sent-modal/useInvitationSentModal';
import InvitationStatus from './invitation-status/InvitationStatus';
import InviteExpertModal from './invite-expert-modal/InviteExpertModal';
import useInviteExpertModal from './invite-expert-modal/useInviteExpertModal';

export default function ClientExpertsListPage() {
  const navigate = useNavigate();

  const {
    invitedExperts,
    onInviteExpert,
  } = useInvitedExperts();
  const {
    data: experts,
    isLoading: isExpertsLoading,
    isFetching: isExpertsFetching,
    changeFilter,
    filters,
    resetFilters,
  } = useGetExperts();
  const {
    isVisible,
    openModal,
    closeModal,
    selectedExpert,
  } = useExpertModal({
    experts,
  });
  const {
    isVisible: isInviteExpertModalVisible,
    openModal: openInviteExpertModal,
    closeModal: closeInviteExpertModal,
    selectedExpert: selectedExpertToInvite,
  } = useInviteExpertModal({ experts });
  const {
    isVisible: isCreateProjectModalVisible,
    closeModal: closeCreateProjectModal,
    openModal: openCreateProjectModal,
  } = useCreateProjectModal();
  const {
    isVisible: isInvitationSentModalVisible,
    closeModal: closeInvitationSentModal,
    openModal: openInvitationSentModal,
  } = useInvitationSentModal();
  const {
    data: projects,
    isLoading: isProjectsLoading,
    isFetching: isProjectsFetching,
  } = useGetPersonalProjectsQuery();

  const {
    create,
    isLoading: isCreateMatchLoading,
  } = useCreateMatch({
    onSuccess: (data) => {
      closeInviteExpertModal();
      openInvitationSentModal();

      if (data?.[0]?.userId) {
        onInviteExpert(data[0].userId);
      }
    },
    isNotify: false,
  });

  const onContact = () => navigate(MESSAGE_ROUTES.SUPPORT_CHAT_PAGE());
  const onSubmit = (projectIds: string[], expertId: string, message?: string) => {
    create({
      projectIds,
      userId: expertId,
      message,
      status: MatchStatuses.RECOMMENDED_TO_EXPERT,
    });
  };

  const onInviteClick = () => {
    if (!selectedExpert) return;

    const expertId = selectedExpert.id;

    closeModal();
    openInviteExpertModal(expertId);
  };

  return (
    <ClientExpertsContainer
      isLoading={isExpertsLoading || isExpertsFetching}
      isEmpty={Boolean(!isExpertsLoading && !experts?.length)}
      sidebarContent={(
        <ExpertFilters
          resetFilters={resetFilters}
          filters={filters}
          onChangeFilter={changeFilter}
        />
      )}
    >
      {experts?.map((expert) => (
        <ExpertItem
          expert={expert}
          key={expert.id}
          onExpertClick={() => openModal(expert.id)}
          isHideContacts
          invitationStatus={(
            <InvitationStatus
              invitedExperts={invitedExperts}
              expertId={expert.id}
            />
          )}
        />
      ))}
      <ExpertModal
        isVisible={isVisible}
        onClose={closeModal}
        selectedExpert={selectedExpert}
        contactBlock={(
          <ContactRaised onContact={onContact} />
        )}
        controls={(
          <ExpertModalControls
            onCancel={closeModal}
            onInvite={onInviteClick}
          />
        )}
      />
      <InviteExpertModal
        isVisible={isInviteExpertModalVisible}
        onClose={closeInviteExpertModal}
        projects={projects || []}
        isProjectsLoading={isProjectsLoading || isProjectsFetching}
        selectedExpert={selectedExpertToInvite}
        onCreateProject={openCreateProjectModal}
        onSubmit={onSubmit}
        isCreateMatchLoading={isCreateMatchLoading}
      />
      <CreateProjectModal
        isVisible={isCreateProjectModalVisible}
        onClose={closeCreateProjectModal}
      />
      <InvitationSentModal
        isVisible={isInvitationSentModalVisible}
        onClose={closeInvitationSentModal}
      />
    </ClientExpertsContainer>
  );
}
