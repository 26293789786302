import { Project, ProjectStatuses } from '@raised/domain';
import { useMemo } from 'react';
import { useGetPersonalProjectsQuery } from '../storage/projectApi';

export enum ProjectsGroups {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
}

type AccType = { [key in ProjectsGroups]: Project.Public[] };

export default function usePersonalProjects() {
  const data = useGetPersonalProjectsQuery();

  const filteredProjects = useMemo(() => data.data?.reduce((acc: AccType, item) => {
    if (item.status === ProjectStatuses.ARCHIVED || item.status === ProjectStatuses.FINISHED) {
      acc.COMPLETED.push(item);
    } else {
      acc.ACTIVE.push(item);
    }

    return acc;
  }, {
    [ProjectsGroups.ACTIVE]: [],
    [ProjectsGroups.COMPLETED]: [],
  }), [data.data]);

  return {
    ...data,
    filteredProjects,
  };
}
