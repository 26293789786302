import { Payment } from '@raised/domain';
import { Typography } from 'antd';
import { useState } from 'react';
import ViewProposalModal from 'components/proposal/components/ViewProposalModal';
import PaymentsTable from './payments-table/PaymentsTable';

interface ExpertBillingInfoComponentProps {
  payments: Payment.Public[]
  onProjectClick: (projectId: string) => void;
  onClientClick: (clientId: string) => void;
  isLoading: boolean;
}

export default function ExpertBillingInfoComponent({
  payments,
  onProjectClick,
  onClientClick,
  isLoading,
}: ExpertBillingInfoComponentProps) {
  const [viewPaymentProposal, setViewPaymentProposal] = useState('');

  const onProposalClick = (proposalId: string) => setViewPaymentProposal(proposalId);
  const onCloseProposalModal = () => setViewPaymentProposal('');

  return (
    <div>
      <Typography.Title level={2}>Billing info</Typography.Title>
      <PaymentsTable
        payments={payments || []}
        isLoading={isLoading}
        onProjectClick={onProjectClick}
        onViewProposalClick={onProposalClick}
        onClientClick={onClientClick}
      />
      <ViewProposalModal
        isVisible={!!viewPaymentProposal}
        onClose={onCloseProposalModal}
        proposalId={viewPaymentProposal}
      />
    </div>
  );
}
