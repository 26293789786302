import { TypographyModule } from '@raised/ui-kit';
import { ReactNode } from 'react';
import styles from './ContentBlock.module.css';

const { Text, Paragraph } = TypographyModule;

interface ContentBlockProps {
  icon: ReactNode;
  title: string;
  content: string;
}
export default function ContentBlock({
  icon,
  title,
  content,
}: ContentBlockProps) {
  return (
    <div className={styles.content_block}>
      <div className={styles.title_block}>
        {icon}
        <Text strong>{title}</Text>
      </div>
      <Paragraph>
        {content}
      </Paragraph>
    </div>
  );
}
