import { ButtonsModule, LayoutModule, TypographyModule } from '@raised/ui-kit';
import { IconFileDownload, IconInfoCircle } from '@tabler/icons-react';
import styles from './ProposalActions.module.css';

const { Divider, Space } = LayoutModule;
const { Title, Text } = TypographyModule;
const { Button } = ButtonsModule;

interface ProposalActionsProps {
  onAccept: () => void;
  onDecline: () => void;
  isLoading: boolean;
}

export default function ProposalActions({
  onAccept,
  onDecline,
  isLoading,
}: ProposalActionsProps) {
  return (
    <div className={styles.container}>
      <div className={styles.actions_block}>
        <div className={styles.actions_block__status}>
          <IconFileDownload stroke={1.5} size={32} />
          <Space size={0} direction="vertical">
            <Title level={5}>Expert made a proposal</Title>
            <Text>Please accept or decline a proposal</Text>
          </Space>
        </div>
        <div className={styles.actions_block__controls}>
          <Button danger onClick={onDecline} disabled={isLoading} loading={isLoading}>Decline</Button>
          <Button type="primary" onClick={onAccept} disabled={isLoading} loading={isLoading}>Accept Proposal</Button>
        </div>
      </div>
      <Divider style={{ margin: '16px 0' }} />
      <div className={styles.questions_block}>
        <div className={styles.questions_block__title}>
          <IconInfoCircle size={20} />
          <Title level={5}>Have questions or comments?</Title>
        </div>
        <Text>If you have any comments on the proposal, feel free to discuss them in the chat. The Expert will then provide a new proposal if needed.</Text>
      </div>
    </div>
  );
}
