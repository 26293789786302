import { ProjectTypes, User } from '@raised/domain';
import {
  ColorsModule, FeedbackModule, LayoutModule, TypographyModule,
} from '@raised/ui-kit';
import { IconCashBanknote, IconChartArrowsVertical, IconClockHour3 } from '@tabler/icons-react';
import ExpertFulltimeRoles from 'components/match/match-overview/client/tabs/bio-tab/expert-roles/ExpertFulltimeRoles';
import ExpertParttimeRoles from 'components/match/match-overview/client/tabs/bio-tab/expert-roles/ExpertParttimeRoles';
import ExpertTestimonials from 'components/match/match-overview/client/tabs/bio-tab/expert-testimonials/ExpertTestimonials';
import ExpertTags from 'components/user/components/ExpertTags';
import { PROJECT_TYPES } from 'components/project/constants/project-types';
import UserAvatar from 'components/user/components/UserAvatar';
import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './ExpertModal.module.css';

const { Modal } = FeedbackModule;
const { Text, Title, Paragraph } = TypographyModule;
const { Space } = LayoutModule;
const { Colors } = ColorsModule;

interface ExpertModalProps {
  isVisible: boolean;
  onClose: () => void;
  selectedExpert?: User.Public;
  contactBlock?: ReactNode;
  controls?: ReactNode;
}

export default function ExpertModal({
  isVisible,
  onClose,
  selectedExpert,
  contactBlock,
  controls,
}: ExpertModalProps) {
  return (
    <Modal
      centered={window.innerWidth > 500}
      footer={controls || null}
      onCancel={onClose}
      open={isVisible}
      closeIcon={null}
      className={cn(styles.expert_modal, {
        [styles.modal_with_controls]: Boolean(controls),
      })}
      width={700}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <UserAvatar role={selectedExpert?.role} size={64} filePath={selectedExpert?.avatar} />
          <div className={styles.header__info}>
            <Title level={2}>{`${selectedExpert?.firstName} ${selectedExpert?.lastName}`}</Title>
          </div>
        </div>
        {contactBlock}
        <div className={styles.bio}>
          {selectedExpert?.rate ? (
            <Space size={4} style={{ alignItems: 'flex-start' }}>
              <IconCashBanknote color={Colors.blue} size={18} />
              <Space size={4} direction="vertical">
                <Title level={3}>{`$${selectedExpert.rate}/h`}</Title>
                <Text>Estimated rate inclusive Raised fee</Text>
              </Space>
            </Space>
          ) : null}
          {selectedExpert?.availabilityTypes?.length ? (
            <Space size={4} style={{ alignItems: 'center' }}>
              <IconClockHour3 style={{ display: 'flex' }} size={18} color={Colors.blue} />
              <Text>{selectedExpert.availabilityTypes.map((type) => PROJECT_TYPES?.[type as ProjectTypes] || '').join(', ')}</Text>
            </Space>
          ) : null}
          <Paragraph className={styles.paragraph}>{selectedExpert?.biography || '-'}</Paragraph>
          {selectedExpert ? (
            <ExpertTags expert={selectedExpert} />
          ) : null}
          {selectedExpert?.fulltimeRoles?.length || selectedExpert?.parttimeRoles?.length ? (
            <Space style={{ marginBottom: 16, alignItems: 'center' }}>
              <IconChartArrowsVertical size={24} color="#000" />
              <Title level={2}>Career Highlights</Title>
            </Space>
          ) : null}
          <div style={{ marginBottom: 40 }}>
            {selectedExpert?.fulltimeRoles?.length ? (
              <ExpertFulltimeRoles fulltimeRoles={selectedExpert?.fulltimeRoles} />
            ) : null}
            {selectedExpert?.parttimeRoles?.length ? (
              <ExpertParttimeRoles parttimeRoles={selectedExpert.parttimeRoles} />
            ) : null}
          </div>
          {selectedExpert?.testimonials?.length ? (
            <ExpertTestimonials testimonials={selectedExpert.testimonials} />
          ) : null}
        </div>
      </div>
    </Modal>
  );
}
