import { ColorsModule } from '@raised/ui-kit';

const { Colors } = ColorsModule;

export default function NotificationDot() {
  return (
    <div style={{
      width: 8, height: 8, background: Colors.red, borderRadius: '50%',
    }}
    />
  );
}
