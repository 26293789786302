import { FormsModule, TypographyModule } from '@raised/ui-kit';
import { useState } from 'react';
import {
  Control, Controller, ControllerRenderProps, useWatch,
} from 'react-hook-form';
import styles from './ProfilePublishedLinkInput.module.css';

const { Text } = TypographyModule;
const { Switch, Input } = FormsModule;

interface ProfilePublishedLinkInputProps {
  control: Control<any>;
}

export default function ProfilePublishedLinkInput({
  control,
}: ProfilePublishedLinkInputProps) {
  const publishedField = useWatch({
    control,
    name: 'publishedOnPublicWebsites',
  });
  const [isPublished, setIsPublished] = useState(Boolean(publishedField));

  const toggle = (onChange: ControllerRenderProps['onChange']) => {
    onChange('');
    setIsPublished(!isPublished);
  };

  return (
    <Controller
      control={control}
      name="publishedOnPublicWebsites"
      render={({ field, fieldState }) => (
        <div className={styles.profile_published_link}>
          <div className={styles.switch}>
            <Text>Published on the public website</Text>
            <Switch checked={isPublished} onChange={() => toggle(field.onChange)} />
          </div>
          {isPublished && (
            <Text type="secondary">{`Link preview: ${window.location.origin}/expert-profile/${publishedField || ''}`}</Text>
          )}
          <Input
            {...field}
            value={field.value || ''}
            status={fieldState.error ? 'error' : undefined}
            error={fieldState.error?.message}
            disabled={!isPublished}
            placeholder="Paste link here"
          />
        </div>
      )}
    />
  );
}
