import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { baseErrorSchema } from './errors';

export const getToken = () => localStorage.getItem('access_token');

export const setToken = (token: string) => localStorage.setItem('access_token', token);

export const removeToken = () => localStorage.removeItem('access_token');

export const getErrorMessage = (error?: FetchBaseQueryError | SerializedError) => {
  const defaultMsg = 'Something went wrong';
  if (!error) return defaultMsg;

  const errorParseResult = baseErrorSchema.safeParse(error);

  if (!errorParseResult.success) {
    return defaultMsg;
  }

  const { data: { data } } = errorParseResult;
  return data.message || data.error;
};
