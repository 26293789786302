import { UserRole } from '@raised/domain';
import { FeedbackModule } from '@raised/ui-kit';
import { useGetTemplateByIdQuery } from 'components/templates/storage/templatesApi';
import { useGetProfileQuery } from 'components/user/storage/userApi';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import useCreateProjectFromTemplate from '../hooks/useCreateProjectFromTemplate';
import { PROJECT_ROUTES } from '../project-routes';
import CreateProjectFromTemplateForm from './create-project-from-template-form/CreateProjectFromTemplateForm';
import CreateProjectFromTemplateContainer from './CreateProjectFromTemplateContainer';

const { Spin } = FeedbackModule;

export default function CreateProjectFromTemplatePage() {
  const {
    data: profile,
  } = useGetProfileQuery();
  const { id } = useParams();
  const navigate = useNavigate();

  const onSuccess = () => {
    if (profile?.role === UserRole.ADMIN) {
      navigate(PROJECT_ROUTES.ASSIGN_PROJECTS());
    } else {
      navigate(PROJECT_ROUTES.PROJECTS());
    }
  };
  const onBack = () => navigate(-1);

  const {
    data: template,
    isLoading: isTemplateLoading,
    isSuccess: isTemplateSuccess,
  } = useGetTemplateByIdQuery({ id: id || '' }, { skip: !id });
  const {
    isLoading: isCreateLoading,
    createProjectFromTemplate,
  } = useCreateProjectFromTemplate({
    onSuccess,
  });

  return (
    <>
      <Helmet>
        <title>Raised - Create Project from Template</title>
      </Helmet>
      {isTemplateLoading ? (
        <Spin />
      ) : null}
      {isTemplateSuccess && id ? (
        <CreateProjectFromTemplateContainer
          onBack={onBack}
        >
          <CreateProjectFromTemplateForm
            isLoading={isCreateLoading}
            onSubmit={createProjectFromTemplate}
            onBack={onBack}
            isAdmin={profile?.role === UserRole.ADMIN}
            organisationId={profile?.organisationId}
            template={template}
          />
        </CreateProjectFromTemplateContainer>
      ) : null}
    </>
  );
}
