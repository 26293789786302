import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import cn from 'classnames';
import styles from './Button.module.css';
import '../../colors/colors.module.css';

interface ButtonProps extends AntButtonProps {}

export default function Button({
  type = 'default',
  danger,
  className,
  ...rest
}: ButtonProps) {
  return (
    <AntButton
      type={type}
      danger={danger}
      {...rest}
      className={cn(styles.button, {
        [styles.ghost_btn]: type === 'ghost',
        [styles.default_btn]: type === 'default',
        [styles.primary_btn]: type === 'primary',
        [styles.danger]: danger,
        [styles.link_btn]: type === 'link',
      }, className)}
    />
  );
}
