import { FeedbackModule } from '@raised/ui-kit';
import { useGetFileQuery } from 'components/files/storage/filesApi';
import styles from './FilesInput.module.css';

const { Spin } = FeedbackModule;

interface AttachedFileProps {
  fileName: string;
}

export default function AttachedStoryFile({
  fileName,
}: AttachedFileProps) {
  const { data, isLoading } = useGetFileQuery({ fileName });

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <img alt="" className={styles.img} src={data?.url || ''} />
      )}
    </>
  );
}
