import { FormsModule } from '@raised/ui-kit';
import styles from './ProjectsFilter.module.css';

const { Input } = FormsModule;

interface ProjectsFilterProps {
  search: string;
  changeSearch: (search: string) => void;
}

export default function ProjectsFilter({
  search,
  changeSearch,
}: ProjectsFilterProps) {
  return (
    <Input className={styles.projects_filter} placeholder="Search..." value={search} onChange={(e) => changeSearch(e.target.value)} />
  );
}
