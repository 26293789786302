import { User } from '@raised/domain';
import { FormsModule } from '@raised/ui-kit';
import { Control } from 'react-hook-form';
import { FormConfig } from 'utils/forms';

const { Input, Password } = FormsModule;

export const getLoginFormConfig = (control: Control<User.Login>): FormConfig<User.Login> => [
  {
    controller: {
      name: 'email',
      control,
      defaultValue: '',
    },
    component: Input,
    componentProps: {
      placeholder: 'Email',
      type: 'email',
      'data-testid': 'form_login__input_email',
    },
  },
  {
    controller: {
      name: 'password',
      control,
      defaultValue: '',
    },
    component: Password,
    componentProps: {
      placeholder: 'Password',
      'data-testid': 'form_login__input_password',
    },
  },
];
