import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import cn from 'classnames';
import { Control, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { renderFormConfig, scrollToError } from 'utils/forms';
import { Project, ProjectSchemas } from '@raised/domain';
import {
  useEffect, ReactNode, useMemo, useState, Ref,
} from 'react';
import styles from './CreateProjectForm.module.css';
import ProjectBudgetInput from './project-budget-input/ProjectBudgetInput';
import { getCreateProjectFormConfigPart1, getCreateProjectFormConfigPart2 } from './createProjectFormConfig';
import AdminUserSelector from './AdminUserSelector';
import UsersToNotifySelector from './users-to-notify-selector/UsersToNotifySelector';
import AdminOrganisationSelector from './admin-organisation-selector/AdminOrganisationSelector';
import ProjectCategoriesForm from './project-categories-form/ProjectCategoriesForm';
import AdminActivitySelector from './admin-activity-selector/AdminActivitySelector';

const { Button } = ButtonsModule;
const { Title } = TypographyModule;

interface CreateProjectComponentProps {
  title?: string;
  isLoading: boolean;
  onSubmit: (values: Project.Create) => void;
  isAdmin: boolean;
  organisationId?: string;
  defaultValues?: Partial<Project.Create>;
  filesInput: ReactNode;
  onBack: () => void;
  formRef?: Ref<HTMLFormElement>;
  isControls?: boolean;
}

export default function CreateProjectComponent({
  title,
  onSubmit,
  isLoading,
  isAdmin,
  organisationId,
  defaultValues,
  filesInput,
  onBack,
  formRef,
  isControls = true,
}: CreateProjectComponentProps) {
  const [selectedOrganisation, setSelectedOrganisation] = useState('');
  const {
    control, resetField, handleSubmit, reset, setValue,
  } = useForm<Project.Create>({
    resolver: zodResolver(ProjectSchemas.createProjectSchema),
    defaultValues,
  });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const formConfigPart1 = useMemo(() => getCreateProjectFormConfigPart1(control), []);
  const formConfigPart2 = useMemo(() => getCreateProjectFormConfigPart2(control), []);

  const onSelectOrganisation = (org: string) => {
    if (org === selectedOrganisation) return;

    setSelectedOrganisation(org);
    resetField('clientId');
    resetField('usersToNotifyId');
  };

  return (
    <form
      ref={formRef}
      className={cn('form_container', styles.container)}
      onSubmit={handleSubmit(onSubmit, scrollToError)}
      onReset={() => reset()}
    >
      {title ? (
        <Title style={{ textAlign: 'center' }} level={1}>{title}</Title>
      ) : null}
      {renderFormConfig(formConfigPart1)}
      <ProjectCategoriesForm setValue={setValue} control={control as unknown as Control} />
      {renderFormConfig(formConfigPart2)}
      <ProjectBudgetInput control={control as unknown as Control} />
      {!isAdmin && organisationId ? (
        <UsersToNotifySelector control={control as unknown as Control} organisationId={organisationId} />
      ) : null}
      {filesInput}
      {isAdmin ? (
        <>
          <AdminOrganisationSelector
            selectedOrganisation={selectedOrganisation}
            onSelectOrganisation={onSelectOrganisation}
          />
          <AdminUserSelector organisationId={selectedOrganisation} control={control as unknown as Control} />
          <UsersToNotifySelector organisationId={selectedOrganisation} control={control as unknown as Control} />
          <AdminActivitySelector control={control as unknown as Control} />
        </>
      ) : null}
      {isControls ? (
        <div className={styles.buttons}>
          <Button className={styles.submit_btn} loading={isLoading} disabled={isLoading} type="primary" htmlType="submit">
            Create Project
          </Button>
          <Button className={styles.cancel_btn} danger onClick={onBack}>Cancel</Button>
        </div>
      ) : null}
    </form>
  );
}
