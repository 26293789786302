import { z } from 'zod'
import { linkRule } from '../common/schemas'

export const createOrganisationSchema = z.object({
  companyName: z.string().min(1),
  companyWebsite: linkRule,
  aboutCompany: z.string().optional(),
})

export const editOrganisationSchema = z.object({}).and(createOrganisationSchema);
