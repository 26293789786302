import { message } from 'antd';
import { getErrorMessage, removeToken, setToken } from 'api/helpers';
import { useLazyGetProfileQuery } from 'components/user/storage/userApi';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTH_ROUTES } from '../auth-routes';
import { useVerifyTokenMutation } from '../storage/authApi';

export default function useVerifyToken() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return {
      redirectTo: searchParams.get('redirectTo'),
      token: searchParams.get('token'),
      userEmail: searchParams.get('email'),
    };
  }, [search]);

  const [getProfile, { isLoading: isProfileLoading }] = useLazyGetProfileQuery();
  const [mutate, data] = useVerifyTokenMutation();
  const {
    isSuccess: isVerifySuccess,
    isLoading: isVerifyLoading,
    data: verifyData,
    isError: isVerifyError,
    error: verifyError,
  } = data;

  useEffect(() => {
    if (!params.token || !params.userEmail) return;

    removeToken();
    mutate({ email: params.userEmail, token: params.token });
  }, []);

  useEffect(() => {
    if (!isVerifySuccess) return;

    setToken(verifyData.access_token);
    getProfile()
      .unwrap()
      .then(() => {
        navigate(params.redirectTo || '');
      });
  }, [isVerifySuccess]);

  useEffect(() => {
    if (!isVerifyError) return;

    message.error(getErrorMessage(verifyError));
    navigate(AUTH_ROUTES.LOGIN());
  }, [isVerifyError]);

  return {
    ...data,
    isLoading: isVerifyLoading || isProfileLoading,
  };
}
