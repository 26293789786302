import { MatchStatuses, Proposal, ProposalStatuses } from '@raised/domain';
import { ReactNode } from 'react';
import {
  IconEyeExclamation, IconFileDownload, IconMessages, IconMoodDollar, IconMoodPlus, IconUserCheck, IconUserX,
} from '@tabler/icons-react';

type MatchStatusLabels = Record<MatchStatuses, { text: string; icon: ReactNode; secondaryText?: string; description?: string }>;

export const CLIENT_MATCH_STATUS_LABELS: MatchStatusLabels = {
  [MatchStatuses.INTERSTED_BY_CLIENT]: {
    text: 'You’ve shown interest',
    icon: <IconEyeExclamation stroke={1.5} />,
    description: 'We`re awaiting the Expert`s response',
  },
  [MatchStatuses.INTERSTED_BY_EXPERT]: {
    text: 'Raised has found a relevant professional',
    description: 'You can begin with a complimentary 30-minute chemistry call',
    icon: <IconUserCheck stroke={1.5} />,
  },
  [MatchStatuses.RECOMMENDED_TO_CLIENT]: {
    text: 'Raised has identified a suitable expert',
    description: 'Let us know if they are a fit and we will verify their availability and interest',
    icon: <IconUserCheck stroke={1.5} />,
  },
  [MatchStatuses.RECOMMENDED_TO_EXPERT]: {
    text: 'Recommended to expert',
    icon: '',
  },
  [MatchStatuses.FINISHED]: {
    text: 'Finished',
    icon: '',
  },
  [MatchStatuses.DECLINED_BY_CLIENT]: {
    text: 'You declined the Expert',
    icon: <IconUserX stroke={1.5} />,
  },
  [MatchStatuses.DECLINED_BY_EXPERT]: {
    text: 'Declined by Expert',
    icon: <IconUserX stroke={1.5} />,
  },
  [MatchStatuses.PAYMENT_RECEIVED]: {
    text: 'Proposal is paid',
    icon: <IconMoodDollar stroke={1.5} />,
  },
  [MatchStatuses.CALL_SCHEDULED]: {
    text: 'Communication with expert',
    icon: <IconMessages stroke={1.5} />,
  },
  [MatchStatuses.CALL_OVER]: {
    text: 'Communication with expert',
    icon: <IconMessages stroke={1.5} />,
  },
  [MatchStatuses.INVITED_TO_INTERVIEW]: {
    text: 'Communication with expert',
    description: 'Please schedule an intro call with the Expert in the chat. Then if both parties agree to proceed, the Expert will create a proposal',
    icon: <IconMessages stroke={1.5} />,
  },
  [MatchStatuses.PAUSE]: {
    text: 'Pause',
    icon: '',
  },
};

export const getClientMatchStatusLabel = (status: MatchStatuses, proposals: Proposal.Public[]) => {
  const statusConfig = CLIENT_MATCH_STATUS_LABELS[status];

  if (statusConfig.text === 'Communication with expert' && proposals.some((proposal) => proposal.status === ProposalStatuses.PENDING)) {
    return {
      text: 'Expert has made a proposal',
      icon: <IconFileDownload stroke={1.5} />,
      description: 'Please accept or decline a proposal',
    };
  }

  return statusConfig;
};

export const EXPERT_MATCH_STATUS_LABELS: MatchStatusLabels = {
  [MatchStatuses.INTERSTED_BY_CLIENT]: {
    text: 'Raised invites you to the project and client is interested to make an intro call',
    icon: <IconMoodPlus stroke={1.5} />,
  },
  [MatchStatuses.INTERSTED_BY_EXPERT]: {
    text: 'You`ve shown interest',
    description: 'We`re awating the Client`s response',
    icon: <IconMessages stroke={1.5} />,
  },
  [MatchStatuses.RECOMMENDED_TO_CLIENT]: {
    text: 'Recommended co client',
    description: 'Please schedule an intro call with the Client in the chat. Then if both parties agree to proceed, create a proposal',
    icon: '',
  },
  [MatchStatuses.RECOMMENDED_TO_EXPERT]: {
    text: 'Accept invitation to the Project',
    secondaryText: 'Raised invites you to the project',
    icon: <IconMoodPlus stroke={1.5} />,
  },
  [MatchStatuses.FINISHED]: {
    text: 'Finished',
    icon: '',
  },
  [MatchStatuses.DECLINED_BY_CLIENT]: {
    text: 'Sorry, Client declined your profile',
    secondaryText: 'Declined by Client',
    icon: <IconUserX stroke={1.5} />,
  },
  [MatchStatuses.DECLINED_BY_EXPERT]: {
    text: 'You declined an invitation to a Project',
    secondaryText: 'Declined by you',
    icon: <IconUserX stroke={1.5} />,
  },
  [MatchStatuses.PAYMENT_RECEIVED]: {
    text: 'Proposal is paid',
    icon: <IconMoodDollar stroke={1.5} />,
  },
  [MatchStatuses.CALL_SCHEDULED]: {
    text: 'Communication with client',
    description: 'Please schedule an intro call with the Client in the chat. Then if both parties agree to proceed, create a proposal',
    icon: <IconMessages stroke={1.5} />,
  },
  [MatchStatuses.CALL_OVER]: {
    text: 'Communication with client',
    description: 'Please schedule an intro call with the Client in the chat. Then if both parties agree to proceed, create a proposal',
    icon: <IconMessages stroke={1.5} />,
  },
  [MatchStatuses.INVITED_TO_INTERVIEW]: {
    text: 'Communication with client',
    description: 'Please schedule an intro call with the Client in the chat. Then if both parties agree to proceed, create a proposal',
    icon: <IconMessages stroke={1.5} />,
  },
  [MatchStatuses.PAUSE]: {
    text: 'Pause',
    icon: '',
  },
};

export const ADMIN_MATCH_STATUS_LABELS: Record<MatchStatuses, string> = {
  [MatchStatuses.INTERSTED_BY_CLIENT]: 'Interested by client',
  [MatchStatuses.INTERSTED_BY_EXPERT]: 'Interested by expert',
  [MatchStatuses.RECOMMENDED_TO_CLIENT]: 'Recommended to client',
  [MatchStatuses.RECOMMENDED_TO_EXPERT]: 'Recommended to expert',
  [MatchStatuses.FINISHED]: 'Finished',
  [MatchStatuses.DECLINED_BY_CLIENT]: 'Declined by client',
  [MatchStatuses.DECLINED_BY_EXPERT]: 'Declined by expert',
  [MatchStatuses.PAYMENT_RECEIVED]: 'Client paid for the proposal',
  [MatchStatuses.CALL_SCHEDULED]: 'Call was scheduled',
  [MatchStatuses.CALL_OVER]: 'Communication',
  [MatchStatuses.INVITED_TO_INTERVIEW]: 'Invited to interview',
  [MatchStatuses.PAUSE]: 'Pause',
};
