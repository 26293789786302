import { MatchStatuses, ProposalStatuses } from '@raised/domain';
import { useGetLogMessagesByMatchQuery } from 'components/log-messages/storage/logMessageApi';
import useUpdateMatch from 'components/match/hooks/useUpdateMatch';
import { useGetMatchByIdQuery } from 'components/match/storage/matchApi';
import { useGetPaymentsByMatchQuery } from 'components/payments/storage/paymentsApi';
import useUpdateProposalStatus from 'components/proposal/hooks/useUpdateProposalStatus';
import { useGetProposalsByMatchQuery } from 'components/proposal/storage/proposalApi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useRestoreMatch from 'components/match/hooks/useRestoreMatch';
import useCreateMessage from 'components/messages/hooks/useCreateMessage';
import { ClientMatchOverviewTabs } from 'components/match/types/client-match-overview-tabs';
import { useGetSupportChatQuery } from 'components/messages/storage/messageApi';
import { Helmet } from 'react-helmet';
import usePaymentLink from 'components/match/hooks/usePaymentLink';
import ClientMatchOverviewComponent from './ClientMatchOverviewComponent';

export default function ClientMatchOverviewPage() {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: match } = useGetMatchByIdQuery({ id: id as string }, { skip: !id });
  const { data: proposals } = useGetProposalsByMatchQuery({ matchId: id as string }, { skip: !id });
  const {
    data: logMessages,
    isLoading: isLogMessagesLoading,
    isFetching: isLogMessagesFetching,
    refetch: refetchLogMessages,
  } = useGetLogMessagesByMatchQuery({ matchId: id as string }, { skip: !id });
  const {
    data: payments,
    isLoading: isPaymentsLoading,
  } = useGetPaymentsByMatchQuery({ matchId: match?.id || '' }, { skip: !match });
  const { data: supportChat } = useGetSupportChatQuery();
  const {
    onPayClick,
    isLoading: isProposalLinkLoading,
    isFetching: isProposalLinkFetching,
    isSuccess: isProposalLinkSuccess,
  } = usePaymentLink();

  const { createMessage } = useCreateMessage();
  const {
    restore,
    isLoading: isRestoreLoading,
  } = useRestoreMatch();
  const {
    update: updateMatch,
    isLoading: isUpdateMatchLoading,
  } = useUpdateMatch();
  const {
    updateProposalStatus,
    isLoading: isUpdateStatusLoading,
  } = useUpdateProposalStatus();

  const onBack = () => navigate(-1);
  const onUpdateProposalStatus = (proposalId: string, status: ProposalStatuses) => {
    if (!id) return;

    updateProposalStatus({ proposalId, status, matchId: id }).then(() => {
      if (status === ProposalStatuses.ACCEPTED) {
        onPayClick(proposalId);
      }
    });
  };
  const onSubmitMatchUpdate = (status: MatchStatuses, message: string) => {
    if (!id) return;

    updateMatch({ id, data: { status, message } });

    if (!message) return;

    const chatId = status === MatchStatuses.DECLINED_BY_CLIENT ? supportChat?.id : match?.chat?.[0]?.id;
    const messageToSend = status === MatchStatuses.DECLINED_BY_CLIENT
      ? `Declining ${match?.user?.firstName} ${match?.user?.lastName} for project ${match?.project?.title}: ${message}`
      : message;

    if (!chatId) return;

    createMessage({
      chatId,
      text: messageToSend,
    });
  };
  const onRestoreMatch = () => {
    if (!id || !match?.oldStatus) return;

    restore({ matchId: id });
  };
  const onTabChange = (tab: ClientMatchOverviewTabs) => {
    if (tab === ClientMatchOverviewTabs.LOG) {
      refetchLogMessages();
    }
  };

  return (
    <>
      <Helmet>
        <title>Raised - Match overview</title>
      </Helmet>
      {match && (
        <ClientMatchOverviewComponent
          selectedTab={state?.selectedTab}
          onBack={onBack}
          match={match}
          logMessages={logMessages}
          isLogMessagesLoading={isLogMessagesLoading || isLogMessagesFetching}
          proposals={proposals || []}
          isUpdateStatusLoading={isUpdateStatusLoading}
          onUpdateProposalStatus={onUpdateProposalStatus}
          payments={payments || []}
          isPaymentsLoading={isPaymentsLoading}
          isUpdateMatchLoading={isUpdateMatchLoading}
          isRestoreMatchLoading={isRestoreLoading}
          onRestoreMatch={onRestoreMatch}
          onSubmitMatchUpdate={onSubmitMatchUpdate}
          onTabChange={onTabChange}
          isProposalLinkLoading={isProposalLinkLoading || isProposalLinkFetching}
          onProposalPayClick={onPayClick}
          isProposalLinkSuccess={isProposalLinkSuccess}
        />
      )}
    </>
  );
}
