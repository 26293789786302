import { FeedbackModule, TypographyModule } from '@raised/ui-kit';
import { ReactNode } from 'react';
import styles from './AdminSuccessStoryListContent.module.css';

const { Title } = TypographyModule;
const { Spin } = FeedbackModule;

interface AdminSuccessStoryListContentProps {
  children: ReactNode;
  count: number;
  isLoading: boolean;
}

export default function AdminSuccessStoryListContent({
  children,
  isLoading,
  count,
}: AdminSuccessStoryListContentProps) {
  return (
    <main className={styles.container}>
      <div className={styles.title_block}>
        <Title level={1}>Success Stories</Title>
        <Title level={1} className={styles.count}>{isLoading ? <Spin /> : count}</Title>
      </div>
      {children}
    </main>
  );
}
