/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import { Usecase } from '@raised/domain';
import { ColorsModule, TypographyModule } from '@raised/ui-kit';
import { IconClockHour3 } from '@tabler/icons-react';
import UserAvatar from 'components/user/components/UserAvatar';
import { formatDate } from 'utils/formats';
import { ReactNode } from 'react';
import styles from './UseCaseItem.module.css';

const { Title, Text } = TypographyModule;
const { Colors } = ColorsModule;

interface UseCaseItemProps {
  usecase: Usecase.Public;
  onClick?: () => void;
  additionalContent?: ReactNode;
}

export default function UseCaseItem({
  usecase,
  onClick,
  additionalContent,
}: UseCaseItemProps) {
  return (
    <div
      onClick={onClick}
      className={cn(styles.use_case, {
        [styles.clickable]: !!onClick,
      })}
    >
      <div className={styles.info}>
        <div className={styles.user_info}>
          <UserAvatar
            size="s"
            role={usecase.user?.role}
            style={{ flexShrink: 0 }}
            filePath={usecase.user?.avatar}
          />
          <Text>{`${usecase.user?.firstName} ${usecase.user?.lastName}`}</Text>
        </div>
        <Title level={3} className={styles.title}>{usecase.title}</Title>
        <div className={styles.created_info}>
          <IconClockHour3 size={14} color={Colors.gray} />
          <Text type="secondary" caption>{formatDate(new Date(usecase.createdAt))}</Text>
        </div>
      </div>
      <Text type="secondary" caption>
        {usecase.template ? `Based on template ${usecase.template.title}` : 'Not based on template'}
      </Text>
      {additionalContent}
    </div>
  );
}
