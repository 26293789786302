import { ORGANISATION_ROUTES } from 'components/organisation/organisation-routes';
import { USER_ROUTES } from 'components/user/user-routes';
import { Helmet } from 'react-helmet';
import useGetProjects from '../hooks/useGetProjects';
import { PROJECT_ROUTES } from '../project-routes';
import AssignProjectTable from './assign-project-table/AssignProjectTable';
import AssignProjectComponent from './AssignProjectComponent';
import ProjectsFilter from './projects-filter/ProjectsFilter';

export default function AssignProjectPage() {
  const {
    data: projects,
    isLoading: isProjectsLoading,
    isFetching: isProjectsFetching,
    search,
    changeSearch,
  } = useGetProjects();

  const getRowLink = (id: string) => PROJECT_ROUTES.PROJECT(id);
  const getUserLink = (userId: string) => USER_ROUTES.USER_PROFILE(userId);
  const getOrganisationLink = (orgId: string) => ORGANISATION_ROUTES.ADMIN_ORGANIZATION(orgId);

  return (
    <>
      <Helmet>
        <title>Raised - Assign project</title>
      </Helmet>
      <AssignProjectComponent>
        <ProjectsFilter
          search={search}
          changeSearch={changeSearch}
        />
        <AssignProjectTable
          isLoading={isProjectsLoading || isProjectsFetching}
          projects={projects}
          getRowLink={getRowLink}
          getUserLink={getUserLink}
          getOrganisationLink={getOrganisationLink}
        />
      </AssignProjectComponent>
    </>
  );
}
