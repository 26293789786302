import { RouteObject } from 'react-router-dom';
import MainLayout from 'components/layout/containers/main-layout/MainLayout';
import ProtectedRouteLayout from 'components/layout/containers/protected-route-layout/ProtectedRouteLayout';
import AdminRouteLayout from 'components/layout/containers/admin-route/AdminRouteLayout';
import PublicLayout from 'components/layout/containers/public-layout/PublicLayout';
import CreateUserPage from './create-user/CreateUserPage';
import PersonalProfilePage from './personal-profile/PersonalProfilePage';
import CreateGhostPage from './create-ghost/CreateGhostPage';
import ActivateGhostPage from './activate-ghost/ActivateGhostPage';
import UserProfilePage from './user-profile/UserProfilePage';
import EditProfilePage from './edit-profile/EditProfilePage';
import AdminUserListPage from './admin-user-list/AdminUserListPage';
import EditUserProfilePage from './edit-profile/EditUserProfilePage';
import JoinOrganisationPage from './join-organisation/JoinOrganisationPage';
import AdminExpertsListPage from './experts-list/admin/AdminExpertsListPage';
import ClientExpertsListPage from './experts-list/client/ClientExpertsListPage';
import PublicExpertProfilePage from './public-expert-profile/PublicExpertProfilePage';

export const USER_ROUTES = {
  CREATE: () => '/user/create',
  PERSONAL_PROFILE: () => '/profile/personal',
  USER_PROFILE: (id: string) => `/profile/user/${id}`,
  CREATE_GHOST: () => '/user/ghost/create',
  ACTIVATE_GHOST: (id: string) => `/user/ghost/activate/${id}`,
  EDIT_PERSONAL_PROFILE: () => '/profile/edit',
  EDIT_USER_PROFILE: (userId: string) => `/user/edit/${userId}`,
  ADMIN_USER_LIST: () => '/user/list',
  JOIN_ORGANISATION: () => '/user/join-organisation',
  ADMIN_EXPERTS_LIST: () => '/user/admin/experts',
  CLIENT_EXPERTS_LIST: () => '/user/client/experts',
  PUBLIC_EXPERT_PROFILE: (expertId: string) => `/expert-profile/${expertId}`,
};

const userRoutes: Array<RouteObject> = [
  {
    element: <MainLayout />,
    children: [
      {
        path: USER_ROUTES.CREATE(),
        element: <CreateUserPage />,
      },
      {
        path: USER_ROUTES.ACTIVATE_GHOST(':id'),
        element: <ActivateGhostPage />,
      },
      {
        path: USER_ROUTES.JOIN_ORGANISATION(),
        element: <JoinOrganisationPage />,
      },
      {
        element: <ProtectedRouteLayout />,
        children: [
          {
            path: USER_ROUTES.PERSONAL_PROFILE(),
            element: <PersonalProfilePage />,
          },
          {
            path: USER_ROUTES.EDIT_PERSONAL_PROFILE(),
            element: <EditProfilePage />,
          },
          {
            path: USER_ROUTES.USER_PROFILE(':id'),
            element: <UserProfilePage />,
          },
          {
            path: USER_ROUTES.CLIENT_EXPERTS_LIST(),
            element: <ClientExpertsListPage />,
          },
        ],
      },
      {
        element: <AdminRouteLayout />,
        children: [
          {
            path: USER_ROUTES.CREATE_GHOST(),
            element: <CreateGhostPage />,
          },
          {
            path: USER_ROUTES.ADMIN_USER_LIST(),
            element: <AdminUserListPage />,
          },
          {
            path: USER_ROUTES.EDIT_USER_PROFILE(':id'),
            element: <EditUserProfilePage />,
          },
          {
            path: USER_ROUTES.ADMIN_EXPERTS_LIST(),
            element: <AdminExpertsListPage />,
          },
        ],
      },
    ],
  },
  {
    element: <PublicLayout />,
    children: [
      {
        element: <PublicExpertProfilePage />,
        path: USER_ROUTES.PUBLIC_EXPERT_PROFILE(':id'),
      },
    ],
  },
];

export default userRoutes;
