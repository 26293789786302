import { ColorsModule, DataModule, FeedbackModule } from '@raised/ui-kit';
import { IconBellRinging } from '@tabler/icons-react';
import useNewNotifications from 'components/notifications/hooks/useNewNotifications';
import styles from './NotificationsButton.module.css';

const { Badge } = DataModule;
const { Spin } = FeedbackModule;
const { Colors } = ColorsModule;

const getIconProps = (isActive: boolean) => (isActive ? { color: Colors.accent, stroke: 2 } : { color: Colors.black, stroke: 1.5 });

interface NotificationsButtonProps {
  profileId: string;
  isNotificationsOpened: boolean;
  onNotificationsClick: () => void;
}

export default function NotificationsButton({
  profileId,
  isNotificationsOpened,
  onNotificationsClick,
}: NotificationsButtonProps) {
  const {
    newNotificationsCount,
    isLoading: isNotificationsLoading,
  } = useNewNotifications({
    profileId,
  });

  return (
    <button onClick={onNotificationsClick} type="button" className={styles.notifications_button}>
      <Badge count={isNotificationsLoading ? <Spin size="small" /> : newNotificationsCount} color="red">
        <IconBellRinging size={24} {...getIconProps(isNotificationsOpened)} />
      </Badge>
    </button>
  );
}
