import { Typography } from 'antd';
import { ComponentProps } from 'react';
import cn from 'classnames';
import styles from './Paragraph.module.css';

interface ParagraphProps extends ComponentProps<typeof Typography.Paragraph> {}

export default function Paragraph({
  className,
  ...props
}: ParagraphProps) {
  return (
    <Typography.Paragraph
      {...props}
      className={cn(styles.paragraph, className)}
    />
  );
}
