import { rootApi } from 'api/rootApi';
import type { Common, User } from '@raised/domain';
import { ForgotPasswordRequestType, ResetPasswordRequestType } from './types';

export const authApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<User.LoginResponse, Common.WithRecaptcha<User.Login>>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth',
      }),
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: '/auth/logout',
        credentials: 'include',
      }),
    }),
    verifyToken: build.mutation<User.LoginResponse, { token: string, email: string }>({
      query: (body) => ({
        url: '/auth/token',
        method: 'POST',
        body,
      }),
    }),
    forgotPassword: build.mutation<void, ForgotPasswordRequestType>({
      query: (body) => ({
        url: '/user/forgot-password',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: build.mutation<void, ResetPasswordRequestType>({
      query: (body) => ({
        url: '/user/reset-password',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useVerifyTokenMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLogoutMutation,
} = authApi;
