import { SuccessStory } from '@raised/domain';
import { rootApi } from 'api/rootApi';

export const successStoriesApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getPersonalSuccessStories: build.query<SuccessStory.Public[], void>({
      providesTags: (res) => (res ? [
        ...res.map((story) => ({ id: story.id, type: 'SUCCESS_STORY' }) as const),
        'SUCCESS_STORY',
      ] : ['SUCCESS_STORY']),
      query: () => ({
        url: '/success-story',
      }),
    }),
    getUserSuccessStories: build.query<SuccessStory.Public[], { id: string }>({
      providesTags: (res) => (res ? [
        ...res.map((story) => ({ id: story.id, type: 'SUCCESS_STORY' }) as const),
        'SUCCESS_STORY',
      ] : ['SUCCESS_STORY']),
      query: ({ id }) => ({
        url: `/success-story/user/${id}`,
      }),
    }),
    getAllSuccessStories: build.query<SuccessStory.Public[], void>({
      providesTags: (res) => (res ? [
        ...res.map((story) => ({ id: story.id, type: 'SUCCESS_STORY' }) as const),
        'SUCCESS_STORY',
      ] : ['SUCCESS_STORY']),
      query: () => ({
        url: '/success-story/all',
      }),
    }),
    getSuccessStoryById: build.query<SuccessStory.Public, { id: string }>({
      providesTags: (_r, _e, args) => [{ id: args.id, type: 'SUCCESS_STORY' }],
      query: ({ id }) => ({
        url: `/success-story/${id}`,
      }),
    }),
    getSuccessStoriesByUsecase: build.query<SuccessStory.Public[], { id: string }>({
      providesTags: (res) => (res ? [
        ...res.map((story) => ({ id: story.id, type: 'SUCCESS_STORY' }) as const),
        'SUCCESS_STORY',
      ] : ['SUCCESS_STORY']),
      query: ({ id }) => ({
        url: `/success-story/by-usecase/${id}`,
      }),
    }),
    createSuccessStory: build.mutation<SuccessStory.Public, SuccessStory.Create>({
      invalidatesTags: ['SUCCESS_STORY'],
      query: (body) => ({
        url: '/success-story',
        method: 'POST',
        body,
      }),
    }),
    editSuccessStory: build.mutation<SuccessStory.Public, SuccessStory.Edit & { id: string }>({
      invalidatesTags: (_r, _e, args) => [{ id: args.id, type: 'SUCCESS_STORY' }],
      query: ({ id, ...body }) => ({
        url: `/success-story/${id}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useGetPersonalSuccessStoriesQuery,
  useGetUserSuccessStoriesQuery,
  useGetSuccessStoriesByUsecaseQuery,
  useGetSuccessStoryByIdQuery,
  useEditSuccessStoryMutation,
  useCreateSuccessStoryMutation,
  useGetAllSuccessStoriesQuery,
} = successStoriesApi;
