import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { renderFormConfig } from 'utils/forms';
import type { User } from '@raised/domain';
import { UserSchemas } from '@raised/domain';
import cn from 'classnames';
import { useMemo } from 'react';
import RecaptchaMessage from 'components/layout/components/recaptcha-message/RecaptchaMessage';
import { Link } from 'react-router-dom';
import { ButtonsModule, TypographyModule } from '@raised/ui-kit';
import styles from './LoginForm.module.css';
import { getLoginFormConfig } from './loginFormConfig';
import { AUTH_ROUTES } from '../auth-routes';

const { Button } = ButtonsModule;
const { Title, Link: TLink } = TypographyModule;

interface LoginFormProps {
  onSubmit: (values: User.Login) => void;
  isLoading: boolean;
  title: string;
}

export default function LoginForm({
  onSubmit,
  title,
  isLoading,
}: LoginFormProps) {
  const { control, handleSubmit } = useForm<User.Login>({
    resolver: zodResolver(UserSchemas.loginSchema),
  });
  const formConfig = useMemo(() => getLoginFormConfig(control), []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn(styles.form, 'form_container')}>
      <Title style={{ textAlign: 'center' }} level={1}>{title}</Title>
      {renderFormConfig(formConfig)}
      <Button loading={isLoading} disabled={isLoading} htmlType="submit" type="primary">
        Sign In
      </Button>
      <Link to={AUTH_ROUTES.FORGOT_PASSWORD()}>
        <TLink>
          Forgot password?
        </TLink>
      </Link>
      <RecaptchaMessage />
    </form>
  );
}
