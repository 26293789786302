import { ButtonsModule } from '@raised/ui-kit';
import styles from './InviteExpertModalFooter.module.css';

const { Button } = ButtonsModule;

interface InviteExpertModalFooterProps {
  onCancel: () => void;
  onAction: () => void;
  actionButtonText: string;
  isLoading: boolean;
}

export default function InviteExpertModalFooter({
  onCancel,
  onAction,
  actionButtonText,
  isLoading,
}: InviteExpertModalFooterProps) {
  return (
    <div className={styles.invite_expert_modal_footer}>
      <Button className={styles.cancel} loading={isLoading} disabled={isLoading} type="text" onClick={onCancel}>Cancel</Button>
      <Button className={styles.action} loading={isLoading} disabled={isLoading} type="primary" onClick={onAction}>{actionButtonText}</Button>
    </div>
  );
}
