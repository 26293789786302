enum ProjectStatuses {
  LOOKING_FOR_PROFESSIONALS = 'LOOKING_FOR_PROFESSIONALS',
  IN_PROGRESS_NO_NEED_EXPERTS = 'IN_PROGRESS_NO_NEED_EXPERTS',
  IN_PROGRESS_NEED_EXPERTS = 'IN_PROGRESS_NEED_EXPERTS',
  FINISHED = 'FINISHED',
  ARCHIVED = 'ARCHIVED',
  FOUND_AN_EXPERT = 'FOUND_AN_EXPERT'
}

export default ProjectStatuses;
