import { ComponentProps } from 'react';
import { Timeline } from '../timeline';
import { WorkTimelineItem as WorkTimelineItemType } from './types';
import WorkTimelineItem from './WorkTimelineItem';

type TimelineProps = ComponentProps<typeof Timeline>;

interface WorkTimelineProps extends Omit<TimelineProps, 'items'> {
  items: WorkTimelineItemType[];
}

export default function WorkTimeline({ items, ...props }: WorkTimelineProps) {
  const baseItems: TimelineProps['items'] = items.map(({
    color, dot, ...rest
  }) => ({
    color,
    dot,
    position: 'right',
    children: <WorkTimelineItem {...rest} />,
  }));

  return (
    <Timeline items={baseItems} {...props} />
  );
}
