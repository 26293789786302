import { message } from 'antd';
import { getErrorMessage } from 'api/helpers';
import { useGetProposalByIdQuery } from 'components/proposal/storage/proposalApi';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import PaymentInfoComponent from './PaymentInfoComponent';

export default function PaymentInfoPage() {
  const { id } = useParams();
  const {
    data, isSuccess, error, isError,
  } = useGetProposalByIdQuery({ id: id || '' }, { skip: !id });

  useEffect(() => {
    if (isError) {
      message.error(getErrorMessage(error));
    }
  }, [isError]);

  return (
    <>
      <Helmet>
        <title>Raised - Payment info</title>
      </Helmet>
      {isSuccess && (
        <PaymentInfoComponent proposal={data} />
      )}
    </>
  );
}
