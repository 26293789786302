import {
  Chat, Message, MessageTypes,
} from '@raised/domain';
import { useGetNewMessagesQuery } from 'components/messages/storage/messageApi';
import { Fragment } from 'react';
import ChatMessage from '../chat-message/ChatMessage';
import ChildrenObserver from '../ChildrenObserver';
import TechMessage from '../tech-messasge/TechMessage';

interface NewMessagesListProps {
  chat: Chat.Public;
  fromDate: string;
  pollingInterval: number;
  personalProfileId?: string;
  setObserveForMessage: (element: HTMLDivElement) => void;
  onNewMessage: () => void;
}

export default function NewMessagesList({
  chat,
  fromDate,
  pollingInterval,
  personalProfileId,
  setObserveForMessage,
  onNewMessage,
}: NewMessagesListProps) {
  const { data } = useGetNewMessagesQuery({
    chatId: chat.id,
    fromDate,
  }, { pollingInterval });

  const observeMessage = (message: Message.Public) => {
    if (!personalProfileId || message.fromId === personalProfileId || message.viewedBy.includes(personalProfileId)) return;

    // eslint-disable-next-line consistent-return
    return setObserveForMessage;
  };

  return (
    <ChildrenObserver onChange={onNewMessage}>
      {data?.map((message) => (
        <Fragment key={message.id}>
          {message.type === MessageTypes.CHAT ? (
            <ChatMessage
              message={message}
              personalProfileId={personalProfileId}
              setObserveForMessage={observeMessage(message)}
            />
          ) : (
            <TechMessage type={message.type} chat={chat} />
          )}
        </Fragment>
      ))}
    </ChildrenObserver>
  );
}
