import { UserRole } from '@raised/domain';
import { ColorsModule, DataModule } from '@raised/ui-kit';
import { useGetFileQuery } from 'components/files/storage/filesApi';
import { ComponentProps } from 'react';

const { Avatar } = DataModule;
const { Colors } = ColorsModule;

const avatarColors = {
  [UserRole.CLIENT]: {
    bg: Colors['light-gray'],
    text: '#000',
  },
  [UserRole.EXPERT]: {
    bg: Colors['light-gray'],
    text: '#000',
  },
  [UserRole.ADMIN]: {
    bg: Colors['light-gray'],
    text: '#000',
  },
};

interface UserAvatarProps extends ComponentProps<typeof Avatar> {
  filePath?: string;
  src?: string;
  role?: UserRole;
}

export default function UserAvatar({
  filePath = '',
  role = UserRole.CLIENT,
  src,
  ...props
}: UserAvatarProps) {
  const { data } = useGetFileQuery({ fileName: filePath }, { skip: !filePath || Boolean(src) });

  return (
    <Avatar
      {...props}
      src={src || data?.url}
      icon={role[0].toUpperCase()}
      color={avatarColors[role].bg}
      textColor={avatarColors[role].text}
    />
  );
}
