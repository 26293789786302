import { SuccessStory } from '@raised/domain';
import DefaultFooter from 'components/layout/components/form-modal/default-footer/DefaultFooter';
import FormModal from 'components/layout/components/form-modal/FormModal';
import { RefObject } from 'react';
import CreateSuccessStoryForm from '../../forms/success-story/CreateSuccessStoryForm';

interface CreateSuccessStoryModalProps {
  isVisible: boolean;
  usecaseId?: string;
  onClose: () => void;
  onSubmit: (values: SuccessStory.CreateForm) => void;
  isLoading: boolean;
  defaultValues?: Partial<SuccessStory.Create>;
  formRef: RefObject<HTMLFormElement>;
}

export default function CreateSuccessStoryModal({
  isLoading,
  usecaseId,
  isVisible,
  onSubmit,
  onClose,
  defaultValues,
  formRef,
}: CreateSuccessStoryModalProps) {
  const handleSubmit = () => {
    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };
  const handleClose = () => {
    onClose();
    formRef.current?.dispatchEvent(new Event('reset', { cancelable: true, bubbles: true }));
  };

  return (
    <FormModal
      isVisible={isVisible}
      onClose={handleClose}
      title="Create Stories & Testimonials"
      footer={(
        <DefaultFooter
          isLoading={isLoading}
          onCancel={handleClose}
          onSubmit={handleSubmit}
        />
)}
    >
      {usecaseId && (
        <CreateSuccessStoryForm
          formRef={formRef}
          defaultValues={defaultValues}
          onSubmit={onSubmit}
        />
      )}
    </FormModal>
  );
}
