/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavigationModule, TypographyModule } from '@raised/ui-kit';
import { ReactNode } from 'react';
import styles from './CreateProjectFromTemplateContainer.module.css';

const { Breadcrumb } = NavigationModule;
const { Title } = TypographyModule;

interface CreateProjectFromTemplateContainerProps {
  onBack: () => void;
  children: ReactNode;
}

export default function CreateProjectFromTemplateContainer({
  onBack,
  children,
}: CreateProjectFromTemplateContainerProps) {
  return (
    <div className="form_container">
      <div className={styles.title_block}>
        <Breadcrumb
          items={[
            {
              title: <a onClick={onBack}>Templates</a>,
            },
            {
              title: 'Create Project from Template',
            },
          ]}
          style={{ alignSelf: 'center' }}
        />
        <Title level={1} className={styles.title}>Create Project from Template</Title>
      </div>
      {children}
    </div>
  );
}
