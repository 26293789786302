import { NavigationModule } from '@raised/ui-kit';
import styles from './ExpertsPagination.module.css';

const { Pagination } = NavigationModule;

interface ExpertsPaginationProps {
  page: number;
  perPage: number;
  onPageChange: (p: number) => void;
  onPerPageChange: (perPage: number) => void;
  total: number;
}

export default function ExpertsPagination({
  page,
  perPage,
  onPageChange,
  onPerPageChange,
  total,
}: ExpertsPaginationProps) {
  return (
    <Pagination
      defaultCurrent={page}
      defaultPageSize={perPage}
      onChange={onPageChange}
      onShowSizeChange={(_, pageSize) => onPerPageChange(pageSize)}
      total={total}
      showSizeChanger
      rootClassName={styles.pagination}
    />
  );
}
