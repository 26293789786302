import { Chat, MessageTypes } from '@raised/domain';
import { ReactNode } from 'react';
import ClientInvite from './messages/client-invite/ClientInvite';
import ExpertAccept from './messages/ExpertAccept';
import ProposalSent from './messages/proposal-sent/ProposalSent';
import styles from './TechMessage.module.css';

interface TechMessageProps {
  chat: Chat.Public;
  type: MessageTypes;
}

export default function TechMessage({
  chat,
  type,
}: TechMessageProps) {
  const client = chat.match?.project?.user;
  const organisation = chat.match?.project?.user?.organisation;
  const expert = chat.match?.user;

  if (!expert || !client || !organisation) return null;

  const messages: Record<MessageTypes, ReactNode> = {
    [MessageTypes.CLIENT_INVITE]: <ClientInvite expert={expert} client={client} />,
    [MessageTypes.EXPERT_ACCEPT]: <ExpertAccept expert={expert} client={client} organisation={organisation} />,
    [MessageTypes.PROPOSAL_SENT]: <ProposalSent expert={expert} client={client} />,
    [MessageTypes.CHAT]: null,
  };

  return (
    <div className={styles.container}>
      {messages[type]}
    </div>
  );
}
