import MainLayout from 'components/layout/containers/main-layout/MainLayout';
import { RouteObject } from 'react-router-dom';
import Error500Page from './pages/Error500/Error500Page';

export const ERROR_ROUTES = {
  500: () => '/500',
};

const errorRoutes: Array<RouteObject> = [
  {
    element: <MainLayout />,
    children: [
      {
        path: ERROR_ROUTES[500](),
        element: <Error500Page />,
      },
    ],
  },
];

export default errorRoutes;
