import { FormsModule } from '@raised/ui-kit';
import { Control } from 'react-hook-form';

const { TextArea } = FormsModule;

export const getParttimeInfoConfig = (control: Control, prefix: string) => [
  {
    controller: {
      name: `${prefix}.challenge`,
      control,
      defaultValue: '',
    },
    component: TextArea,
    componentProps: {
      placeholder: 'Challenge',
      rows: 5,
      showCount: true,
      maxLength: 500,
    },
  },
  {
    controller: {
      name: `${prefix}.action`,
      control,
      defaultValue: '',
    },
    component: TextArea,
    componentProps: {
      placeholder: 'Action',
      rows: 5,
      showCount: true,
      maxLength: 500,
    },
  },
  {
    controller: {
      control,
      defaultValue: '',
      name: `${prefix}.result`,
    },
    component: TextArea,
    componentProps: {
      placeholder: 'Result',
      rows: 5,
      showCount: true,
      maxLength: 500,
    },
  },
];
