import { FeedbackModule } from '@raised/ui-kit';
import styles from './LoadingPlaceholder.module.css';

const { Spin } = FeedbackModule;

export default function LoadingPlaceholder() {
  return (
    <div className={styles.container}>
      <Spin />
    </div>
  );
}
