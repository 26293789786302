import { z } from "zod";
import { Template } from "..";
import { baseProjectSchema } from "../project/schemas";

const projectPartSchema = z.object({
  title: z.string().min(1),
  description: z.string().min(1),
})

export const createTemplateSchema: z.ZodType<Template.Create> = z.object({
  challenge: z.string().optional(),
  parts: projectPartSchema.array().optional(),
  title: z.string().min(1),
  userId: z.string().optional(),
}).and(baseProjectSchema.partial());
