/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  isRejected, Middleware, MiddlewareAPI,
} from '@reduxjs/toolkit';
import { ERROR_ROUTES } from 'components/errors/errors-routes';
import router from '../router';

export const errorHandlerMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejected(action)) {
    console.error('API_ERROR', action.type, action?.meta?.arg?.endpointName, action.payload);

    if (action.payload?.status === 500) {
      router.navigate(ERROR_ROUTES[500]());
    }
  }

  return next(action);
};
