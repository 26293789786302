import ViewProposalModal from 'components/proposal/components/ViewProposalModal';
import useSetProposalAsPaid from 'components/payments/hooks/useSetProposalAsPaid';
import { useGetAcceptedProposalsQuery } from 'components/proposal/storage/proposalApi';
import { USER_ROUTES } from 'components/user/user-routes';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import AdminCreatePaymentsComponent from './AdminCreatePaymentsComponent';
import CreatePaymentsTable from './create-payments-table/CreatePaymentsTable';

export default function AdminCreatePaymentsPage() {
  const [viewProposalId, setViewProposalId] = useState('');
  const {
    setAsPaid,
    isLoading: isPaymentLoading,
  } = useSetProposalAsPaid();
  const {
    data: proposals,
    isLoading: isProposalsLoading,
  } = useGetAcceptedProposalsQuery();

  const getExpertLink = (expertId: string) => USER_ROUTES.USER_PROFILE(expertId);
  const onPayClick = (proposalId: string) => {
    setAsPaid({ proposalId });
  };
  const onCloseModal = () => setViewProposalId('');
  const onViewProposalClick = (proposalId: string) => setViewProposalId(proposalId);

  return (
    <AdminCreatePaymentsComponent>
      <Helmet>
        <title>Raised - Create payment</title>
      </Helmet>
      <CreatePaymentsTable
        proposals={proposals || []}
        onPayClick={onPayClick}
        getExpertLink={getExpertLink}
        isLoading={isProposalsLoading || isPaymentLoading}
        onViewProposalClick={onViewProposalClick}
      />
      <ViewProposalModal
        isVisible={!!viewProposalId}
        proposalId={viewProposalId}
        onClose={onCloseModal}
      />
    </AdminCreatePaymentsComponent>
  );
}
