import { TypographyModule } from '@raised/ui-kit';
import { IconAddressBook } from '@tabler/icons-react';
import styles from './LastThingMessage.module.css';

const { Text } = TypographyModule;

export default function LastThingMessage() {
  return (
    <div className={styles.last_message}>
      <IconAddressBook size={32} stroke={1.5} />
      <div className={styles.content}>
        <Text className={styles.title}>One last thing</Text>
        <Text>Please fill in and check your profile information</Text>
      </div>
    </div>
  );
}
