import { Project } from '@raised/domain';
import { ColorsModule, DataModule, TypographyModule } from '@raised/ui-kit';
import RecommendedProject from './recommended-project/RecommendedProject';
import styles from './RecommendedProjects.module.css';

const { Title } = TypographyModule;
const { Badge } = DataModule;
const { Colors } = ColorsModule;

interface RecommendedProjectsProps {
  projects: Project.Public[];
  getProjectLink: (projectID: string) => string;
}

export default function RecommendedProjects({
  projects,
  getProjectLink,
}: RecommendedProjectsProps) {
  return (
    <div className={styles.recommended_projects}>
      <div className={styles.title_block}>
        <Title level={4}>Recommendations for You</Title>
        <Badge count={projects.length} color={Colors['light-gray']} className={styles.badge} />
      </div>
      <div className={styles.projects}>
        {projects.map((project) => (
          <RecommendedProject projectLink={getProjectLink(project.id)} key={project.id} title={project.title} description={project.description} />
        ))}
      </div>
    </div>
  );
}
